import React from 'react';
import { Menu } from 'semantic-ui-react';

const NavMenus = (props) => {
  const {
    content,
    active_index,
    strat_mode,
  } = props;
  const nav_menus = content.map((exam, i) => {
    return (
      <React.Fragment key={`menuitem${i}`}>
        <Menu.Item className="font-small" active={active_index===i && !strat_mode}>{`${i===0?1:i*2+1}) ${exam.code}T`}</Menu.Item>
        <Menu.Item className="font-small" active={active_index===i && strat_mode}>{`${(i+1)*2}) ${exam.code}L`}</Menu.Item>
      </React.Fragment>
    );
  });
  return nav_menus;
}

export default NavMenus;