import * as actions from '../actions/types/answerInstance';

const defaultState = {
};

export default function reducer(state=defaultState, action) {
  switch(action.type) {
    case actions.UPDATE_ANSWER_INSTANCE: {
      return {...action.payload};
    }
    case actions.RESET_ANSWER_INSTANCE: {
      return {...defaultState};
    }
    default: return {...state};
  }
};