import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import * as listenExamActions from "../../../../../../actions/app/listenExam";
import { Grid } from "semantic-ui-react";
import Header from "./Header";
import Sidebar from "./Sidebar";
import ExamContainer from "../common/ExamContainer";
import * as mockTestActions from "../../../../../../actions/app/mockTest";

function onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}

function pad(string) {
  return ("0" + string).slice(-2);
}

function format(seconds) {
  const date = new Date(seconds * 1000);
  const hh = date.getUTCHours();
  const mm = date.getUTCMinutes();
  const ss = pad(date.getUTCSeconds());
  if (hh) {
    return `Time Remaining: ${hh}h ${pad(mm)}m ${ss}s`;
  }
  return `Time Remaining: ${mm}m ${ss}s`;
}

class ListenExam extends React.Component {
  componentDidMount() {
    const { answers, countdown_started } = this.props;
    this.props.dispatch(mockTestActions.updateConfirmModal(false));
    if (answers.length === 0) {
      this.setBlankAnswers();
    }
    if (countdown_started) {
      this.startTimer();
    }
  }
  setBlankAnswers() {
    const { content, dispatch } = this.props;
    const final_blank_answers = [];
    const final_blank_answers2 = [];
    for (let i = 0; i < content.length; i++) {
      const answers = [];
      const answers2 = [];
      // eslint-disable-next-line
      content[i].exam.exam.exams.map((exam) => {
        answers.push(Array(exam.correct_answer.length).join(".").split("."));
        answers2.push(Array(exam.correct_answer.length).join(".").split("."));
      });
      final_blank_answers.push(answers);
      final_blank_answers2.push(answers2);
    }
    dispatch(listenExamActions.updateAnswer(final_blank_answers));
    dispatch(listenExamActions.updateAnswerStrat(final_blank_answers2));
  }
  componentWillUnmount() {}
  startTimer() {
    this.timer = setInterval(this.tick.bind(this), 1000);
  }
  tick() {
    const { timer, dispatch } = this.props;
    const newTimer = timer - 1;
    if (newTimer >= 0) {
      dispatch(listenExamActions.updateTimer(newTimer));
    } else {
      this.clearTimer();
      this.saveAnswer();
    }
  }
  clearTimer() {
    clearInterval(this.timer);
    this.timer = null;
  }
  handleOnChange = (i, j, e) => {
    const { answers, current_index, dispatch } = this.props;
    let newAnswers = answers.slice();
    newAnswers[current_index][i][j] = e.target.value;
    dispatch(listenExamActions.updateAnswer(newAnswers));
  };
  handleRadioOnChange = (i, j, answer, e) => {
    const { answers, current_index, dispatch } = this.props;
    let newAnswers = answers.slice();
    newAnswers[current_index][i][j] = answer;
    dispatch(listenExamActions.updateAnswer(newAnswers));
  };
  handleCheckboxOnChange = (i, j, answer, checked) => {
    const { answers, dispatch, content, current_index } = this.props;
    // Get max answer size
    const max_answers =
      content[current_index].exam.exam.exams[i].correct_answer[j].length;
    let newAnswers = answers.slice();
    let newArr = [];
    if (newAnswers[current_index][i][j].length > 0) {
      newArr = newAnswers[current_index][i][j].split(",");
    }
    if (newArr.indexOf(answer.toString()) === -1) {
      // If current is equal or greater than the size of max answer, do not proceed
      if (newArr.length >= max_answers) {
        return;
      }
      newArr.push(answer);
    } else {
      newArr.splice(newArr.indexOf(answer.toString()), 1);
    }
    newArr = newArr.filter(onlyUnique).join(",");
    newAnswers[current_index][i][j] = newArr;
    dispatch(listenExamActions.updateAnswer(newAnswers));
  };
  toggleTranscript = () => {
    this.setState({ showTranscript: !this.state.showTranscript });
  };
  saveAnswer = () => {
    const elements = document.getElementsByClassName("exam-divs");
    for (let i = 0; i < elements.length; i++) {
      elements[i].scrollTo(0, 0);
    }
  };
  nextPage = () => {
    this.props.dispatch(mockTestActions.updateConfirmModal(true));
  };
  onAccordionClick = (parentIndex, index) => {
    if (
      parentIndex === this.state.accordionIndex.parentIndex &&
      index === this.state.accordionIndex.index
    ) {
      this.setState({
        accordionIndex: { parentIndex: parentIndex, index: -1 },
      });
    } else {
      this.setState({
        accordionIndex: { parentIndex: parentIndex, index: index },
      });
    }
  };
  handleDetailedFlowChartChange = (i, j, e) => {
    const { answers, current_index, dispatch } = this.props;
    let newAnswers = answers.slice();
    newAnswers[current_index][i][j] = e.target.value;
    dispatch(listenExamActions.updateAnswer(newAnswers));
  };
  resetAccordion() {
    this.setState({ accordionIndex: { parentIndex: -1, index: -1 } });
  }
  state = {
    answers: [],
    handleOnChange: this.handleOnChange.bind(this),
    handleRadioOnChange: this.handleRadioOnChange.bind(this),
    handleCheckboxOnChange: this.handleCheckboxOnChange.bind(this),
    showTranscript: false,
    current_index: 0,
    accordionIndex: { parentIndex: -1, index: -1 },
    onAccordionClick: this.onAccordionClick.bind(this),
    handleDetailedFlowChartChange:
      this.handleDetailedFlowChartChange.bind(this),
  };
  render() {
    const {
      openSidebar,
      timer,
      strat_mode,
      content,
      changeIndex,
      current_index,
      active_index,
    } = this.props;

    const { showTranscript } = this.state;

    let show = "hide";
    if (openSidebar) {
      show = "show";
    }
    const section_btns = content.map((exam, i) => {
      return (
        <button
          type="button"
          onClick={changeIndex.bind(this, i)}
          disabled={i === current_index}
        >
          {exam.name}
        </button>
      );
    });
    return (
      <React.Fragment>
        <Header
          {...this.state}
          {...this.props}
          show={show}
          timer={format(timer)}
        />
        <Sidebar {...this.state} {...this.props} show={show} />
        <div className="main-container">
          <div className="content-new-ui header-new-ui">
            <p
              className="text-center no-margin-top"
              style={{
                fontWeight: "bold",
                background:
                  "linear-gradient(180deg, #1488CC 0%, #3398d3 21%, #6AADE4 100%)",
                color: "white",
                marginTop: "0px!important",
              }}
            >
              You are now in {content[active_index].name}
            </p>
            {showTranscript && strat_mode ? (
              <div id="content-split" className="content-split">
                <div
                  id="content-left"
                  className="content-left"
                  style={{
                    overflowY: "auto",
                    margin: "2rem 1rem 1rem 1rem",
                    paddingBottom: "2rem",
                  }}
                >
                  <ExamContainer {...this.state} {...this.props} />
                </div>
                <div
                  id="content-right"
                  className="content-right"
                  style={{
                    overflowY: "auto",
                    margin: "2rem 1rem 1rem 1rem",
                    paddingBottom: "2rem",
                  }}
                >
                  <h3 className="text-center">Transcript</h3>
                  <div
                    className="instruction"
                    dangerouslySetInnerHTML={{
                      __html: content[current_index].transcript,
                    }}
                  />
                </div>
              </div>
            ) : (
              <div className="listen-content-split">
                <div
                  className="blue-scrollbar"
                  style={{
                    overflowY: "auto",
                    margin: "2rem .5rem .5rem .5rem",
                    paddingBottom: "2rem",
                    maxWidth: "700px",
                    textAlign: "left",
                    display: "inline-block",
                  }}
                >
                  <ExamContainer
                    current_index={current_index}
                    {...this.state}
                    {...this.props}
                  />
                </div>
              </div>
            )}
            <div className="content-button">
              <Grid columns={"equal"}>
                <Grid.Column>{section_btns}</Grid.Column>
                <Grid.Column className="text-center">
                  {/* {strat_mode ? (
                    <button type="button" onClick={this.toggleTranscript}>
                      {showTranscript ? "Hide Transcript" : "Show Transcript"}
                    </button>
                  ) : null} */}
                </Grid.Column>
                <Grid.Column className="text-right">
                  <button type="button" onClick={this.nextPage}>
                    Finish Early and Proceed to Reading Test
                  </button>
                </Grid.Column>
              </Grid>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    content: state.listenExam.content,
    timer: state.listenExam.timer,
    strat_mode: state.listenExam.strat_mode,
    answers: state.listenExam.answers,
    answers_strat: state.listenExam.answers_strat,
    audio_position: state.listenExam.position,
    countdown_started: state.listenExam.countdown_started,
    answerInstance: state.answerInstance,
    is_staff: state.auth.is_staff,
    openSidebar: state.app.openSidebar,
    current_page: state.mockTest.currentActivePage,
    current_index: state.listenExam.current_index,
    active_index: state.listenExam.active_index,
  };
};

export default withRouter(connect(mapStateToProps)(ListenExam));
