import * as actions from '../actions/types/app';

const defaultState = {
  start_exam: false,
  openSidebar: false,
};

export default function reducer(state=defaultState, action) {
  switch(action.type) {
    case actions.UPDATE_START_EXAM: {
      return {...state, start_exam: action.payload};
    }
    case actions.UPDATE_OPEN_SIDEBAR: {
      return {...state, openSidebar: action.payload};
    }
    default: return {...state};
  }
};