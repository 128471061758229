import React from 'react';
import { Grid, Container, Form, Select, Breadcrumb, Button, Segment } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import * as Parts from '../dashboard/parts';

const subtype_options = [
	{ key: "aaaa3", value: "object", text: "Question Object" },
	{ key: "aaaa1", value: "number", text: "Number" },
	{ key: "aaaa2", value: "string", text: "Text" },
]

class JsonBuilder extends React.Component {
	state = {
		jsontype: "object",
		content: [this.createObject()]
	}
	handleTypeChange = (e, component) => {
		const jsontype = component.value;
		this.setState({[component.name]:jsontype});
		switch(jsontype){
			case "object":
				this.setState({content:[this.createObject()]});
				break;
			case "string":
				this.setState({content:this.createString()});
				break;
			default:
				this.setState({content:this.createNumber()});
				break;
		}
	}
	createObject(){
		return {
			text:"",
			choices:["",""]
		}
	}
	createString(){
		return [""]
	}
	createNumber(){
		return [1]
	}
	addContent = () =>{
		const currentContent = this.state.content.slice();
		switch(this.state.jsontype){
			case "object":
				currentContent.push(this.createObject())
				break;
			case "string":
				currentContent.push("");
				break;
			default:
				currentContent.push(1);
				break;
		}
		this.setState({content:currentContent});
	}
	removeContent = (i) => {
		const currentContent = this.state.content.slice();
		currentContent.splice(i, 1);
		this.setState({content:currentContent});
	}
	handleChange = (i, e) => {
		const currentContent = this.state.content.slice();
		if (e.target.name === "number"){
			currentContent[i] = parseInt(e.target.value, 10);
		} else if (e.target.name === "string") {
			currentContent[i] = e.target.value;
		} else {
			currentContent[i].text = e.target.value;
		}
		this.setState({content:currentContent});
	}
	handleChoiceChange(i, j, e){
		const currentContent = this.state.content.slice();
		currentContent[i].choices[j] = e.target.value;
		this.setState({content:currentContent});
	}
	addChoice = (i) => {
		const currentContent = this.state.content.slice();
		currentContent[i].choices.push("");
		this.setState({content:currentContent});
	}
	removeChoice = (i, j) => {
		const currentContent = this.state.content.slice();
		currentContent[i].choices.splice(j, 1);
		this.setState({content:currentContent});
	}
	render(){
		const {
			jsontype,
			content
		} = this.state

		let forms = [];

		if (jsontype === "number"){
			forms = content.map((obj, i) => {
				return (
					<Form.Field key={`jsoneditor${i}`} inline style={{marginTop:"1rem", marginLeft:"2rem"}}>
						<input
							required
							type="number"
							size={5}
							width={4}
							name="number"
							value={content[i]}
							placeholder={`Number ${i+1}`}
							onChange={this.handleChange.bind(this, i)} />
							{
								i === content.length - 1?
									<Button icon='plus' color='green' size='tiny' onClick={this.addContent} />
									:
									null
							}
							{
								content.length > 1?
									<Button icon='minus' color='red' size='tiny' onClick={this.removeContent.bind(this, i)} />
									:
									null
							}
					</Form.Field>
				)
			})
		} else if (jsontype === "string"){
			forms = content.map((obj, i) => {
				return (
					<Form.Field key={`jsoneditor${i}`} inline style={{marginTop:"1rem", marginLeft:"2rem"}}>
						<input
							required
							type="text"
							size={40}
							name="string"
							value={content[i]}
							placeholder={`String ${i+1}`}
							style={{width:"80%"}}
							onChange={this.handleChange.bind(this, i)} />
							{
								i === content.length - 1?
									<Button icon='plus' color='green' size='tiny' onClick={this.addContent} />
									:
									null
							}
							{
								content.length > 1?
									<Button icon='minus' color='red' size='tiny' onClick={this.removeContent.bind(this, i)} />
									:
									null
							}
					</Form.Field>
				)
			})
		} else if (jsontype === "object"){
			forms = content.map((obj, i) => {
				return (
					<React.Fragment key={`jsoneditor${i}`}>
						<Form.Field inline style={{marginTop:"1rem", marginLeft:"2rem"}}>
							<input
								required
								type="text"
								size={40}
								name="object"
								value={content[i].text}
								placeholder={`Question ${i+1}`}
								style={{width:"80%"}}
								onChange={this.handleChange.bind(this, i)} />
								{
									i === content.length - 1?
										<Button icon='plus' color='green' size='tiny' onClick={this.addContent} />
										:
										null
								}
								{
									content.length > 1?
										<Button icon='minus' color='red' size='tiny' onClick={this.removeContent.bind(this, i)} />
										:
										null
								}
						</Form.Field>
						{
							obj.choices.map((obj2, j) => {
								return (
									<Form.Field key={`asdasdasd${j}`} inline style={{marginTop:"1rem", marginLeft:"4rem"}}>
										<input
											required
											type="text"
											size={40}
											name="object"
											value={obj2}
											placeholder={`Choice ${j+1}`}
											style={{width:"78.5%"}}
											onChange={this.handleChoiceChange.bind(this, i, j)} />
											{
												j === obj.choices.length - 1?
													<Button icon='plus' color='green' size='tiny' onClick={this.addChoice.bind(this, i)} />
													:
													null
											}
											{
												obj.choices.length > 1?
													<Button icon='minus' color='red' size='tiny' onClick={this.removeChoice.bind(this, i, j)} />
													:
													null
											}
									</Form.Field>
								)
							})
						}
					</React.Fragment>
				)
			})
		}

		return (
			<React.Fragment>
				<Grid doubling stackable padded className="padding-top-1rem padding-bottom-2rem">
					<Parts.Header/>
					<Grid.Row className='footer-margin' columns={2}>
						<Grid.Column>
							<Container>
								<Segment padded>
									<Breadcrumb style={{paddingBottom:"2rem"}}>
										<Breadcrumb.Section><Link to='/'>Home</Link></Breadcrumb.Section>
										<Breadcrumb.Divider icon='right angle'/>
										<Breadcrumb.Section>Json Editor</Breadcrumb.Section>
									</Breadcrumb>
									<Form onSubmit={this.handleSubmit}>
										<Form.Field inline>
											<h4 style={{marginBottom:"10px"}}>Exam Part </h4>
											<Select
												options={subtype_options}
												required
												name="jsontype"
												placeholder='Select Json Type'
												onChange={this.handleTypeChange}
												value={jsontype}
											/>
										</Form.Field>
										{forms}
									</Form>
								</Segment>
							</Container>
						</Grid.Column>
						<Grid.Column>
							<Container textAlign='center'>
								<Segment padded>
									<h4>JSON Result (Copy this):</h4>
									<br/>
									{JSON.stringify(content)}
								</Segment>
							</Container>
						</Grid.Column>
					</Grid.Row>
					<Parts.Footer/>
				</Grid>
			</React.Fragment>
		);
	}
}

export default JsonBuilder;