import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import * as appActions from "../../../../../actions/app/app";
import { Grid } from "semantic-ui-react";
import HintModal from "./HintModal";
import Player from "../speak/Player";
import * as EXAMS from "../../../../../constants/exam_subtypes";

class SpeakingContainer extends React.Component {
  toggleNav() {
    const { dispatch, openSidebar } = this.props;
    dispatch(appActions.updateOpenSidebar(!openSidebar));
  }
  closeNav() {
    this.props.dispatch(appActions.updateOpenSidebar(false));
  }
  state = {
    active_index: 0,
    openHintModal: false,
    toggleNav: this.toggleNav.bind(this),
    closeNav: this.closeNav.bind(this),
    current_subtype: 0,
  };
  placeholder() {}
  openHintModal = () => {
    this.setState({ openHintModal: true });
  };
  closeHintModal = () => {
    this.setState({ openHintModal: false });
  };
  goToDashboard = () => {
    this.props.history.push("/");
  };
  goToDashboard = () => {
    this.props.history.push("/");
  };
  updateActiveIndex = (i, current_subtype) => {
    this.setState({ active_index: i, current_subtype: current_subtype });
    this.closeNav();
  };
  render() {
    const { content, answers, active_index, current_subtype } = this.props;
    const { openHintModal } = this.state;
    let model_answer = "";
    if (content[active_index].exam.part === EXAMS.SPEAK_PART_2_VALUE) {
      model_answer = content[active_index].exam.model_answer;
    } else {
      switch (current_subtype) {
        case 0:
          model_answer = content[active_index].exam.model_answer;
          break;
        case 1:
          model_answer = content[active_index].exam.model_answer2;
          break;
        case 2:
          model_answer = content[active_index].exam.model_answer3;
          break;
        case 3:
          model_answer = content[active_index].exam.model_answer4;
          break;
        default:
          break;
      }
    }
    return (
      <React.Fragment>
        <HintModal
          header="Vocab"
          hint={content[active_index].exam.vocab}
          open={openHintModal}
          onClose={this.closeHintModal}
        />
        <div className="main-container">
          <div className="content-new-ui header-new-ui">
            <div id="content-split" className="content-split">
              {content[active_index].exam.part === EXAMS.SPEAK_PART_2_VALUE ? (
                <div
                  id="content-left"
                  className="content-left"
                  style={{ overflow: "auto", margin: "2rem 1rem 1rem 1rem" }}
                >
                  <div
                    className="paper-box text-center"
                    style={{
                      marginTop: "3px",
                      marginBottom: "0rem",
                      paddingBottom: "0px",
                      overflowY: "hidden",
                      fontWeight: "normal",
                    }}
                  >
                    <h3 style={{ marginTop: "1rem" }}>Question</h3>
                    <div
                      style={{ margin: "0rem 1.5rem 1.5rem 1.5rem" }}
                      className="instruction text-left"
                      dangerouslySetInnerHTML={{
                        __html: content[active_index].exam.question,
                      }}
                    />
                  </div>
                  <Player title="Audio Answer" url={answers[active_index]} />
                </div>
              ) : (
                <div
                  id="content-left"
                  className="content-left"
                  style={{ overflow: "auto", margin: "2rem 1rem 1rem 1rem" }}
                >
                  <Player
                    title="Audio Question"
                    url={content[active_index].exam.audio[current_subtype]}
                  />
                  <Player
                    title="Audio Answer"
                    url={answers[active_index][current_subtype]}
                  />
                </div>
              )}
              <div
                id="content-right"
                className="content-right"
                style={{ overflow: "auto", margin: "2rem 1rem 1rem 1rem" }}
              >
                <h3 className="text-center">Model Answer</h3>
                <div
                  className="instruction text-left"
                  dangerouslySetInnerHTML={{ __html: model_answer }}
                ></div>
              </div>
            </div>
            {/* <div className="content-button">
							<Grid columns={'equal'}>
								<Grid.Column>
								</Grid.Column>
                <Grid.Column className="text-center">
                  <button
                    type="button"
                    onClick={this.openHintModal}
                  >
                    Vocab
                  </button>
								</Grid.Column>
                <Grid.Column>
								</Grid.Column>
							</Grid>
						</div> */}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    marketing: state.marketing,
    openSidebar: state.app.openSidebar,
  };
};

export default withRouter(connect(mapStateToProps)(SpeakingContainer));
