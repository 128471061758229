import React from "react";
import { connect } from "react-redux";
import MicRecorder from "mic-recorder-to-mp3";
import * as speakExamActions from "../../../../actions/app/speakExam";
import * as speakPart1Actions from "../../../../actions/app/speakPart1";
import * as speakPart2Actions from "../../../../actions/app/speakPart2";
import * as speakPart3Actions from "../../../../actions/app/speakPart3";
import * as speakReviewActions from "../../../../actions/app/speakReview";
import * as apiActions from "../../../../actions/app/api";
import * as api from "../../../../actions/api/requests";
import { uploadFile } from "../../../../actions/api/storage";
import EXAM from "../../../../constants/examTypes";
import * as EXAMS from "../../../../constants/exam_subtypes";
import SpeakPart1 from "./SpeakPart1";
import SpeakPart2 from "./SpeakPart2";
import SpeakPart3 from "./SpeakPart3";

const recorder = new MicRecorder({
  bitRate: 128,
});

class SpeakingContainer extends React.Component {
  componentDidMount() {
    const { dispatch, answers } = this.props;
    if (answers.length === 0) {
      this.setBlankAnswers();
    }
    dispatch(speakPart1Actions.reset());
    dispatch(speakPart2Actions.reset());
    dispatch(speakPart3Actions.reset());
  }
  setBlankAnswers() {
    const { content, dispatch } = this.props;
    const final_blank_answers = [];
    const final_blank_answers2 = [];
    const notes = [];
    for (let i = 0; i < content.length; i++) {
      if (content[i].part === EXAMS.SPEAK_PART_2_VALUE) {
        final_blank_answers.push("");
        final_blank_answers2.push("");
      } else {
        final_blank_answers.push(Array(4).join(".").split("."));
        final_blank_answers2.push(Array(4).join(".").split("."));
        notes.push("");
      }
    }
    dispatch(speakExamActions.updateAnswer(final_blank_answers));
    dispatch(speakExamActions.updateAnswerStrat(final_blank_answers2));
    dispatch(speakExamActions.updateNotes1(notes.slice()));
    dispatch(speakExamActions.updateNotes2(notes.slice()));
  }
  constructor(props) {
    super(props);
    this.state = {
      startRecording: this.startRecording.bind(this),
      stopRecording: this.stopRecording.bind(this),
      uploadRecording: this.uploadRecording.bind(this),
      updateAnswer: this.updateAnswer.bind(this),
      stopRecordingStratMode: this.stopRecordingStratMode.bind(this),
    };
  }
  startRecording = () => {
    recorder
      .start()
      .then(() => {})
      .catch((e) => {
        alert("Unable to start recording: " + e);
      });
  };
  stopRecording = () => {
    const { strat_mode, dispatch, content, active_index } = this.props;
    recorder
      .stop()
      .getMp3()
      .then(([buffer, blob]) => {
        const file = new File(buffer, "recording.mp3", {
          type: blob.type,
          lastModified: Date.now(),
        });
        if (!strat_mode) {
          this.uploadRecording(file);
        } else {
          if (content[active_index].part === EXAMS.SPEAK_PART_1_VALUE) {
            dispatch(speakPart1Actions.updateFile(file));
            dispatch(speakPart1Actions.updateUrl(URL.createObjectURL(file)));
          } else if (content[active_index].part === EXAMS.SPEAK_PART_2_VALUE) {
            dispatch(speakPart2Actions.updateFile(file));
            dispatch(speakPart2Actions.updateUrl(URL.createObjectURL(file)));
          } else if (content[active_index].part === EXAMS.SPEAK_PART_3_VALUE) {
            dispatch(speakPart3Actions.updateFile(file));
            dispatch(speakPart3Actions.updateUrl(URL.createObjectURL(file)));
          }
        }
      })
      .catch((e) => {
        alert("Something is wrong with the microphone: " + e);
      });
  };
  stopRecordingStratMode = () => {
    recorder
      .stop()
      .getMp3()
      .then(([buffer, blob]) => {
        const file = new File(buffer, "recording.mp3", {
          type: blob.type,
          lastModified: Date.now(),
        });
        this.uploadRecording(file);
      })
      .catch((e) => {
        alert("Something is wrong with the microphone: " + e);
      });
  };
  uploadRecording = (file) => {
    const { username } = this.props;
    const key =
      "student_answers/speaking/" + username + "/" + Date.now() + ".mp3";
    uploadFile(file, key)
      .then((location) => {
        this.updateAnswer(location);
      })
      .catch((error) => {
        console.log(error);
        return alert(
          "There was an error uploading your audio: " +
            error +
            "\n\n Please refresh the page multiple times until the audio plays again."
        );
      });
  };

  reset = () => {
    const { dispatch } = this.props;
    dispatch(speakPart1Actions.reset());
    dispatch(speakPart2Actions.reset());
    dispatch(speakPart3Actions.reset());
  };

  updateAnswer(location) {
    const {
      answers,
      answers_strat,
      active_index,
      current_subtype,
      dispatch,
      strat_mode,
      content,
      answerInstance,
      is_staff,
      history,
      notes_1,
      notes_2,
      notes_3,
    } = this.props;
    if (strat_mode) {
      const newAnswers = answers_strat.slice();

      if (content[active_index].part === EXAMS.SPEAK_PART_2_VALUE) {
        newAnswers[active_index] = location;
      } else {
        newAnswers[active_index][current_subtype] = location;
      }

      dispatch(speakExamActions.updateAnswerStrat(newAnswers));
      dispatch(apiActions.updateApiSending(false));

      // Save to speakExamReview reducer
      const data = {
        name: answerInstance.name,
        answers: answers,
        answers_strat: newAnswers,
        content: content,
        id: answerInstance.id,
        finished_at: new Date(),
        test_type: EXAM.SPEAKING,
        notes_1,
        notes_2,
        notes_3,
      };
      dispatch(speakReviewActions.updateContent(data));

      // Check if Speaking Part 2
      if (content[active_index].part === EXAMS.SPEAK_PART_2_VALUE) {
        // If last element, proceed with review page
        if (active_index + 1 === content.length) {
          if (is_staff) {
            history.push("/review/speak");
            return;
          } else {
            // If student submit to server
            dispatch(apiActions.updateApiSending(true));
            dispatch(
              api.updateAnswerInstance({ ...data }, history, "/review/speak")
            );
            return;
          }
        }
        dispatch(speakExamActions.updateActiveIndex(active_index + 1));
        return;
      }

      // Check if Speaking Part 3
      if (content[active_index].part === EXAMS.SPEAK_PART_3_VALUE) {
        // If final item in the test
        if (active_index + 1 === content.length) {
          // If 4th audio is blank or
          // last item in the subtype,
          // push to review page (or also send to server if student)
          if (
            (current_subtype === 2 &&
              content[active_index].audio[3].trim() === "") ||
            current_subtype === 3
          ) {
            if (is_staff) {
              history.push("/review/speak");
              return;
            } else {
              dispatch(apiActions.updateApiSending(true));
              dispatch(
                api.updateAnswerInstance({ ...data }, history, "/review/speak")
              );
              return;
            }
          } else {
            // If not on the last subtype, update the current subtype
            dispatch(speakExamActions.updateSubtype(current_subtype + 1));
          }
          // If not on the last item, update the current index
        } else {
          if (
            (current_subtype === 2 &&
              content[active_index].audio[3].trim() === "") ||
            current_subtype === 3
          ) {
            dispatch(speakExamActions.updateSubtype(0));
            dispatch(speakExamActions.updateActiveIndex(active_index + 1));
          } else {
            dispatch(speakExamActions.updateSubtype(current_subtype + 1));
          }
        }
      }

      // If final item in the test
      if (active_index + 1 === content.length) {
        // If last item in the subtype, push to review page (or also send to server if student)
        if (current_subtype === 3) {
          if (is_staff) {
            history.push("/review/speak");
            return;
          } else {
            dispatch(apiActions.updateApiSending(true));
            dispatch(
              api.updateAnswerInstance({ ...data }, history, "/review/speak")
            );
            return;
          }
        } else {
          // If not on the last subtype, update the current subtype
          dispatch(speakExamActions.updateSubtype(current_subtype + 1));
        }
        // If not on the last item, update the current index
      } else {
        if (current_subtype === 3) {
          dispatch(speakExamActions.updateSubtype(0));
          dispatch(speakExamActions.updateActiveIndex(active_index + 1));
        } else {
          dispatch(speakExamActions.updateSubtype(current_subtype + 1));
        }
      }
    } else {
      const newAnswers = answers.slice();
      if (content[active_index].part === EXAMS.SPEAK_PART_2_VALUE) {
        newAnswers[active_index] = location;
      } else {
        newAnswers[active_index][current_subtype] = location;
      }
      dispatch(speakExamActions.updateAnswer(newAnswers));
      dispatch(apiActions.updateApiSending(false));

      // Check if Speaking Part 2
      // If part 2, check if its the end of the list.
      // If not, increase the active_index by +1.
      // If it is, change strat_mode to true
      if (content[active_index].part === EXAMS.SPEAK_PART_2_VALUE) {
        if (active_index + 1 === content.length) {
          dispatch(speakExamActions.updateActiveIndex(0));
          dispatch(speakExamActions.updateSubtype(0));
          dispatch(speakExamActions.updateStratMode(true));
        } else {
          this.reset();
          dispatch(speakExamActions.updateActiveIndex(active_index + 1));
        }
        return;
      }

      // Check if Speaking Part 3
      // If part 3, check if its the end of the list.
      // If not, increase the active_index by +1.
      // If it is, change strat_mode to true
      if (content[active_index].part === EXAMS.SPEAK_PART_3_VALUE) {
        if (active_index + 1 === content.length) {
          if (
            (current_subtype === 2 &&
              content[active_index].audio[3].trim() === "") ||
            current_subtype === 3
          ) {
            dispatch(speakExamActions.updateSubtype(0));
            dispatch(speakExamActions.updateActiveIndex(0));
            dispatch(speakExamActions.updateStratMode(true));
          } else {
            this.reset();
            dispatch(speakExamActions.updateSubtype(current_subtype + 1));
          }
        } else {
          this.reset();
          if (
            (current_subtype === 2 &&
              content[active_index].audio[3].trim() === "") ||
            current_subtype === 3
          ) {
            dispatch(speakExamActions.updateSubtype(0));
            dispatch(speakExamActions.updateActiveIndex(active_index + 1));
          } else {
            dispatch(speakExamActions.updateSubtype(current_subtype + 1));
          }
        }
        return;
      }

      // For Speaking Part 1
      // Check if its the end of the list.
      // If it is and is the last subtype, change strat_mode to true
      // If not the last subtype, increase subtype to +1
      // If it is not the end of the list, check subtype.
      // If it is the last subtype,
      if (active_index + 1 === content.length) {
        if (current_subtype === 3) {
          dispatch(speakExamActions.updateActiveIndex(0));
          dispatch(speakExamActions.updateSubtype(0));
          dispatch(speakExamActions.updateStratMode(true));
        } else {
          this.reset();
          dispatch(speakExamActions.updateSubtype(current_subtype + 1));
        }
      } else {
        if (current_subtype === 3) {
          dispatch(speakExamActions.updateSubtype(0));
          dispatch(speakExamActions.updateActiveIndex(active_index + 1));
        } else {
          dispatch(speakExamActions.updateSubtype(current_subtype + 1));
        }
        this.reset();
        return;
      }
    }
  }

  render() {
    const { content, active_index } = this.props;

    if (content[active_index].part === EXAMS.SPEAK_PART_1_VALUE) {
      return <SpeakPart1 {...this.state} />;
    }
    if (content[active_index].part === EXAMS.SPEAK_PART_2_VALUE) {
      return <SpeakPart2 {...this.state} />;
    }
    if (content[active_index].part === EXAMS.SPEAK_PART_3_VALUE) {
      return <SpeakPart3 {...this.state} />;
    }
    return <div></div>;
  }
}

const mapStateToProps = (state) => {
  return {
    content: state.speakExam.content,
    active_index: state.speakExam.active_index,
    answers: state.speakExam.answers,
    answers_strat: state.speakExam.answers_strat,
    current_subtype: state.speakExam.current_subtype,
    username: state.auth.username,
    answerInstance: state.answerInstance,
    is_staff: state.auth.is_staff,
    strat_mode: state.speakExam.strat_mode,
    notes_1: state.speakExam.notes_1,
    notes_2: state.speakExam.notes_2,
    notes_3: state.speakExam.notes_3,
  };
};

export default connect(mapStateToProps)(SpeakingContainer);
