import React from 'react';
import { NavLink, withRouter } from 'react-router-dom';

/* eslint-disable */
const TopNav = (props) => {
  return (
    <div className="top-nav">
      <div className="top-nav_center">
        <NavLink to="/dashboard" className="active">
          <img alt="logo" src="https://ieltsapp.s3-ap-southeast-1.amazonaws.com/resources/new-ui/logo.png" className="logo_center"/>
        </NavLink>
      </div>
      <div className="top-nav_left">
        <a onClick={props.openMainNav.bind(this)}><i className="fa fa-bars"></i></a>
        <p>IELTS University</p>
      </div>
      <div className="top-nav_right">
        <NavLink to="/logout"><i className="fa fa-power-off"></i></NavLink>
      </div>
    </div>
  )
}

export default withRouter(TopNav);