import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import * as appActions from "../../../../../actions/app/app";
import { Grid } from "semantic-ui-react";
import HintModal from "./HintModal";
import * as EXAMS from "../../../../../constants/exams";
import _ from "lodash";
import ReadingContent from "./ReadingContent";
import ExamContainer from "./ExamContainer";

class ReadingContainer extends React.Component {
  toggleNav() {
    const { dispatch, openSidebar } = this.props;
    dispatch(appActions.updateOpenSidebar(!openSidebar));
  }
  closeNav() {
    this.props.dispatch(appActions.updateOpenSidebar(false));
  }
  placeholder() {}
  updateActiveIndex = (i) => {
    this.closeNav();
    this.setState({ active_index: i });
  };
  onAccordionClick = (parentIndex, index) => {
    if (
      parentIndex === this.state.accordionIndex.parentIndex &&
      index === this.state.accordionIndex.index
    ) {
      this.setState({
        accordionIndex: { parentIndex: parentIndex, index: -1 },
      });
    } else {
      this.setState({
        accordionIndex: { parentIndex: parentIndex, index: index },
      });
    }
  };
  state = {
    active_index: 0,
    openHintModal: false,
    myAnswerMode: true,
    handleOnChange: this.placeholder.bind(this),
    handleRadioOnChange: this.placeholder.bind(this),
    handleCheckboxOnChange: this.placeholder.bind(this),
    toggleNav: this.toggleNav.bind(this),
    closeNav: this.closeNav.bind(this),
    updateActiveIndex: this.updateActiveIndex.bind(this),
    accordionIndex: { parentIndex: -1, index: -1 },
    onAccordionClick: this.onAccordionClick.bind(this),
  };
  openHintModal = () => {
    this.setState({ openHintModal: true });
  };
  closeHintModal = () => {
    this.setState({ openHintModal: false });
  };
  openVocabModal = () => {
    this.setState({ openVocabModal: true });
  };
  closeVocabModal = () => {
    this.setState({ openVocabModal: false });
  };
  myAnswerModeOn = () => {
    this.setState({ myAnswerMode: true });
  };
  myAnswerModeOff = () => {
    this.setState({ myAnswerMode: false });
  };
  goToDashboard = () => {
    this.props.history.push("/");
  };
  checkInAnswerGroups(item, answer_group) {
    try {
      for (let i = 0; i < answer_group.length; i++) {
        if (answer_group[i].indexOf(item) !== -1) {
          return true;
        }
      }
      return false;
    } catch (ex) {
      return false;
    }
  }
  isFirstIndex(item, answer_group) {
    try {
      for (let i = 0; i < answer_group.length; i++) {
        if (answer_group[i][0] === item) {
          return true;
        }
      }
      return false;
    } catch (ex) {
      return false;
    }
  }
  isLastIndex(item, answer_group) {
    try {
      for (let i = 0; i < answer_group.length; i++) {
        if (answer_group[i][answer_group[i].length - 1] === item) {
          return true;
        }
      }
      return false;
    } catch (ex) {
      return false;
    }
  }
  render() {
    const { content, answers } = this.props;
    const { active_index, openHintModal, myAnswerMode } = this.state;

    const correctAnswers = [];
    const correct_list = [];
    let max_score = 0;
    let answer_score = 0;
    let item_number = 1;

    // eslint-disable-next-line
    content[active_index].exam.exam.exams.forEach((exam, i) => {
      item_number = exam.start_number;
      const answer_groups = exam.answer_group;

      // eslint-disable-next-line
      exam.correct_answer.map((item, j) => {
        if (exam.exam_type === EXAMS.READ_RADIO_MCQ) {
          correct_list.push(
            <tr className="r-thin">
              <td key={`correct-list-${i}${j}`}>
                <span
                  className={item_number < 10 ? "exam-number" : "exam-number2"}
                >
                  {item_number++ + "."}
                </span>
                {exam.questions[j].choices[item - 1]}
              </td>
            </tr>
          );
          correctAnswers.push({
            c_answer: item,
            type: EXAMS.READ_RADIO_MCQ,
            value: exam.questions[j].choices,
          });
          max_score++;
        } else if (exam.exam_type === EXAMS.READ_RADIO_CHECKBOX) {
          if (exam.questions[j].condition === "and") {
            max_score++;
            const innerHtml = item.map((obj, k) => {
              return (
                <p
                  key={`correct-list-content-inner${k}`}
                  className="no-margin-top-bottom"
                >{`${exam.questions[j].choices[obj - 1]}`}</p>
              );
            });
            correct_list.push(
              <tr className="r-thin">
                <td key={`correct-list-${i}${j}`}>
                  <span
                    className={
                      item_number < 10 ? "exam-number" : "exam-number2"
                    }
                  >
                    {item_number++ + "."}
                  </span>
                  <div style={{ display: "inline-block" }}>{innerHtml}</div>
                </td>
              </tr>
            );
          } else {
            item.forEach((obj, k) => {
              max_score++;
              correct_list.push(
                <tr className="r-thin">
                  <td key={`correct-list-${i}${j}`}>
                    <span
                      className={
                        item_number < 10 ? "exam-number" : "exam-number2"
                      }
                    >
                      {item_number++ + "."}
                    </span>
                    {`${exam.questions[j].choices[obj - 1]}`}
                  </td>
                </tr>
              );
            });
          }
          correctAnswers.push({
            c_answer: item,
            type: EXAMS.READ_RADIO_CHECKBOX,
            value: exam.questions[j].choices,
            condition: exam.questions[j].condition,
          });
        } else {
          if (this.checkInAnswerGroups(j + 1, answer_groups)) {
            if (this.isFirstIndex(j + 1, answer_groups)) {
              max_score++;
              correct_list.push(
                <tr className="r-thin">
                  <td key={`correct-list-${i}${j}`}>
                    <span
                      className={
                        item_number < 10 ? "exam-number" : "exam-number2"
                      }
                    >
                      {item_number++ + "."}
                    </span>
                    <div style={{ display: "inline-block" }}>
                      {item.split("{n}").map((choice, k) => {
                        return (
                          <p
                            className="no-margin-top-bottom"
                            key={"choice3213123" + k}
                          >
                            {choice}
                          </p>
                        );
                      })}
                    </div>
                  </td>
                </tr>
              );
            }
          } else {
            max_score++;
            correct_list.push(
              <tr className="r-thin">
                <td key={`correct-list-${i}${j}`}>
                  <span
                    className={
                      item_number < 10 ? "exam-number" : "exam-number2"
                    }
                  >
                    {item_number++ + "."}
                  </span>
                  <div style={{ display: "inline-block" }}>
                    {item.split("{n}").map((choice, k) => {
                      return (
                        <p
                          className="no-margin-top-bottom"
                          key={"choice3213123" + k}
                        >
                          {choice}
                        </p>
                      );
                    })}
                  </div>
                </td>
              </tr>
            );
          }
          correctAnswers.push(item.replace(/[.,]/g, ""));
        }
      });
    });

    let currentIndex = 0;

    let and_answer_mode = false;
    let first_index = false;
    let last_index = false;
    let and_answers = [];

    const answer_list = [];
    // eslint-disable-next-line
    answers[active_index].map((answer, i) => {
      const answer_groups =
        content[active_index].exam.exam.exams[i].answer_group;
      item_number = content[active_index].exam.exam.exams[i].start_number;

      let ol_list = [];
      answer.forEach((item, j) => {
        if (correctAnswers[currentIndex].type) {
          if (correctAnswers[currentIndex].type === EXAMS.READ_RADIO_MCQ) {
            if (correctAnswers[currentIndex].c_answer === parseInt(item, 10)) {
              answer_score++;
            }
            ol_list.push(
              <tr className="r-thin">
                <td
                  className={
                    correctAnswers[currentIndex].c_answer === parseInt(item, 10)
                      ? ""
                      : "wrong-answer"
                  }
                  key={`answer-list-${i}${j}`}
                >
                  <span
                    className={
                      item_number < 10 ? "exam-number" : "exam-number2"
                    }
                  >
                    {item_number++ + "."}
                  </span>
                  {correctAnswers[currentIndex].value[item - 1]}
                </td>
              </tr>
            );
          } else if (
            correctAnswers[currentIndex].type === EXAMS.READ_RADIO_CHECKBOX
          ) {
            let s_answers = item.split(",");
            s_answers = s_answers.map((single_answer, l) =>
              parseInt(single_answer, 10)
            );
            s_answers = _.orderBy(s_answers);
            let corrects = 0;

            if (correctAnswers[currentIndex].condition === "and") {
              const innerHtml = [];
              s_answers.forEach((c, i) => {
                if (
                  correctAnswers[currentIndex].c_answer.indexOf(
                    parseInt(c, 10)
                  ) === -1
                ) {
                  // Not right minus wrong
                  // corrects--;
                } else {
                  corrects++;
                }
                innerHtml.push(
                  <p
                    className={
                      correctAnswers[currentIndex].c_answer.indexOf(
                        parseInt(c, 10)
                      ) !== -1
                        ? "no-margin-top-bottom"
                        : "no-margin-top-bottom wrong-answer"
                    }
                  >
                    {correctAnswers[currentIndex].value[c - 1]}
                  </p>
                );
              });
              const answer_is_correct =
                correctAnswers[currentIndex].c_answer.length === corrects;
              if (answer_is_correct) {
                answer_score += 1;
              }
              ol_list.push(
                <tr className="r-thin">
                  <td
                    key={`asdasdasd${i}`}
                    className={answer_is_correct ? "" : "wrong-answer"}
                  >
                    <span
                      className={
                        item_number < 10 ? "exam-number" : "exam-number2"
                      }
                    >
                      {item_number++ + "."}
                    </span>
                    {innerHtml}
                  </td>
                </tr>
              );
            } else {
              s_answers.forEach((c, i) => {
                if (
                  correctAnswers[currentIndex].c_answer.indexOf(
                    parseInt(c, 10)
                  ) === -1
                ) {
                  // Not right minus wrong
                  // corrects--;
                } else {
                  corrects++;
                }
                ol_list.push(
                  <tr className="r-thin">
                    <td
                      key={`asdasdasd${i}`}
                      className={
                        correctAnswers[currentIndex].c_answer.indexOf(
                          parseInt(c, 10)
                        ) !== -1
                          ? ""
                          : "wrong-answer"
                      }
                    >
                      <span
                        className={
                          item_number < 10 ? "exam-number" : "exam-number2"
                        }
                      >
                        {item_number++ + "."}
                      </span>
                      {correctAnswers[currentIndex].value[c - 1]}
                    </td>
                  </tr>
                );
              });
              for (
                let loop_index = 0;
                loop_index <
                correctAnswers[currentIndex].c_answer.length - s_answers.length;
                loop_index++
              ) {
                ol_list.push(
                  <tr className="r-thin">
                    <span
                      className={
                        item_number < 10 ? "exam-number" : "exam-number2"
                      }
                    >
                      {item_number++ + "."}
                    </span>
                  </tr>
                );
              }

              if (corrects < 0) {
                corrects = 0;
              }
              if (correctAnswers[currentIndex].condition === "and") {
                if (correctAnswers[currentIndex].c_answer.length === corrects) {
                  answer_score += 1;
                }
              } else {
                answer_score += corrects;
              }
            }
          }
          currentIndex++;
        } else {
          and_answer_mode = this.checkInAnswerGroups(j + 1, answer_groups);
          first_index = this.isFirstIndex(j + 1, answer_groups);
          last_index = this.isLastIndex(j + 1, answer_groups);

          const possibleAnswers = correctAnswers[currentIndex++]
            .split("{n}")
            .map((str) => {
              return str.trim().toLowerCase();
            });
          let processed_answer = item
            .trim()
            .toLowerCase()
            .replace(/\s\s+/g, " ");
          processed_answer = processed_answer.replace(/[.,]/g, "");
          if (and_answer_mode) {
            if (first_index) {
              and_answers = [];
            }
            and_answers.push(processed_answer);
            if (last_index) {
              const prev_answers = [];
              let wrong = false;
              and_answers.forEach((o) => {
                if (
                  possibleAnswers.indexOf(o) === -1 ||
                  prev_answers.indexOf(o) !== -1
                ) {
                  wrong = true;
                }
                prev_answers.push(o);
              });
              const and_s_answers = and_answers.map((a_s_answer) => {
                return <p className="no-margin-top-bottom">{a_s_answer}</p>;
              });
              if (!wrong) {
                answer_score++;
              }
              ol_list.push(
                <tr className="r-thin">
                  <td
                    className={!wrong ? "" : "wrong-answer"}
                    key={`answer-list-${i}${j}`}
                  >
                    <span
                      className={
                        item_number < 10 ? "exam-number" : "exam-number2"
                      }
                    >
                      {item_number++ + "."}
                    </span>
                    {and_s_answers}
                  </td>
                </tr>
              );
            }
          } else {
            if (
              possibleAnswers.indexOf(processed_answer.trim().toLowerCase()) !==
              -1
            ) {
              answer_score++;
            }
            ol_list.push(
              <tr className="r-thin">
                <td
                  className={
                    possibleAnswers.indexOf(processed_answer) !== -1
                      ? ""
                      : "wrong-answer"
                  }
                  key={`answer-list-${i}${j}`}
                >
                  <span
                    className={
                      item_number < 10 ? "exam-number" : "exam-number2"
                    }
                  >
                    {item_number++ + "."}
                  </span>
                  {item}
                </td>
              </tr>
            );
          }
        }
      });
      answer_list.push(ol_list);
    });

    currentIndex = 0;

    and_answer_mode = false;
    first_index = false;
    last_index = false;
    and_answers = [];

    return (
      <React.Fragment>
        <HintModal
          header="Hints"
          hint={content[active_index].exam.hint_review}
          open={openHintModal}
          onClose={this.closeHintModal}
        />
        <div className="main-container">
          <div className="content-new-ui header-new-ui">
            <div id="content-split" className="content-split">
              {myAnswerMode ? (
                <React.Fragment>
                  <div
                    id="content-left"
                    className="content-left"
                    style={{ overflow: "auto", margin: "2rem 1rem 1rem 1rem" }}
                  >
                    <h3 className="text-center review-font-blue">
                      Answers (Testing)
                    </h3>
                    <table className="table-recent">{answer_list}</table>
                    <h3 className="text-center review-font-blue">{`Total Score: ${answer_score}`}</h3>
                  </div>
                  <div
                    id="content-right"
                    className="content-right"
                    style={{ overflow: "auto", margin: "2rem 1rem 1rem 1rem" }}
                  >
                    <h3 className="text-center review-font-blue">
                      Answers (Correct)
                    </h3>
                    <table className="table-recent">{correct_list}</table>
                    <h4 className="text-center review-font-blue">{`Max Score: ${max_score}`}</h4>
                  </div>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <div
                    id="content-left"
                    className="content-left"
                    style={{ overflow: "auto", margin: "2rem 1rem 1rem 1rem" }}
                  >
                    <ReadingContent
                      explanation={true}
                      reading={true}
                      {...this.props}
                      {...this.state}
                    />
                  </div>
                  <div
                    id="content-right"
                    className="content-right"
                    style={{ overflow: "auto", margin: "2rem 1rem 1rem 1rem" }}
                  >
                    <ExamContainer
                      explanation={true}
                      current_index={active_index}
                      {...this.props}
                      {...this.state}
                    />
                  </div>
                </React.Fragment>
              )}
            </div>
            <div className="content-button">
              <Grid columns={"equal"}>
                <Grid.Column></Grid.Column>
                <Grid.Column className="text-center">
                  <button type="button" onClick={this.openHintModal}>
                    Hints Explained
                  </button>
                </Grid.Column>
                <Grid.Column className="text-right">
                  <button
                    type="button"
                    disabled={myAnswerMode}
                    className={myAnswerMode ? "active-art" : ""}
                    onClick={this.myAnswerModeOn}
                  >
                    Answers
                  </button>
                  <button
                    type="button"
                    disabled={!myAnswerMode}
                    className={!myAnswerMode ? "active-art" : ""}
                    onClick={this.myAnswerModeOff}
                  >
                    Explanations
                  </button>
                </Grid.Column>
              </Grid>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    marketing: state.marketing,
    openSidebar: state.app.openSidebar,
  };
};

export default withRouter(connect(mapStateToProps)(ReadingContainer));
