import React from 'react';
import { Grid } from 'semantic-ui-react';
import { Link } from "react-router-dom";

const ErrorPage = () => {
  return (
    <Grid textAlign='center' className='error-bg' verticalAlign="middle">
      <div className="card card-error">
        <p className="error404 r-black no-margin-404">4<img alt="logo" src="https://ieltsapp.s3-ap-southeast-1.amazonaws.com/resources/new-ui/error_logo.png" width="125"/>4</p>
        <p className="rem5 m-0 m-t-2 margin-bottom-404">Page not found</p>
        <Link to="/">
          <button type="button" name="submit" className="btn-error r-light">Go back to home page</button>
        </Link>
      </div>
    </Grid>
  );
};

export default ErrorPage;