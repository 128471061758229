import * as actionTypes from "../types/speakReview";

export const reset = () => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.SPEAK_REVIEW_RESET,
    });
  };
};

export const updateContent = (payload) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.SPEAK_REVIEW_UPDATE_CONTENT,
      payload: payload,
    });
  };
};
