import React from 'react';
import Sound from 'react-sound';
import { connect } from 'react-redux';
import * as listenExamActions from '../../../../actions/app/listenExam';

class SpeakingSounds extends React.Component {
  onStop = (e) => {
    this.props.dispatch(listenExamActions.setCountdownStarted(true));
    this.props.startTimer();
  }
  render() {
    const { url, dispatch, audio_position } = this.props;
    return (
      <Sound
        url={url}
        playStatus={Sound.status.PLAYING}
        onPlaying={({ position }) => dispatch(listenExamActions.updateAudioPosition(position))}
        playFromPosition={audio_position}
        onFinishedPlaying={this.onStop.bind(this)}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    audio_position  : state.listenExam.position,
  }
};

export default connect(mapStateToProps)(SpeakingSounds);