import React, { Component } from 'react';
import { Link } from "react-router-dom";
import {
  Grid,
  Menu,
  Icon,
  Container,
  Dropdown,
} from 'semantic-ui-react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as readActions from '../../../actions/app/readBuilder';
import * as listenActions from '../../../actions/app/listenBuilder';
import * as speakActions from '../../../actions/app/speakBuilder';
import * as writeActions from '../../../actions/app/writeBuilder';
import * as apiActions from '../../../actions/app/api';
import * as mockTestActions from '../../../actions/app/mockTest';
import * as api from '../../../actions/api/requests';

class AppHeader extends Component {
  redirectTo = (url) => {
    this.resetAllReducers();
    const { history } = this.props;
    history.push(url);
  }
  openTo = (url) => {
    window.open(url, "_blank");
  }
  resetAllReducers(){
    const { dispatch } = this.props;
    dispatch(readActions.reset());
    dispatch(listenActions.reset());
    dispatch(speakActions.reset());
    dispatch(writeActions.reset());
  }
  startMockTest = (exam_type) => {
    const { dispatch, user_id } = this.props;
    dispatch(apiActions.updateApiSending(true));
    dispatch(mockTestActions.reset());
    dispatch(api.startMockTestStudent({exam_type:exam_type, test_type:'imt', id: user_id}, this.props.history));
  }
  render() {
    const { is_staff } = this.props;
    return (
      <Grid.Row stretched style={{marginBottom:"1rem"}}>
        <Menu fixed='top' style={{backgroundColor: '#f2711c'}}>
          <Container>
            <Menu.Item header style={{color: "white"}}>
              <Link style={{color: "white"}} to='/'><Icon name='student'/> IELTS University</Link>
            </Menu.Item>
            <Menu.Item className='header-menu'><Link style={{color: "white"}} to='/'>Home</Link></Menu.Item>
            <Dropdown className='header-menu' item simple text='Exam' style={{color: "white"}}>
              <Dropdown.Menu>
                {
                  is_staff?
                    <React.Fragment>
                      <Dropdown.Item>
                        <i className='dropdown icon' />
                        <span className='text'>Create a new exam</span>
                        <Dropdown.Menu>
                          <Dropdown.Item onClick={this.redirectTo.bind(this, '/dashboard/create/listening')}><Icon name='sound'/> Listening</Dropdown.Item>
                          <Dropdown.Item onClick={this.redirectTo.bind(this, '/dashboard/create/reading')}><Icon name='book'/> Reading</Dropdown.Item>
                          <Dropdown.Item onClick={this.redirectTo.bind(this, '/dashboard/create/writing')}><Icon name='pencil alternate' /> Writing</Dropdown.Item>
                          <Dropdown.Item onClick={this.redirectTo.bind(this, '/dashboard/create/speaking')}><Icon name='microphone' /> Speaking</Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown.Item>
                      <Dropdown.Item>
                        <i className='dropdown icon' />
                        <span className='text'>Modify an existing exam</span>
                        <Dropdown.Menu>
                          <Dropdown.Item onClick={this.redirectTo.bind(this, '/dashboard/edit/listening')}><Icon name='sound'/> Listening</Dropdown.Item>
                          <Dropdown.Item onClick={this.redirectTo.bind(this, '/dashboard/edit/reading')}><Icon name='book'/> Reading</Dropdown.Item>
                          <Dropdown.Item onClick={this.redirectTo.bind(this, '/dashboard/edit/writing')}><Icon name='pencil alternate' /> Writing</Dropdown.Item>
                          <Dropdown.Item onClick={this.redirectTo.bind(this, '/dashboard/edit/speaking')}><Icon name='microphone' /> Speaking</Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown.Item>
                      <Dropdown.Item onClick={this.redirectTo.bind(this, '/dashboard/create/mock-test')}>
                        <span className='text'>Create a mock exam</span>
                      </Dropdown.Item>
                      <Dropdown.Item onClick={this.redirectTo.bind(this, '/dashboard/edit/mock-test')}>
                        <span className='text'>Edit an existing mock exam</span>
                      </Dropdown.Item>
                    </React.Fragment>
                    :
                    <Dropdown.Item>
                      <i className='dropdown icon' />
                      <span className='text'>Create a practice exam</span>
                      <Dropdown.Menu>
                        <Dropdown.Item onClick={this.redirectTo.bind(this, '/dashboard/build/listen')}><Icon name='sound'/> Listening</Dropdown.Item>
                        <Dropdown.Item onClick={this.redirectTo.bind(this, '/dashboard/build/read')}><Icon name='book'/> Reading</Dropdown.Item>
                        <Dropdown.Item onClick={this.redirectTo.bind(this, '/dashboard/build/write')}><Icon name='pencil alternate' /> Writing</Dropdown.Item>
                        <Dropdown.Item onClick={this.redirectTo.bind(this, '/dashboard/build/speak')}><Icon name='microphone' /> Speaking</Dropdown.Item>
                        <Dropdown.Item onClick={this.startMockTest.bind(this, 'gt')}><Icon name='certificate'/> General Training Mock Test</Dropdown.Item>
                        <Dropdown.Item onClick={this.startMockTest.bind(this, 'ac')}><Icon name='chart area'/> Academic Mock Test</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown.Item>
                }
              </Dropdown.Menu>
            </Dropdown>
            {
              is_staff?
                <React.Fragment>
                  <Dropdown className='header-menu' item simple text='Tools' style={{color: "white"}}>
                    <Dropdown.Menu>
                      <Dropdown.Item onClick={this.openTo.bind(this, '/json')}>
                        Json Editor
                      </Dropdown.Item>
                      <Dropdown.Item  onClick={this.openTo.bind(this, '/question-edit')}>
                        Question Editor
                      </Dropdown.Item>
                      <Dropdown.Item>
                        <i className='dropdown icon' />
                        <span className='text'>Marketing</span>
                        <Dropdown.Menu>
                          <Dropdown.Item  onClick={this.redirectTo.bind(this, '/marketing')}>
                            IMT
                          </Dropdown.Item>
                          <Dropdown.Item  onClick={this.redirectTo.bind(this, '/marketing-individual')}>
                            Individual
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                  <Dropdown className='header-menu' item simple text='Student' style={{color: "white"}}>
                    <Dropdown.Menu>
                      <Dropdown.Item onClick={this.openTo.bind(this, '/student-ips')}>
                        Student IP Addresses
                      </Dropdown.Item>
                      <Dropdown.Item onClick={this.openTo.bind(this, '/non-student')}>
                        Non Student management
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </React.Fragment>
                :
                null
            }
            <Menu.Menu position='right'>
              <Menu.Item>
                <Link style={{color: "white"}} to="/logout">
                  Logout
                </Link>
              </Menu.Item>
            </Menu.Menu>
          </Container>
        </Menu>
      </Grid.Row>
    )
  }
}


const mapStateToProps = (state) => {
  return {
    permission: state.auth.permission,
    is_staff: state.auth.is_staff,
    user_id: state.auth.id,
  }
};

export default withRouter(connect(mapStateToProps)(AppHeader));