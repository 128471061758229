import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import * as appActions from "../../../../../actions/app/app";
import * as apiActions from "../../../../../actions/app/api";
import * as api from "../../../../../actions/api/requests";
import * as readExamActions from "../../../../../actions/app/readExam";
import * as readReviewActions from "../../../../../actions/app/readReview";
import EXAM from "../../../../../constants/examTypes";
import { Grid } from "semantic-ui-react";
import Header from "./Header";
import Sidebar from "./Sidebar";
import HintModal from "./HintModal";
import ReadContent from "./ReadingContent";
import ExamContainer from "../common/ExamContainer";

function onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}

function pad(string) {
  return ("0" + string).slice(-2);
}

function format(seconds) {
  const date = new Date(seconds * 1000);
  const hh = date.getUTCHours();
  const mm = date.getUTCMinutes();
  const ss = pad(date.getUTCSeconds());
  if (hh) {
    return `Time Remaining: ${hh}h ${pad(mm)}m ${ss}s`;
  }
  return `Time Remaining: ${mm}m ${ss}s`;
}

class ReadingContainer extends React.Component {
  componentDidMount() {
    const { answers, strat_mode } = this.props;
    if (answers.length === 0) {
      this.setBlankAnswers();
    }
    if (!strat_mode) {
      this.startTimer();
    }
  }
  setBlankAnswers() {
    const { content, dispatch } = this.props;
    const final_blank_answers = [];
    const final_blank_answers2 = [];
    for (let i = 0; i < content.length; i++) {
      const answers = [];
      const answers2 = [];
      // eslint-disable-next-line
      content[i].exam.exams.map((exam) => {
        answers.push(Array(exam.correct_answer.length).join(".").split("."));
        answers2.push(Array(exam.correct_answer.length).join(".").split("."));
      });
      final_blank_answers.push(answers);
      final_blank_answers2.push(answers2);
    }
    dispatch(readExamActions.updateAnswer(final_blank_answers));
    dispatch(readExamActions.updateAnswerStrat(final_blank_answers2));
  }
  componentWillUnmount() {
    // this.clearTimer();
    // this.props.dispatch(readExamActions.reset());
  }
  startTimer() {
    this.timer = setInterval(this.tick.bind(this), 1000);
  }
  tick() {
    const { timer, dispatch } = this.props;
    const newTimer = timer - 1;
    if (newTimer >= 0) {
      dispatch(readExamActions.updateTimer(newTimer));
    } else {
      this.clearTimer();
      this.saveAnswer();
    }
  }
  clearTimer() {
    clearInterval(this.timer);
    this.timer = null;
  }
  handleOnChange = (i, j, e) => {
    const { answers, answers_strat, active_index, dispatch, strat_mode } =
      this.props;
    let newAnswers = answers.slice();
    if (strat_mode) {
      newAnswers = answers_strat.slice();
    }
    newAnswers[active_index][i][j] = e.target.value;
    if (strat_mode) {
      dispatch(readExamActions.updateAnswerStrat(newAnswers));
    } else {
      dispatch(readExamActions.updateAnswer(newAnswers));
    }
  };
  handleRadioOnChange = (i, j, answer, e) => {
    const { answers, answers_strat, active_index, dispatch, strat_mode } =
      this.props;
    let newAnswers = answers.slice();
    if (strat_mode) {
      newAnswers = answers_strat.slice();
    }
    newAnswers[active_index][i][j] = answer;
    if (strat_mode) {
      dispatch(readExamActions.updateAnswerStrat(newAnswers));
    } else {
      dispatch(readExamActions.updateAnswer(newAnswers));
    }
  };
  handleCheckboxOnChange = (i, j, answer, checked) => {
    const {
      answers,
      answers_strat,
      active_index,
      dispatch,
      strat_mode,
      content,
    } = this.props;
    // Get max answer size
    const max_answers =
      content[active_index].exam.exams[i].correct_answer[j].length;
    let newAnswers = answers.slice();
    if (strat_mode) {
      newAnswers = answers_strat.slice();
    }
    let newArr = [];
    if (newAnswers[active_index][i][j].length > 0) {
      newArr = newAnswers[active_index][i][j].split(",");
    }
    if (newArr.indexOf(answer.toString()) === -1) {
      // If current is equal or greater than the size of max answer, do not proceed
      if (newArr.length >= max_answers) {
        return;
      }
      newArr.push(answer);
    } else {
      newArr.splice(newArr.indexOf(answer.toString()), 1);
    }
    newArr = newArr.filter(onlyUnique).join(",");
    newAnswers[active_index][i][j] = newArr;
    if (strat_mode) {
      dispatch(readExamActions.updateAnswerStrat(newAnswers));
    } else {
      dispatch(readExamActions.updateAnswer(newAnswers));
    }
  };
  saveAnswer = () => {
    const elements1 = document.getElementsByClassName("content-left");
    for (let i = 0; i < elements1.length; i++) {
      elements1[i].scrollTo(0, 0);
    }
    const elements2 = document.getElementsByClassName("content-right");
    for (let i = 0; i < elements2.length; i++) {
      elements2[i].scrollTo(0, 0);
    }
    const {
      dispatch,
      strat_mode,
      active_index,
      content,
      history,
      answers,
      answers_strat,
      answerInstance,
      is_staff,
    } = this.props;

    if (!strat_mode) {
      this.clearTimer();
      dispatch(readExamActions.updateStratMode(true));
    } else {
      if (active_index + 1 === content.length) {
        content[0].name = answerInstance.name;
        const data = {
          name: answerInstance.name,
          id: answerInstance.id,
          answers: answers,
          answers_strat: answers_strat,
          content: content,
          finished_at: new Date(),
          test_type: EXAM.READING,
        };
        dispatch(readReviewActions.updateContent(data));
        dispatch(apiActions.updateApiSending(true));
        if (is_staff) {
          history.push("/review/read");
          return;
        } else {
          dispatch(
            api.updateAnswerInstance({ ...data }, history, "/review/read")
          );
          return;
        }
      }
      dispatch(readExamActions.resetTimer());
      dispatch(readExamActions.updateStratMode(false));
      dispatch(readExamActions.updateActiveIndex(active_index + 1));
      this.startTimer();
      dispatch(readReviewActions.updateContent());
    }
    this.resetAccordion();
  };
  handleDetailedFlowChartChange = (i, j, e) => {
    const { answers, answers_strat, active_index, dispatch, strat_mode } =
      this.props;
    let newAnswers = answers.slice();
    if (strat_mode) {
      newAnswers = answers_strat.slice();
    }
    newAnswers[active_index][i][j] = e.target.value;
    if (strat_mode) {
      dispatch(readExamActions.updateAnswerStrat(newAnswers));
    } else {
      dispatch(readExamActions.updateAnswer(newAnswers));
    }
  };
  onAccordionClick = (parentIndex, index) => {
    if (
      parentIndex === this.state.accordionIndex.parentIndex &&
      index === this.state.accordionIndex.index
    ) {
      this.setState({
        accordionIndex: { parentIndex: parentIndex, index: -1 },
      });
    } else {
      this.setState({
        accordionIndex: { parentIndex: parentIndex, index: index },
      });
    }
  };
  resetAccordion() {
    this.setState({ accordionIndex: { parentIndex: -1, index: -1 } });
  }
  state = {
    answers: [],
    toggleNav: this.toggleNav.bind(this),
    closeNav: this.closeNav.bind(this),
    handleOnChange: this.handleOnChange.bind(this),
    handleRadioOnChange: this.handleRadioOnChange.bind(this),
    handleCheckboxOnChange: this.handleCheckboxOnChange.bind(this),
    handleDetailedFlowChartChange:
      this.handleDetailedFlowChartChange.bind(this),
    openHintModal: false,
    openVocabModal: false,
    accordionIndex: { parentIndex: -1, index: -1 },
    onAccordionClick: this.onAccordionClick.bind(this),
  };
  openHintModal = () => {
    this.setState({ openHintModal: true });
  };
  closeHintModal = () => {
    this.setState({ openHintModal: false });
  };
  openVocabModal = () => {
    this.setState({ openVocabModal: true });
  };
  closeVocabModal = () => {
    this.setState({ openVocabModal: false });
  };
  toggleNav() {
    const { dispatch, openSidebar } = this.props;
    dispatch(appActions.updateOpenSidebar(!openSidebar));
  }
  closeNav() {
    this.props.dispatch(appActions.updateOpenSidebar(false));
  }
  render() {
    const { openSidebar, timer, strat_mode, content, active_index } =
      this.props;
    const { openHintModal } = this.state;
    let show = "hide";
    if (openSidebar) {
      show = "show";
    }
    return (
      <React.Fragment>
        <HintModal
          header="Hints"
          hint={content[active_index].hint}
          open={openHintModal}
          onClose={this.closeHintModal}
        />
        <Header
          {...this.state}
          {...this.props}
          show={show}
          timer={format(timer)}
          strat_mode={strat_mode}
          part={content[active_index].part}
        />
        <Sidebar {...this.state} {...this.props} show={show} />
        <div className="main-container">
          <div className="content-new-ui header-new-ui">
            <div id="content-split" className="content-split">
              <div
                id="content-left"
                className="content-left"
                style={{
                  overflow: "auto",
                  margin: "2rem 1rem 2rem 1rem",
                  paddingBottom: "2rem",
                }}
              >
                <div
                  className="instruction"
                  dangerouslySetInnerHTML={{
                    __html: content[active_index].instruction,
                  }}
                  style={{ marginBottom: "2rem" }}
                />
                <ReadContent reading={true} {...this.props} />
              </div>
              <div
                id="content-right"
                className="content-right"
                style={{
                  overflow: "auto",
                  margin: "2rem 1rem 2rem 1rem",
                  paddingBottom: "2rem",
                }}
              >
                <ExamContainer {...this.state} {...this.props} />
              </div>
            </div>
            <div className="content-button">
              <Grid columns={"equal"}>
                <Grid.Column></Grid.Column>
                <Grid.Column className="text-center">
                  {/* {strat_mode && content[active_index].hint ? (
                    <button type="button" onClick={this.openHintModal}>
                      Hints
                    </button>
                  ) : null} */}
                </Grid.Column>
                <Grid.Column className="text-right">
                  <button type="button" onClick={this.saveAnswer}>
                    {strat_mode
                      ? "Finish And Proceed"
                      : 'Finish Early And Proceed to "Learning"'}
                  </button>
                </Grid.Column>
              </Grid>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    content: state.readExam.content,
    timer: state.readExam.timer,
    active_index: state.readExam.active_index,
    strat_mode: state.readExam.strat_mode,
    answers: state.readExam.answers,
    answers_strat: state.readExam.answers_strat,
    answerInstance: state.answerInstance,
    is_staff: state.auth.is_staff,
    openSidebar: state.app.openSidebar,
  };
};

export default withRouter(connect(mapStateToProps)(ReadingContainer));
