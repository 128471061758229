import { createStore, applyMiddleware } from "redux";
import setAuthorizationToken from "../utils/setAuthorizationToken";

import reducer from "../reducers";
import throttle from "lodash/throttle";
import thunk from "redux-thunk";
const MyLocalStorage = require("better-localstorage");
const storage = new MyLocalStorage();

import { PERSISTED_STATE_KEY } from "../constants/keys";

let persistedState = {};
let defaultState = {};

const state = await storage.getItem(PERSISTED_STATE_KEY);

if (state) {
  persistedState = JSON.parse(state);
} else {
  persistedState = defaultState;
}

if (persistedState !== defaultState) {
  try {
    setAuthorizationToken(persistedState.auth.token);
  } catch (e) {}
}

const middleware = applyMiddleware(thunk);

const store = createStore(reducer, persistedState, middleware);

store.subscribe(
  throttle(() => {
    storage.setItem(PERSISTED_STATE_KEY, JSON.stringify(store.getState()));
  }),
  1000
);

export default store;
