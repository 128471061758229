import * as actions from '../actions/types/speakReview';

const defaultState = {
  content: null
};

export default function reducer(state=defaultState, action) {
  switch(action.type) {
    case actions.SPEAK_REVIEW_RESET: {
      return {...defaultState};
    }
    case actions.SPEAK_REVIEW_UPDATE_CONTENT: {
      return {...state, content:action.payload};
    }
    default: return {...state};
  }
};