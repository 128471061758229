import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import * as apiActions from "../../../../../../actions/app/api";
import * as api from "../../../../../../actions/api/requests";
import * as writeExamActions from "../../../../../../actions/app/writeExam";
import * as EXAM_SUBTYPES from "../../../../../../constants/exam_subtypes";
import { Grid, Confirm } from "semantic-ui-react";
import Header from "./Header";
import Sidebar from "./Sidebar";
import wordcount from "wordcount";
import * as mockTestActions from "../../../../../../actions/app/mockTest";
import * as answerInstanceActions from "../../../../../../actions/app/answerInstance";

function pad(string) {
  return ("0" + string).slice(-2);
}

function format(seconds) {
  const date = new Date(seconds * 1000);
  const hh = date.getUTCHours();
  const mm = date.getUTCMinutes();
  const ss = pad(date.getUTCSeconds());
  if (hh) {
    return `Time Remaining: ${hh}h ${pad(mm)}m ${ss}s`;
  }
  return `Time Remaining: ${mm}m ${ss}s`;
}

class MockWriting extends React.Component {
  componentDidMount() {
    const { answers, strat_mode, content, active_index, dispatch, timer } =
      this.props;
    dispatch(mockTestActions.updateConfirmModal(false));
    if (answers.length === 0) {
      this.setBlankAnswers();
    }

    if (!(timer !== 2400 && timer !== 1200)) {
      if (content[active_index].part === EXAM_SUBTYPES.WRITE_PART_2_VALUE) {
        dispatch(writeExamActions.updateTimer(2400));
      } else {
        dispatch(writeExamActions.resetTimer());
      }
    }

    if (!strat_mode) {
      this.startTimer();
    }
  }
  setBlankAnswers() {
    const { content, dispatch } = this.props;
    const final_blank_answers = Array(content.length).join(".").split(".");
    const final_blank_answers2 = Array(content.length).join(".").split(".");
    dispatch(writeExamActions.updateAnswer(final_blank_answers));
    dispatch(writeExamActions.updateAnswerStrat(final_blank_answers2));
  }
  componentWillUnmount() {
    this.clearTimer();
    // this.props.dispatch(writeExamActions.reset());
  }
  startTimer() {
    this.timer = setInterval(this.tick.bind(this), 1000);
  }
  tick() {
    const { timer, dispatch } = this.props;
    const newTimer = timer - 1;
    if (newTimer >= 0) {
      dispatch(writeExamActions.updateTimer(newTimer));
    } else {
      this.clearTimer();
      this.saveAnswer();
    }
  }
  clearTimer() {
    clearInterval(this.timer);
    this.timer = null;
  }
  handleOnChange = (e) => {
    const { answers, answers_strat, strat_mode, active_index, dispatch } =
      this.props;
    let newAnswers = answers.slice();
    if (strat_mode) {
      newAnswers = answers_strat.slice();
    }
    newAnswers[active_index] = e.target.value;
    if (strat_mode) {
      dispatch(writeExamActions.updateAnswerStrat(newAnswers));
    } else {
      dispatch(writeExamActions.updateAnswer(newAnswers));
    }
  };
  openModal = () => {
    this.setState({ openModal: true });
  };
  closeModal = () => {
    this.setState({ openModal: false });
  };
  saveAnswer = () => {
    const elements = document.getElementsByClassName("exam-divs");
    for (let i = 0; i < elements.length; i++) {
      elements[i].scrollTo(0, 0);
    }
    const { dispatch, current_page } = this.props;
    dispatch(mockTestActions.updateCurrentPage(current_page + 1));
  };
  changeActiveIndex = (i) => {
    const { dispatch } = this.props;
    dispatch(writeExamActions.updateActiveIndex(i));
  };
  nextPage = () => {
    const {
      dispatch,
      current_page,
      data,
      answers,
      content,
      test_type,
      user,
      nonStudent,
    } = this.props;
    if (test_type !== "writing") {
      data["answers"]["writing"] = answers;
      data["content"]["writing"] = content;
      dispatch(answerInstanceActions.updateContent(data));
      dispatch(mockTestActions.updateCurrentPage(current_page + 1));
    } else {
      const data = {
        name: `Mock Test (${new Date().toLocaleString()})`,
        answers: {
          writing: answers,
        },
        content: {
          writing: content,
        },
        test_type: "writing",
      };
      data["user"] = user;
      data["answers"]["speaking"] = [];
      data["content"]["speaking"] = [];
      data["answers"]["reading"] = [];
      data["content"]["reading"] = [];
      data["answers"]["listening"] = [];
      data["content"]["listening"] = [];
      data["finished_at"] = new Date();
      dispatch(apiActions.updateApiSending(true));
      dispatch(
        api.createNonStudentAnswerInstance(
          data,
          nonStudent.profile,
          this.props.history
        )
      );
      dispatch(mockTestActions.updateCurrentPage(current_page + 1));
    }
  };
  closeModal = () => {
    this.props.dispatch(mockTestActions.updateConfirmModal(false));
  };
  confirmNextPage = () => {
    const { dispatch } = this.props;
    dispatch(mockTestActions.updateConfirmModal(true));
  };
  render() {
    const {
      openSidebar,
      content,
      timer,
      active_index,
      strat_mode,
      answers,
      answers_strat,
      openConfirmModal,
    } = this.props;
    let text = "";
    if (answers.length > 0) {
      text = answers[active_index];
      if (strat_mode) {
        text = answers_strat[active_index];
      }
    }
    let show = "hide";
    if (openSidebar) {
      show = "show";
    }
    const section_btns = content.map((exam, i) => {
      return (
        <button
          type="button"
          disabled={i === active_index}
          onClick={this.changeActiveIndex.bind(this, i)}
        >
          {exam.name}
        </button>
      );
    });
    return (
      <React.Fragment>
        <Confirm
          open={openConfirmModal}
          header="Confirm"
          onCancel={this.closeModal}
          onConfirm={this.nextPage}
          content="Are you sure you want to finish the Writing Exam and move on to the Speaking Exam?"
        />
        <Header
          {...this.state}
          {...this.props}
          show={show}
          timer={format(timer)}
        />
        <Sidebar {...this.state} {...this.props} show={show} />
        <div className="main-container">
          <div className="content-new-ui header-new-ui">
            <div id="content-split" className="content-split">
              <div
                id="content-left"
                className="content-left"
                style={{
                  overflow: "auto",
                  margin: "2rem .5rem .5rem .5rem",
                  paddingBottom: "2rem",
                }}
              >
                <div
                  className="instruction"
                  style={{ padding: ".5rem" }}
                  dangerouslySetInnerHTML={{
                    __html: content[active_index].exam,
                  }}
                />
              </div>
              <div
                id="content-right"
                className="content-right text-center"
                style={{
                  overflow: "auto",
                  margin: "2rem .5rem .5rem .5rem",
                  paddingBottom: "2rem",
                }}
              >
                <textarea
                  className="art"
                  id="input-textArea"
                  placeholder="Place your answer here"
                  onChange={this.handleOnChange.bind(this)}
                  value={text}
                />
                <span id="wordCount" style={{ marginTop: ".25rem" }}>
                  <strong>Word Count:</strong>&nbsp;{wordcount(text)}
                </span>
              </div>
            </div>
            <div className="content-button">
              <Grid columns={"equal"}>
                <Grid.Column>{section_btns}</Grid.Column>
                <Grid.Column className="text-center">
                  {strat_mode ? (
                    <button type="button" onClick={this.openModal}>
                      Points & Vocab
                    </button>
                  ) : null}
                </Grid.Column>
                <Grid.Column className="text-right">
                  <button type="button" onClick={this.confirmNextPage}>
                    Finish And Proceed
                  </button>
                </Grid.Column>
              </Grid>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    timer: state.writeExam.timer,
    active_index: state.writeExam.active_index,
    strat_mode: false,
    content: state.writeExam.content,
    answers: state.writeExam.answers,
    answers_strat: state.writeExam.answers_strat,
    answerInstance: state.answerInstance,
    is_staff: state.auth.is_staff,
    openSidebar: state.app.openSidebar,
    current_page: state.mockTest.currentActivePage,
    openConfirmModal: state.mockTest.openConfirmModal,
    data: state.answerInstance,
    nonStudent: state.nonStudent,
    user: state.mockTest.content.user,
    test_type: state.mockTest.content.test_type,
  };
};

export default withRouter(connect(mapStateToProps)(MockWriting));
