import React from 'react';
import { connect } from 'react-redux';
import * as listenExamActions from '../../../../../../actions/app/listenExam';
import * as apiActions from '../../../../../../actions/app/api';
import * as api from '../../../../../../actions/api/requests';
import SoundContainer from './SoundContainer';
import * as mockTestActions from '../../../../../../actions/app/mockTest';
import * as answerInstanceActions from '../../../../../../actions/app/answerInstance';
import EXAM from '../../../../../../constants/examTypes';
import ListenExam from './ListenExam';
import { Confirm } from 'semantic-ui-react';

class MockListening extends React.Component {
  changeIndex = (i) => {
    const elements = document.getElementsByClassName("exam-divs");
    for (let i = 0; i < elements.length; i++){
      elements[i].scrollTo(0, 0);
    }
    this.props.dispatch(listenExamActions.updateCurrentIndex(i));
  }
  closeModal = () =>{
    this.props.dispatch(mockTestActions.updateConfirmModal(false))
  }
  nextPage = () => {
    const { dispatch, current_page, answers, content, test_type, user, nonStudent } = this.props;
    const data = {
      name: `Mock Test (${new Date().toLocaleString()})`,
      answers: {
        listening: answers
      },
      content: {
        listening: content
      },
      test_type: EXAM.MOCK_TEST
    }
    if (test_type !== 'listening'){
      dispatch(answerInstanceActions.updateContent(data));
      dispatch(mockTestActions.updateCurrentPage(current_page+1));
    } else {
      data['test_type'] = "listening";
      data['user'] = user;
      data['answers']['speaking'] = [];
      data['content']['speaking'] = [];
      data['answers']['writing'] = [];
      data['content']['writing'] = [];
      data['answers']['reading'] = [];
      data['content']['reading'] = [];
      data['finished_at'] = new Date();
      dispatch(apiActions.updateApiSending(true));
      dispatch(api.createNonStudentAnswerInstance(data, nonStudent.profile, this.props.history));
      dispatch(mockTestActions.updateCurrentPage(current_page+1));
    }
  }
  render(){
    const { content, active_index, openConfirmModal } = this.props;
    let url = "";
    if (content.length !== 0){
      try {
        url = content[active_index].audio;
      } catch (ex){}
    }
    return (
      <React.Fragment>
        <Confirm
          open={openConfirmModal}
          content="Are you sure you want to finish the Listening Exam and move on to the Reading Exam?"
          header='Confirm'
          onCancel={this.closeModal}
          onConfirm={this.nextPage}
        />
        <SoundContainer url={url} startTimer={this.startTimer}/>
        <ListenExam {...this.state} changeIndex={this.changeIndex.bind(this)}/>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    content:      state.listenExam.content,
    timer:        state.listenExam.timer,
    active_index: state.listenExam.active_index,
    strat_mode:   false,
    answers:      state.listenExam.answers,
    audio_position: state.listenExam.position,
    countdown_started: state.listenExam.countdown_started,
    answerInstance: state.answerInstance,
    is_staff      : state.auth.is_staff,
    current_page      : state.mockTest.currentActivePage,
    current_index     : state.listenExam.current_index,
    openConfirmModal    : state.mockTest.openConfirmModal,
    nonStudent          : state.nonStudent,
    user                : state.mockTest.content.user,
  }
};

export default connect(mapStateToProps)(MockListening);