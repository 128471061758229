import React from 'react';
import { connect } from 'react-redux';
import { Icon } from 'semantic-ui-react'

class AppLoader extends React.Component {
  render() {
    const { sending } = this.props;
    return (
      <div id="load-overlay" style={sending?{width:"100%"}:null}>
        <div className="load-overlay_body">
          <p className="loader-new-ui"><Icon loading name='spinner' /></p>
          <span className="r-light f-15">Please Wait</span>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    sending: state.api.sending,
  }
};

export default connect(mapStateToProps)(AppLoader);