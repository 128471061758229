import * as actionTypes from '../types/listenReview';

export const reset = () => {
  return dispatch => {
    dispatch({
      type : actionTypes.LISTEN_REVIEW_RESET,
    });
  };
};

export const updateContent = (payload) => {
  return dispatch => {
    dispatch({
      type    : actionTypes.LISTEN_REVIEW_UPDATE_CONTENT,
      payload : payload,
    });
  };
};