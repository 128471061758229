import React from "react";
import { Table, Icon, Pagination, Container, Button } from "semantic-ui-react";
import { connect } from "react-redux";
import * as historyActions from "../../../actions/app/answerHistory";
import * as api from "../../../actions/api/requests";
import EXAM from "../../../constants/examTypes";

import * as readReviewActions from "../../../actions/app/readReview";
import * as listenReviewActions from "../../../actions/app/listenReview";
import * as speakReviewActions from "../../../actions/app/speakReview";
import * as writeReviewActions from "../../../actions/app/writeReview";
import * as answerInstanceActions from "../../../actions/app/answerInstance";

class AnswerHistoryTable extends React.Component {
  componentDidMount() {
    this.resetCurrentPage();
    this.props.dispatch(api.getAnswers());
  }
  componentWillUnmount() {
    this.resetCurrentPage();
  }
  resetCurrentPage() {
    const { dispatch } = this.props;
    dispatch(historyActions.updateCurrentPage(1));
  }
  onPageChange = (e, data) => {
    const { dispatch } = this.props;
    dispatch(historyActions.updateCurrentPage(data.activePage));
  };
  doReview = (answerInstance) => {
    const { dispatch, history } = this.props;
    const answer = { ...answerInstance };
    answer.content = JSON.parse(answer.content);
    answer.answers = JSON.parse(answer.answers);
    try {
      answer.answers_strat = JSON.parse(answer.answers_strat);
    } catch (ex) {}
    // eslint-disable-next-line
    switch (answerInstance.test_type) {
      case EXAM.READING:
        dispatch(readReviewActions.updateContent(answer));
        history.push("/review/read");
        break;
      case EXAM.LISTENING:
        dispatch(listenReviewActions.updateContent(answer));
        history.push("/review/listen");
        break;
      case EXAM.SPEAKING:
        dispatch(speakReviewActions.updateContent(answer));
        history.push("/review/speak");
        break;
      case EXAM.WRITING:
        dispatch(writeReviewActions.updateContent(answer));
        history.push("/review/write");
        break;
      case EXAM.MOCK_TEST:
        dispatch(answerInstanceActions.updateContent(answer));
        history.push("/mock-test/review");
        break;
    }
  };
  render() {
    const { content, currentActivePage, maxPerPage, is_staff } = this.props;
    const startIndex = (currentActivePage - 1) * maxPerPage;
    const finalIndex = maxPerPage * currentActivePage;
    const slicedContent = content.slice(startIndex, finalIndex);
    const rows = slicedContent.map((item, i) => {
      return (
        <Table.Row key={i}>
          <Table.Cell>{item.name}</Table.Cell>
          <Table.Cell>
            {item.finished_at ? (
              <Icon color="green" name="checkmark" size="large" />
            ) : (
              <Icon color="red" name="cancel" size="large" />
            )}
          </Table.Cell>
          <Table.Cell>{new Date(item.created_at).toLocaleString()}</Table.Cell>
          <Table.Cell>
            {item.finished_at === ""
              ? "Not Finished"
              : new Date(item.finished_at).toLocaleString()}
          </Table.Cell>
          <Table.Cell>
            <Button
              disabled={!item.finished_at}
              primary
              size="tiny"
              icon
              labelPosition="left"
              onClick={this.doReview.bind(this, item)}
            >
              <Icon name="eye" /> Review
            </Button>
          </Table.Cell>
        </Table.Row>
      );
    });
    if (is_staff) {
      return <React.Fragment />;
    }
    return (
      <React.Fragment>
        <div style={{ maxHeight: "70vh", overflowY: "scroll" }}>
          <Table striped compact stackable>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Name</Table.HeaderCell>
                <Table.HeaderCell>Finished</Table.HeaderCell>
                <Table.HeaderCell>Started At</Table.HeaderCell>
                <Table.HeaderCell>Completed At</Table.HeaderCell>
                <Table.HeaderCell>Action</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>{rows}</Table.Body>
          </Table>
          <Container textAlign={"center"}>
            <Pagination
              activePage={currentActivePage}
              totalPages={Math.ceil(content.length / maxPerPage)}
              onPageChange={this.onPageChange}
            />
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    content: state.answerHistory.content,
    currentActivePage: state.answerHistory.currentActivePage,
    maxPerPage: state.answerHistory.maxPerPage,
    is_staff: state.auth.is_staff,
  };
};

export default connect(mapStateToProps)(AnswerHistoryTable);
