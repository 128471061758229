import * as actionTypes from '../types/listenExam';

export const reset = () => {
  return dispatch => {
    dispatch({
      type : actionTypes.LISTEN_EXAM_RESET_LISTEN_EXAM,
    });
  };
};

export const updateTimer = (payload) => {
  return dispatch => {
    dispatch({
      type    : actionTypes.LISTEN_EXAM_UPDATE_TIMER,
      payload : payload,
    });
  };
};

export const updateActiveIndex = (payload) => {
  return dispatch => {
    dispatch({
      type    : actionTypes.LISTEN_EXAM_UPDATE_ACTIVE_INDEX,
      payload : payload,
    });
  };
};

export const updateStratMode = (payload) => {
  return dispatch => {
    dispatch({
      type    : actionTypes.LISTEN_EXAM_UPDATE_STRAT_MODE,
      payload : payload,
    });
  };
};

export const resetTimer = () => {
  return dispatch => {
    dispatch({
      type    : actionTypes.LISTEN_EXAM_RESET_TIMER,
    });
  };
};

export const updateAnswer = (payload) => {
  return dispatch => {
    dispatch({
      type    : actionTypes.LISTEN_EXAM_UPDATE_ANSWERS,
      payload : payload
    });
  };
};

export const updateAnswerStrat = (payload) => {
  return dispatch => {
    dispatch({
      type    : actionTypes.LISTEN_EXAM_UPDATE_ANSWERS_STRAT,
      payload : payload
    });
  };
};

export const updateAudioPosition = (payload) => {
  return dispatch => {
    dispatch({
      type    : actionTypes.LISTEN_EXAM_UPDATE_POSITION,
      payload : payload
    });
  };
};

export const resetPosition = () => {
  return dispatch => {
    dispatch({
      type    : actionTypes.LISTEN_EXAM_RESET_POSITION
    });
  };
};

export const setCountdownStarted = (payload) => {
  return dispatch => {
    dispatch({
      type    : actionTypes.LISTEN_EXAM_SET_COUNTDOWN_STARTED,
      payload : payload
    });
  };
};

export const updateContent = (payload) => {
  return dispatch => {
    dispatch({
      type    : actionTypes.LISTEN_EXAM_UPDATE_CONTENT,
      payload : payload
    });
  };
};

export const updateCurrentIndex = (payload) => {
  return dispatch => {
    dispatch({
      type    : actionTypes.LISTEN_EXAM_UPDATE_CURRENT_INDEX,
      payload : payload
    });
  };
};

export const updateAudioIndex = (payload) => {
  return dispatch => {
    dispatch({
      type    : actionTypes.LISTEN_EXAM_UPDATE_AUDIO_INDEX,
      payload : payload
    });
  };
};