import * as answerInstance from '../types/answerInstance';

export const updateContent = (payload) => {
  return dispatch => {
    dispatch({
      type    : answerInstance.UPDATE_ANSWER_INSTANCE,
      payload : payload,
    });
  };
};

export const reset = () => {
  return dispatch => {
    dispatch({
      type    : answerInstance.RESET_ANSWER_INSTANCE,
    });
  };
};

