import React from "react";
import { Grid } from "semantic-ui-react";
import { getCurrentYear } from "../../../common";

export default () => (
  <Grid className="footer" stretched textAlign="center">
    <Grid.Column width={16} className="footer-copyright">
      <p>
        <strong>© IELTS University {getCurrentYear()}</strong>
      </p>
    </Grid.Column>
  </Grid>
);
