import * as actionTypes from '../types/writeReview';

export const reset = () => {
  return dispatch => {
    dispatch({
      type : actionTypes.WRITE_REVIEW_RESET,
    });
  };
};

export const updateContent = (payload) => {
  return dispatch => {
    dispatch({
      type    : actionTypes.WRITE_REVIEW_UPDATE_CONTENT,
      payload : payload,
    });
  };
};