import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import '../../includes/css/style.css';
import '../../includes/css/fontawesome.min.css';
import '../../includes/css/solid.css';
import '../../includes/css/fonts.css';
import * as MainComponents from './components/homeContent';
import * as appActions from '../../actions/app/app';
import * as apiActions from '../../actions/app/api';
import * as api from '../../actions/api/requests';
import * as mockTestActions from '../../actions/app/mockTest';
import Loader from '../others/Loader';

class MainContainer extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      openMainNav: this.openMainNav.bind(this),
      closeMainNav: this.closeMainNav.bind(this),
      startMockTest: this.startMockTest.bind(this),
    }
  }
  componentDidMount(){
    this.closeMainNav();
  }
  openMainNav() {
    this.props.dispatch(appActions.updateOpenSidebar(true));
  }
  closeMainNav() {
    this.props.dispatch(appActions.updateOpenSidebar(false));
  }
  startMockTest = (exam_type) => {
    const { dispatch, user_id } = this.props;
    dispatch(apiActions.updateApiSending(true));
    dispatch(mockTestActions.reset());
    dispatch(api.startMockTestStudent({exam_type:exam_type, test_type:'imt', id: user_id}, this.props.history));
  }
  render() {
    return (
      <React.Fragment>
        <div className="main-page">
          <Loader/>
          <MainComponents.Sidebar {...this.state} {...this.props}/>
          <MainComponents.TopNav {...this.state}/>
          <MainComponents.Body/>
          <MainComponents.Footer/>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    fullName          : state.auth.full_name,
    openSidebar       : state.app.openSidebar,
    user              : state.login,
    is_authenticated  : state.auth.is_authenticated,
    nonStudent        : state.nonStudent,
    user_id           : state.auth.id,
  }
};

export default withRouter(connect(mapStateToProps)(MainContainer));