import * as answerHistory from '../types/answerHistory';

export const updateContent = (payload) => {
  return dispatch => {
    dispatch({
      type    : answerHistory.UPDATE_ANSWER_HISTORY_CONTENT,
      payload : payload,
    });
  };
};

export const updateCurrentPage = (payload) => {
  return dispatch => {
    dispatch({
      type    : answerHistory.UPDATE_ANSWER_HISTORY_CURRENT_ACTIVE_PAGE,
      payload : payload,
    });
  };
};

