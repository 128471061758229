import React from 'react';
import { Link, NavLink, withRouter } from 'react-router-dom';

/* eslint-disable */
const Sidebar = (props) => {
  const pathName = window.location.pathname;
  const activeHome = pathName === "/dashboard" || pathName === "/dashboard/"? "active" : "";
  const open = props.openSidebar;
  const show = open?"show":"hide";
  return (
    <React.Fragment>
      <div id="overlay" onClick={props.closeMainNav.bind(this)} style={open?{width:"100%"}:{width:"0%"}}></div>
      <div id="side-nav" className="side-nav r-light" style={open?{width:"400px"}:{width:"0px"}}>
        <div id="side-nav-close" className={"side-nav-close "+show}>
          <a className="closebtn" onClick={props.closeMainNav.bind(this)}>&times;</a>
        </div>
        <div id="side-nav-profile" className={"side-nav-profile "+show}>
          <img alt="logo" id="profile_pic" src="https://ieltsapp.s3-ap-southeast-1.amazonaws.com/resources/new-ui/profile_pic.png" />
          <br/>
          <span className="f-20">{props.fullName}</span>
          <hr width="380"/>
        </div>
        <div id="side-nav-list" className={"side-nav-list "+show}>
          <Link to="/dashboard" onClick={props.closeMainNav.bind(this)} className={activeHome}><i className="fa fa-home"></i>Home</Link>
          <NavLink to="/dashboard/exams" onClick={props.closeMainNav.bind(this)} activeClassName="active"><i className="fa fa-clock"></i>Recent Examinations</NavLink>
          <span><i className="fa fa-file-alt"></i>Take an Exam</span>
          <div className="sub-menu" onClick={props.closeMainNav.bind(this)}>
            <NavLink to="/dashboard/listening" activeClassName="active"><i className="fa fa-headphones-alt"></i>Listening</NavLink>
            <NavLink to="/dashboard/reading" activeClassName="active"><i className="fa fa-book"></i>Reading</NavLink>
            <NavLink to="/dashboard/writing" activeClassName="active"><i className="fa fa-pen"></i>Writing</NavLink>
            <NavLink to="/dashboard/speaking" activeClassName="active"><i className="fa fa-microphone"></i>Speaking</NavLink>
          </div>
          <span><i className="fa fa-file-alt"></i>Take a Mock Exam</span>
          <div onClick={props.closeMainNav.bind(this)} className="sub-menu">
            <a onClick={props.startMockTest.bind(this, 'gt')}><i className="fas fa-award"></i>General Training</a>
            <a onClick={props.startMockTest.bind(this, 'ac')}><i className="fas fa-school"></i>Academic</a>
          </div>
          <NavLink to="/logout"><i className="fa fa-power-off"></i>Logout</NavLink>
        </div>
      </div>
    </React.Fragment>
  )
};

export default withRouter(Sidebar);