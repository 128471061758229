import React from "react";
import * as EXAM_TYPES from "../../../../../constants/exams";
import * as Exams from "./index";

const ReadCombined = (props) => {
  const { content, explanation } = props;
  let components;
  if (explanation) {
    try {
      components = content.exam_explanation.exams.map((exam, i) => {
        switch (exam.exam_type) {
          case EXAM_TYPES.READ_FLOW_CHART:
            return (
              <Exams.FlowChart
                {...props}
                parentIndex={i}
                {...exam}
                key={"exam" + i}
              />
            );
          case EXAM_TYPES.READ_FLOW_CHART_DETAILED:
            return (
              <Exams.FlowChartDetailed
                {...props}
                parentIndex={i}
                {...exam}
                key={"exam" + i}
              />
            );
          case EXAM_TYPES.READ_IMAGE_LABELING:
            return (
              <Exams.ImageLabeling
                {...props}
                parentIndex={i}
                {...exam}
                key={"exam" + i}
              />
            );
          case EXAM_TYPES.READ_MATCHING:
            return (
              <Exams.Matching
                {...props}
                parentIndex={i}
                {...exam}
                key={"exam" + i}
              />
            );
          case EXAM_TYPES.READ_NOTE_COMPLETION:
            return (
              <Exams.NoteCompletion
                {...props}
                parentIndex={i}
                {...exam}
                key={"exam" + i}
              />
            );
          case EXAM_TYPES.READ_RADIO_CHECKBOX:
            return (
              <Exams.CheckBox
                {...props}
                parentIndex={i}
                {...exam}
                key={"exam" + i}
              />
            );
          case EXAM_TYPES.READ_RADIO_MCQ:
            return (
              <Exams.RadioMcq
                {...props}
                parentIndex={i}
                {...exam}
                key={"exam" + i}
              />
            );
          case EXAM_TYPES.READ_SENTENCE_COMPLETION:
            return (
              <Exams.SentenceCompletion
                {...props}
                parentIndex={i}
                {...exam}
                key={"exam" + i}
              />
            );
          case EXAM_TYPES.READ_SHORT_ANSWERS:
            return (
              <Exams.ShortAnswers
                {...props}
                parentIndex={i}
                {...exam}
                key={"exam" + i}
              />
            );
          default:
            return <React.Fragment></React.Fragment>;
        }
      });
    } catch (ex) {}
  } else {
    try {
      components = content.exam.exams.map((exam, i) => {
        switch (exam.exam_type) {
          case EXAM_TYPES.READ_FLOW_CHART:
            return (
              <Exams.FlowChart
                {...props}
                parentIndex={i}
                {...exam}
                key={"exam" + i}
              />
            );
          case EXAM_TYPES.READ_FLOW_CHART_DETAILED:
            return (
              <Exams.FlowChartDetailed
                {...props}
                parentIndex={i}
                {...exam}
                key={"exam" + i}
              />
            );
          case EXAM_TYPES.READ_IMAGE_LABELING:
            return (
              <Exams.ImageLabeling
                {...props}
                parentIndex={i}
                {...exam}
                key={"exam" + i}
              />
            );
          case EXAM_TYPES.READ_MATCHING:
            return (
              <Exams.Matching
                {...props}
                parentIndex={i}
                {...exam}
                key={"exam" + i}
              />
            );
          case EXAM_TYPES.READ_NOTE_COMPLETION:
            return (
              <Exams.NoteCompletion
                {...props}
                parentIndex={i}
                {...exam}
                key={"exam" + i}
              />
            );
          case EXAM_TYPES.READ_RADIO_CHECKBOX:
            return (
              <Exams.CheckBox
                {...props}
                parentIndex={i}
                {...exam}
                key={"exam" + i}
              />
            );
          case EXAM_TYPES.READ_RADIO_MCQ:
            return (
              <Exams.RadioMcq
                {...props}
                parentIndex={i}
                {...exam}
                key={"exam" + i}
              />
            );
          case EXAM_TYPES.READ_SENTENCE_COMPLETION:
            return (
              <Exams.SentenceCompletion
                {...props}
                parentIndex={i}
                {...exam}
                key={"exam" + i}
              />
            );
          case EXAM_TYPES.READ_SHORT_ANSWERS:
            return (
              <Exams.ShortAnswers
                {...props}
                parentIndex={i}
                {...exam}
                key={"exam" + i}
              />
            );
          default:
            return <React.Fragment></React.Fragment>;
        }
      });
    } catch (ex) {}
  }
  return components;
};

export default ReadCombined;
