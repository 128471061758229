import React from "react";

const Player = (props) => {
  const { title, url } = props;
  return (
    <div
      className="paper-box"
      style={{
        marginTop: "1rem",
        height: "7rem",
        paddingBottom: "0px",
        overflowY: "hidden",
      }}
    >
      <div style={{ height: "50%" }}>
        <h4
          style={{ marginTop: "1rem", marginLeft: "1rem", textAlign: "left" }}
          className="red"
        >
          {title}
        </h4>
      </div>
      <div className="recorder-bottom text-center" style={{ height: "50%" }}>
        <audio
          style={{ height: "25px", marginTop: "5px" }}
          src={url}
          controls
          controlsList="nodownload"
        />
      </div>
    </div>
  );
};

export default Player;
