import * as app from '../types/api';

export const updateApiSending = (payload) => {
  return dispatch => {
    dispatch({
      type    : app.UPDATE_API_SENDING,
      payload : payload,
    });
  };
};
