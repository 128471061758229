import * as actions from "../actions/types/examCount";

const defaultState = {
  SpeakingPart1: 0,
  SpeakingPart2: 0,
  SpeakingPart3: 0,
  ReadingAcPart1: 0,
  ReadingAcPart2: 0,
  ReadingAcPart3: 0,
  ReadingGtPart1: 0,
  ReadingGtPart2: 0,
  ReadingGtPart3: 0,
  ListeningPart1: 0,
  ListeningPart2: 0,
  ListeningPart3: 0,
  ListeningPart4: 0,
  WritingAcPart1: 0,
  WritingAcPart2: 0,
  WritingGtPart1: 0,
  WritingGtPart2: 0,
};

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case actions.UPDATE_EXAM_COUNT: {
      return { ...state, ...action.payload };
    }
    case actions.RESET_EXAM_COUNT: {
      return { ...defaultState };
    }
    default:
      return { ...state };
  }
}
