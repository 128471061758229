import React from 'react';
import { Grid, Container, Segment } from 'semantic-ui-react';
import { Editor } from '@tinymce/tinymce-react';
import * as Parts from '../dashboard/parts';
import * as TINY_MCE_SETTINGS from '../../constants/tinyMceSettings';

class JsonBuilder extends React.Component {
	state = {
		content:""
	}
	updateContent = (editor) => {
		this.setState({content:editor});
	}
	render(){

		return (
			<React.Fragment>
				<Grid doubling stackable padded className="padding-top-1rem padding-bottom-2rem">
					<Parts.Header/>
					<Grid.Row className='footer-margin' columns={2}>
						<Grid.Column>
							<Container style={{paddingTop:"1rem"}}>
								<Segment padded>
                  <Editor
                    init={TINY_MCE_SETTINGS.SETTINGS_NO_BLANK}
										onEditorChange={this.updateContent}
										value={this.state.content}
                  />
								</Segment>
							</Container>
						</Grid.Column>
						<Grid.Column>
							<Container textAlign='center' style={{paddingTop:"1rem"}}>
								<Segment padded>
                  <h4>HTML Result (Copy whats inside the &lt;body>&lt;/body>):</h4>
                  <p>{this.state.content}</p>
									<br/>
								</Segment>
							</Container>
						</Grid.Column>
					</Grid.Row>
					<Parts.Footer/>
				</Grid>
			</React.Fragment>
		);
	}
}

export default JsonBuilder;