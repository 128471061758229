import * as actionTypes from '../types/listenBuilder';

export const reset = () => {
  return dispatch => {
    dispatch({
      type : actionTypes.LISTEN_BUILDER_RESET,
    });
  };
};

export const updateContent = (payload) => {
  return dispatch => {
    dispatch({
      type    : actionTypes.LISTEN_BUILDER_UPDATE_CONTENT,
      payload : payload,
    });
  };
};