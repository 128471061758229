import * as actions from '../actions/types/readBuilder';

const defaultState = {
  code: "",
  content: "",
  instruction: "",
  content_explanation: "",
  instruction_explanation: "",
  hint:"",
  hint_review:"",
  vocab:"",
  exam:{
    exams:[
      // Image Labeling
      {
        "exam_type":"READ_IMAGE_LABELING",
        "start_number":1,
        "total_count":5,
        "content":"",
        "image":"",
        "instruction":"",
        "correct_answer":[""],
        "size":[]
      },
    ]
  },
  exam_explanation:{
    exams:[
      // Image Labeling
      {
        "exam_type":"READ_IMAGE_LABELING",
        "start_number":1,
        "total_count":5,
        "content":"",
        "image":"",
        "instruction":"",
        "correct_answer":[""],
        "size":[]
      },
    ]
  },
  part: "ReadPart1",
  exam_type: "GT",
};

export default function reducer(state=defaultState, action) {
  switch(action.type) {
    case actions.READ_BUILDER_RESET: {
      return {...defaultState};
    }
    case actions.READ_BUILDER_UPDATE_CONTENT: {
      return {...action.payload};
    }
    default: return {...state};
  }
};