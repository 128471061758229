import React from "react";
import { connect } from "react-redux";
import "../../includes/css/style.css";
import "../../includes/css/fontawesome.min.css";
import "../../includes/css/solid.css";
import "../../includes/css/fonts.css";
import * as MainComponents from "./components/reviews";
import * as appActions from "../../actions/app/app";

class ReviewContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openMainNav: this.openMainNav.bind(this),
      closeMainNav: this.closeMainNav.bind(this),
    };
  }
  componentDidMount() {
    this.closeMainNav();
  }
  openMainNav() {
    this.props.dispatch(appActions.updateOpenSidebar(true));
  }
  closeMainNav() {
    this.props.dispatch(appActions.updateOpenSidebar(false));
  }
  render() {
    return (
      <div className="exam-pages">
        <MainComponents.ExamBody />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    fullName: state.auth.full_name,
    openSidebar: state.app.openSidebar,
    user: state.login,
    is_authenticated: state.auth.is_authenticated,
  };
};

export default connect(mapStateToProps)(ReviewContainer);
