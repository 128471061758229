import * as actions from "../actions/types/signup";

const defaultState = {
  username: "",
  email: "",
  first_name: "",
  last_name: "",
  password: "",
  password2: "",
};

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case actions.UPDATE_SIGNUP_FIELDS: {
      return { ...action.payload };
    }
    case actions.RESET_SIGNUP_FIELDS: {
      return { ...defaultState };
    }
    default:
      return { ...state };
  }
}
