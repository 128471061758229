import React from "react";
import { connect } from "react-redux";
import {
  Grid,
  Icon,
  Button,
  Progress,
  Segment,
  TextArea,
} from "semantic-ui-react";
import * as speakPart3Actions from "../../../../../../actions/app/speakPart3";
import * as apiActions from "../../../../../../actions/app/api";
import ReactPlayer from "react-player";
import { ReactMic } from "react-mic";
import AudioPlayer from "./Player";
import Recorder from "./Recorder";
import HintModal from "./HintModal";

function pad(string) {
  return ("0" + string).slice(-2);
}

function format(seconds) {
  const date = new Date(seconds * 1000);
  const mm = date.getUTCMinutes();
  const ss = pad(date.getUTCSeconds());
  return `${pad(mm)}:${pad(ss)}`;
}

class speakPart3 extends React.Component {
  state = {
    openModal: false,
    oscillationOn: false,
    studentAnswer: "",
  };
  openModal = () => {
    this.setState({ openModal: true });
  };
  closeModal = () => {
    this.setState({ openModal: false });
  };
  componentDidMount() {
    const { dispatch, strat_mode } = this.props;
    if (!strat_mode) {
      dispatch(speakPart3Actions.reset());
    }
    dispatch(apiActions.updateApiSending(false));
  }
  componentWillUnmount() {
    this.clearTimer();
    const { dispatch, strat_mode } = this.props;
    if (!strat_mode) {
      dispatch(speakPart3Actions.reset());
    }
  }
  clearTimer() {
    clearInterval(this.timer);
    this.timer = null;
  }
  onStop = () => {
    this.props.startRecording();
    this.props.dispatch(speakPart3Actions.updatePlaying(false));
    this.props.dispatch(speakPart3Actions.updateRecording(true));
    this.timer = setInterval(this.tick.bind(this), 1000);
  };
  tick = () => {
    const { timer, dispatch } = this.props;
    const newTimer = timer - 1;
    if (newTimer >= 0) {
      dispatch(speakPart3Actions.updateTimer(newTimer));
    } else {
      this.stopRecording();
    }
  };
  tick2 = () => {
    const { timer2, dispatch } = this.props;
    const newTimer = timer2 - 1;
    if (newTimer >= 1) {
      dispatch(speakPart3Actions.updateTimer2(newTimer));
    } else {
      this.props.stopRecording();
      this.clearTimer();
      dispatch(speakPart3Actions.updateRecording2(false));
      dispatch(speakPart3Actions.updateTimer2(45));
    }
  };
  stopRecording = () => {
    this.clearTimer();
    const { recording, dispatch } = this.props;
    dispatch(apiActions.updateApiSending(true));
    dispatch(speakPart3Actions.updateRecording(false));
    if (recording) {
      this.props.stopRecording();
    } else {
      dispatch(speakPart3Actions.updatePlaying(false));
      this.props.updateAnswer("");
    }
  };
  toggleRecording(on) {
    if (on) {
      this.timer = setInterval(this.tick2.bind(this), 1000);
      this.props.startRecording();
    } else {
      this.clearTimer();
      this.props.stopRecording();
    }
    this.props.dispatch(speakPart3Actions.updateRecording2(on));
    if (!on) {
      this.props.dispatch(speakPart3Actions.updateTimer2(45));
    }
  }
  finishStratMode = () => {
    const { dispatch, strat_file, recording2 } = this.props;
    if (recording2) {
      this.clearTimer();
      dispatch(speakPart3Actions.updateRecording2(false));
      dispatch(apiActions.updateApiSending(true));
      this.props.stopRecordingStratMode();
    } else {
      if (strat_file !== null) {
        dispatch(apiActions.updateApiSending(true));
        this.props.uploadRecording(strat_file);
      } else {
        this.props.updateAnswer("");
      }
    }
  };
  render() {
    const {
      content,
      active_index,
      strat_mode,
      timer,
      recording,
      current_subtype,
      playing,
      strat_url,
      answers,
      timer2,
      recording2,
      playing2,
    } = this.props;
    const { openModal } = this.state;
    let record_percent = 100 - (timer / 45) * 100;
    let record_percent2 = 100 - (timer2 / 45) * 100;
    return (
      <div>
        <center>
          <Segment className="speaking-title">Part 3</Segment>
          <HintModal
            header="Vocab"
            hint={content[active_index].vocab}
            open={openModal}
            onClose={this.closeModal}
          />
          <ReactPlayer
            key={"player1"}
            className="invisible"
            url={content[active_index].exam.audio[current_subtype]}
            playing={playing}
            onEnded={this.onStop}
          />
          <ReactPlayer
            key={"player2"}
            className="invisible"
            url={content[active_index].exam.audio[current_subtype]}
            playing={playing2}
            onEnded={this.stopPlaying2}
          />
          <Grid
            className="speaking-container"
            columns={"equal"}
            style={{ margin: ".5rem", width: "50%" }}
          >
            {!strat_mode ? (
              <Grid.Column
                className="content-speaking-rec"
                style={{ margin: "1rem" }}
              >
                <p
                  className={
                    recording ? "text-center red" : "text-center red blinking"
                  }
                >
                  Please listen to the question from the Examiner
                </p>
                <div
                  className="paper-box"
                  style={{
                    marginBottom: "2rem",
                    height: "182px",
                    paddingBottom: "0px",
                    overflowY: "hidden",
                  }}
                >
                  <div style={{ height: "65%" }}>
                    <h4
                      style={{
                        marginTop: "1rem",
                        marginLeft: "1rem",
                        textAlign: "left",
                      }}
                      className="red"
                    >
                      Examiner's Question
                    </h4>
                    {!recording || playing2 ? (
                      <React.Fragment>
                        <Icon
                          color={"green"}
                          name={"user"}
                          className="interviewer-icon"
                        />
                        <Icon
                          color={"green"}
                          name={"comment alternate"}
                          className="interviewer-icon"
                        />
                      </React.Fragment>
                    ) : (
                      <Icon
                        color={"red"}
                        name={"user cancel"}
                        className="interviewer-icon"
                      />
                    )}
                  </div>
                  <div className="recorder-bottom text-center"></div>
                </div>
                <p
                  className={
                    recording ? "text-center red blinking" : "text-center red"
                  }
                >
                  Please answer the examiner's question. Your response is being
                  recorder. When you've finished you can click the red stop
                  button or the button "Finish and Proceed" below
                </p>
                <div
                  className="paper-box"
                  style={{
                    marginTop: "2rem",
                    height: "182px",
                    paddingBottom: "0px",
                    overflowY: "hidden",
                  }}
                >
                  <div style={{ height: "65%" }}>
                    <h4
                      style={{
                        marginTop: "1rem",
                        marginLeft: "1rem",
                        textAlign: "left",
                      }}
                      className="red"
                    >
                      Audio Recorder To Record Your "Testing" Answer
                    </h4>
                    <ReactMic
                      key={"microphone"}
                      record={recording}
                      className="sound-wave"
                      backgroundColor="#F5F8FF"
                    />
                  </div>
                  <div className="recorder-bottom text-center">
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div
                        style={{
                          marginTop: "7px",
                          marginLeft: "1rem",
                          marginRight: "1rem",
                        }}
                      >
                        <Button
                          disabled={!recording}
                          onClick={this.stopRecording.bind(this)}
                          circular
                          className={recording ? "blinking" : ""}
                          icon={recording ? "stop" : "circle"}
                          style={{ color: "red" }}
                        />
                      </div>
                      <Progress
                        className="progress-bar"
                        percent={record_percent}
                        size="small"
                        style={{
                          flexGrow: "1",
                          marginTop: "1.46rem",
                          backgroundColor: "#DDDDDD",
                        }}
                      />
                      <div
                        style={{
                          float: "right",
                          marginLeft: "1rem",
                          marginRight: "1rem",
                          marginTop: ".1rem",
                        }}
                      >
                        <p>{format(timer)}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </Grid.Column>
            ) : (
              <Grid.Column
                className="content-speaking-rec"
                style={{ margin: "1rem" }}
              >
                <h4 className="speaking-review-header">
                  Understanding the "Speaking" Learning Section
                </h4>
                <AudioPlayer
                  title="Examiner's Question"
                  url={content[active_index].exam.audio[current_subtype]}
                />
                <AudioPlayer
                  title='Your "Testing" Recorded Answer'
                  url={answers[active_index][current_subtype]}
                />
                <h5 className="speaking-review-header">
                  Your "Learning" Notes (Ideas, Vocabulary, etc.)
                </h5>
                <TextArea className="notes-text" rows={5} />
                <h5 className="speaking-review-header">
                  Your "Learning" Speaking Written Response
                </h5>
                <TextArea className="notes-text" rows={5} />
                <Recorder
                  recording2={recording2}
                  record_percent2={record_percent2}
                  timer={format(timer2)}
                  toggleRecording={this.toggleRecording.bind(this)}
                  title={
                    'Audio Recorder To Record Your "Learning" Answer (You can re-record this until you\'re satisfied with your answer)'
                  }
                />
                <AudioPlayer
                  title={'Your "Learning" Recorded Answer'}
                  url={strat_url}
                />
                {/* <div style={{ marginTop: "1rem" }}>
                <button
                  onClick={this.openModal.bind(this)}
                  className={"speaking-stop-button"}
                >
                  Vocab
                </button>
              </div> */}
              </Grid.Column>
            )}
          </Grid>
          <button
            className={"speaking-stop-button red finish-btn"}
            onClick={
              strat_mode ? this.finishStratMode : this.stopRecording.bind(this)
            }
          >
            Finish And Proceed
          </button>
        </center>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    content: state.speakExam.content,
    active_index: state.speakExam.active_index,
    answers: state.speakExam.answers,
    answers_strat: state.speakExam.answers_strat,
    current_subtype: state.speakExam.current_subtype,
    strat_mode: state.speakExam.strat_mode,
    timer: state.speakPart3.timer,
    timer2: state.speakPart3.timer2,
    playing: state.speakPart3.playing,
    recording: state.speakPart3.recording,
    strat_url: state.speakPart3.strat_url,
    strat_file: state.speakPart3.strat_file,
    recording2: state.speakPart3.recording2,
    playing2: state.speakPart3.playing2,
  };
};

export default connect(mapStateToProps)(speakPart3);
