import React from 'react';
import { Form, Checkbox } from 'semantic-ui-react';
import Instruction from '../Instruction';;

const ReadRadioCheckbox = (props) => {
    const {
      questions,
      start_number,
      parentIndex,
      handleCheckboxOnChange,
      answers,
      active_index,
      answers_strat,
      strat_mode,
      explanation,
      correct_answer,
    } = props;
    const blanks = questions.map((obj, i) => {
      let value = [];
      if (answers.length > 0 && answers_strat.length > 0){
        value = answers[active_index][parentIndex][i].split(",");
        if (strat_mode){
          value = answers_strat[active_index][parentIndex][i].split(",");
        }
      }
      return (
        <React.Fragment key={"question" + i}>
          <Form.Field>
            {obj.number? obj.number + ". ":start_number + i + ". "}
            <span dangerouslySetInnerHTML={{__html:obj.text}}/>
          </Form.Field>
          {
            obj.choices.map((choice, j) => {
              const answer = j + 1;
              return (
                <Form.Field inline key={"choice" + j} style={{lineHeight:"17px"}}>
                  <Checkbox
                    style={{paddingTop:'3px'}}
                    name={'chkboxGroup'+i}
                    onClick={handleCheckboxOnChange.bind(this, parentIndex, i, j+1, answers.length > 0 && answers[active_index][parentIndex][i].indexOf(j+1) === -1)}
                    key={`readcheckboxmcq${parentIndex}${i}`}
                    checked={explanation? correct_answer[i].indexOf(j+1) !== -1 :value.indexOf(answer.toString()) !== -1}
                  />
                  <span dangerouslySetInnerHTML={{__html:choice}} />
                </Form.Field>
              );
            })
          }
          {
            i + 1 < questions.length?
            <br/>:null
          }
        </React.Fragment>
      )
    });
		return (
      <React.Fragment>
        <Instruction {...props} />
        <Form className="read-mcq-radio">
          {blanks}
        </Form>
      </React.Fragment>
		);
}

export default ReadRadioCheckbox;