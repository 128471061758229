import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Button } from "semantic-ui-react";

const componentStyle = {
  display: "flex",
  textAlign: "center",
};

const btnStyle1 = {
  float: "left",
  fontSize: ".65rem",
};

const selectStyle1 = {
  float: "left",
  marginTop: "2px",
};

const selectStyle2 = {
  float: "right",
  marginTop: "2px",
};

class FirstPage extends React.Component {
  state = {
    playbackRate: "1",
    rewindSec: "1",
  };
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
  }
  handleRewindSecChange = (e) => {
    this.setState({ rewindSec: e.target.value });
  };
  rewind() {
    const { rewindSec } = this.state;
    const backSec = parseInt(rewindSec, 10);
    const newTime = this.myRef.current.currentTime - backSec;
    if (newTime < 0) {
      this.myRef.current.currentTime = 0;
    } else {
      this.myRef.current.currentTime = newTime;
    }
  }
  forward() {
    const { rewindSec } = this.state;
    const backSec = parseInt(rewindSec, 10);
    const newTime = this.myRef.current.currentTime + backSec;
    if (newTime < 0) {
      this.myRef.current.currentTime = 0;
    } else {
      this.myRef.current.currentTime = newTime;
    }
  }
  handlePlaybackChange = (e) => {
    this.setState({ playbackRate: e.target.value });
    const playbackRate = parseFloat(e.target.value);
    this.myRef.current.playbackRate = playbackRate;
  };
  render() {
    const { audio_url, key } = this.props;
    const { rewindSec, playbackRate } = this.state;
    return (
      <div style={componentStyle} key={key}>
        <div style={{ flex: 1, display: "flex" }}>
          <Button
            style={btnStyle1}
            icon="backward"
            size="mini"
            onClick={this.rewind.bind(this)}
          />
          <select
            style={selectStyle1}
            onChange={this.handleRewindSecChange.bind(this)}
            value={rewindSec}
          >
            <option value={"1"}>1 sec</option>
            <option value={"2"}>2 sec</option>
            <option value={"3"}>3 sec</option>
            <option value={"4"}>4 sec</option>
            <option value={"5"}>5 sec</option>
          </select>
          <Button
            style={btnStyle1}
            icon="forward"
            size="mini"
            onClick={this.forward.bind(this)}
          />
        </div>
        <div style={{ flex: 4, display: "flex" }}>
          <audio
            ref={this.myRef}
            style={{ height: "23px" }}
            src={audio_url}
            controls
            controlsList="nodownload"
          />
        </div>
        <div style={{ flex: 1, display: "flex" }}>
          {/* <select
            style={selectStyle2}
            onChange={this.handlePlaybackChange.bind(this)}
            value={playbackRate}
          >
            <option value={".7"}>0.7</option>
            <option value={".8"}>0.8</option>
            <option value={".9"}>0.9</option>
            <option value={"1"}>normal</option>
            <option value={"1.25"}>1.25</option>
            <option value={"1.5"}>1.5</option>
            <option value={"1.75"}>1.75</option>
            <option value={"2.0"}>2</option>
          </select> */}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

export default withRouter(connect(mapStateToProps)(FirstPage));
