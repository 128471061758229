import * as mockExamTable from '../types/mockExamTable';

export const updateContent = (payload) => {
  return dispatch => {
    dispatch({
      type    : mockExamTable.UPDATE_MOCK_EXAM_TABLE_CONTENT,
      payload : payload,
    });
  };
};

export const updateCurrentPage = (payload) => {
  return dispatch => {
    dispatch({
      type    : mockExamTable.UPDATE_MOCK_EXAM_TABLE_CURRENT_ACTIVE_PAGE,
      payload : payload,
    });
  };
};

