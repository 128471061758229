import React from 'react';
import { Menu, Icon, Dropdown } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import * as readActions from '../../../actions/app/readBuilder';
import * as listenActions from '../../../actions/app/listenBuilder';
import * as speakActions from '../../../actions/app/speakBuilder';
import * as writeActions from '../../../actions/app/writeBuilder';

class Sidebar extends React.Component {
  redirectTo = (url) => {
    this.resetAllReducers();
    const { history } = this.props;
    history.push(url);
  }
  resetAllReducers(){
    const { dispatch } = this.props;
    dispatch(readActions.reset());
    dispatch(listenActions.reset());
    dispatch(speakActions.reset());
    dispatch(writeActions.reset());
  }
  render(){
    const { is_staff } = this.props;
    return (
      <Menu vertical fluid>
        {
          is_staff?
            <React.Fragment>
              <Dropdown text='Build an exam' pointing='left' className='link item dropdown-bold'>
                <Dropdown.Menu>
                  <Dropdown.Item onClick={this.redirectTo.bind(this, '/dashboard/create/listening')}><h5><Icon name='sound'/> Listening</h5></Dropdown.Item>
                  <Dropdown.Item onClick={this.redirectTo.bind(this, '/dashboard/create/reading')}><h5><Icon name='book'/> Reading</h5></Dropdown.Item>
                  <Dropdown.Item onClick={this.redirectTo.bind(this, '/dashboard/create/writing')}><h5><Icon name='pencil alternate' /> Writing</h5></Dropdown.Item>
                  <Dropdown.Item onClick={this.redirectTo.bind(this, '/dashboard/create/speaking')}><h5><Icon name='microphone' /> Speaking</h5></Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              <Dropdown text='Edit an exam' pointing='left' className='link item dropdown-bold'>
                <Dropdown.Menu>
                  <Dropdown.Item onClick={this.redirectTo.bind(this, '/dashboard/edit/listening')}><h5><Icon name='sound'/> Listening</h5></Dropdown.Item>
                  <Dropdown.Item onClick={this.redirectTo.bind(this, '/dashboard/edit/reading')}><h5><Icon name='book'/> Reading</h5></Dropdown.Item>
                  <Dropdown.Item onClick={this.redirectTo.bind(this, '/dashboard/edit/writing')}><h5><Icon name='pencil alternate' /> Writing</h5></Dropdown.Item>
                  <Dropdown.Item onClick={this.redirectTo.bind(this, '/dashboard/edit/speaking')}><h5><Icon name='microphone' /> Speaking</h5></Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </React.Fragment>
            :
            <Dropdown text='Create an exam' pointing='left' className='link item dropdown-bold'>
              <Dropdown.Menu>
                <Dropdown.Item onClick={this.redirectTo.bind(this, '/dashboard/build/listen')}><h5><Icon name='sound'/> Listening</h5></Dropdown.Item>
                <Dropdown.Item onClick={this.redirectTo.bind(this, '/dashboard/build/read')}><h5><Icon name='book'/> Reading</h5></Dropdown.Item>
                <Dropdown.Item onClick={this.redirectTo.bind(this, '/dashboard/build/write')}><h5><Icon name='pencil alternate' /> Writing</h5></Dropdown.Item>
                <Dropdown.Item onClick={this.redirectTo.bind(this, '/dashboard/build/speak')}><h5><Icon name='microphone' /> Speaking</h5></Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
        }
      </Menu>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    is_staff: state.auth.is_staff,
  }
};

export default withRouter(connect(mapStateToProps)(Sidebar));