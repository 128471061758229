export const SPEAK_EXAM_UPDATE_TIMER = "SPEAK_EXAM_UPDATE_TIMER";
export const SPEAK_EXAM_RESET_SPEAK_EXAM = "SPEAK_EXAM_RESET_SPEAK_EXAM";
export const SPEAK_EXAM_RESET_TIMER = "SPEAK_EXAM_RESET_TIMER";
export const SPEAK_EXAM_UPDATE_ACTIVE_INDEX = "SPEAK_EXAM_UPDATE_ACTIVE_INDEX";
export const SPEAK_EXAM_UPDATE_STRAT_MODE = "SPEAK_EXAM_UPDATE_STRAT_MODE";
export const SPEAK_EXAM_UPDATE_CONTENT = "SPEAK_EXAM_UPDATE_CONTENT";
export const SPEAK_EXAM_UPDATE_ANSWERS = "SPEAK_EXAM_UPDATE_ANSWERS";
export const SPEAK_EXAM_UPDATE_ANSWERS_STRAT =
  "SPEAK_EXAM_UPDATE_ANSWERS_STRAT";
export const SPEAK_EXAM_UPDATE_SUBTYPE = "SPEAK_EXAM_UPDATE_SUBTYPE";
export const SPEAK_EXAM_UPDATE_RECORDING = "SPEAK_EXAM_UPDATE_RECORDING";
export const SPEAK_EXAM_UPDATE_PLAYING = "SPEAK_EXAM_UPDATE_PLAYING";
export const SPEAK_EXAM_UPDATE_ADMIN_QUESTION_MODE =
  "SPEAK_EXAM_UPDATE_ADMIN_QUESTION_MODE";
export const SPEAK_EXAM_UPDATE_ADMIN_QUESTION_INDEX =
  "SPEAK_EXAM_UPDATE_ADMIN_QUESTION_INDEX";
export const SPEAK_EXAM_UPDATE_NOTES_1 = "SPEAK_EXAM_UPDATE_NOTES_1";
export const SPEAK_EXAM_UPDATE_NOTES_2 = "SPEAK_EXAM_UPDATE_NOTES_2";
export const SPEAK_EXAM_UPDATE_NOTES_3 = "SPEAK_EXAM_UPDATE_NOTES_3";
