import axios from "axios";
import * as apiActions from "../app/api";
import * as API from "../../constants/urls";
import * as authActions from "../app/auth";
import * as historyActions from "../app/answerHistory";
import * as readExam from "../app/readExam";
import * as listenExam from "../app/listenExam";
import * as writeExam from "../app/writeExam";
import * as speakExam from "../app/speakExam";
import * as answerInstance from "../app/answerInstance";

import * as writeBuilder from "../app/writeBuilder";
import * as speakBuilder from "../app/speakBuilder";
import * as readBuilder from "../app/readBuilder";
import * as listenBuilder from "../app/listenBuilder";

import * as readingTable from "../app/readingTable";
import * as listeningTable from "../app/listeningTable";
import * as writingTable from "../app/writingTable";
import * as speakingTable from "../app/speakingTable";
import * as nonStudent from "../app/nonStudent";
import * as nonStudentList from "../app/nonStudentList";
import * as studentIPs from "../app/studentIPs";
import * as marketing from "../app/marketing";
import * as mockExamTable from "../app/mockExamTable";

import * as mockTest from "../app/mockTest";
import * as examCount from "../app/examCount";

import { Auth, API as AwsAPI } from "aws-amplify";

const api = "ielts-appify-api";

import _ from "lodash";

export function login(user, history) {
  return function (dispatch) {
    Auth.signIn(user.username, user.password)
      .then(function (response) {
        const { given_name, family_name } = response.attributes;
        dispatch(authActions.updateUsername(user.username));
        dispatch(authActions.updateFullName(`${given_name} ${family_name}`));
        dispatch(
          authActions.updateIsStaff(
            response.attributes["custom:is_staff"] === "true"
          )
        );
        dispatch(authActions.updateIsAuthenticated(true));
        dispatch(apiActions.updateApiSending(false));
        history.push("/dashboard");
      })
      .catch(function (error) {
        console.log(error);
        alert(
          "Invalid credentials or email has not been verified. Please try again."
        );
        dispatch(apiActions.updateApiSending(false));
      });
  };
}

export function signup(user, history) {
  return function (dispatch) {
    const { username, password, first_name, last_name } = user;
    Auth.signUp({
      username,
      password,
      attributes: {
        given_name: first_name,
        family_name: last_name,
        email: username,
        "custom:is_staff": "false",
      },
    })
      .then(function (response) {
        (async () => {
          dispatch(apiActions.updateApiSending(false));
          alert(
            "Successfully signed up. Please verify your email before logging in."
          );
          history.push("/");
        })();
      })
      .catch(function (error) {
        if (error.toString().includes("UsernameExistsException")) {
          alert("This username is no longer available.");
        } else {
          alert(
            "Something went wrong. Please try again later or contact the staff."
          );
        }
        dispatch(apiActions.updateApiSending(false));
      });
  };
}

export function generateReadingExam(data, closeModal, history) {
  return function (dispatch) {
    AwsAPI.post(api, "/exam/reading", { body: data })
      .then((examData) => {
        dispatch(apiActions.updateApiSending(false));
        if (examData.exams.length === 0) {
          alert("There are no more tests available.");
          closeModal();
          return;
        }
        examData.exams.forEach((exam, i) => {
          exam.exam = JSON.parse(exam.exam);
        });
        dispatch(readExam.reset());
        dispatch(readExam.updateContent(examData.exams));
        dispatch(answerInstance.updateContent(examData.answer));
        closeModal();
        history.push("/exam/read");
      })
      .catch((error) => {
        console.error(error);
        alert("Unable to proceed with exam generation. Please try again.");
        dispatch(apiActions.updateApiSending(false));
        closeModal();
      });
  };
}

export function generateListeningExam(data, closeModal, history) {
  return function (dispatch) {
    AwsAPI.post(api, "/exam/listening", { body: data })
      .then((examData) => {
        dispatch(apiActions.updateApiSending(false));
        if (examData.exams.length === 0) {
          alert("There are no more tests available.");
          closeModal();
          return;
        }
        examData.exams.forEach((exam, i) => {
          exam.exam = JSON.parse(exam.exam);
        });
        dispatch(listenExam.reset());
        dispatch(listenExam.updateContent(examData.exams));
        dispatch(answerInstance.updateContent(examData.answer));
        closeModal();
        history.push("/exam/listen");
      })
      .catch((error) => {
        console.error(error);
        alert("Unable to proceed with exam generation. Please try again.");
        dispatch(apiActions.updateApiSending(false));
        closeModal();
      });
  };
}

export function generateWritingExam(data, closeModal, history) {
  return function (dispatch) {
    AwsAPI.post(api, "/exam/writing", { body: data })
      .then((examData) => {
        dispatch(apiActions.updateApiSending(false));
        if (examData.exams.length === 0) {
          alert("There are no more tests available.");
          closeModal();
          return;
        }
        dispatch(writeExam.reset());
        dispatch(writeExam.updateContent(examData.exams));
        dispatch(answerInstance.updateContent(examData.answer));
        closeModal();
        history.push("/exam/write");
      })
      .catch((error) => {
        console.error(error);
        alert("Unable to proceed with exam generation. Please try again.");
        dispatch(apiActions.updateApiSending(false));
        closeModal();
      });
  };
}

export function generateSpeakingExam(data, closeModal, history) {
  return function (dispatch) {
    AwsAPI.post(api, "/exam/speaking", { body: data })
      .then((examData) => {
        dispatch(apiActions.updateApiSending(false));
        if (examData.exams.length === 0) {
          alert("There are no more tests available.");
          closeModal();
          return;
        }
        examData.exams.forEach((exam) => {
          exam.audio = JSON.parse(exam.audio);
        });
        dispatch(speakExam.reset());
        dispatch(speakExam.updateContent(examData.exams));
        dispatch(answerInstance.updateContent(examData.answer));
        closeModal();
        history.push("/exam/speak");
      })
      .catch((error) => {
        console.error(error);
        alert("Unable to proceed with exam generation. Please try again.");
        dispatch(apiActions.updateApiSending(false));
        closeModal();
      });
  };
}

export function getAnswers() {
  return function (dispatch) {
    AwsAPI.get(api, "/answer", {})
      .then(function (response) {
        dispatch(historyActions.updateContent(response));
        dispatch(apiActions.updateApiSending(false));
      })
      .catch(function (response) {
        console.error(response);
        dispatch(historyActions.updateContent([]));
        dispatch(apiActions.updateApiSending(false));
      });
  };
}

export function deleteAnswer(id) {
  return function (dispatch) {
    AwsAPI.del(api, "/answer/" + id, {})
      .then(function (response) {
        dispatch(getAnswers());
        alert("Answer successfully removed.");
      })
      .catch(function (response) {
        console.error(response);
        dispatch(historyActions.updateContent([]));
        dispatch(apiActions.updateApiSending(false));
      });
  };
}

export function updateAnswerInstance(data, history, url) {
  data.answers = JSON.stringify(data.answers);
  data.answers_strat = JSON.stringify(data.answers_strat);
  data.content = JSON.stringify(data.content);
  data.notes_1 = JSON.stringify(data.notes_1);
  data.notes_2 = JSON.stringify(data.notes_2);
  data.notes_3 = JSON.stringify(data.notes_3);
  return function (dispatch) {
    AwsAPI.patch(api, `/answer/${data.id}`, { body: data })
      .then(function () {
        dispatch(apiActions.updateApiSending(false));
        history.push(url);
      })
      .catch(function () {
        alert("Unable to update answer. Please try again.");
        dispatch(apiActions.updateApiSending(false));
      });
  };
}

export function updateAnswerInstance2(data) {
  return function (dispatch) {
    dispatch(apiActions.updateApiSending(true));
    AwsAPI.patch(api, `/answer/${data.id}`, { body: data })
      .then(function () {
        dispatch(apiActions.updateApiSending(false));
        alert("Notes and answer updated.");
      })
      .catch(function () {
        alert("Unable to update notes and answer. Please try again.");
        dispatch(apiActions.updateApiSending(false));
      });
  };
}

export function createWritingExam(data) {
  return function (dispatch) {
    AwsAPI.post(api, "/write", { body: { ...data } })
      .then(function (response) {
        dispatch(apiActions.updateApiSending(false));
        dispatch(writeBuilder.reset());
        alert("Successfully created exam");
      })
      .catch(function (error) {
        console.error(error);
        alert("Unable to create exam");
        dispatch(apiActions.updateApiSending(false));
      });
  };
}

export function createWritingExamWithHistory(data, history) {
  return function (dispatch) {
    AwsAPI.post(api, "/write", { body: data })
      .then((response) => {
        dispatch(apiActions.updateApiSending(false));
        dispatch(writeBuilder.reset());
        alert("Successfully created exam");
        dispatch(history.push("/dashboard/edit/writing"));
      })
      .catch(function (response) {
        alert("Unable to create a new exam.");
        dispatch(apiActions.updateApiSending(false));
      });
  };
}

export function updateWritingExam(data, history) {
  return function (dispatch) {
    AwsAPI.patch(api, `/write/${data.id}`, { body: { ...data } })
      .then((response) => {
        dispatch(apiActions.updateApiSending(false));
        dispatch(writeBuilder.reset());
        alert("Successfully updated exam");
        history.push("/dashboard/edit/writing");
      })
      .catch((error) => {
        console.error(error);
        alert("Unable to update writing exam.");
        dispatch(apiActions.updateApiSending(false));
      });
  };
}

export function createSpeakingExam(data) {
  return function (dispatch) {
    AwsAPI.post(api, "/speak", { body: data })
      .then(function (response) {
        dispatch(apiActions.updateApiSending(false));
        dispatch(speakBuilder.reset());
        alert("Successfully created speaking exam.");
      })
      .catch(function (response) {
        dispatch(apiActions.updateApiSending(false));
        console.error(error);
        alert("Unable to create speaking exam.");
      });
  };
}

export function createSpeakingExamWithHistory(data, history) {
  data.audio = JSON.stringify(data.audio);
  return function (dispatch) {
    AwsAPI.post(api, "/speak", { body: data })
      .then(function (response) {
        dispatch(apiActions.updateApiSending(false));
        dispatch(speakBuilder.reset());
        alert("Successfully created exam");
        history.push("/dashboard/edit/speaking");
      })
      .catch(function (response) {
        dispatch(apiActions.updateApiSending(false));
        console.error(error);
        alert("Unable to create speaking exam.");
      });
  };
}

export function updateSpeakingExam(data, history) {
  data.audio = JSON.stringify(data.audio);
  return function (dispatch) {
    AwsAPI.patch(api, `/speak/${data.id}`, { body: data })
      .then(function (response) {
        dispatch(apiActions.updateApiSending(false));
        dispatch(speakBuilder.reset());
        alert("Successfully updated exam");
        history.push("/dashboard/edit/speaking");
      })
      .catch(function (response) {
        dispatch(apiActions.updateApiSending(false));
        console.error(error);
        alert("Unable to update speaking exam.");
      });
  };
}

export function createReadingExam(data) {
  data.exam = JSON.stringify(data.exam);
  data.exam_explanation = JSON.stringify(data.exam_explanation);
  return function (dispatch) {
    AwsAPI.post(api, "/read", { body: data })
      .then(function (response) {
        dispatch(apiActions.updateApiSending(false));
        dispatch(readBuilder.reset());
        alert("Successfully created reading exam");
      })
      .catch(function (response) {
        alert("Unable to create reading exam.");
        dispatch(apiActions.updateApiSending(false));
      });
  };
}

export function createReadingExamWithHistory(data, history) {
  data.exam = JSON.stringify(data.exam);
  data.exam_explanation = JSON.stringify(data.exam_explanation);
  return function (dispatch) {
    AwsAPI.post(api, "/read", { body: data })
      .then(function (response) {
        dispatch(apiActions.updateApiSending(false));
        dispatch(readBuilder.reset());
        alert("Successfully created reading exam.");
        history.push("/dashboard/edit/reading");
      })
      .catch(function (response) {
        alert("Unable to create reading exam.");
        dispatch(apiActions.updateApiSending(false));
      });
  };
}

export function updateReadingExam(data, history) {
  data.exam = JSON.stringify(data.exam);
  data.exam_explanation = JSON.stringify(data.exam_explanation);
  return function (dispatch) {
    AwsAPI.patch(api, `/read/${data.id}`, { body: data })
      .then(function (response) {
        dispatch(apiActions.updateApiSending(false));
        dispatch(readBuilder.reset());
        history.push("/dashboard/edit/reading");
        alert("Successfully updated reading exam.");
      })
      .catch(function (response) {
        console.error(response);
        alert("Unable to update reading exam.");
        dispatch(apiActions.updateApiSending(false));
      });
  };
}

export function createListeningExam(data) {
  data.exam = JSON.stringify(data.exam);
  data.exam_explanation = JSON.stringify(data.exam_explanation);
  return function (dispatch) {
    AwsAPI.post(api, "/listen", { body: data })
      .then(function (response) {
        dispatch(apiActions.updateApiSending(false));
        dispatch(listenBuilder.reset());
        alert("Successfully created listening exam");
      })
      .catch(function (response) {
        console.error(response);
        alert("Unable to create listening exam.");
        dispatch(apiActions.updateApiSending(false));
      });
  };
}

export function createListeningExamWithHistory(data, history) {
  data.exam = JSON.stringify(data.exam);
  data.exam_explanation = JSON.stringify(data.exam_explanation);
  return function (dispatch) {
    AwsAPI.post(api, "/listen", { body: data })
      .then(function (response) {
        dispatch(apiActions.updateApiSending(false));
        dispatch(listenBuilder.reset());
        alert("Successfully created exam");
        history.push("/dashboard/edit/listening");
      })
      .catch(function (response) {
        console.error(response);
        alert("Unable to create listening exam.");
        dispatch(apiActions.updateApiSending(false));
      });
  };
}

export function updateListeningExam(data, history) {
  data.exam = JSON.stringify(data.exam);
  data.exam_explanation = JSON.stringify(data.exam_explanation);
  return function (dispatch) {
    AwsAPI.patch(api, `/listen/${data.id}`, { body: data })
      .then(function (response) {
        dispatch(apiActions.updateApiSending(false));
        dispatch(listenBuilder.reset());
        alert("Successfully updated listening exam");
        history.push("/dashboard/edit/listening");
      })
      .catch(function (response) {
        console.error(response);
        alert("Unable to update listening exam.");
        dispatch(apiActions.updateApiSending(false));
      });
  };
}

export function getReadingExams() {
  return function (dispatch) {
    AwsAPI.get(api, "/read", {})
      .then(function (data) {
        dispatch(readingTable.updateContent(data));
        dispatch(apiActions.updateApiSending(false));
      })
      .catch(function (response) {
        alert("Unable to get reading exams.");
        dispatch(apiActions.updateApiSending(false));
      });
  };
}

export function getListeningExams() {
  return function (dispatch) {
    AwsAPI.get(api, "/listen", {})
      .then(function (response) {
        dispatch(listeningTable.updateContent(response));
        dispatch(apiActions.updateApiSending(false));
      })
      .catch(function (response) {
        console.error(response);
        dispatch(apiActions.updateApiSending(false));
        alert("Unable to get listening exams.");
      });
  };
}

export function getWritingExams() {
  return function (dispatch) {
    AwsAPI.get(api, "/write", {})
      .then((data) => {
        dispatch(writingTable.updateContent(data));
        dispatch(apiActions.updateApiSending(false));
      })
      .catch((error) => {
        alert("Unable to get writing exams.");
        dispatch(apiActions.updateApiSending(false));
      });
  };
}

export function getSpeakingExams() {
  return function (dispatch) {
    AwsAPI.get(api, "/speak", {})
      .then(function (response) {
        const newData = response.slice();
        newData.forEach((item) => {
          item.audio = JSON.parse(item.audio);
        });
        dispatch(speakingTable.updateContent(newData));
        dispatch(apiActions.updateApiSending(false));
      })
      .catch(function (response) {
        console.error(response);
        dispatch(apiActions.updateApiSending(false));
        alert("Unable to get speaking exams.");
      });
  };
}

export function createMockTest(data, reset) {
  return function (dispatch) {
    AwsAPI.post(api, "/mock-test", { body: data })
      .then(function (response) {
        dispatch(apiActions.updateApiSending(false));
        reset();
        alert("Successfully created mock test");
      })
      .catch(function (response) {
        dispatch(apiActions.updateApiSending(false));
        alert("Unable to create mock test.");
      });
  };
}

export function updateMockTest(data, history) {
  return function (dispatch) {
    AwsAPI.patch(api, `/mock-test/${data.id}`, { body: data })
      .then(function (response) {
        dispatch(apiActions.updateApiSending(false));
        alert("Successfully updated mock test");
        history.push("/dashboard/edit/mock-test");
      })
      .catch(function (response) {
        dispatch(apiActions.updateApiSending(false));
        alert("Unable to update mock test.");
      });
  };
}

export function getMockExams() {
  return function (dispatch) {
    AwsAPI.get(api, `/mock-test`, {})
      .then(function (response) {
        const tests = response;
        tests.forEach((data) => {
          data.listening = JSON.parse(data.listening);
          data.reading = JSON.parse(data.reading);
          data.speaking = JSON.parse(data.speaking);
          data.writing = JSON.parse(data.writing);
        });
        dispatch(mockExamTable.updateContent(tests));
        dispatch(apiActions.updateApiSending(false));
      })
      .catch(function (response) {
        console.log(response);
        dispatch(apiActions.updateApiSending(false));
        alert("Unable to get mock tests.");
      });
  };
}

export function startMockTestStudent(data, history) {
  return function (dispatch) {
    AwsAPI.post(api, "/mock-test-student", { body: data })
      .then(function (response) {
        dispatch(mockTest.updateContent(response.data));
        dispatch(listenExam.reset());
        dispatch(readExam.reset());
        dispatch(writeExam.reset());
        dispatch(speakExam.reset());
        try {
          response.data.listening.forEach((exam) => {
            exam.exam.exam = JSON.parse(exam.exam.exam);
          });
        } catch (ex) {}
        dispatch(listenExam.updateContent(response.data.listening));
        try {
          response.data.reading.forEach((exam) => {
            exam.exam.exam = JSON.parse(exam.exam.exam);
          });
        } catch (ex) {}
        dispatch(readExam.updateContent(response.data.reading));
        dispatch(readExam.updateTimer(3600));
        dispatch(writeExam.updateContent(response.data.writing));
        dispatch(writeExam.updateTimer(3600));
        try {
          response.data.speaking.forEach((exam) => {
            exam.exam.audio = JSON.parse(exam.exam.audio);
          });
        } catch (ex) {}
        dispatch(speakExam.updateContent(response.data.speaking));
        dispatch(apiActions.updateApiSending(false));
        history.push("/exam/mock-test");
      })
      .catch(function (response) {
        console.log(response);
        dispatch(apiActions.updateApiSending(false));
        alert(
          "No mock tests available. Please contact the admin for further assistance."
        );
      });
  };
}

export function startMockTest(data, history) {
  return function (dispatch) {
    axios
      .post(API.GENERATE_MOCK_TEST_API, data)
      .then(function (response) {
        dispatch(mockTest.updateContent(response.data.data));
        dispatch(listenExam.reset());
        dispatch(readExam.reset());
        dispatch(writeExam.reset());
        dispatch(speakExam.reset());
        try {
          response.data.data.listening.forEach((exam) => {
            exam.exam.exam = JSON.parse(exam.exam.exam);
          });
        } catch (ex) {}
        dispatch(listenExam.updateContent(response.data.data.listening));
        try {
          response.data.data.reading.forEach((exam) => {
            exam.exam.exam = JSON.parse(exam.exam.exam);
          });
        } catch (ex) {}
        dispatch(readExam.updateContent(response.data.data.reading));
        dispatch(readExam.updateTimer(3600));
        dispatch(writeExam.updateContent(response.data.data.writing));
        dispatch(writeExam.updateTimer(3600));
        try {
          response.data.data.speaking.forEach((exam) => {
            exam.exam.audio = JSON.parse(exam.exam.audio);
          });
        } catch (ex) {}
        dispatch(speakExam.updateContent(response.data.data.speaking));
        dispatch(apiActions.updateApiSending(false));
        history.push("/mock-test");
      })
      .catch(function (response) {
        if (response.response.data.error) {
          alert(response.response.data.error);
        } else {
          alert(response.toString());
        }
        dispatch(apiActions.updateApiSending(false));
      });
  };
}

export function previewMockTest(data) {
  return function (dispatch) {
    AwsAPI.post(api, "/preview-mock-test", { body: data })
      .then(function (response) {
        dispatch(mockTest.updateContent(response.data));
        dispatch(listenExam.reset());
        dispatch(readExam.reset());
        dispatch(writeExam.reset());
        dispatch(speakExam.reset());
        response.data.listening.forEach((exam) => {
          exam.exam.exam = JSON.parse(exam.exam.exam);
        });
        dispatch(listenExam.updateContent(response.data.listening));
        response.data.reading.forEach((exam) => {
          exam.exam.exam = JSON.parse(exam.exam.exam);
        });
        dispatch(readExam.updateContent(response.data.reading));
        dispatch(readExam.updateTimer(3600));
        dispatch(writeExam.updateContent(response.data.writing));
        dispatch(writeExam.updateTimer(3600));
        response.data.speaking.forEach((exam) => {
          exam.exam.audio = JSON.parse(exam.exam.audio);
        });
        dispatch(speakExam.updateContent(response.data.speaking));
        dispatch(apiActions.updateApiSending(false));
        window.open("/exam/mock-test", "_blank");
      })
      .catch(function (response) {
        console.log(response);
        dispatch(apiActions.updateApiSending(false));
        alert("Unable to preview mock test.");
      });
  };
}

export function createAnswerInstance(data, history) {
  data.answers = JSON.stringify(data.answers);
  data.answers_strat = JSON.stringify(data.answers_strat);
  data.content = JSON.stringify(data.content);
  delete data.answers_strat;
  return function (dispatch) {
    AwsAPI.post(api, "/answer", { body: data })
      .then(function () {
        dispatch(apiActions.updateApiSending(false));
        history.push("/review/mock-test");
      })
      .catch(function (response) {
        dispatch(apiActions.updateApiSending(false));
        alert(
          "Unable to complete the exam. Please contact the admin for assistance."
        );
      });
  };
}

export function lookUpCountry(profile) {
  return function (dispatch) {
    axios
      .get(API.IP_LOOKUP)
      .then(function (response) {
        profile.country = response.data.country;
        dispatch(nonStudent.updateProfile(profile));
      })
      .catch(function (response) {
        profile.country = "Unable to detect country";
        dispatch(nonStudent.updateProfile(profile));
      });
  };
}

export function registerInternational(profile, history) {
  return function (dispatch) {
    axios
      .post(API.NON_STUDENT_ITL_API, profile)
      .then(function (response) {
        response.data["test"] = profile.test;
        dispatch(nonStudent.updateProfile(response.data));
        dispatch(
          startMockTest(
            { exam_type: profile.test, test_type: profile.test_type },
            history
          )
        );
      })
      .catch(function (response) {
        alert(
          "Unable to proceed with the test. Please contact the administrator."
        );
        dispatch(apiActions.updateApiSending(false));
      });
  };
}

export function registerSingapore(profile, history) {
  return function (dispatch) {
    axios
      .post(API.NON_STUDENT_SG_API, profile)
      .then(function (response) {
        dispatch(nonStudent.updateProfile(response.data));
        dispatch(
          startMockTest(
            { exam_type: profile.test, test_type: profile.test_type },
            history
          )
        );
      })
      .catch(function (response) {
        alert(
          "Unable to proceed with the test. Please contact the administrator."
        );
        dispatch(apiActions.updateApiSending(false));
      });
  };
}

export function createNonStudentAnswerInstance(data, profile, history) {
  data.user = parseInt(profile.id, 10);
  data.sg = profile.country.toLowerCase() === "sg";
  data.answers = JSON.stringify(data.answers);
  data.answers_strat = JSON.stringify(data.answers_strat);
  data.content = JSON.stringify(data.content);
  delete data.answers_strat;
  return function (dispatch) {
    axios
      .post(API.NON_STUDENT_ANSWER_API, data)
      .then(function (response) {
        if (data.sg) {
          window.location = API.SG_EXAM_DESTINATION_URL;
        } else {
          window.location = API.INT_EXAM_DESTINATION_URL;
        }
      })
      .catch(function (response) {
        if (response) {
          alert(response);
        } else {
          alert(response.toString());
        }
        dispatch(apiActions.updateApiSending(false));
      });
  };
}

export function getNonStudentAnswerInstance(id) {
  return function (dispatch) {
    axios
      .get(API.NON_STUDENT_ANSWER_API + id)
      .then(function (response) {
        const data = response.data;
        data.answers = JSON.parse(data.answers);
        data.content = JSON.parse(data.content);
        dispatch(answerInstance.reset());
        dispatch(answerInstance.updateContent(response.data));
        dispatch(getMarketing(data.test_type !== "5"));
      })
      .catch(function (response) {
        alert("Your link is broken. Please contact us to have this fixed.");
        dispatch(apiActions.updateApiSending(false));
      });
  };
}

export function getMarketing(individual) {
  return function (dispatch) {
    axios
      .get(API.MARKETING_API + "?individual=" + individual)
      .then(function (response) {
        if (response.data.length > 0) {
          const data = response.data[0];
          dispatch(marketing.update(data));
        }
        dispatch(apiActions.updateApiSending(false));
      })
      .catch(function (response) {
        dispatch(apiActions.updateApiSending(false));
      });
  };
}

export function updateMarketing(data) {
  return function (dispatch) {
    axios
      .patch(API.MARKETING_API + data.id + "/", data)
      .then(function (response) {
        alert("Successfully updated the marketing content");
        dispatch(apiActions.updateApiSending(false));
      })
      .catch(function (response) {
        alert(response);
        dispatch(apiActions.updateApiSending(false));
      });
  };
}

export function getNonStudentList() {
  return function (dispatch) {
    axios
      .get(API.NON_STUDENT_LIST)
      .then(function (response) {
        const data = _.sortBy(
          response.data.data,
          (student) => student.created_at
        ).reverse();
        dispatch(nonStudentList.updateContent(data));
      })
      .catch(function (response) {
        alert(response);
      });
  };
}

export function getStudentIPs() {
  return function (dispatch) {
    axios
      .get(API.STUDENT_IP_API)
      .then(function (response) {
        dispatch(studentIPs.updateContent(response.data));
      })
      .catch(function (response) {
        alert(response);
      });
  };
}

export function verifyEmail(username, history) {
  return function (dispatch) {
    axios
      .get(API.VERIFY_EMAIL + username)
      .then(function (response) {
        history.push("/?email_success=yes");
      })
      .catch(function (response) {
        alert(response);
      });
  };
}

export function getExamCount() {
  return function (dispatch) {
    AwsAPI.get(api, "/exam-count", {})
      .then(function (response) {
        dispatch(examCount.updateExamCount(response));
        dispatch(apiActions.updateApiSending(false));
      })
      .catch(function (response) {
        console.error(response);
        dispatch(apiActions.updateApiSending(false));
        alert("Unable to get examCount.");
      });
  };
}
