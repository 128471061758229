import React from "react";
import {
  Grid,
  Container,
  Form,
  Button,
  Input,
  Select,
  Confirm,
  Breadcrumb,
  Segment,
} from "semantic-ui-react";
import { connect } from "react-redux";
import { Editor } from "@tinymce/tinymce-react";
import * as writingBuilderActions from "../../actions/app/writeBuilder";
import * as apiActions from "../../actions/app/api";
import * as writeExamActions from "../../actions/app/writeExam";
import * as api from "../../actions/api/requests";
import * as SUBTYPES from "../../constants/exam_subtypes";
import Loader from "../dashboard/parts/Loader";
import { withRouter, Link } from "react-router-dom";
import * as Parts from "../dashboard/parts";
import * as TINY_MCE_SETTINGS from "../../constants/tinyMceSettings";

const subtype_options = [
  {
    key: SUBTYPES.WRITE_PART_1_VALUE,
    value: SUBTYPES.WRITE_PART_1_VALUE,
    text: SUBTYPES.WRITE_PART_1_TEXT,
  },
  {
    key: SUBTYPES.WRITE_PART_2_VALUE,
    value: SUBTYPES.WRITE_PART_2_VALUE,
    text: SUBTYPES.WRITE_PART_2_TEXT,
  },
];

const exam_type_options = [
  {
    key: SUBTYPES.ACADEMIC_VALUE,
    value: SUBTYPES.ACADEMIC_VALUE,
    text: SUBTYPES.ACADEMIC_TEXT,
  },
  {
    key: SUBTYPES.GENERAL_TRAINING_VALUE,
    value: SUBTYPES.GENERAL_TRAINING_VALUE,
    text: SUBTYPES.GENERAL_TRAINING_TEXT,
  },
];

class WriteBuilder extends React.Component {
  state = {
    open: false,
  };
  componentDidMount() {
    this.props.dispatch(apiActions.updateApiSending(false));
  }
  open = () => {
    this.setState({ open: true });
  };
  close = () => {
    this.setState({ open: false });
  };
  handleExamTypeChange = (e, component) => {
    this.update(component.value, "exam_type");
  };
  handleExamPartChange = (e, component) => {
    this.update(component.value, "part");
  };
  handleCodeChange = (value) => {
    this.update(value, "code");
  };
  handleContentChange = (value, editor) => {
    this.update(value, "content");
  };
  handleVocabChange = (value, editor) => {
    this.update(value, "vocab");
  };
  handeModelAnswerChange = (value, editor) => {
    this.update(value, "model_answer");
  };
  handleHintChange = (value, editor) => {
    this.update(value, "hint");
  };
  handleChange = (e) => {
    this.update(e.target.value, e.target.name);
  };
  update(value, name) {
    const data = { ...this.props.writingBuilder };
    data[name] = value;
    this.props.dispatch(writingBuilderActions.updateContent(data));
  }
  handleSubmit = (e) => {
    e.preventDefault();
    this.open();
  };
  handleConfirm = () => {
    const data = { ...this.props.writingBuilder };
    const { dispatch } = this.props;
    dispatch(api.createWritingExam(data));
    dispatch(apiActions.updateApiSending(true));
    this.close();
  };
  showPreview = () => {
    this.props.dispatch(
      writeExamActions.updateContent([{ ...this.props.writingBuilder }])
    );
    window.open("/exam/write");
  };
  resetForm = () => {
    this.props.dispatch(writeExamActions.reset());
  };
  goToDashboard = () => {
    this.props.history.push("/");
  };
  render() {
    const data = { ...this.props.writingBuilder };
    const { open } = this.state;
    return (
      <Grid
        doubling
        stackable
        padded
        className="padding-top-1rem padding-bottom-2rem"
      >
        <Confirm
          open={open}
          content="Do you want to proceed with the submission?"
          onCancel={this.close}
          onConfirm={this.handleConfirm}
        />
        <Loader />
        <Parts.Header />
        <Grid.Row style={{ paddingBottom: "0px" }}>
          <Grid.Column>
            <Breadcrumb>
              <Breadcrumb.Section>
                <Link to="/">Home</Link>
              </Breadcrumb.Section>
              <Breadcrumb.Divider icon="right angle" />
              <Breadcrumb.Section>Create Writing Exam</Breadcrumb.Section>
            </Breadcrumb>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={"equal"} className="footer-margin-8">
          <Grid.Column>
            <Container className={"overflow-y-auto"}>
              <Segment style={{ paddingBottom: "0px!important" }}>
                <Form onSubmit={this.handleSubmit}>
                  <Form.Field inline>
                    <h4 style={{ marginBottom: "10px" }}>Exam Type </h4>
                    <Select
                      required
                      value={data.exam_type}
                      name="exam_type"
                      placeholder="Select Exam Type"
                      options={exam_type_options}
                      onChange={this.handleExamTypeChange}
                    />
                  </Form.Field>
                  <br />
                  <Form.Field inline>
                    <h4 style={{ marginBottom: "10px" }}>Exam Part </h4>
                    <Select
                      required
                      value={data.part}
                      name="part"
                      placeholder="Select Exam Part"
                      options={subtype_options}
                      onChange={this.handleExamPartChange}
                    />
                  </Form.Field>
                  <br />
                  <Form.Field inline>
                    <h4 style={{ marginBottom: "10px" }}>Code </h4>
                    <Input
                      required
                      width={4}
                      fluid
                      name="code"
                      onChange={this.handleChange.bind(this)}
                      value={data.code}
                      placeholder="Exam Code"
                    />
                  </Form.Field>
                  <br />
                  <Form.Field>
                    <h4>Content </h4>
                    <Editor
                      init={TINY_MCE_SETTINGS.SETTINGS_NO_BLANK}
                      value={data.content}
                      onEditorChange={this.handleContentChange.bind(this)}
                    />
                  </Form.Field>
                  <br />
                  <Form.Field>
                    <h4>Vocab </h4>
                    <Editor
                      init={TINY_MCE_SETTINGS.SETTINGS_NO_BLANK}
                      value={data.vocab}
                      onEditorChange={this.handleVocabChange.bind(this)}
                    />
                  </Form.Field>
                  <br />
                  <Form.Field>
                    <h4>Model Answer </h4>
                    <Editor
                      init={TINY_MCE_SETTINGS.SETTINGS_NO_BLANK}
                      value={data.model_answer}
                      onEditorChange={this.handeModelAnswerChange.bind(this)}
                    />
                  </Form.Field>
                  <br />
                  {/* <Form.Field>
                    <h4>Hints</h4>
                    <Editor
                      init={TINY_MCE_SETTINGS.SETTINGS_NO_BLANK}
                      value={data.hint}
                      onEditorChange={this.handleHintChange.bind(this)}
                    />
                  </Form.Field> */}
                  <br />
                  <Form.Field className="text-center margin-top-25-impt">
                    <Button type="submit" color="green" size="tiny">
                      Finish Exam
                    </Button>
                    &nbsp;
                    <Button
                      onClick={this.showPreview}
                      type="button"
                      color="orange"
                      size="tiny"
                    >
                      Preview Exam
                    </Button>
                    &nbsp;
                    <Button
                      onClick={this.resetForm}
                      type="button"
                      color="red"
                      size="tiny"
                    >
                      Reset
                    </Button>
                  </Form.Field>
                </Form>
                <br />
                <br />
              </Segment>
            </Container>
          </Grid.Column>
          <Grid.Column>
            <Container className={"overflow-y-auto"}>
              <Segment style={{ backgroundColor: "#F5F8FF" }}>
                <h4>Content</h4>
                <div
                  className="instruction"
                  dangerouslySetInnerHTML={{ __html: data.content }}
                />
              </Segment>
              <Segment style={{ backgroundColor: "#F5F8FF" }}>
                <h4>Vocab</h4>
                <div
                  className="instruction"
                  dangerouslySetInnerHTML={{ __html: data.vocab }}
                />
              </Segment>
              <Segment style={{ backgroundColor: "#F5F8FF" }}>
                <h4>Model Answer</h4>
                <div
                  className="instruction"
                  dangerouslySetInnerHTML={{ __html: data.model_answer }}
                />
              </Segment>
              {/* <Segment style={{ backgroundColor: "#F5F8FF" }}>
                <h4>Hints</h4>
                <div
                  className="instruction"
                  dangerouslySetInnerHTML={{ __html: data.hint }}
                />
              </Segment> */}
            </Container>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    writingBuilder: state.writingBuilder,
  };
};

export default withRouter(connect(mapStateToProps)(WriteBuilder));
