export const unescapeHTML = (escapedHTML) => {
  return escapedHTML
    .replace(/&lt;/g, "<")
    .replace(/&gt;/g, ">")
    .replace(/&amp;/g, "&")
    .replace("<html>", "")
    .replace("</html>", "")
    .replace("<head>", "")
    .replace("</head>", "")
    .replace("<body>", "")
    .replace("</body>", "");
};

export const formatDate = (date) => {
  return `${date.getDate()}/${
    date.getMonth() + 1
  }/${date.getFullYear()} ${date.toLocaleTimeString()}`;
};

export const getCurrentYear = () => {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  return currentYear;
};

export const extractNumber = (str) => {
  // Use a regular expression to match the first number in the string
  const match = str.match(/\d+/);

  // If a number is found, convert it to an integer and return
  if (match) {
    return Number(match[0]);
  } else {
    // If no number found, return null or whatever makes sense in your context
    return null;
  }
};
