import * as listeningModal from '../types/listeningModal';

export const openModal = () => {
  return dispatch => {
    dispatch({
      type    : listeningModal.TOGGLE_LISTENING_MODAL_STATUS,
      payload : true,
    });
  };
};

export const closeModal = () => {
  return dispatch => {
    dispatch({
      type    : listeningModal.TOGGLE_LISTENING_MODAL_STATUS,
      payload : false,
    });
  };
};
