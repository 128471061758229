import * as actionTypes from "../types/writeExam";

export const reset = () => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.WRITE_EXAM_RESET_WRITE_EXAM,
    });
  };
};

export const updateTimer = (payload) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.WRITE_EXAM_UPDATE_TIMER,
      payload: payload,
    });
  };
};

export const updateActiveIndex = (payload) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.WRITE_EXAM_UPDATE_ACTIVE_INDEX,
      payload: payload,
    });
  };
};

export const updateStratMode = (payload) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.WRITE_EXAM_UPDATE_STRAT_MODE,
      payload: payload,
    });
  };
};

export const resetTimer = () => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.WRITE_EXAM_RESET_TIMER,
    });
  };
};

export const updateAnswer = (payload) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.WRITE_EXAM_UPDATE_ANSWERS,
      payload: payload,
    });
  };
};

export const updateAnswerStrat = (payload) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.WRITE_EXAM_UPDATE_ANSWERS_STRAT,
      payload: payload,
    });
  };
};

export const updateContent = (payload) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.WRITE_EXAM_UPDATE_CONTENT,
      payload: payload,
    });
  };
};

export const updateNotes = (payload) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.WRITE_EXAM_UPDATE_NOTES,
      payload: payload,
    });
  };
};
