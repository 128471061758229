import React from 'react';
import { withRouter } from "react-router-dom";
import { Icon } from 'semantic-ui-react';
import * as api from '../../actions/api/requests'
import queryString from 'query-string';
import { connect } from 'react-redux';

class VerifyEmail extends React.Component {
  componentDidMount(){
    const values = queryString.parse(this.props.location.search)
    this.props.dispatch(api.verifyEmail(values.username, this.props.history));
  }
	render(){
		return (
			<div id="load-overlay" style={{width:"100%"}}>
        <div className="load-overlay_body">
          <p className="loader-new-ui"><Icon loading name='spinner' /></p>
          <span className="r-light f-15">Verifying Email</span>
        </div>
      </div>
		);
	}
}

const mapStateToProps = (state) => {
  return {
  }
};

export default withRouter(connect(mapStateToProps)(VerifyEmail));