import * as actions from '../actions/types/readExam';

const default_timer = 1200;

const defaultState = {
  timer: default_timer,
  active_index: 0,
  strat_mode: false,
  content: [],
  answers:[],
  answers_strat:[],
};

export default function reducer(state=defaultState, action) {
  switch(action.type) {
    case actions.READ_EXAM_RESET_READ_EXAM: {
      return {...defaultState};
    }
    case actions.READ_EXAM_UPDATE_TIMER:{
      return {...state, timer: action.payload}
    }
    case actions.READ_EXAM_UPDATE_ACTIVE_INDEX:{
      return {...state, active_index: action.payload}
    }
    case actions.READ_EXAM_UPDATE_STRAT_MODE:{
      return {...state, strat_mode: action.payload}
    }
    case actions.READ_EXAM_RESET_TIMER: {
      return {...state, timer: default_timer}
    }
    case actions.READ_EXAM_UPDATE_CONTENT: {
      return {...state, content: action.payload}
    }
    case actions.READ_EXAM_UPDATE_ANSWERS: {
      return {...state, answers: action.payload.slice()}
    }
    case actions.READ_EXAM_UPDATE_ANSWERS_STRAT: {
      return {...state, answers_strat: action.payload.slice()}
    }
    default: return {...state};
  }
};