import React from 'react';
import { Container, Segment, Form, Button, Icon, Input, Popup, Confirm } from 'semantic-ui-react';
import { connect } from 'react-redux';
import * as SUBTYPES from '../../../constants/exam_subtypes';
import * as api from '../../../actions/api/requests';
import * as apiActions from '../../../actions/app/api';

const subtype_options = [
  { key: SUBTYPES.READ_PART_1_VALUE, value: SUBTYPES.READ_PART_1_VALUE, text: SUBTYPES.READ_PART_1_TEXT },
  { key: SUBTYPES.READ_PART_2_VALUE, value: SUBTYPES.READ_PART_2_VALUE, text: SUBTYPES.READ_PART_2_TEXT },
  { key: SUBTYPES.READ_PART_3_VALUE, value: SUBTYPES.READ_PART_3_VALUE, text: SUBTYPES.READ_PART_3_TEXT },
]

const exam_type_options = [
  { key: SUBTYPES.ACADEMIC_VALUE, value: SUBTYPES.ACADEMIC_VALUE, text: SUBTYPES.ACADEMIC_TEXT },
  { key: SUBTYPES.GENERAL_TRAINING_VALUE, value: SUBTYPES.GENERAL_TRAINING_VALUE, text: SUBTYPES.GENERAL_TRAINING_TEXT },
]

class CustomizeReading extends React.Component {
  componentDidMount(){
		this.nameInput.focus();
		this.props.dispatch(apiActions.updateApiSending(false));
  }
  state = {
		modalOpen: false,
		name: "",
		content:[this.createContent()]
	}
	createContent(){
		return {
			part:subtype_options[0].value,
			repeat:false,
			count:1,
			exam_type:exam_type_options[0].value,
		}
	}
	handleSubmit = (e) => {
		e.preventDefault();
		this.openModal();
	}
	closeModal = () => {
		this.setState({modalOpen:false});
	}
	openModal = () => {
		this.setState({modalOpen:true});
	}
	handleConfirm = () => {
		const { history, dispatch } = this.props;
		this.closeModal();
		dispatch(apiActions.updateApiSending(true));
		const exam = {...this.state};
		delete exam.modalOpen;
		dispatch(api.generateReadingExam(exam, () => this.setState({modalOpen:false}), history));
	}
	handleNameChange = (e) => {
		this.setState({name:e.target.value})
	}
	addRow = () => {
		const { content } = this.state;
		const newContent = content.slice();
		newContent.push(this.createContent());
		this.setState({content:newContent});
	}
	removeRow = (i) => {
		const { content } = this.state;
		const newContent = content.slice();
		newContent.splice(i, 1);
		this.setState({content:newContent});
	}
	handleRowChange = (e, component) => {
		const { content } = this.state;
		const newContent = content.slice();
		if (component.name === 'repeat'){
			newContent[parseInt(component.id, 10)][component.name] = component.checked;
		} else {
			newContent[parseInt(component.id, 10)][component.name] = component.value;
		}
		this.setState({content:newContent});
	}
  render(){
    const { modalOpen, content } = this.state;
		const rows = content.map((exam, i) => {
			return (
				<Form.Group key={"row" + i}>
					<Form.Select
						compact
						required
						id={"0000"+i}
						name='exam_type'
						width={2}
						placeholder='Exam Type'
						options={exam_type_options}
						value={exam.exam_type}
						onChange={this.handleRowChange}
					/>
					<Form.Select
						compact
						required
						id={"0"+i}
						name='part'
						width={5}
						placeholder='Select the exam part'
						options={subtype_options}
						value={exam.part}
						onChange={this.handleRowChange}
					/>
					<Form.Checkbox
						id={"00" + i}
						name='repeat'
						width={3}
						label='Non-repeat'
						toggle
						className='text-center margin-top-9'
						checked={exam.repeat}
						onClick={this.handleRowChange}
					/>
					<Form.Input
						required
						id={"000"+i}
						name='count'
						width={3}
						type="number"
						placeholder="Total number of exams"
						min="1"
						step={1}
						value={exam.count}
						onChange={this.handleRowChange}
					/>
					<Form.Field width={3}>
						{
							i === content.length - 1?
								<Button.Group>
									<Button size='tiny' onClick={this.addRow} type='button' positive icon><Icon name='add' /></Button>
								</Button.Group>
								:
								null
						}
						{` `}
						{
							content.length > 1?
								<Button.Group>
									<Button size='tiny' onClick={this.removeRow.bind(this, i)} type='button' negative icon><Icon name='remove' /></Button>
								</Button.Group>
								:
								null
						}
					</Form.Field>
				</Form.Group>
			)
		});
    return (
			<Container fluid>
        <Confirm
          open={modalOpen}
          content='Do you want to proceed with the practice test?'
          onCancel={this.closeModal}
          onConfirm={this.handleConfirm}
        />
        <Segment>
          <Form onSubmit={this.handleSubmit}>
            <Form.Field inline>
              <label>Name of Exam: </label>
              <Popup
								content='Enter the name of this exam session'
								on={'focus'}
								trigger={
									<Input
										required
										ref={(input) => { this.nameInput = input; }}
										onChange={this.handleNameChange}
										width={4}
										placeholder='My reading exam...' />}
							/>
            </Form.Field>
            { rows }
            <Form.Field className='text-center margin-top-25-impt'>
              <Button size='tiny' type='submit' color='green'>START EXAM</Button>
            </Form.Field>
          </Form>
        </Segment>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
  }
};
export default connect(mapStateToProps)(CustomizeReading);