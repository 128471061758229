import * as marketing from '../types/marketing';

export const update = (payload) => {
  return dispatch => {
    dispatch({
      type    : marketing.UPDATE_MARKETING_CONTENT,
      payload : payload,
    });
  };
};
