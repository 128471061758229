import React from 'react';
import { Table, Pagination, Container, Grid, Breadcrumb, Select } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import * as studentIPsActions from '../../actions/app/studentIPs';
import * as api from '../../actions/api/requests';
import * as Parts from '../dashboard/parts';
import _ from 'lodash';

class StudentIPs extends React.Component {
	componentDidMount(){
		this.resetCurrentPage();
		this.props.dispatch(api.getStudentIPs());
	}
	componentWillUnmount(){
		this.resetCurrentPage();
		this.props.dispatch(studentIPsActions.updateContent([]));
	}
	resetCurrentPage(){
		const { dispatch } = this.props;
		dispatch(studentIPsActions.updateCurrentPage(1));
	}
	onPageChange = (e, data) => {
		const { dispatch } = this.props;
		dispatch(studentIPsActions.updateCurrentPage(data.activePage));
	}
	close = () => {
		this.setState({open:false,copiedToClipboard:false});
	}
	state = {
    column: null,
    data: [],
		direction: null,
		user: "all",
	}
	handleSort = clickedColumn => () => {
    const { column, direction } = this.state

		let data = this.props.content;

    if (column !== clickedColumn) {
			data = _.sortBy(data, [clickedColumn])
      this.setState({
        column: clickedColumn,
        direction: 'ascending',
			})
			this.props.dispatch(studentIPsActions.updateContent(data));
			return;
		}

    this.setState({
      direction: direction === 'ascending' ? 'descending' : 'ascending',
		})
		this.props.dispatch(studentIPsActions.updateContent(data.reverse()));
	}
	changeUser = (e, value) => {
		this.setState({user:value.value})
		this.resetCurrentPage();
	}
	render() {
		const { content, currentActivePage, maxPerPage } = this.props;
		const users = Array.from(new Set(content.map((ob) => ob.student)))
	 	const userOptions = users.map(obj => {
			 return { key: obj, value: obj, text: obj }
		 })
		userOptions.splice(0, 0, { key: "empty", value: "all", text: "Show All" });

		const { column, direction, user } = this.state;
		const startIndex = (currentActivePage - 1) * maxPerPage;
		const finalIndex = maxPerPage * currentActivePage;
		let slicedContent = content;
		let slicedLength = content.length;
		if (user !== 'all'){
			slicedContent = slicedContent.filter((content) => content.student === user);
			slicedLength = slicedContent.length;
		}
		slicedContent = slicedContent.slice(startIndex, finalIndex);

		const rows = slicedContent.map((item, i) => {
			return (
				<Table.Row key={i}>
          <Table.Cell>{item.student}</Table.Cell>
					<Table.Cell>{item.ip}</Table.Cell>
					<Table.Cell>{item.country}</Table.Cell>
					<Table.Cell>{new Date(item.created_at).toLocaleString()}</Table.Cell>
				</Table.Row>
			)
		});
		return (
			<Grid doubling stackable padded>
				<Parts.Header/>
				<Grid.Row style={{paddingBottom:"0px", paddingTop:"2rem"}} >
					<Grid.Column>
						<Breadcrumb>
							<Breadcrumb.Section link><Link to='/'>Home</Link></Breadcrumb.Section>
							<Breadcrumb.Divider icon='right angle' />
							<Breadcrumb.Section>Student IP Addresses</Breadcrumb.Section>
						</Breadcrumb>
					</Grid.Column>
				</Grid.Row>
				<Grid.Row>
					<Grid.Column width={16} style={{maxHeight:"70vh", overflowY:"scroll"}} >
						<span>Show Only:&nbsp; <Select options={userOptions} value={this.state.user} onChange={this.changeUser} /></span>
						<Table striped sortable celled compact stackable>
							<Table.Header>
								<Table.Row>
									<Table.HeaderCell
										sorted={column === 'student' ? direction : null}
										onClick={this.handleSort('student')}>
										Student Code
									</Table.HeaderCell>
									<Table.HeaderCell
										sorted={column === 'ip' ? direction : null}
										onClick={this.handleSort('ip')}>
										IP Address
									</Table.HeaderCell>
									<Table.HeaderCell
										sorted={column === 'country' ? direction : null}
										onClick={this.handleSort('country')}>
										Country
									</Table.HeaderCell>
									<Table.HeaderCell
										sorted={column === 'created_at' ? direction : null}
										onClick={this.handleSort('created_at')}>
										Date Logged In
									</Table.HeaderCell>
								</Table.Row>
							</Table.Header>
							<Table.Body>
							{ rows }
							</Table.Body>
						</Table>
						<Container textAlign={"center"}>
							<Pagination
								activePage={currentActivePage}
								totalPages={Math.ceil(slicedLength / maxPerPage)}
								onPageChange={this.onPageChange}
							/>
						</Container>
					</Grid.Column>
				</Grid.Row>
				<Parts.Footer/>
			</Grid>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		content: state.studentIPs.content,
		currentActivePage: state.studentIPs.currentActivePage,
		maxPerPage: state.studentIPs.maxPerPage,
		is_staff: state.auth.is_staff,
	}
};

export default connect(mapStateToProps)(StudentIPs);