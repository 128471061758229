import React, { Component } from 'react';
import { Button, Modal, Form } from 'semantic-ui-react';
import { connect } from 'react-redux';
import * as listeningModal from '../../../actions/app/listeningModal';
import * as SUBTYPES from '../../../constants/exam_subtypes';

const subtype_options = [
  { key: SUBTYPES.LISTEN_PART_1_VALUE, value: SUBTYPES.LISTEN_PART_1_VALUE, text: SUBTYPES.LISTEN_PART_1_TEXT },
  { key: SUBTYPES.LISTEN_PART_2_VALUE, value: SUBTYPES.LISTEN_PART_2_VALUE, text: SUBTYPES.LISTEN_PART_2_TEXT },
  { key: SUBTYPES.LISTEN_PART_3_VALUE, value: SUBTYPES.LISTEN_PART_3_VALUE, text: SUBTYPES.LISTEN_PART_3_TEXT },
  { key: SUBTYPES.LISTEN_PART_4_VALUE, value: SUBTYPES.LISTEN_PART_4_VALUE, text: SUBTYPES.LISTEN_PART_4_TEXT },
]

class CreateListeningModal extends Component {
  closeModal = () => this.props.dispatch(listeningModal.closeModal());
  render() {
    const { open } = this.props;
    return (
      <Modal size={"fullscreen"} open={open} onClose={this.closeModal.bind(this)} closeIcon >
        <Modal.Header>Create the Exam</Modal.Header>
        <Modal.Content>
          <Form>
            <Form.Group>
              <Form.Select width={4} placeholder='Select the exam part' options={subtype_options} />
              <Form.Radio width={2} toggle label='Repeat' />
              <Form.Input width={4} type="number" placeholder="Total number of exams" min="1" value={1} step={1}/>
            </Form.Group>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button negative onClick={this.closeModal} content='Cancel' />
          <Button positive onClick={this.closeModal} content='Proceed' />
        </Modal.Actions>
      </Modal>
    )
  }
}

const mapStateToProps = (state) => {
	return {
		open: state.listeningModal.open,
	}
};

export default connect(mapStateToProps)(CreateListeningModal);