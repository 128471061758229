import React from "react";
import { Grid } from "semantic-ui-react";
import { NavLink } from "react-router-dom";
import { extractNumber } from "../../../../../common";

const Header = (props) => {
  const { strat_mode, part } = props;
  return (
    <div className="top-nav">
      <Grid columns={"equal"}>
        <Grid.Column>
          <p className="writing-new-ui-p">
            <i
              onClick={props.toggleNav}
              className="fa fa-bars cursor-pointer"
            />
            &nbsp;&nbsp; {`IELTS Speaking Part ${extractNumber(part)} `}
            {strat_mode ? "(Learning Section)" : "(Testing Section)"}
          </p>
        </Grid.Column>
        <Grid.Column className="text-center">
          <NavLink to="/dashboard" className="active" style={{ float: "none" }}>
            <img
              alt="logo"
              src="https://ieltsapp.s3-ap-southeast-1.amazonaws.com/resources/new-ui/logo.png"
              className="logo_center"
              style={{ marginTop: "10px" }}
            />
          </NavLink>
        </Grid.Column>
        <Grid.Column style={{ marginRight: "1.5rem" }} />
      </Grid>
    </div>
  );
};

export default Header;
