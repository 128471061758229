import * as actionTypes from "../types/speakExam";

export const reset = () => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.SPEAK_EXAM_RESET_SPEAK_EXAM,
    });
  };
};

export const updateTimer = (payload) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.SPEAK_EXAM_UPDATE_TIMER,
      payload: payload,
    });
  };
};

export const updateActiveIndex = (payload) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.SPEAK_EXAM_UPDATE_ACTIVE_INDEX,
      payload: payload,
    });
  };
};

export const updateStratMode = (payload) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.SPEAK_EXAM_UPDATE_STRAT_MODE,
      payload: payload,
    });
  };
};

export const resetTimer = () => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.SPEAK_EXAM_RESET_TIMER,
    });
  };
};

export const updateAnswer = (payload) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.SPEAK_EXAM_UPDATE_ANSWERS,
      payload: payload,
    });
  };
};

export const updateAnswerStrat = (payload) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.SPEAK_EXAM_UPDATE_ANSWERS_STRAT,
      payload: payload,
    });
  };
};

export const updateSubtype = (payload) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.SPEAK_EXAM_UPDATE_SUBTYPE,
      payload: payload,
    });
  };
};

export const updateRecording = (payload) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.SPEAK_EXAM_UPDATE_RECORDING,
      payload: payload,
    });
  };
};

export const updatePlaying = (payload) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.SPEAK_EXAM_UPDATE_PLAYING,
      payload: payload,
    });
  };
};

export const updateContent = (payload) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.SPEAK_EXAM_UPDATE_CONTENT,
      payload: payload,
    });
  };
};

export const updateAdminQuestionMode = (payload) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.SPEAK_EXAM_UPDATE_ADMIN_QUESTION_MODE,
      payload: payload,
    });
  };
};

export const updateAdminQuestionIndex = (payload) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.SPEAK_EXAM_UPDATE_ADMIN_QUESTION_INDEX,
      payload: payload,
    });
  };
};

export const updateNotes1 = (payload) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.SPEAK_EXAM_UPDATE_NOTES_1,
      payload: payload,
    });
  };
};

export const updateNotes2 = (payload) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.SPEAK_EXAM_UPDATE_NOTES_2,
      payload: payload,
    });
  };
};

export const updateNotes3 = (payload) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.SPEAK_EXAM_UPDATE_NOTES_3,
      payload: payload,
    });
  };
};
