import React from 'react';
import { connect } from 'react-redux';
import { Grid, Button, Segment, Icon } from 'semantic-ui-react';
import MicRecorder from 'mic-recorder-to-mp3';

const recorder = new MicRecorder({
  bitRate: 128
});

class MicTest extends React.Component {
  state = {
    audio_url: '',
    recording: false,
  }
  startRecording = () => {
    this.setState({recording:true});
    recorder.start().then(() => {
    }).catch((e) => {
      alert("Unable to start recording: " + e);
    });
  }
  stopRecording = () => {
    this.setState({recording:false});
    recorder
      .stop()
      .getMp3().then(([buffer, blob]) => {
        const file = new File(buffer, 'recording.mp3', {
          type: blob.type,
          lastModified: Date.now()
        });
        this.setState({audio_url:URL.createObjectURL(file)})
      }).catch((e) => {
        alert('Something is wrong with the microphone: ' + e);
      });
  }
  render(){
    const { recording, audio_url } = this.state;
    return (
      <React.Fragment>
        <Grid.Row className='text-center'>
          <audio src={audio_url} controls />
        </Grid.Row>
        <Grid.Row className='text-center' style={{marginTop:'1rem'}}>
          <Button.Group>
            <Button onClick={this.startRecording} disabled={recording} color='green' size='tiny' icon><Icon name='microphone'/></Button>
            <Button.Or />
            <Button onClick={this.stopRecording} disabled={!recording} color='red' size='tiny' icon><Icon name='microphone slash'/></Button>
          </Button.Group>
        </Grid.Row>
        <Segment compact>
          <p>For the Listening and the Speaking Test to take place properly, you need to see if your Microphone and Speakers/Earphones work properly.</p>
          <p>Click the red microphone button above and speak into your Microphone for a few seconds.</p>
          <p>If you can hear your voice clearly, then everything should be OK!</p>
        </Segment>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
  }
};

export default connect(mapStateToProps)(MicTest);