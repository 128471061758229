import React from 'react';
import { Button, Icon, Modal } from 'semantic-ui-react';
import { Editor } from '@tinymce/tinymce-react';
import * as TINY_MCE_SETTINGS from '../../constants/tinyMceSettings';
import * as EXAM from '../../constants/exams';


const QuestionEditor = (props) => {
  let editors = [];
  let section = [];
  try {
    section = props.readingBuilder.exam_explanation.exams[props.questionEditorIndex];
  } catch (ex) {}
  let onChangeMethod;
  if (section.exam_type === EXAM.READ_RADIO_MCQ || section.exam_type === EXAM.READ_RADIO_CHECKBOX){
    onChangeMethod = props.handleMcqQuestionChange;
  } else if (
    section.exam_type === EXAM.READ_FLOW_CHART ||
    section.exam_type === EXAM.READ_FLOW_CHART_DETAILED ||
    section.exam_type === EXAM.READ_MATCHING ||
    section.exam_type === EXAM.READ_SENTENCE_COMPLETION ||
    section.exam_type === EXAM.READ_SHORT_ANSWERS){
    onChangeMethod = props.handleNormalQuestionChange;
  }
  try {
    editors = section.questions.map((question, i) => {
      let text = "";
      if (section.exam_type === EXAM.READ_RADIO_MCQ || section.exam_type === EXAM.READ_RADIO_CHECKBOX){
        text = question.text;
      } else if (section.exam_type === EXAM.READ_FLOW_CHART ||
                  section.exam_type === EXAM.READ_FLOW_CHART_DETAILED ||
                  section.exam_type === EXAM.READ_MATCHING ||
                  section.exam_type === EXAM.READ_SENTENCE_COMPLETION ||
                  section.exam_type === EXAM.READ_SHORT_ANSWERS){
        text = question;
      }
      return (
        <React.Fragment>
          <h3>{`#${i+1}`}</h3>
          <Editor
            key={"2asd9xlkjc"+i}
            init={TINY_MCE_SETTINGS.SETTINGS_NON_FULL_PAGE}
            value={text}
            onEditorChange={onChangeMethod.bind(this, i)}
          />
          <br/>
        </React.Fragment>
      );
    })
  } catch (ex){}
  return (
    <Modal closeOnEscape={true} closeOnDimmerClick={true} open={props.openQuestionEditor} onClose={props.closeQuestionEditor} closeIcon>
      <Modal.Header>Edit Questions</Modal.Header>
      <Modal.Content scrolling>
        {editors}
      </Modal.Content>
      <Modal.Actions>
        <Button primary type='button' onClick={props.handleQuestionEditFinish}>
          Finish <Icon name='chevron right' />
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

export default QuestionEditor