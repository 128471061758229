import React from 'react';
import { Grid, Container, Form, Button, Breadcrumb, Segment, Tab, Confirm } from 'semantic-ui-react';
import { connect } from 'react-redux';
import Loader from '../dashboard/parts/Loader';
import { withRouter, Link } from 'react-router-dom';
import * as Parts from '../dashboard/parts';
import _ from 'lodash';
import * as api from '../../actions/api/requests';
import * as marketingActions from '../../actions/app/marketing';
import * as apiActions from '../../actions/app/api';
import * as TINY_MCE_SETTINGS from '../../constants/tinyMceSettings';
import { Editor } from '@tinymce/tinymce-react';


class MarketingEditorIndividual extends React.Component {
  componentDidMount(){
    const { dispatch } = this.props;
    dispatch(api.getMarketing(true));
    dispatch(apiActions.updateApiSending(false));
  }
  handleInstructionChange(name, value){
    const { dispatch, marketing } = this.props;
    marketing[name] = value;
    dispatch(marketingActions.update(marketing));
  }
  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({open: true});
  }
  close = () => {
    this.setState({open: false});
  }
  state = {
    open: false
  }
  handleConfirm = () => {
    const { dispatch } = this.props;
    const data = _.cloneDeep({...this.props.marketing});
    dispatch(apiActions.updateApiSending(true));
    dispatch(api.updateMarketing(data));
    this.close();
  }
  render(){
    const { my_classes, listening, reading, writing } = this.props.marketing;
    const { open } = this.state;
    const left_panes = [{ menuItem: 'My Classes', render: () => {
      return (
        <Tab.Pane>
          <Editor
            key={'editor1'}
            init={TINY_MCE_SETTINGS.SETTINGS_NO_BLANK}
            onEditorChange={this.handleInstructionChange.bind(this, "my_classes")}
            value={my_classes}
          />
        </Tab.Pane>
      )},
    },{ menuItem: 'Listening', render: () => {
      return (
        <Tab.Pane>
          <Editor
            key={'editor2'}
            init={TINY_MCE_SETTINGS.SETTINGS_NO_BLANK}
            onEditorChange={this.handleInstructionChange.bind(this, "listening")}
            value={listening}
          />
        </Tab.Pane>
      )},
    },{ menuItem: 'Reading', render: () => {
      return (
        <Tab.Pane>
          <Editor
            key={'editor3'}
            init={TINY_MCE_SETTINGS.SETTINGS_NO_BLANK}
            onEditorChange={this.handleInstructionChange.bind(this, "reading")}
            value={reading}
          />
        </Tab.Pane>
      )},
    },{ menuItem: 'Writing', render: () => {
      return (
        <Tab.Pane>
          <Editor
            key={'editor4'}
            init={TINY_MCE_SETTINGS.SETTINGS_NO_BLANK}
            onEditorChange={this.handleInstructionChange.bind(this, "writing")}
            value={writing}
          />
        </Tab.Pane>
      )},
    }];
    const right_panes = [{ menuItem: 'My Classes', render: () => {
      return (
        <Tab.Pane>
          <div className="instruction" dangerouslySetInnerHTML={{__html: my_classes}} />
        </Tab.Pane>
      )},
    },{ menuItem: 'Listening', render: () => {
      return (
        <Tab.Pane>
        <div className="instruction" dangerouslySetInnerHTML={{__html: listening}} />
        </Tab.Pane>
      )},
    },{ menuItem: 'Reading', render: () => {
      return (
        <Tab.Pane>
        <div className="instruction" dangerouslySetInnerHTML={{__html: reading}} />
        </Tab.Pane>
      )},
    },{ menuItem: 'Writing', render: () => {
      return (
        <Tab.Pane>
          <div className="instruction" dangerouslySetInnerHTML={{__html: writing}} />
        </Tab.Pane>
      )},
    }];
    return (
      <React.Fragment>
        <Confirm
          open={open}
          content='Do you want to proceed with the update?'
          onCancel={this.close}
          onConfirm={this.handleConfirm}
        />
        <Loader/>
        <Grid doubling stackable padded className="padding-top-1rem padding-bottom-2rem">
          <Parts.Header/>
          <Grid.Row style={{paddingBottom:"0px"}} >
            <Grid.Column>
              <Breadcrumb>
                <Breadcrumb.Section><Link to='/'>Home</Link></Breadcrumb.Section>
                <Breadcrumb.Divider icon='right angle'/>
                <Breadcrumb.Section>Edit Marketing (Individual)</Breadcrumb.Section>
              </Breadcrumb>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={'equal'} className="footer-margin-8">
            <Grid.Column>
              <Container>
                <Segment style={{paddingBottom:"0px!important"}}>
                  <Form onSubmit={this.handleSubmit}>
                    <Tab className="overflow-y-auto" panes={left_panes}/>
                    <Form.Field className='text-center margin-top-25-impt'>
                      <Button type='submit' color='green' size='tiny'>Update</Button>
                    </Form.Field>
                  </Form>
                  <br/><br/>
                </Segment>
              </Container>
            </Grid.Column>
            <Grid.Column>
              <Container>
                <Segment style={{paddingBottom:"0px!important"}}>
                  <Tab className="overflow-y-auto" panes={right_panes}/>
                </Segment>
              </Container>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    marketing: state.marketing,
  }
};

export default withRouter(connect(mapStateToProps)(MarketingEditorIndividual));