export const SETTINGS_NO_BLANK = {
  plugins: 'print preview fullpage searchreplace autolink directionality visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern help code lineheight',
  toolbar: 'formatselect | bold italic strikethrough forecolor backcolor permanentpen formatpainter | link image media pageembed | alignleft aligncenter alignright alignjustify  | numlist bullist outdent indent | removeformat | addcomment | code | insertblank | lineheightselect',
  image_advtab: true,
  height: 400,
  content_style: ".mce-content-body > p { margin: 0px!important; } .mce-content-body { margin-bottom: 1rem; }",
  setup: (editor) => {
    // editor.ui.registry.addButton('insertblank', {
    //   text: 'Insert Blank',
    //   onAction: function (_) {
    //     editor.insertContent('__blank__');
    //   }
    // });
    editor.on('init', function() {
      editor.formatter.register({
        lineheight: {
          selector: 'p,h1,h2,h3,h4,h5,h6,td,th,div,ul,ol,li,table',
          styles: { 'line-height': '%value' }
        }
      });

      editor.ui.registry.addIcon(
        'line-height',
        `
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <path d="M9.984 12.984v-1.969h12v1.969h-12zM9.984 18.984v-1.969h12v1.969h-12zM9.984 5.016h12v1.969h-12v-1.969zM6 6.984v10.031h2.484l-3.469 3.469-3.516-3.469h2.484v-10.031h-2.484l3.516-3.469 3.469 3.469h-2.484z"></path>
          </svg>
        `
      );
    });

    editor.ui.registry.addMenuButton('lineheightselect', {
      tooltip: 'Line height',
      icon: 'line-height',
      fetch: (callback) => {
        const defaultLineHeightFormats = '1 Default 1.5 1.75 2 3 4 5';
        const userSettings = editor.settings.lineheight_formats;
        const lineheightFormats = typeof userSettings === 'string' ? userSettings : defaultLineHeightFormats;

        const items = lineheightFormats.split(' ').map(item => {
          let text = item,
            value = item;
          const values = item.split('=');
          if (values.length > 1) {
            [text, value] = values;
          }

          return {
            type: 'togglemenuitem',
            text: text,
            onAction: () => {
              if (value === 'Default'){
                value = "1.4";
              }
              editor.formatter.apply('lineheight', { value: value });
              editor.fire('change', {});
            },
            onSetup: (api) => {
              const lineHeight = editor.selection.getNode().style.lineHeight;
              if (value === "Default"){
                value = "1.4";
              }
              api.setActive((lineHeight === "" && value === "1.4") || lineHeight === value);
            }
          };
        });

        callback(items);
      }
    });
  }
 };

export const SETTINGS_WITH_BLANK = {
  plugins: 'print preview fullpage searchreplace autolink directionality visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern help code lineheight',
  toolbar: 'formatselect | bold italic strikethrough forecolor backcolor permanentpen formatpainter | link image media pageembed | alignleft aligncenter alignright alignjustify  | numlist bullist outdent indent | removeformat | addcomment | code | insertblank | lineheightselect',
  image_advtab: true,
  height: 400,
  content_style: ".mce-content-body > p { margin: 0px!important; } .mce-content-body { margin-bottom: 1rem; }",
  setup: (editor) => {
    editor.ui.registry.addButton('insertblank', {
      text: 'Insert Blank',
      onAction: function (_) {
        editor.insertContent('__blank__');
      }
    });
    editor.on('init', function() {
      editor.formatter.register({
        lineheight: {
          selector: 'p,h1,h2,h3,h4,h5,h6,td,th,div,ul,ol,li,table',
          styles: { 'line-height': '%value' }
        }
      });

      editor.ui.registry.addIcon(
        'line-height',
        `
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <path d="M9.984 12.984v-1.969h12v1.969h-12zM9.984 18.984v-1.969h12v1.969h-12zM9.984 5.016h12v1.969h-12v-1.969zM6 6.984v10.031h2.484l-3.469 3.469-3.516-3.469h2.484v-10.031h-2.484l3.516-3.469 3.469 3.469h-2.484z"></path>
          </svg>
        `
      );
    });

    editor.ui.registry.addMenuButton('lineheightselect', {
      tooltip: 'Line height',
      icon: 'line-height',
      fetch: (callback) => {
        const defaultLineHeightFormats = '1 Default 1.5 1.75 2 3 4 5';
        const userSettings = editor.settings.lineheight_formats;
        const lineheightFormats = typeof userSettings === 'string' ? userSettings : defaultLineHeightFormats;

        const items = lineheightFormats.split(' ').map(item => {
          let text = item,
            value = item;
          const values = item.split('=');
          if (values.length > 1) {
            [text, value] = values;
          }

          return {
            type: 'togglemenuitem',
            text: text,
            onAction: () => {
              if (value === 'Default'){
                value = "1.4";
              }
              editor.formatter.apply('lineheight', { value: value });
              editor.fire('change', {});
            },
            onSetup: (api) => {
              const lineHeight = editor.selection.getNode().style.lineHeight;
              if (value === "Default"){
                value = "1.4";
              }
              api.setActive((lineHeight === "" && value === "1.4") || lineHeight === value);
            }
          };
        });

        callback(items);
      }
    });
  }
 };

 export const SETTINGS_NON_FULL_PAGE = {
  plugins: 'print preview searchreplace autolink directionality visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern help code lineheight',
  toolbar: 'formatselect | bold italic strikethrough forecolor backcolor permanentpen formatpainter | link image media pageembed | alignleft aligncenter alignright alignjustify  | numlist bullist outdent indent | removeformat | addcomment | code | insertblank | lineheightselect',
  image_advtab: true,
  height: 200,
  content_style: ".mce-content-body > p { margin: 0px!important; } .mce-content-body { margin-bottom: 1rem; }",
  setup: (editor) => {
    editor.ui.registry.addButton('insertblank', {
      text: 'Insert Blank',
      onAction: function (_) {
        editor.insertContent('__blank__');
      }
    });
    editor.on('init', function() {
      editor.formatter.register({
        lineheight: {
          selector: 'p,h1,h2,h3,h4,h5,h6,td,th,div,ul,ol,li,table',
          styles: { 'line-height': '%value' }
        }
      });

      editor.ui.registry.addIcon(
        'line-height',
        `
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <path d="M9.984 12.984v-1.969h12v1.969h-12zM9.984 18.984v-1.969h12v1.969h-12zM9.984 5.016h12v1.969h-12v-1.969zM6 6.984v10.031h2.484l-3.469 3.469-3.516-3.469h2.484v-10.031h-2.484l3.516-3.469 3.469 3.469h-2.484z"></path>
          </svg>
        `
      );
    });

    editor.ui.registry.addMenuButton('lineheightselect', {
      tooltip: 'Line height',
      icon: 'line-height',
      fetch: (callback) => {
        const defaultLineHeightFormats = '1 Default 1.5 1.75 2 3 4 5';
        const userSettings = editor.settings.lineheight_formats;
        const lineheightFormats = typeof userSettings === 'string' ? userSettings : defaultLineHeightFormats;

        const items = lineheightFormats.split(' ').map(item => {
          let text = item,
            value = item;
          const values = item.split('=');
          if (values.length > 1) {
            [text, value] = values;
          }

          return {
            type: 'togglemenuitem',
            text: text,
            onAction: () => {
              if (value === 'Default'){
                value = "1.4";
              }
              editor.formatter.apply('lineheight', { value: value });
              editor.fire('change', {});
            },
            onSetup: (api) => {
              const lineHeight = editor.selection.getNode().style.lineHeight;
              if (value === "Default"){
                value = "1.4";
              }
              api.setActive((lineHeight === "" && value === "1.4") || lineHeight === value);
            }
          };
        });

        callback(items);
      }
    });
  }
 };