import * as actions from "../actions/types/writeBuilder";

const defaultState = {
  code: "",
  content: "",
  vocab: "",
  model_answer: "",
  part: "WritePart1",
  exam_type: "GT",
  hint: "",
};

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case actions.WRITE_BUILDER_RESET: {
      return { ...defaultState };
    }
    case actions.WRITE_BUILDER_UPDATE_CONTENT: {
      return { ...action.payload };
    }
    default:
      return { ...state };
  }
}
