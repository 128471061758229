import * as actionTypes from '../types/speakPart1';

export const reset = () => {
  return dispatch => {
    dispatch({
      type : actionTypes.SPEAK_EXAM_PART1_RESET,
    });
  };
};

export const updateTimer = (payload) => {
  return dispatch => {
    dispatch({
      type    : actionTypes.SPEAK_EXAM_PART1_UPDATE_TIMER,
      payload : payload,
    });
  };
};

export const updateTimer2 = (payload) => {
  return dispatch => {
    dispatch({
      type    : actionTypes.SPEAK_EXAM_PART1_UPDATE_TIMER2,
      payload : payload,
    });
  };
};

export const updateActiveIndex = (payload) => {
  return dispatch => {
    dispatch({
      type    : actionTypes.SPEAK_EXAM_PART1_UPDATE_ACTIVE_INDEX,
      payload : payload,
    });
  };
};

export const updateStratMode = (payload) => {
  return dispatch => {
    dispatch({
      type    : actionTypes.SPEAK_EXAM_PART1_UPDATE_STRAT_MODE,
      payload : payload,
    });
  };
};

export const resetTimer = () => {
  return dispatch => {
    dispatch({
      type    : actionTypes.SPEAK_EXAM_PART1_RESET_TIMER,
    });
  };
};

export const updateAnswer = (payload) => {
  return dispatch => {
    dispatch({
      type    : actionTypes.SPEAK_EXAM_PART1_UPDATE_ANSWERS,
      payload : payload
    });
  };
};

export const updateAnswerStrat = (payload) => {
  return dispatch => {
    dispatch({
      type    : actionTypes.SPEAK_EXAM_PART1_UPDATE_ANSWERS_STRAT,
      payload : payload
    });
  };
};

export const updateSubtype = (payload) => {
  return dispatch => {
    dispatch({
      type    : actionTypes.SPEAK_EXAM_PART1_UPDATE_SUBTYPE,
      payload : payload
    });
  };
};

export const updateRecording = (payload) => {
  return dispatch => {
    dispatch({
      type    : actionTypes.SPEAK_EXAM_PART1_UPDATE_RECORDING,
      payload : payload
    });
  };
};

export const updatePlaying = (payload) => {
  return dispatch => {
    dispatch({
      type    : actionTypes.SPEAK_EXAM_PART1_UPDATE_PLAYING,
      payload : payload
    });
  };
};

export const updateRecording2 = (payload) => {
  return dispatch => {
    dispatch({
      type    : actionTypes.SPEAK_EXAM_PART1_UPDATE_RECORDING2,
      payload : payload
    });
  };
};

export const updatePlaying2 = (payload) => {
  return dispatch => {
    dispatch({
      type    : actionTypes.SPEAK_EXAM_PART1_UPDATE_PLAYING2,
      payload : payload
    });
  };
};

export const updateFile = (payload) => {
  return dispatch => {
    dispatch({
      type    : actionTypes.SPEAK_EXAM_PART1_UPDATE_STRAT_FILE,
      payload : payload
    });
  };
};

export const updateUrl = (payload) => {
  return dispatch => {
    dispatch({
      type    : actionTypes.SPEAK_EXAM_PART1_UPDATE_STRAT_URL,
      payload : payload
    });
  };
};