import React from "react";
import { Form } from "semantic-ui-react";
import Instruction from "./Instruction";

const ReadShortAnswer = (props) => {
  const {
    questions,
    start_number,
    handleOnChange,
    parentIndex,
    answers,
    answers_strat,
    active_index,
    strat_mode,
    size,
    explanation,
    correct_answer,
  } = props;
  const blanks = questions.map((obj, i) => {
    let value = "";
    if (answers.length > 0 && answers_strat.length > 0) {
      value = answers[active_index][parentIndex][i];
      if (strat_mode) {
        value = answers_strat[active_index][parentIndex][i];
      }
    }
    return (
      <React.Fragment key={"question" + i}>
        <Form.Field key={"text" + i}>
          {start_number + i + ". "}
          <span dangerouslySetInnerHTML={{ __html: obj }} />
        </Form.Field>
        <Form.Group inline key={"input" + i} style={{ paddingTop: ".5rem" }}>
          <input
            size={explanation ? "auto" : size ? (size[i] ? size[i] : 10) : 10}
            value={
              explanation ? correct_answer[i]?.replace(/{n}/g, " / ") : value
            }
            className="blank2"
            type="text"
            onChange={handleOnChange.bind(this, parentIndex, i)}
            key={"readshortanswerinput" + parentIndex + i}
          />
        </Form.Group>
      </React.Fragment>
    );
  });
  return (
    <React.Fragment>
      <Instruction {...props} />
      <Form className="read-mcq-radio">{blanks}</Form>
    </React.Fragment>
  );
};
export default ReadShortAnswer;
