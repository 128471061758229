import React from "react";
import {
  Container,
  Grid,
  Segment,
  TextArea,
  Form,
  Menu,
  Button,
  Icon,
} from "semantic-ui-react";
import { connect } from "react-redux";
import wordcount from "wordcount";
import ExamNav from "../ExamNav";
import HintModal from "../HintModal";
import * as writeExamActions from "../../../../actions/app/writeExam";
import * as writeReviewActions from "../../../../actions/app/writeReview";
import * as apiActions from "../../../../actions/app/api";
import * as api from "../../../../actions/api/requests";
import * as EXAM_SUBTYPES from "../../../../constants/exam_subtypes";

import EXAM from "../../../../constants/examTypes";

function pad(string) {
  return ("0" + string).slice(-2);
}

function format(seconds) {
  const date = new Date(seconds * 1000);
  const hh = date.getUTCHours();
  const mm = date.getUTCMinutes();
  const ss = pad(date.getUTCSeconds());
  if (hh) {
    return `Time Remaining: ${hh}h ${pad(mm)}m ${ss}s`;
  }
  return `Time Remaining: ${mm}m ${ss}s`;
}

class WritingContainer extends React.Component {
  componentDidMount() {
    const { answers, strat_mode, content, active_index, dispatch, timer } =
      this.props;
    if (answers.length === 0) {
      this.setBlankAnswers();
    }

    if (!(timer !== 2400 && timer !== 1200)) {
      if (content[active_index].part === EXAM_SUBTYPES.WRITE_PART_2_VALUE) {
        dispatch(writeExamActions.updateTimer(2400));
      } else {
        dispatch(writeExamActions.resetTimer());
      }
    }

    if (!strat_mode) {
      this.startTimer();
    }
  }
  setBlankAnswers() {
    const { content, dispatch } = this.props;
    const final_blank_answers = Array(content.length).join(".").split(".");
    const final_blank_answers2 = Array(content.length).join(".").split(".");
    dispatch(writeExamActions.updateAnswer(final_blank_answers));
    dispatch(writeExamActions.updateAnswerStrat(final_blank_answers2));
  }
  componentWillUnmount() {
    this.clearTimer();
    this.props.dispatch(writeExamActions.reset());
  }
  startTimer() {
    this.timer = setInterval(this.tick.bind(this), 1000);
  }
  tick() {
    const { timer, dispatch } = this.props;
    const newTimer = timer - 1;
    if (newTimer >= 0) {
      dispatch(writeExamActions.updateTimer(newTimer));
    } else {
      this.clearTimer();
      this.saveAnswer();
    }
  }
  clearTimer() {
    clearInterval(this.timer);
    this.timer = null;
  }
  state = {
    openModal: false,
  };
  handleOnChange = (e) => {
    const { answers, answers_strat, strat_mode, active_index, dispatch } =
      this.props;
    let newAnswers = answers.slice();
    if (strat_mode) {
      newAnswers = answers_strat.slice();
    }
    newAnswers[active_index] = e.target.value;
    if (strat_mode) {
      dispatch(writeExamActions.updateAnswerStrat(newAnswers));
    } else {
      dispatch(writeExamActions.updateAnswer(newAnswers));
    }
  };
  openModal = () => {
    this.setState({ openModal: true });
  };
  closeModal = () => {
    this.setState({ openModal: false });
  };
  saveAnswer = () => {
    const elements = document.getElementsByClassName("exam-divs");
    for (let i = 0; i < elements.length; i++) {
      elements[i].scrollTo(0, 0);
    }
    const {
      dispatch,
      strat_mode,
      active_index,
      content,
      history,
      answers,
      answers_strat,
      answerInstance,
      is_staff,
    } = this.props;

    if (!strat_mode) {
      this.clearTimer();
      dispatch(writeExamActions.updateStratMode(true));
    } else {
      if (active_index + 1 === content.length) {
        const data = {
          name: answerInstance.name,
          answers: answers,
          answers_strat: answers_strat,
          content: content,
          id: answerInstance.id,
          finished_at: new Date(),
          test_type: EXAM.WRITING,
        };
        dispatch(writeReviewActions.updateContent(data));
        dispatch(apiActions.updateApiSending(true));
        if (is_staff) {
          history.push("/review/write");
          return;
        } else {
          dispatch(
            api.updateAnswerInstance({ ...data }, history, "/review/write")
          );
          return;
        }
      }
      if (content[active_index + 1].part === EXAM_SUBTYPES.WRITE_PART_2_VALUE) {
        dispatch(writeExamActions.updateTimer(2400));
      } else {
        dispatch(writeExamActions.resetTimer());
      }
      dispatch(writeExamActions.updateStratMode(false));
      dispatch(writeExamActions.updateActiveIndex(active_index + 1));
      this.startTimer();
    }
  };
  render() {
    const { content, timer, active_index, strat_mode, answers, answers_strat } =
      this.props;
    const { openModal } = this.state;
    let text = "";
    if (answers.length > 0) {
      text = answers[active_index];
      if (strat_mode) {
        text = answers_strat[active_index];
      }
    }
    return (
      <React.Fragment>
        <HintModal
          header="Points & Vocab"
          hint={content[active_index].vocab}
          open={openModal}
          onClose={this.closeModal}
        />
        <Grid.Row columns={2}>
          <Grid.Column width={2} className="no-padding-right">
            <Container fluid className="full-height exam-nav">
              <Menu fluid vertical className="test-nav">
                <ExamNav {...this.props} />
              </Menu>
            </Container>
          </Grid.Column>
          <Grid.Column width={14}>
            {!strat_mode ? (
              <Container fluid style={{ marginBottom: "0rem" }}>
                <Segment className="text-center timer-header">
                  <Icon name="stopwatch" /> {format(timer)}
                </Segment>
              </Container>
            ) : null}
            <Grid
              columns={"equal"}
              style={!strat_mode ? { marginTop: ".2rem" } : null}
            >
              <Grid.Column className="no-padding-right">
                <Container>
                  <Segment className="exam-divs height-85vh max-height-85vh">
                    <div
                      className="instruction"
                      dangerouslySetInnerHTML={{
                        __html: content[active_index].content,
                      }}
                    />
                  </Segment>
                </Container>
              </Grid.Column>
              <Grid.Column>
                <Container className="full-height" style={{ height: "100%" }}>
                  <Segment className="full-height" style={{ height: "100%" }}>
                    <Form>
                      <TextArea
                        onChange={this.handleOnChange}
                        value={text}
                        placeholder="Place your answer here"
                        rows={25}
                      />
                    </Form>
                    <p className="text-center">
                      <b>Word Count: </b>
                      {wordcount(text)}
                    </p>
                  </Segment>
                </Container>
              </Grid.Column>
            </Grid>
            <Grid.Row className="padding-top-1rem">
              <div style={{ width: "33%", display: "inline-block" }}>
                &nbsp;
              </div>
              <div
                style={{ width: "34%", display: "inline-block" }}
                className="text-center"
              >
                {strat_mode ? (
                  <Button
                    size="tiny"
                    style={{
                      backgroundColor: "rgb(242, 113, 28)",
                      color: "white",
                    }}
                    onClick={this.openModal}
                  >
                    Points & Vocab
                  </Button>
                ) : null}
              </div>
              <div
                style={{ width: "33%", display: "inline-block" }}
                className="text-right"
              >
                <Button
                  size="tiny"
                  onClick={this.saveAnswer}
                  style={{
                    backgroundColor: "rgb(242, 113, 28)",
                    color: "white",
                  }}
                >
                  Finish And Proceed
                </Button>
              </div>
            </Grid.Row>
          </Grid.Column>
        </Grid.Row>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    timer: state.writeExam.timer,
    active_index: state.writeExam.active_index,
    strat_mode: state.writeExam.strat_mode,
    content: state.writeExam.content,
    answers: state.writeExam.answers,
    answers_strat: state.writeExam.answers_strat,
    answerInstance: state.answerInstance,
    is_staff: state.auth.is_staff,
  };
};

export default connect(mapStateToProps)(WritingContainer);
