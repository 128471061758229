import * as login from '../types/login';

export const updateLoginFields = (payload) => {
  return dispatch => {
    dispatch({
      type    : login.UPDATE_LOGIN_FIELDS,
      payload : payload,
    });
  };
};
