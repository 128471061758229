export const UPDATE_MOCK_TEST_CONTENT = 'UPDATE_MOCK_TEST_CONTENT';
export const UPDATE_MOCK_TEST_CURRENT_ACTIVE_PAGE = 'UPDATE_MOCK_TEST_CURRENT_ACTIVE_PAGE';
export const UPDATE_MOCK_TEST_IS_READY = 'UPDATE_MOCK_TEST_IS_READY';

export const RESET_MOCK_TEST_INSTRUCTION_TIMER = 'RESET_MOCK_TEST_INSTRUCTION_TIMER';
export const RESET_MOCK_TEST_EXAM_TIMER = 'RESET_MOCK_TEST_EXAM_TIMER';

export const UPDATE_MOCK_TEST_INSTRUCTION_TIMER = 'UPDATE_MOCK_TEST_INSTRUCTION_TIMER';
export const UPDATE_MOCK_TEST_EXAM_TIMER = 'UPDATE_MOCK_TEST_EXAM_TIMER';

export const UPDATE_MOCK_TEST_SHOW_CONFIRM_MODAL = 'UPDATE_MOCK_TEST_SHOW_CONFIRM_MODAL';

export const RESET_MOCK_TEST = 'RESET_MOCK_TEST';