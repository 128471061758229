import * as actions from '../actions/types/listenBuilder';

const defaultState = {
  code: "",
  audio: "",
  transcript: "",
  transcript_explanation: "",
  teacher_explanation: "",
  exam:{
    exams:[
      // Image Labeling
      {
        "exam_type":"READ_IMAGE_LABELING",
        "start_number":1,
        "total_count":5,
        "content":"",
        "image":"",
        "instruction":"",
        "correct_answer":[""]
      },
    ]
  },
  exam_explanation:{
    exams:[
      // Image Labeling
      {
        "exam_type":"READ_IMAGE_LABELING",
        "start_number":1,
        "total_count":5,
        "content":"",
        "image":"",
        "instruction":"",
        "correct_answer":[""]
      },
    ]
  },
  part: "ListenPart1",
};

export default function reducer(state=defaultState, action) {
  switch(action.type) {
    case actions.LISTEN_BUILDER_RESET: {
      return {...defaultState};
    }
    case actions.LISTEN_BUILDER_UPDATE_CONTENT: {
      return {...action.payload};
    }
    default: return {...state};
  }
};