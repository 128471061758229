import React from "react";
import { Form } from "semantic-ui-react";
import Instruction from "./Instruction";
import stringReplace from "react-string-replace";

const SentenceCompletion = (props) => {
  const {
    questions,
    start_number,
    handleOnChange,
    parentIndex,
    answers,
    answers_strat,
    active_index,
    strat_mode,
    size,
    explanation,
    correct_answer,
  } = props;
  const blanks = questions.map((obj, i) => {
    let value = "";
    if (answers.length > 0 && answers_strat.length > 0) {
      value = answers[active_index][parentIndex][i];
      if (strat_mode) {
        value = answers_strat[active_index][parentIndex][i];
      }
    }
    let htmlString = "";
    if (explanation) {
      let underlined_answer = "";
      try {
        underlined_answer =
          '<span style="text-decoration: underline;">&nbsp;&nbsp;' +
          correct_answer[i]?.replace(/{n}/g, " / ") +
          "&nbsp;&nbsp;</span>";
      } catch (ex) {}
      htmlString = obj
        .replace('<input class="replace"/>', underlined_answer)
        .replace('<input class="replace" type="text" />', underlined_answer);
    } else {
      htmlString = stringReplace(
        start_number + i + ". " + obj,
        '<input class="replace"/>',
        (match, j) => {
          return (
            <input
              size={size ? (size[i] ? size[i] : 10) : 10}
              value={
                explanation ? correct_answer[i].replace(/{n}/g, " / ") : value
              }
              className="blank2"
              type="text"
              onChange={handleOnChange.bind(this, parentIndex, i)}
              key={"sentencecompletioninput" + parentIndex + i}
            />
          );
        }
      );
    }
    return (
      <Form.Group inline key={"question" + i}>
        {explanation ? (
          <p dangerouslySetInnerHTML={{ __html: htmlString }} />
        ) : (
          <p>{htmlString}</p>
        )}
      </Form.Group>
    );
  });
  return (
    <React.Fragment>
      <Instruction {...props} />
      <Form className="read-mcq-radio no-margin-p">{blanks}</Form>
    </React.Fragment>
  );
};

export default SentenceCompletion;
