import React from "react";
import { connect } from "react-redux";
import { Grid, Icon, Button, Progress, TextArea } from "semantic-ui-react";
import * as speakPart1Actions from "../../../../../actions/app/speakPart1";
import * as speakExamActions from "../../../../../actions/app/speakExam";
import * as apiActions from "../../../../../actions/app/api";
import ReactPlayer from "react-player";
import { ReactMic } from "react-mic";
import AudioPlayer from "./Player";
import Recorder from "./Recorder";
import HintModal from "./HintModal";

function pad(string) {
  return ("0" + string).slice(-2);
}

function format(seconds) {
  const date = new Date(seconds * 1000);
  const mm = date.getUTCMinutes();
  const ss = pad(date.getUTCSeconds());
  return `${pad(mm)}:${pad(ss)}`;
}

class SpeakPart1 extends React.Component {
  state = {
    openModal: false,
    openHint: false,
    oscillationOn: false,
    studentAnswer: "",
  };
  openModal = () => {
    this.setState({ openModal: true });
  };
  closeModal = () => {
    this.setState({ openModal: false });
  };
  openHint = () => {
    this.setState({ openHint: true });
  };
  closeHint = () => {
    this.setState({ openHint: false });
  };
  componentDidMount() {
    const { dispatch, strat_mode } = this.props;
    if (!strat_mode) {
      dispatch(speakPart1Actions.reset());
    }
    dispatch(apiActions.updateApiSending(false));
  }
  componentWillUnmount() {
    this.clearTimer();
  }
  clearTimer() {
    clearInterval(this.timer);
    this.timer = null;
  }
  onStop = () => {
    this.props.startRecording();
    this.props.dispatch(speakPart1Actions.updatePlaying(false));
    this.props.dispatch(speakPart1Actions.updateRecording(true));
    this.timer = setInterval(this.tick.bind(this), 1000);
  };
  tick = () => {
    const { timer, dispatch } = this.props;
    const newTimer = timer - 1;
    if (newTimer >= 0) {
      dispatch(speakPart1Actions.updateTimer(newTimer));
    } else {
      this.stopRecording();
    }
  };
  tick2 = () => {
    const { timer2, dispatch } = this.props;
    const newTimer = timer2 - 1;
    if (newTimer >= 1) {
      dispatch(speakPart1Actions.updateTimer2(newTimer));
    } else {
      this.props.stopRecording();
      this.clearTimer();
      dispatch(speakPart1Actions.updateRecording2(false));
      dispatch(speakPart1Actions.updateTimer2(45));
    }
  };
  stopRecording = () => {
    this.clearTimer();
    const { recording, dispatch } = this.props;
    dispatch(apiActions.updateApiSending(true));
    dispatch(speakPart1Actions.updateRecording(false));
    if (recording) {
      this.props.stopRecording();
    } else {
      dispatch(speakPart1Actions.updatePlaying(false));
      this.props.updateAnswer("");
    }
  };
  toggleRecording(on) {
    if (on) {
      this.timer = setInterval(this.tick2.bind(this), 1000);
      this.props.startRecording();
    } else {
      this.clearTimer();
      this.props.stopRecording();
    }
    this.props.dispatch(speakPart1Actions.updateRecording2(on));
    if (!on) {
      this.props.dispatch(speakPart1Actions.updateTimer2(45));
    }
  }
  finishStratMode = () => {
    const { dispatch, strat_file, recording2 } = this.props;
    if (recording2) {
      this.clearTimer();
      dispatch(speakPart1Actions.updateRecording2(false));
      dispatch(apiActions.updateApiSending(true));
      this.props.stopRecordingStratMode();
    } else {
      if (strat_file !== null) {
        dispatch(apiActions.updateApiSending(true));
        this.props.uploadRecording(strat_file);
      } else {
        this.props.updateAnswer("");
      }
    }
  };
  stopPlaying2 = () => {
    this.props.dispatch(speakPart1Actions.updatePlaying2(false));
  };
  updateNotes1 = (event, data) => {
    const { notes_1, active_index, current_subtype } = this.props;
    const newNotes = JSON.parse(JSON.stringify(notes_1));
    newNotes[active_index][current_subtype] = data.value;
    this.props.dispatch(speakExamActions.updateNotes1(newNotes));
  };
  updateNotes2 = (event, data) => {
    const { notes_2, active_index, current_subtype } = this.props;
    const newNotes = JSON.parse(JSON.stringify(notes_2));
    newNotes[active_index][current_subtype] = data.value;
    this.props.dispatch(speakExamActions.updateNotes2(newNotes));
  };
  getHintKey = () => {
    const { content, active_index, current_subtype } = this.props;
    let postfix = "";
    if (current_subtype > 0) {
      postfix = current_subtype + 1;
    }
    return content[active_index][`hint${postfix}`];
  };
  render() {
    const {
      content,
      active_index,
      strat_mode,
      timer,
      recording,
      current_subtype,
      playing,
      strat_url,
      answers,
      timer2,
      playing2,
      recording2,
      notes_1,
      notes_2,
    } = this.props;
    const { openModal, openHint } = this.state;
    let record_percent = 100 - (timer / 45) * 100;
    let record_percent2 = 100 - (timer2 / 45) * 100;

    const vocabs = [
      content[active_index].vocab || "",
      content[active_index].vocab2 || "",
      content[active_index].vocab3 || "",
      content[active_index].vocab4 || "",
    ];

    return (
      <div>
        <center>
          <HintModal
            header="Vocab"
            hint={vocabs[current_subtype]}
            open={openModal}
            onClose={this.closeModal}
          />
          <HintModal
            header="Hints"
            hint={this.getHintKey()}
            open={openHint}
            onClose={this.closeHint}
          />
          <ReactPlayer
            key={"player1"}
            className="invisible"
            url={content[active_index].audio[current_subtype]}
            playing={playing}
            onEnded={this.onStop}
          />
          <ReactPlayer
            key={"player2"}
            className="invisible"
            url={content[active_index].audio[current_subtype]}
            playing={playing2}
            onEnded={this.stopPlaying2}
          />
          <Grid
            className="speaking-container"
            columns={"equal"}
            style={{ margin: ".5rem", width: "50%" }}
          >
            {!strat_mode ? (
              <Grid.Column
                className="content-speaking-rec"
                style={{ margin: "1rem" }}
              >
                <p
                  className={
                    recording ? "text-center red" : "text-center red blinking"
                  }
                >
                  Please listen to the question from the Examiner
                </p>
                <div
                  className="paper-box"
                  style={{
                    marginBottom: "2rem",
                    height: "182px",
                    paddingBottom: "0px",
                    overflowY: "hidden",
                  }}
                >
                  <div style={{ height: "65%" }}>
                    <h4
                      style={{
                        marginTop: "1rem",
                        marginLeft: "1rem",
                        textAlign: "left",
                      }}
                      className="red"
                    >
                      Examiner's Question
                    </h4>
                    {!recording || playing2 ? (
                      <React.Fragment>
                        <Icon
                          color={"green"}
                          name={"user"}
                          className="interviewer-icon"
                        />
                        <Icon
                          color={"green"}
                          name={"comment alternate"}
                          className="interviewer-icon"
                        />
                      </React.Fragment>
                    ) : (
                      <Icon
                        color={"red"}
                        name={"user cancel"}
                        className="interviewer-icon"
                      />
                    )}
                  </div>
                  <div className="recorder-bottom text-center"></div>
                </div>
                <p
                  className={
                    recording ? "text-center red blinking" : "text-center red"
                  }
                >
                  Please answer the examiner's question. Your response is being
                  recorder. When you've finished you can click the red stop
                  button or the button "Finish and Proceed" below
                </p>
                <div
                  className="paper-box"
                  style={{
                    marginTop: "2rem",
                    height: "182px",
                    paddingBottom: "0px",
                    overflowY: "hidden",
                  }}
                >
                  <div style={{ height: "65%" }}>
                    <h4
                      style={{
                        marginTop: "1rem",
                        marginLeft: "1rem",
                        textAlign: "left",
                      }}
                      className="red"
                    >
                      Audio Recorder To Record Your "Testing" Answer
                    </h4>
                    <ReactMic
                      key={"microphone"}
                      record={recording}
                      className="sound-wave"
                      backgroundColor="#F5F8FF"
                    />
                  </div>
                  <div className="recorder-bottom text-center">
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div
                        style={{
                          marginTop: "7px",
                          marginLeft: "1rem",
                          marginRight: "1rem",
                        }}
                      >
                        <Button
                          disabled={!recording}
                          onClick={this.stopRecording.bind(this)}
                          circular
                          className={recording ? "blinking" : ""}
                          icon={recording ? "stop" : "circle"}
                          style={{ color: "red" }}
                        />
                      </div>
                      <Progress
                        className="progress-bar"
                        percent={record_percent}
                        size="small"
                        style={{
                          flexGrow: "1",
                          marginTop: "1.46rem",
                          backgroundColor: "#DDDDDD",
                        }}
                      />
                      <div
                        style={{
                          float: "right",
                          marginLeft: "1rem",
                          marginRight: "1rem",
                          marginTop: ".1rem",
                        }}
                      >
                        <p>{format(timer)}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </Grid.Column>
            ) : (
              <Grid.Column
                className="content-speaking-rec"
                style={{ margin: "1rem" }}
              >
                <div className="testing-area-speaking">
                  <AudioPlayer
                    title="Examiner's Question"
                    url={content[active_index].audio[current_subtype]}
                    key={`audio-player-question-${active_index}-${current_subtype}`}
                  />
                  <AudioPlayer
                    title='Your "Testing" Recorded Answer'
                    url={answers[active_index][current_subtype]}
                    key={`audio-player-recorded-answer-${active_index}-${current_subtype}`}
                  />
                </div>
                <div className="learning-area-speaking">
                  <h5 className="speaking-review-header">
                    Your "Learning" Notes (Ideas, Vocab, etc.)
                  </h5>
                  <TextArea
                    key="text-area-1"
                    onChange={this.updateNotes1.bind(this)}
                    className="notes-text"
                    rows={5}
                    value={notes_1[active_index][current_subtype]}
                  />
                  <h5 className="speaking-review-header">
                    Your "Learning" Written Response
                  </h5>
                  <TextArea
                    key="text-area-2"
                    onChange={this.updateNotes2.bind(this)}
                    className="notes-text"
                    rows={5}
                    value={notes_2[active_index][current_subtype]}
                  />
                  <Recorder
                    recording2={recording2}
                    record_percent2={record_percent2}
                    timer={format(timer2)}
                    toggleRecording={this.toggleRecording.bind(this)}
                    title={'Audio Recorder To Record Your "Learning" Answer'}
                  />
                  <AudioPlayer
                    key={`audio-player-recording-${active_index}-${current_subtype}`}
                    title={'Your "Learning" Recorded Answer'}
                    url={strat_url}
                  />
                </div>
                <div style={{ marginTop: "1rem" }}>
                  <button
                    onClick={this.openModal.bind(this)}
                    className={"speaking-stop-button"}
                  >
                    Vocab
                  </button>
                </div>
              </Grid.Column>
            )}
          </Grid>
          <button
            className={"speaking-stop-button red finish-btn"}
            onClick={strat_mode ? this.finishStratMode : this.stopRecording}
          >
            Finish And Proceed
          </button>
        </center>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    content: state.speakExam.content,
    active_index: state.speakExam.active_index,
    answers: state.speakExam.answers,
    answers_strat: state.speakExam.answers_strat,
    current_subtype: state.speakExam.current_subtype,
    strat_mode: state.speakExam.strat_mode,
    timer: state.speakPart1.timer,
    timer2: state.speakPart1.timer2,
    playing: state.speakPart1.playing,
    recording: state.speakPart1.recording,
    strat_url: state.speakPart1.strat_url,
    strat_file: state.speakPart1.strat_file,
    recording2: state.speakPart1.recording2,
    playing2: state.speakPart1.playing2,
    notes_1: state.speakExam.notes_1,
    notes_2: state.speakExam.notes_2,
  };
};

export default connect(mapStateToProps)(SpeakPart1);
