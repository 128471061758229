import * as actions from '../actions/types/auth';

const defaultState = {
  is_authenticated:false,
  token:"",
  username: "",
  full_name: "",
  is_staff: false,
  id: -1,
  permission: {
    reading: false,
    listening: false,
    writing: false,
    speaking: false
  }
};

export default function reducer(state=defaultState, action) {
  switch(action.type) {
    case actions.UPDATE_TOKEN: {
      return {...state, token: action.payload};
    }
    case actions.UPDATE_IS_AUTHENTICATED: {
      return {...state, is_authenticated: true};
    }
    case actions.UPDATE_AUTH_USERNAME: {
      return {...state, username: action.payload.username, permission: action.payload.permissions, id: action.payload.id};
    }
    case actions.UPDATE_AUTH_IS_STAFF: {
      return {...state, is_staff: action.payload};
    }
    case actions.UPDATE_AUTH_FULL_NAME: {
      return {...state, full_name: action.payload};
    }
    case actions.AUTH_RESET: {
      return {...defaultState};
    }
    default: return {...state};
  }
};