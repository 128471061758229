import React from "react";
import {
  Grid,
  Table,
  Icon,
  Pagination,
  Container,
  Button,
} from "semantic-ui-react";
import { connect } from "react-redux";
import * as historyActions from "../../../../actions/app/answerHistory";
import * as api from "../../../../actions/api/requests";
import EXAM from "../../../../constants/examTypes";

import * as readReviewActions from "../../../../actions/app/readReview";
import * as listenReviewActions from "../../../../actions/app/listenReview";
import * as speakReviewActions from "../../../../actions/app/speakReview";
import * as writeReviewActions from "../../../../actions/app/writeReview";
import * as answerInstanceActions from "../../../../actions/app/answerInstance";
import { formatDate } from "../../../../common";

function sortByDateDesc(arr, dateProperty) {
  return arr.sort(
    (a, b) => new Date(b[dateProperty]) - new Date(a[dateProperty])
  );
}

const getCodes = (content) => {
  const codes = new Set();
  if (content.trim()) {
    JSON.parse(content).forEach((obj) => codes.add(obj.code));
    return Array.from(codes);
  }
  return [];
};

class AnswerHistoryTable extends React.Component {
  componentDidMount() {
    this.resetCurrentPage();
    this.props.dispatch(api.getAnswers());
  }
  componentWillUnmount() {
    this.resetCurrentPage();
  }
  resetCurrentPage() {
    const { dispatch } = this.props;
    dispatch(historyActions.updateCurrentPage(1));
  }
  onPageChange = (e, data) => {
    const { dispatch } = this.props;
    dispatch(historyActions.updateCurrentPage(data.activePage));
  };
  doReview = (answerInstance) => {
    const { dispatch, history } = this.props;
    const answer = { ...answerInstance };
    answer.content = JSON.parse(answer.content);
    answer.answers = JSON.parse(answer.answers);
    try {
      answer.answers_strat = JSON.parse(answer.answers_strat);
    } catch (ex) {}
    // eslint-disable-next-line
    switch (answerInstance.test_type) {
      case EXAM.READING:
        dispatch(readReviewActions.updateContent(answer));
        history.push("/review/read");
        break;
      case EXAM.LISTENING:
        dispatch(listenReviewActions.updateContent(answer));
        history.push("/review/listen");
        break;
      case EXAM.SPEAKING:
        dispatch(speakReviewActions.updateContent(answer));
        history.push("/review/speak");
        break;
      case EXAM.WRITING:
        dispatch(writeReviewActions.updateContent(answer));
        history.push("/review/write");
        break;
      case EXAM.MOCK_TEST:
        dispatch(answerInstanceActions.updateContent(answer));
        history.push("/review/mock-test");
        break;
    }
  };
  doDelete = (answerInstance) => {
    if (confirm("Are you sure you want to remove this exam?")) {
      const { dispatch } = this.props;
      dispatch(api.deleteAnswer(answerInstance.id));
    }
  };
  render() {
    const { content, currentActivePage, maxPerPage } = this.props;
    const startIndex = (currentActivePage - 1) * maxPerPage;
    const finalIndex = maxPerPage * currentActivePage;

    const sortedContent = sortByDateDesc(
      content.filter((item) => !!item.finished_at),
      "finished_at"
    );
    const slicedContent = sortedContent.slice(startIndex, finalIndex);
    let rows = slicedContent.map((item, i) => {
      return (
        <Table.Row key={i}>
          <Table.Cell>{item.name}</Table.Cell>
          <Table.Cell>
            {getCodes(item.content).map((code) => (
              <>
                {code}
                <br />
              </>
            ))}
          </Table.Cell>
          <Table.Cell>
            {item.finished_at ? (
              <Icon color="green" name="checkmark" size="large" />
            ) : (
              <Icon color="red" name="cancel" size="large" />
            )}
          </Table.Cell>
          <Table.Cell>{formatDate(new Date(item.created_at))}</Table.Cell>
          <Table.Cell>
            {item.finished_at === ""
              ? "Not Finished"
              : formatDate(new Date(item.finished_at))}
          </Table.Cell>
          <Table.Cell>
            <Button
              disabled={!item.finished_at}
              primary
              size="tiny"
              icon
              labelPosition="left"
              onClick={this.doReview.bind(this, item)}
            >
              <Icon name="eye" /> Review
            </Button>
            <Button
              size="tiny"
              icon
              labelPosition="left"
              color="red"
              onClick={this.doDelete.bind(this, item)}
            >
              <Icon name="trash" /> Remove
            </Button>
          </Table.Cell>
        </Table.Row>
      );
    });
    if (slicedContent.length === 0) {
      rows = (
        <Table.Row key={"noexam"}>
          <Table.Cell colSPan="5" className="text-center">
            No exams taken
          </Table.Cell>
        </Table.Row>
      );
    }
    return (
      <Grid stretched verticalAlign="middle" padded>
        <div
          style={{
            maxHeight: "72vh",
            overflowY: "auto",
            width: "100%",
            margin: "1rem 0rem .5rem 0rem",
          }}
        >
          <Table
            striped
            compact
            stackable
            style={{ paddingLeft: "0px", paddingRight: "0px" }}
          >
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Name</Table.HeaderCell>
                <Table.HeaderCell>Code(s)</Table.HeaderCell>
                <Table.HeaderCell>Finished</Table.HeaderCell>
                <Table.HeaderCell>Started At</Table.HeaderCell>
                <Table.HeaderCell>Completed At</Table.HeaderCell>
                <Table.HeaderCell>Action</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>{rows}</Table.Body>
          </Table>
        </div>
        <Container textAlign={"center"}>
          {slicedContent.length !== 0 ? (
            <Pagination
              activePage={currentActivePage}
              totalPages={Math.ceil(sortedContent.length / maxPerPage)}
              onPageChange={this.onPageChange}
            />
          ) : null}
        </Container>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    content: state.answerHistory.content,
    currentActivePage: state.answerHistory.currentActivePage,
    maxPerPage: state.answerHistory.maxPerPage,
    is_staff: state.auth.is_staff,
  };
};

export default connect(mapStateToProps)(AnswerHistoryTable);
