import * as listeningTable from '../types/listeningTable';

export const updateContent = (payload) => {
  return dispatch => {
    dispatch({
      type    : listeningTable.UPDATE_LISTENING_TABLE_CONTENT,
      payload : payload,
    });
  };
};

export const updateCurrentPage = (payload) => {
  return dispatch => {
    dispatch({
      type    : listeningTable.UPDATE_LISTENING_TABLE_CURRENT_ACTIVE_PAGE,
      payload : payload,
    });
  };
};

