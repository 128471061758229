import React from 'react';
import { connect } from 'react-redux';
import { Dimmer, Loader } from 'semantic-ui-react'

class AppLoader extends React.Component {
  render() {
    return (
      <Dimmer active={this.props.sending}>
        <Loader>Please wait</Loader>
      </Dimmer>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    sending: state.api.sending,
  }
};

export default connect(mapStateToProps)(AppLoader);