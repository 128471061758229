import React from "react";
import { connect } from "react-redux";
import { Route, Switch } from "react-router-dom";
import * as Others from "./components/others";
import Main from "./components/main/MainContainer";
import AdminMain from "./components/dashboard/Main";
import Exam from "./components/main/ExamContainer";
import Review from "./components/main/ReviewContainer";
import { withRouter } from "react-router-dom";
import "./includes/css/style.css";
import "./includes/css/fontawesome.min.css";
import "./includes/css/solid.css";
import "./includes/css/fonts.css";
import NonStudentContainer from "./components/main/components/nonStudent/MainContainer";
import MockTestContainer from "./components/main/MockTestContainer";
import * as Builders from "./components/builder";
import * as Editors from "./components/editor";
import "@aws-amplify/ui-react/styles.css";
import * as apiActions from "./actions/app/api";

import { isMobile } from "react-device-detect";

class App extends React.Component {
  componentDidMount() {
    this.props.dispatch(apiActions.updateApiSending(false));
  }
  preventRightClick = (e) => {
    e.preventDefault();
  };
  doNotCopy = (e) => {
    const pathName = this.props.history.location.pathname;

    const isCopyPasteAllowed =
      pathName?.includes("exam/write") ||
      pathName?.includes("exam/speak") ||
      pathName?.includes("review/speak");

    if (isCopyPasteAllowed) {
      return;
    }

    if (!this.props.is_staff) {
      e.preventDefault();
      e.nativeEvent.stopImmediatePropagation();
    }
  };
  render() {
    const { is_staff } = this.props;
    let browserAllowed = true;

    let osAllowed = true;
    if (isMobile) {
      osAllowed = false;
    }

    if (!browserAllowed || !osAllowed) {
      return <h1>Your browser is incompatible.</h1>;
    }

    return (
      <div
        onCopy={this.doNotCopy}
        onPaste={this.doNotCopy}
        onCut={this.doNotCopy}
        className="full-height-width"
      >
        {is_staff ? (
          <Switch>
            <Route exact path="/" component={Others.Login} />
            <Route exact path="/verify_email" component={Others.VerifyEmail} />
            <Route exact path="/signup" component={Others.Signup} />
            <Route exact path="/logout" component={Others.Logout} />
            <Route exact path="/" component={Others.Login} />
            <Route exact path="/imt" component={NonStudentContainer} />
            <Route exact path="/reading" component={NonStudentContainer} />
            <Route exact path="/listening" component={NonStudentContainer} />
            <Route exact path="/writing" component={NonStudentContainer} />
            <Route exact path="/speaking" component={NonStudentContainer} />
            <Route exact path="/mock-test" component={MockTestContainer} />
            {/* <Route exact path="/forgot-password" component={ForgotPassword} /> */}
            <Others.PrivateRoute path="/exam" component={Exam} />
            <Others.PrivateRoute path="/review" component={Review} />
            <Route exact path="/json" component={Others.JsonBuilder} />
            <Route
              exact
              path="/question-edit"
              component={Others.QuestionEditor}
            />
            <Others.PrivateRoute2
              path="/dashboard/create/writing"
              Component={() => <Builders.WritingBuilder />}
            />
            <Others.PrivateRoute2
              path="/dashboard/create/speaking"
              Component={() => <Builders.SpeakingBuilder />}
            />
            <Others.PrivateRoute2
              path="/dashboard/create/reading"
              Component={() => <Builders.ReadingBuilder />}
            />
            <Others.PrivateRoute2
              path="/dashboard/create/listening"
              Component={() => <Builders.ListeningBuilder />}
            />
            <Others.PrivateRoute2
              path="/dashboard/create/mock-test"
              Component={() => <Builders.MockTestBuilder />}
            />
            <Others.PrivateRoute2
              path="/dashboard/edit/mock-test/actual"
              Component={() => <Editors.MockTestEditor />}
            />
            <Others.PrivateRoute2
              path="/dashboard/edit/mock-test"
              Component={() => <Editors.MockExamTable />}
            />
            <Others.PrivateRoute2
              path="/dashboard/edit/reading/actual"
              Component={() => <Editors.ReadingEditor />}
            />
            <Others.PrivateRoute2
              path="/dashboard/edit/reading"
              Component={() => <Editors.ReadingExamTable />}
            />
            <Others.PrivateRoute2
              path="/dashboard/edit/listening/actual"
              Component={() => <Editors.ListeningEditor />}
            />
            <Others.PrivateRoute2
              path="/dashboard/edit/listening"
              Component={() => <Editors.ListeningExamTable />}
            />
            <Others.PrivateRoute2
              path="/dashboard/edit/writing/actual"
              Component={() => <Editors.WritingEditor />}
            />
            <Others.PrivateRoute2
              path="/dashboard/edit/writing"
              Component={() => <Editors.WritingExamTable />}
            />
            <Others.PrivateRoute2
              path="/dashboard/edit/speaking/actual"
              Component={() => <Editors.SpeakingEditor />}
            />
            <Others.PrivateRoute2
              path="/dashboard/edit/speaking"
              Component={() => <Editors.SpeakingExamTable />}
            />
            <Others.PrivateRoute2
              path="/student-ips"
              Component={() => <Editors.StudentIPs />}
            />
            <Others.PrivateRoute path="/dashboard" component={AdminMain} />
            <Others.PrivateRoute2
              path="/marketing"
              Component={() => <Editors.MarketingEditor />}
            />
            <Others.PrivateRoute2
              path="/marketing-individual"
              Component={() => <Editors.MarketingEditorIndividual />}
            />
            <Others.PrivateRoute2
              path="/non-student"
              Component={() => <Editors.NonStudents />}
            />
            <Route component={Others.ErrorPage} />
          </Switch>
        ) : (
          <Switch>
            <Route exact path="/" component={Others.Login} />
            <Route exact path="/verify_email" component={Others.VerifyEmail} />
            <Route exact path="/signup" component={Others.Signup} />
            <Route exact path="/logout" component={Others.Logout} />
            <Route exact path="/" component={Others.Login} />
            <Route exact path="/imt" component={NonStudentContainer} />
            <Route exact path="/reading" component={NonStudentContainer} />
            <Route exact path="/listening" component={NonStudentContainer} />
            <Route exact path="/writing" component={NonStudentContainer} />
            <Route exact path="/speaking" component={NonStudentContainer} />
            <Route exact path="/mock-test" component={MockTestContainer} />
            {/* <Route exact path="/forgot-password" component={ForgotPassword} /> */}
            <Others.PrivateRoute path="/exam" component={Exam} />
            <Others.PrivateRoute path="/review" component={Review} />
            <Others.PrivateRoute path="/dashboard" component={Main} />
            <Route component={Others.ErrorPage} />
          </Switch>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentPage: state.app.currentPage,
    is_staff: state.auth.is_staff,
  };
};

export default withRouter(connect(mapStateToProps)(App));
