import React from "react";
import {
  Container,
  Grid,
  Segment,
  Icon,
  Button,
  Menu,
} from "semantic-ui-react";
import { connect } from "react-redux";
import * as ReadingExams from "./index";
import ExamNav from "../ExamNav";
import * as readExamActions from "../../../../actions/app/readExam";
import * as readReviewActions from "../../../../actions/app/readReview";
import * as apiActions from "../../../../actions/app/api";
import * as api from "../../../../actions/api/requests";
import HintModal from "../HintModal";

import EXAM from "../../../../constants/examTypes";

function onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}

function pad(string) {
  return ("0" + string).slice(-2);
}

function format(seconds) {
  const date = new Date(seconds * 1000);
  const hh = date.getUTCHours();
  const mm = date.getUTCMinutes();
  const ss = pad(date.getUTCSeconds());
  if (hh) {
    return `Time Remaining: ${hh}h ${pad(mm)}m ${ss}s`;
  }
  return `Time Remaining: ${mm}m ${ss}s`;
}

class ReadContainer extends React.Component {
  componentDidMount() {
    // const { content, active_index, dispatch } = this.props;
    // dispatch(readExamActions.resetTimer())
    const { answers, strat_mode } = this.props;
    if (answers.length === 0) {
      this.setBlankAnswers();
    }
    if (!strat_mode) {
      this.startTimer();
    }
  }
  setBlankAnswers() {
    const { content, dispatch } = this.props;
    const final_blank_answers = [];
    const final_blank_answers2 = [];
    for (let i = 0; i < content.length; i++) {
      const answers = [];
      const answers2 = [];
      // eslint-disable-next-line
      content[i].exam.exams.map((exam) => {
        answers.push(Array(exam.correct_answer.length).join(".").split("."));
        answers2.push(Array(exam.correct_answer.length).join(".").split("."));
      });
      final_blank_answers.push(answers);
      final_blank_answers2.push(answers2);
    }
    dispatch(readExamActions.updateAnswer(final_blank_answers));
    dispatch(readExamActions.updateAnswerStrat(final_blank_answers2));
  }
  componentWillUnmount() {
    this.clearTimer();
    this.props.dispatch(readExamActions.reset());
  }
  startTimer() {
    this.timer = setInterval(this.tick.bind(this), 1000);
  }
  tick() {
    const { timer, dispatch } = this.props;
    const newTimer = timer - 1;
    if (newTimer >= 0) {
      dispatch(readExamActions.updateTimer(newTimer));
    } else {
      this.clearTimer();
      this.saveAnswer();
    }
  }
  clearTimer() {
    clearInterval(this.timer);
    this.timer = null;
  }
  handleOnChange = (i, j, e) => {
    const { answers, answers_strat, active_index, dispatch, strat_mode } =
      this.props;
    let newAnswers = answers.slice();
    if (strat_mode) {
      newAnswers = answers_strat.slice();
    }
    newAnswers[active_index][i][j] = e.target.value;
    if (strat_mode) {
      dispatch(readExamActions.updateAnswerStrat(newAnswers));
    } else {
      dispatch(readExamActions.updateAnswer(newAnswers));
    }
  };
  handleRadioOnChange = (i, j, answer, e) => {
    const { answers, answers_strat, active_index, dispatch, strat_mode } =
      this.props;
    let newAnswers = answers.slice();
    if (strat_mode) {
      newAnswers = answers_strat.slice();
    }
    newAnswers[active_index][i][j] = answer;
    if (strat_mode) {
      dispatch(readExamActions.updateAnswerStrat(newAnswers));
    } else {
      dispatch(readExamActions.updateAnswer(newAnswers));
    }
  };
  handleCheckboxOnChange = (i, j, answer, checked) => {
    const {
      answers,
      answers_strat,
      active_index,
      dispatch,
      strat_mode,
      content,
    } = this.props;
    // Get max answer size
    const max_answers =
      content[active_index].exam.exams[i].correct_answer[j].length;
    let newAnswers = answers.slice();
    if (strat_mode) {
      newAnswers = answers_strat.slice();
    }
    let newArr = [];
    if (newAnswers[active_index][i][j].length > 0) {
      newArr = newAnswers[active_index][i][j].split(",");
    }
    if (newArr.indexOf(answer.toString()) === -1) {
      // If current is equal or greater than the size of max answer, do not proceed
      if (newArr.length >= max_answers) {
        return;
      }
      newArr.push(answer);
    } else {
      newArr.splice(newArr.indexOf(answer.toString()), 1);
    }
    newArr = newArr.filter(onlyUnique).join(",");
    newAnswers[active_index][i][j] = newArr;
    if (strat_mode) {
      dispatch(readExamActions.updateAnswerStrat(newAnswers));
    } else {
      dispatch(readExamActions.updateAnswer(newAnswers));
    }
  };
  saveAnswer = () => {
    const elements = document.getElementsByClassName("exam-divs");
    for (let i = 0; i < elements.length; i++) {
      elements[i].scrollTo(0, 0);
    }
    const {
      dispatch,
      strat_mode,
      active_index,
      content,
      history,
      answers,
      answers_strat,
      answerInstance,
      is_staff,
    } = this.props;

    if (!strat_mode) {
      this.clearTimer();
      dispatch(readExamActions.updateStratMode(true));
    } else {
      if (active_index + 1 === content.length) {
        content[0].name = answerInstance.name;
        const data = {
          name: answerInstance.name,
          id: answerInstance.id,
          answers: answers,
          answers_strat: answers_strat,
          content: content,
          finished_at: new Date(),
          test_type: EXAM.READING,
        };
        dispatch(readReviewActions.updateContent(data));
        dispatch(apiActions.updateApiSending(true));
        if (is_staff) {
          history.push("/review/read");
          return;
        } else {
          dispatch(
            api.updateAnswerInstance({ ...data }, history, "/review/read")
          );
          return;
        }
      }
      dispatch(readExamActions.resetTimer());
      dispatch(readExamActions.updateStratMode(false));
      dispatch(readExamActions.updateActiveIndex(active_index + 1));
      this.startTimer();
      dispatch(readReviewActions.updateContent());
    }
  };
  handleDetailedFlowChartChange = (i, j, e) => {
    const { answers, answers_strat, active_index, dispatch, strat_mode } =
      this.props;
    let newAnswers = answers.slice();
    if (strat_mode) {
      newAnswers = answers_strat.slice();
    }
    newAnswers[active_index][i][j] = e.target.value;
    if (strat_mode) {
      dispatch(readExamActions.updateAnswerStrat(newAnswers));
    } else {
      dispatch(readExamActions.updateAnswer(newAnswers));
    }
  };
  state = {
    answers: [],
    handleOnChange: this.handleOnChange.bind(this),
    handleRadioOnChange: this.handleRadioOnChange.bind(this),
    handleCheckboxOnChange: this.handleCheckboxOnChange.bind(this),
    handleDetailedFlowChartChange:
      this.handleDetailedFlowChartChange.bind(this),
    openHintModal: false,
    openVocabModal: false,
  };
  openHintModal = () => {
    this.setState({ openHintModal: true });
  };
  closeHintModal = () => {
    this.setState({ openHintModal: false });
  };
  openVocabModal = () => {
    this.setState({ openVocabModal: true });
  };
  closeVocabModal = () => {
    this.setState({ openVocabModal: false });
  };
  render() {
    const { strat_mode, content, active_index, timer } = this.props;
    const { openHintModal, openVocabModal } = this.state;
    return (
      <React.Fragment>
        <HintModal
          header="Hints"
          hint={content[active_index].hint}
          open={openHintModal}
          onClose={this.closeHintModal}
        />
        <HintModal
          header="Vocab"
          hint={content[active_index].vocab}
          open={openVocabModal}
          onClose={this.closeVocabModal}
        />
        <Grid.Row columns={2}>
          <Grid.Column width={2} className="no-padding-right">
            <Container fluid className="full-height exam-nav">
              <Menu fluid vertical className="test-nav">
                <ExamNav {...this.props} />
              </Menu>
            </Container>
          </Grid.Column>
          <Grid.Column width={14} className="children-margin-bottom-5px">
            {!strat_mode ? (
              <Container fluid>
                <Segment className="text-center timer-header">
                  <Icon name="stopwatch" /> {format(timer)}
                </Segment>
              </Container>
            ) : null}
            <Container fluid>
              <Segment className="instruction-header">
                <div
                  className="instruction"
                  dangerouslySetInnerHTML={{
                    __html: content[active_index].instruction,
                  }}
                />
              </Segment>
            </Container>
            <Grid columns={"equal"}>
              <Grid.Column className="no-padding-right">
                <Container>
                  <Segment className="exam-divs height-85vh max-height-85vh">
                    <ReadingExams.ReadingContent
                      reading={true}
                      {...this.props}
                    />
                  </Segment>
                </Container>
              </Grid.Column>
              <Grid.Column className="full-height">
                <Container className="full-height">
                  <Segment className="exam-divs height-85vh max-height-85vh margin-bottom-child-10px">
                    <ReadingExams.ReadCombined
                      {...this.state}
                      {...this.props}
                    />
                  </Segment>
                </Container>
              </Grid.Column>
            </Grid>
            <Grid columns={"equal"}>
              {strat_mode ? (
                <React.Fragment>
                  <Grid.Column className="no-padding-top-impt"></Grid.Column>
                  <Grid.Column className="no-padding-top-impt text-center">
                    <Container fluid>
                      <Button
                        style={{
                          backgroundColor: "rgb(242, 113, 28)",
                          color: "white",
                        }}
                        onClick={this.openHintModal}
                        size="tiny"
                      >
                        Hints
                      </Button>
                    </Container>
                  </Grid.Column>
                </React.Fragment>
              ) : null}
              <Grid.Column className="no-padding-top-impt">
                <Container fluid>
                  <Button
                    style={{
                      backgroundColor: "rgb(242, 113, 28)",
                      color: "white",
                    }}
                    floated="right"
                    onClick={this.saveAnswer}
                    size="tiny"
                  >
                    {strat_mode
                      ? "Finish And Proceed"
                      : 'Finish Early And Proceed to "Learning"'}
                  </Button>
                </Container>
              </Grid.Column>
            </Grid>
          </Grid.Column>
        </Grid.Row>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    content: state.readExam.content,
    timer: state.readExam.timer,
    active_index: state.readExam.active_index,
    strat_mode: state.readExam.strat_mode,
    answers: state.readExam.answers,
    answers_strat: state.readExam.answers_strat,
    answerInstance: state.answerInstance,
    is_staff: state.auth.is_staff,
  };
};

export default connect(mapStateToProps)(ReadContainer);
