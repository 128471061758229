import React from 'react';

/* eslint-disable */
const Sidebar = (props) => {
  const nav_menus = [];
  const { content, test_type } = props.answerInstance;
  const { show, openSidebar, changeSelectedIndex, changeSelectedSpeaking, selectedExam, selectedIndex, selectedSubtype } = props;

  if (props.nonStudent){
    nav_menus.push(
      <a
        onClick={changeSelectedIndex.bind(this, 'myclasses', 0)}
        key={`myclases`}
        style={{fontWeight:'bold'}}
        className={selectedExam==='myclasses'?"active":null}
      >
        My Classes
      </a>
    );
  }
  if (test_type === 'listening' || test_type === '5'){
    nav_menus.push(<a className="font-small" style={{fontWeight:'bold'}}>Listening Test</a>);
  }
  content.listening.forEach((section, i) => {
    nav_menus.push(
      <a
        onClick={changeSelectedIndex.bind(this, 'listening', i)}
        key={`listeningsidemenu${i}`}
        className={selectedExam==='listening' && selectedIndex === i?"active":null}
      >
          {section.name}
      </a>
    );
  });

  if (test_type === 'reading' || test_type === '5'){
    nav_menus.push(<a style={{fontWeight:'bold'}}>Reading Test</a>);
  }

  content.reading.forEach((section, i) => {
    nav_menus.push(
      <a
        onClick={changeSelectedIndex.bind(this, 'reading', i)}
        className={selectedExam==='reading' && selectedIndex === i?"active":null}
        key={`readingsidemenu${i}`}
      >
        {section.name}
      </a>
    );
  });

  if (test_type === 'writing' || test_type === '5'){
    nav_menus.push(<a className="font-small" style={{fontWeight:'bold'}}>Writing Test</a>);
  }
  content.writing.forEach((section, i) => {
    nav_menus.push(
      <a
        onClick={changeSelectedIndex.bind(this, 'writing', i)}
        className={selectedExam==='writing' && selectedIndex === i?"active":null}
        key={`writingsidemenu${i}`}
      >
        {section.name}
      </a>
    );
  });
  if (content.speaking.length > 0){
    nav_menus.push(<a style={{fontWeight:'bold'}}>Speaking Test</a>);
    content.speaking.forEach((section, i) => {
      if (section.exam.part !== "SpeakPart2"){
        section.exam.audio.forEach((audio, j) => {
          if (audio !== ""){
            let subtype = "a";
            if (j === 1){
              subtype = "b";
            }
            if (j === 2){
              subtype = "c";
            }
            if (j === 3){
              subtype = "d";
            }
            nav_menus.push(
              <a
                onClick={changeSelectedSpeaking.bind(this, 'speaking', i, j)}
                className={selectedExam==='speaking' && selectedIndex === i && selectedSubtype === j?"active":null}
                key={`speakingsidemenu111${i}`}
              >
                {section.name + subtype}
              </a>
            );
          }
        });
      } else {
        nav_menus.push(
          <a
            onClick={changeSelectedSpeaking.bind(this, 'speaking', i, 0)}
            className={selectedExam==='speaking' && selectedIndex === i && selectedSubtype === 0?"active":null}
            key={`speakingsidemenu2222${i}`}
          >
            {section.name}
          </a>
        );
      }
    });
  }
  return (
    <React.Fragment>
      <div id="exam-overlay" onClick={props.closeNav} style={openSidebar?{width:"100%"}:{}}></div>
      <div id="exam-side-nav" className="exam-side-nav r-light" style={openSidebar?{width:"415px"}:null}>
        <div id="exam-side-nav-close" className={"exam-side-nav-close " + show}>
          <a className="closebtn" onClick={props.closeNav}>&times;</a>
        </div>
        <div id="exam-side-nav-list" className={"exam-side-nav-list " + show}>
          {nav_menus}
        </div>
      </div>
    </React.Fragment>
  );
}

export default Sidebar;