import React from "react";
import { Segment, Icon, Form } from "semantic-ui-react";
import Instruction from "../Instruction";
import parse from "html-react-parser";
import { unescapeHTML } from "../../../../common";

const ReadFlowChart = (props) => {
  const {
    questions,
    start_number,
    handleDetailedFlowChartChange,
    parentIndex,
    size,
    correct_answer,
    answers,
    strat_mode,
    explanation,
    active_index,
    answers_strat,
    prefixes,
  } = props;
  let answer_index = 0;
  let item_number = 0;
  const blanks = questions.map((obj, i) => {
    let str_replaced = obj
      .toString()
      .split("__blank__")
      .join('<input class="replace" />');
    str_replaced = str_replaced
      .split("__blank2__")
      .join('<input id="nonumber" class="replace" />');
    str_replaced = unescapeHTML(str_replaced);
    return parse(str_replaced, {
      replace: (domNode) => {
        if (domNode.attribs && domNode.attribs.class === "replace") {
          let value = "";
          if (answers.length > 0 && answers_strat.length > 0) {
            value = answers[active_index][parentIndex][answer_index];
            if (strat_mode) {
              value = answers_strat[active_index][parentIndex][answer_index];
            }
          }
          let explanation_answer = "";
          try {
            explanation_answer = correct_answer[answer_index].replace(
              /{n}/g,
              " / "
            );
          } catch (ex) {
            explanation_answer = value;
          }
          answer_index++;
          let prefix = "";
          try {
            if (prefixes[i - 1]) {
              prefix = prefixes[i - 1];
            }
          } catch (ex) {}
          return (
            <React.Fragment>
              <b>
                {domNode.attribs.id !== "nonumber"
                  ? start_number + item_number++ + ". "
                  : null}
              </b>
              {prefix}
              <input
                value={explanation ? explanation_answer : value}
                onChange={handleDetailedFlowChartChange.bind(
                  this,
                  parentIndex,
                  answer_index - 1
                )}
                size={
                  size
                    ? size[answer_index - 1]
                      ? size[answer_index - 1]
                      : 10
                    : 10
                }
                type={"text"}
                className="blank2"
                key={"notecompletioninput" + parentIndex + i}
              />
            </React.Fragment>
          );
        }
      },
    });
  });
  const final_questions = blanks.map((blank, i) => {
    return (
      <div
        key={"ReadFlowChartContainer" + parentIndex + i}
        className="text-center"
      >
        <Segment textAlign="left" className="flow-chart-box full-height-100">
          {blank}
        </Segment>
        {i !== questions.length - 1 ? (
          <Icon name="arrow down" size="big" />
        ) : null}
      </div>
    );
  });
  return (
    <React.Fragment>
      <Instruction {...props} className="margin-top-1rem" />
      <Form key={"ReadFlowChartForm" + parentIndex}>{final_questions}</Form>
    </React.Fragment>
  );
};

export default ReadFlowChart;
