import * as actions from '../actions/types/api';

const defaultState = {
  sending: false
};

export default function reducer(state=defaultState, action) {
  switch(action.type) {
    case actions.UPDATE_API_SENDING: {
      return {...state, sending: action.payload};
    }
    default: return {...state};
  }
};