import * as actionTypes from "../types/examCount";

export const updateExamCount = (payload) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.UPDATE_EXAM_COUNT,
      payload,
    });
  };
};

export const resetExamCount = () => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.RESET_EXAM_COUNT,
    });
  };
};
