import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import * as answerActions from "../../../../../actions/app/answerInstance";
import * as writeReviewActions from "../../../../../actions/app/writeReview";
import * as appActions from "../../../../../actions/app/app";
import { Grid, TextArea } from "semantic-ui-react";
import Header from "./ReviewHeader";
import Sidebar from "./ReviewSidebar";
import HintModal from "./HintModal";
import wordcount from "wordcount";
import * as api from "../../../../../actions/api/requests";

class WritingContainer extends React.Component {
  toggleNav() {
    const { dispatch, openSidebar } = this.props;
    dispatch(appActions.updateOpenSidebar(!openSidebar));
  }
  closeNav() {
    this.props.dispatch(appActions.updateOpenSidebar(false));
  }
  state = {
    active_index: 0,
    openHintModal: false,
    openHintModal2: false,
    myAnswerMode: true,
    handleOnChange: this.placeholder.bind(this),
    handleRadioOnChange: this.placeholder.bind(this),
    handleCheckboxOnChange: this.placeholder.bind(this),
    toggleNav: this.toggleNav.bind(this),
    closeNav: this.closeNav.bind(this),
  };
  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(writeReviewActions.reset());
    dispatch(answerActions.reset());
  }
  placeholder() {}
  updateActiveIndex = (i) => {
    this.toggleNav();
    this.setState({ active_index: i });
  };
  openHintModal = () => {
    this.setState({ openHintModal: true });
  };
  closeHintModal = () => {
    this.setState({ openHintModal: false });
  };
  openHintModal2 = () => {
    this.setState({ openHintModal2: true });
  };
  closeHintModal2 = () => {
    this.setState({ openHintModal2: false });
  };
  myAnswerModeOn = () => {
    this.setState({ myAnswerMode: true });
  };
  myAnswerModeOff = () => {
    this.setState({ myAnswerMode: false });
  };
  goToDashboard = () => {
    this.props.history.push("/");
  };
  goToDashboard = () => {
    this.props.history.push("/");
  };
  updateAnswerStrat = (event, data) => {
    const { raw_content, dispatch } = this.props;
    const { active_index } = this.state;
    const newContent = { ...raw_content };
    newContent.answers_strat[active_index] = data.value;
    dispatch(writeReviewActions.updateContent({ ...raw_content }));
  };
  updateNotes = (event, data) => {
    const { notes_1, raw_content } = this.props;
    const { active_index } = this.state;
    const newNotes = JSON.parse(JSON.stringify(notes_1));
    newNotes[active_index] = data.value;
    const newContent = { ...raw_content };
    newContent.notes_1 = newNotes;
    this.props.dispatch(writeReviewActions.updateContent(newContent));
  };

  save = () => {
    const { answers_strat } = this.props;
    const { id, notes_1 } = this.props.raw_content;
    this.props.dispatch(
      api.updateAnswerInstance2({
        id,
        notes_1:
          typeof notes_1 === "string" ? notes_1 : JSON.stringify(notes_1),
        answers_strat: JSON.stringify(answers_strat),
      })
    );
  };

  render() {
    const { openSidebar, content, answers, answers_strat, notes_1 } =
      this.props;
    const { active_index, openHintModal, openHintModal2 } = this.state;
    let show = "hide";
    if (openSidebar) {
      show = "show";
    }

    return (
      <React.Fragment>
        <Header {...this.state} {...this.props} show={show} />
        <Sidebar
          {...this.state}
          {...this.props}
          show={show}
          updateActiveIndex={this.updateActiveIndex}
        />
        <HintModal
          header="Vocab"
          hint={content[active_index].vocab}
          open={openHintModal}
          onClose={this.closeHintModal}
        />
        <HintModal
          header="Hints"
          hint={content[active_index]?.hint}
          open={openHintModal2}
          onClose={this.closeHintModal2}
        />
        <div className="main-container">
          <div className="content-new-ui header-new-ui">
            <div id="content-split" className="content-split">
              {/* {myAnswerMode ? (
                <React.Fragment>
                  <div
                    id="content-left"
                    className="content-left"
                    style={{ overflow: "auto", margin: "2rem 1rem 1rem 1rem" }}
                  >
                    <h3 className="text-center">Answers (Testing)</h3>
                    <div
                      className="instruction"
                      style={{ padding: ".5rem" }}
                      dangerouslySetInnerHTML={{
                        __html: answers[active_index],
                      }}
                    />
                  </div>
                  <div
                    id="content-right"
                    className="content-right"
                    style={{ overflow: "auto", margin: "2rem 1rem 1rem 1rem" }}
                  >
                    <h3 className="text-center">Answers (Learning)</h3>
                    <div
                      className="instruction"
                      dangerouslySetInnerHTML={{
                        __html: answers_strat[active_index],
                      }}
                    />
                  </div>
                </React.Fragment>
              ) : ( */}

              {/* )} */}
              <React.Fragment>
                <div
                  id="content-left"
                  className="content-left"
                  style={{
                    overflow: "auto",
                    margin: "2rem 1rem 1rem 1rem",
                    paddingBottom: "2rem",
                  }}
                >
                  <div
                    className="instruction"
                    dangerouslySetInnerHTML={{
                      __html: content[active_index].content,
                    }}
                  />
                </div>
                <div
                  id="content-right"
                  className="content-right"
                  style={{
                    overflow: "auto",
                    margin: "2rem 1rem 1rem 1rem",
                    paddingBottom: "2rem",
                  }}
                >
                  <div className="testing-area-speaking text-center">
                    <h3 className="text-center">
                      Your "Testing" Written Response
                    </h3>
                    <TextArea
                      key="text-area-2"
                      className="notes-text no-resize"
                      rows={5}
                      value={answers[active_index]}
                      disabled
                    />
                    <p className="text-center">
                      <b>Word Count: </b>
                      {wordcount(answers[active_index])}
                    </p>
                  </div>
                  <div className="learning-area-speaking text-center">
                    <h3 className="text-center">
                      Your "Learning" Notes (Ideas, Vocab, etc.)
                    </h3>
                    <TextArea
                      key="text-area-2"
                      className="notes-text"
                      rows={5}
                      onChange={this.updateNotes.bind(this)}
                      value={notes_1[active_index]}
                    />
                    <h3 className="text-center">
                      Your "Learning" Written Response
                    </h3>
                    <TextArea
                      key="text-area-2"
                      className="notes-text"
                      rows={5}
                      onChange={this.updateAnswerStrat.bind(this)}
                      value={answers_strat[active_index]}
                    />
                    <p className="text-center">
                      <b>Word Count: </b>
                      {wordcount(answers_strat[active_index])}
                    </p>
                  </div>
                  <h3 className="text-center">Model Answer</h3>
                  <div
                    className="instruction model-answer-writing"
                    dangerouslySetInnerHTML={{
                      __html: content[active_index].model_answer,
                    }}
                  />
                </div>
              </React.Fragment>
            </div>
            <div className="content-button">
              <Grid columns={"equal"}>
                <Grid.Column></Grid.Column>
                <Grid.Column className="text-center">
                  <button type="button" onClick={this.openHintModal}>
                    Vocab
                  </button>{" "}
                  <button type="button" onClick={this.save}>
                    Save Learning Notes & Written Response
                  </button>
                  {/* {" "}
                  <button type="button" onClick={this.openHintModal2}>
                    Hints
                  </button> */}
                </Grid.Column>
                <Grid.Column className="text-right">
                  {/* <button
                    type="button"
                    disabled={myAnswerMode}
                    className={myAnswerMode ? "active-art" : ""}
                    onClick={this.myAnswerModeOn}
                  >
                    My Answers
                  </button>
                  <button
                    type="button"
                    disabled={!myAnswerMode}
                    className={!myAnswerMode ? "active-art" : ""}
                    onClick={this.myAnswerModeOff}
                  >
                    Model Answers
                  </button> */}
                </Grid.Column>
              </Grid>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    id: state.writeReview.content.id,
    content: state.writeReview.content.content,
    answers: state.writeReview.content.answers,
    notes_1:
      typeof state.writeReview.content.notes_1 === "string"
        ? JSON.parse(state.writeReview.content.notes_1)
        : state.writeReview.content.notes_1,
    answers_strat: state.writeReview.content.answers_strat,
    name: state.writeReview.content.name,
    openSidebar: state.app.openSidebar,
    raw_content: state.writeReview.content,
  };
};

export default withRouter(connect(mapStateToProps)(WritingContainer));
