import React from "react";
import { ReactMic } from "react-mic";
import { Button, Progress, Icon } from "semantic-ui-react";

const Recorder = (props) => {
  const { recording2, toggleRecording, record_percent2, timer, title } = props;

  const titleStr = title || "Audio Recorder";

  return (
    <div
      className="paper-box"
      style={{
        marginTop: "1rem",
        height: "182px",
        paddingBottom: "0px",
        overflowY: "hidden",
      }}
    >
      <div style={{ height: "65%" }}>
        <h4
          style={{ marginTop: "1rem", marginLeft: "1rem", textAlign: "left" }}
        >
          {titleStr}
        </h4>
        {recording2 ? (
          <ReactMic
            key={"microphone2"}
            record={recording2}
            className="sound-wave"
            backgroundColor="#F5F8FF"
          />
        ) : (
          <p className="press-btn-red">
            Press the <Icon name="circle" style={{ color: "red" }} />
            button to start recording
          </p>
        )}
      </div>
      <div className="recorder-bottom text-center">
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              marginTop: "7px",
              marginLeft: "1rem",
              marginRight: "1rem",
            }}
          >
            <Button
              circular
              className={recording2 ? "blinking" : ""}
              icon={!recording2 ? "circle" : "stop"}
              style={{ cursor: "default", color: "red" }}
              onClick={toggleRecording.bind(this, !recording2)}
            />
          </div>
          <Progress
            className="progress-bar"
            percent={record_percent2}
            size="small"
            style={{
              flexGrow: "1",
              marginTop: "1.46rem",
              backgroundColor: "#DDDDDD",
            }}
          />
          <div
            style={{
              float: "right",
              marginLeft: "1rem",
              marginRight: "1rem",
              marginTop: ".1rem",
            }}
          >
            <p>{timer}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Recorder;
