import React from 'react';
import { Dimmer, Loader } from 'semantic-ui-react';

const LoadingScreen = props => {
  return (
    <Dimmer active>
      <Loader>Saving your answers</Loader>
    </Dimmer>
  )
};
export default LoadingScreen;