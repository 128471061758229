import React from 'react';
import { connect } from 'react-redux';
import * as listenExamActions from '../../../../actions/app/listenExam';
import * as listenReviewActions from '../../../../actions/app/listenReview';
import * as apiActions from '../../../../actions/app/api';
import * as api from '../../../../actions/api/requests';
import SoundContainer from './SoundContainer';

import EXAM from '../../../../constants/examTypes';
import ListenExam from './ListenExam';

class ListeningContainer extends React.Component {
  componentDidMount(){
    const { countdown_started } = this.props;
    if (countdown_started){
      this.startTimer();
    }
  }
  saveAnswer = () => {
    const elements = document.getElementsByClassName("exam-divs");
    for (let i = 0; i < elements.length; i++){
      elements[i].scrollTo(0, 0);
    }
    const { dispatch, strat_mode, active_index, content, history, answers, answers_strat, answerInstance, is_staff } = this.props;

    if (!strat_mode){
      this.clearTimer();
      dispatch(listenExamActions.updateStratMode(true));
    } else {
      if (active_index + 1 === content.length){
        const data = {
          name: answerInstance.name,
          answers: answers,
          answers_strat: answers_strat,
          content: content,
          id: answerInstance.id,
          finished_at: new Date(),
          test_type: EXAM.LISTENING
        }
        dispatch(listenReviewActions.updateContent(data));
        dispatch(apiActions.updateApiSending(true));
        if (is_staff){
          history.push('/review/listen');
          return;
        } else {
          dispatch(api.updateAnswerInstance({...data}, history, '/review/listen'));
          return;
        }
      }
      dispatch(listenExamActions.resetTimer());
      dispatch(listenExamActions.resetPosition());
      dispatch(listenExamActions.setCountdownStarted(false));
      dispatch(listenExamActions.updateStratMode(false));
      dispatch(listenExamActions.updateActiveIndex(active_index+1));
    }
  }
  startTimer = () => {
    this.timer = setInterval(this.tick.bind(this), 1000);
  }
  tick(){
    const { timer, dispatch } = this.props;
    const newTimer = timer - 1;
    if (newTimer >= 0){
      dispatch(listenExamActions.updateTimer(newTimer));
    } else {
      this.clearTimer();
      this.saveAnswer();
    }
  }
  clearTimer(){
    clearInterval(this.timer);
    this.timer = null;
  }
  render(){
    const { content, active_index, strat_mode } = this.props;
    const url = content[active_index].audio;
    return (
      <React.Fragment>
        {
          !strat_mode?
            <SoundContainer url={url} startTimer={this.startTimer}/>
            :
            null
        }
        <ListenExam/>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    content:      state.listenExam.content,
    timer:        state.listenExam.timer,
    active_index: state.listenExam.active_index,
    strat_mode:   state.listenExam.strat_mode,
    answers:      state.listenExam.answers,
    answers_strat:state.listenExam.answers_strat,
    audio_position: state.listenExam.position,
    countdown_started: state.listenExam.countdown_started,
    answerInstance: state.answerInstance,
    is_staff      : state.auth.is_staff
  }
};

export default connect(mapStateToProps)(ListeningContainer);