import * as actions from '../actions/types/nonStudentList';

const defaultState = {
  content: [],
  currentActivePage:1,
  maxPerPage:50,
};

export default function reducer(state=defaultState, action) {
  switch(action.type) {
    case actions.UPDATE_NON_STUDENT_LIST_CONTENT: {
      return {...state, content: action.payload};
    }
    case actions.UPDATE_NON_STUDENT_LIST_CURRENT_ACTIVE_PAGE: {
      return {...state, currentActivePage: action.payload};
    }
    default: return {...state};
  }
};