import React from 'react';
import { Message, Icon } from 'semantic-ui-react';

const Information = props => (
  <Message
    style={{
      maxWidth: '25%',
      position: 'absolute',
      zIndex: '2',
      right: '1rem',
      top: '2rem'
    }}
    info
    icon
  >
    <Icon name='info' />
    <Message.Content>
    Please take note that if your screen is not big enough, you may need to use the <b>scroll bar on the right to scroll up and down</b> to see everything during this test
    </Message.Content>
  </Message>
);

export default Information;