import React from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import * as auth from "../../actions/app/auth";
import clearAuth from "../../utils/clearAuth";
import { Auth } from "aws-amplify";
import LoadingScreen from "../dashboard/exams/mock_test/LoadingScreen";

class Logout extends React.Component {
  state = {
    done: false,
  };
  componentDidMount() {
    const { dispatch } = this.props;
    clearAuth();
    dispatch(auth.reset());
    (async () => {
      await Auth.signOut();
      this.setState({ done: true });
    })();
  }
  render() {
    if (!this.state.done) {
      return <LoadingScreen />;
    }
    return <Redirect to={{ pathname: "/" }} />;
  }
}

const mapStateToProps = (state) => {
  return {
    is_authenticated: state.app.is_authenticated,
  };
};

export default connect(mapStateToProps)(Logout);
