function pad(string) {
  return ("0" + string).slice(-2);
}

export const format = (seconds) => {
  const date = new Date(seconds * 1000);
  const mm = date.getUTCMinutes();
  const ss = pad(date.getUTCSeconds());
  return `${pad(mm)}:${pad(ss)}`;
};
