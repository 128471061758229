export const ACADEMIC_VALUE = "AC";
export const GENERAL_TRAINING_VALUE = "GT";

export const ACADEMIC_TEXT = "AC";
export const GENERAL_TRAINING_TEXT = "GT";

export const LISTEN_PART_1_VALUE = "ListenPart1";
export const LISTEN_PART_2_VALUE = "ListenPart2";
export const LISTEN_PART_3_VALUE = "ListenPart3";
export const LISTEN_PART_4_VALUE = "ListenPart4";

export const LISTEN_PART_1_TEXT = "Listening Part 1";
export const LISTEN_PART_2_TEXT = "Listening Part 2";
export const LISTEN_PART_3_TEXT = "Listening Part 3";
export const LISTEN_PART_4_TEXT = "Listening Part 4";

export const READ_PART_1_VALUE = "ReadPart1";
export const READ_PART_2_VALUE = "ReadPart2";
export const READ_PART_3_VALUE = "ReadPart3";

export const READ_PART_1_TEXT = "Reading Part 1";
export const READ_PART_2_TEXT = "Reading Part 2";
export const READ_PART_3_TEXT = "Reading Part 3";

export const WRITE_PART_1_VALUE = "WritePart1";
export const WRITE_PART_2_VALUE = "WritePart2";
export const WRITE_PART_3_VALUE = "WritePart3";

export const WRITE_PART_1_TEXT = "Writing Part 1";
export const WRITE_PART_2_TEXT = "Writing Part 2";
export const WRITE_PART_3_TEXT = "Writing Part 3";

export const SPEAK_PART_1_VALUE = "SpeakPart1";
export const SPEAK_PART_2_VALUE = "SpeakPart2";
export const SPEAK_PART_3_VALUE = "SpeakPart3";

export const SPEAK_PART_1_TEXT = "Speaking Part 1";
export const SPEAK_PART_2_TEXT = "Speaking Part 2";
export const SPEAK_PART_3_TEXT = "Speaking Part 3";