import React from 'react';
import { connect } from 'react-redux';
import MainInstruction from './others/MainInstruction';
import ListenContainer from './listen/MockListening';
import ReadContainer from './read/MockReading';
import WriteContainer from './write/MockWriting';
import SpeakContainer from './speak/MockSpeaking';
import * as mockTestActions from '../../../../../actions/app/mockTest';
import * as apiActions from '../../../../../actions/app/api';
import * as api from '../../../../../actions/api/requests';
import * as answerInstanceActions from '../../../../../actions/app/answerInstance';
import LoadingScreen from './others/Loader';

class MockTestContainer extends React.Component {
  nextPage = () => {
    this.props.dispatch(mockTestActions.resetInstructionTimer());
    this.props.dispatch(mockTestActions.updateCurrentPage(this.props.activePage+1));
  }
  state = {
    speakMode: "ready",
  }
  handleChangeSpeakMode = (e, value) => {
    this.setState({speakMode:value.value});
  }
  finishExam = () => {
    const { dispatch, data, is_staff, user_id, nonStudent, content } = this.props;
    if (content.test_type === 'imt'){
      data['user'] = user_id;
      data['answers']['speaking'] = [];
      data['content']['speaking'] = [];
      data['finished_at'] = new Date();
      dispatch(answerInstanceActions.updateContent(data));
      dispatch(apiActions.updateApiSending(true));
      this.props.dispatch(mockTestActions.updateCurrentPage(this.props.activePage+10));
      if (is_staff){
        dispatch(apiActions.updateApiSending(false));
        window.location = "/review/mock-test";
      } else if (nonStudent.profile.id) {
        dispatch(api.createNonStudentAnswerInstance(data, nonStudent.profile, this.props.history));
      } else {
        dispatch(api.createAnswerInstance(data, this.props.history));
      }
    } else {
      alert("To take this exam. Please setup your microphone properly.");
    }
  }
	render() {
    const { activePage } = this.props;
    const { test_type } = this.props.content;
    if (test_type === 'imt') {
      return (
        <React.Fragment>
          {
            activePage === 1 || activePage === 3 || activePage === 5 || activePage === 7?
              <MainInstruction finishExam={this.finishExam} handleChangeSpeakMode={this.handleChangeSpeakMode} nextPage={this.nextPage} {...this.props} {...this.state} />
              :
            activePage === 2?
              <ListenContainer/>
            :
            activePage === 4?
              <ReadContainer/>
            :
            activePage === 6?
              <WriteContainer/>
            :
            activePage === 8?
              <SpeakContainer/>
            :
              <LoadingScreen/>
          }
        </React.Fragment>
      );
    } else if (test_type === 'listening') {
      return (
        <React.Fragment>
          {
            activePage === 1?
              <MainInstruction finishExam={this.finishExam} handleChangeSpeakMode={this.handleChangeSpeakMode} nextPage={this.nextPage} {...this.props} {...this.state} />
              :
            activePage === 2?
              <ListenContainer/>
              :
              <LoadingScreen/>
          }
        </React.Fragment>
      );
    } else if (test_type === 'reading') {
      return (
        <React.Fragment>
          {
            activePage === 1?
              <MainInstruction finishExam={this.finishExam} handleChangeSpeakMode={this.handleChangeSpeakMode} nextPage={this.nextPage} {...this.props} {...this.state} />
              :
            activePage === 2?
              <ReadContainer/>
              :
              <LoadingScreen/>
          }
        </React.Fragment>
      );
    } else if (test_type === 'writing') {
      return (
        <React.Fragment>
          {
            activePage === 1?
              <MainInstruction finishExam={this.finishExam} handleChangeSpeakMode={this.handleChangeSpeakMode} nextPage={this.nextPage} {...this.props} {...this.state} />
              :
            activePage === 2?
              <WriteContainer/>
              :
              <LoadingScreen/>
          }
        </React.Fragment>
      );
    } else if (test_type === 'speaking') {
      return (
        <React.Fragment>
          {
            activePage === 1?
              <MainInstruction finishExam={this.finishExam} handleChangeSpeakMode={this.handleChangeSpeakMode} nextPage={this.nextPage} {...this.props} {...this.state} />
              :
            activePage === 2?
              <SpeakContainer/>
              :
              <LoadingScreen/>
          }
        </React.Fragment>
      );
    }
    return <React.Fragment></React.Fragment>;
	}
}

const mapStateToProps = (state) => {
	return {
    content           : state.mockTest.content,
    activePage        : state.mockTest.currentActivePage,
    is_staff          : state.auth.is_staff,
    user_id           : state.auth.id,
    user              : state.mockTest.content.user,
    data              : state.answerInstance,
    nonStudent        : state.nonStudent,
	}
};

export default connect(mapStateToProps)(MockTestContainer);