import React from 'react';
import { Form, Radio } from 'semantic-ui-react';
import Instruction from '../Instruction';

const ReadRadioMcq = (props) => {
  const {
    questions,
    start_number,
    handleRadioOnChange,
    parentIndex,
    answers,
    active_index,
    answers_strat,
    strat_mode,
    explanation,
    correct_answer,
  } = props;
  const blanks = questions.map((obj, i) => {
    let value = "";
      if (answers.length > 0 && answers_strat.length > 0){
        value = answers[active_index][parentIndex][i];
        if (strat_mode){
          value = answers_strat[active_index][parentIndex][i];
        }
      }
    return (
      <React.Fragment key={"question" + i}>
        <Form.Field>
          {obj.number? obj.number + ". ":start_number + i + ". "}
          <span dangerouslySetInnerHTML={{__html:obj.text}}/>
        </Form.Field>
        {
          obj.choices.map((choice, j) => {
            return (
              <Form.Field key={"choice" + j}>
                <Radio
                  key={`readradiomcq${parentIndex}${i}`}
                  onClick={handleRadioOnChange.bind(this, parentIndex, i, j+1)}
                  name={'radioGroup'+i}
                  checked={explanation? correct_answer[i] === j+1 :parseInt(value, 10) === j+1}
                />
                <span dangerouslySetInnerHTML={{__html:choice}} />
              </Form.Field>
            );
          })
        }
        {
          i + 1 < questions.length?
          <br/>:null
        }
      </React.Fragment>
    )
  });
  return (
    <React.Fragment>
      <Instruction {...props} />
      <Form className="read-mcq-radio">
        {blanks}
      </Form>
    </React.Fragment>
  );
}

export default ReadRadioMcq;