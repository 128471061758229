import * as app from '../types/auth';

export const updateIsAuthenticated = (payload) => {
  return dispatch => {
    dispatch({
      type    : app.UPDATE_IS_AUTHENTICATED,
      payload : payload,
    });
  };
};

export const updateToken = (payload) => {
  return dispatch => {
    dispatch({
      type    : app.UPDATE_TOKEN,
      payload : payload,
    });
  };
};

export const updateUsername = (payload) => {
  return dispatch => {
    dispatch({
      type    : app.UPDATE_AUTH_USERNAME,
      payload : payload,
    });
  };
};


export const reset = () => {
  return dispatch => {
    dispatch({
      type    : app.AUTH_RESET,
    });
  };
};

export const updateIsStaff = (payload) => {
  return dispatch => {
    dispatch({
      type    : app.UPDATE_AUTH_IS_STAFF,
      payload : payload,
    });
  };
};

export const updateFullName = (payload) => {
  return dispatch => {
    dispatch({
      type    : app.UPDATE_AUTH_FULL_NAME,
      payload : payload,
    });
  };
};