import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import * as answerActions from "../../../../../actions/app/answerInstance";
import * as speakReviewActions from "../../../../../actions/app/speakReview";
import * as appActions from "../../../../../actions/app/app";
import * as api from "../../../../../actions/api/requests";
import { Grid, TextArea } from "semantic-ui-react";
import Header from "./ReviewHeader";
import Sidebar from "./ReviewSidebar";
import HintModal from "./HintModal";
import Player from "./Player";
import * as EXAMS from "../.././../../../constants/exam_subtypes";
import { Instruction } from "./Instruction";
import Recorder from "./Recorder";
import { uploadFile } from "../../../../../actions/api/storage";

class SpeakingContainer extends React.Component {
  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(speakReviewActions.reset());
    dispatch(answerActions.reset());
  }
  toggleNav() {
    const { dispatch, openSidebar } = this.props;
    dispatch(appActions.updateOpenSidebar(!openSidebar));
  }
  closeNav() {
    this.props.dispatch(appActions.updateOpenSidebar(false));
  }
  state = {
    active_index: 0,
    openHintModal: false,
    openHintModal2: false,
    toggleNav: this.toggleNav.bind(this),
    closeNav: this.closeNav.bind(this),
    current_subtype: 0,
  };
  placeholder() {}
  openHintModal = () => {
    this.setState({ openHintModal: true });
  };
  closeHintModal = () => {
    this.setState({ openHintModal: false });
  };
  openHintModal2 = () => {
    this.setState({ openHintModal2: true });
  };
  closeHintModal2 = () => {
    this.setState({ openHintModal2: false });
  };
  goToDashboard = () => {
    this.props.history.push("/");
  };
  goToDashboard = () => {
    this.props.history.push("/");
  };
  updateActiveIndex = (i, current_subtype) => {
    this.setState({ active_index: i, current_subtype: current_subtype });
    this.closeNav();
  };
  saveNotes = () => {
    const { id, notes_1, notes_2, notes_3, answers_strat } = this.props;
    this.props.dispatch(
      api.updateAnswerInstance2({
        id,
        notes_1:
          typeof notes_1 === "string" ? notes_1 : JSON.stringify(notes_1),
        notes_2:
          typeof notes_2 === "string" ? notes_2 : JSON.stringify(notes_2),
        notes_3:
          typeof notes_3 === "string" ? notes_3 : JSON.stringify(notes_3),
        answers_strat,
      }),
      null,
      null,
      true
    );
  };

  updateNotes1 = (event, data) => {
    const { notes_1, fullContent, content } = this.props;
    const { active_index, current_subtype } = this.state;
    const newNotes = JSON.parse(JSON.stringify(notes_1));

    if (content[active_index].part === EXAMS.SPEAK_PART_2_VALUE) {
      newNotes[active_index] = data.value;
    } else {
      newNotes[active_index][current_subtype] = data.value;
    }

    const newContent = { ...fullContent };
    newContent.notes_1 = newNotes;
    this.props.dispatch(speakReviewActions.updateContent(newContent));
  };

  updateNotes2 = (event, data) => {
    const { notes_2, fullContent, content } = this.props;
    const { active_index, current_subtype } = this.state;
    const newNotes = JSON.parse(JSON.stringify(notes_2));

    if (content[active_index].part === EXAMS.SPEAK_PART_2_VALUE) {
      newNotes[active_index] = data.value;
    } else {
      newNotes[active_index][current_subtype] = data.value;
    }

    const newContent = { ...fullContent };
    newContent.notes_2 = newNotes;
    this.props.dispatch(speakReviewActions.updateContent(newContent));
  };

  updateNotes3 = (event, data) => {
    const { notes_3, fullContent, content } = this.props;
    const { active_index, current_subtype } = this.state;
    const newNotes = JSON.parse(JSON.stringify(notes_3));

    if (content[active_index].part === EXAMS.SPEAK_PART_2_VALUE) {
      newNotes[active_index] = data.value;
    } else {
      newNotes[active_index][current_subtype] = data.value;
    }

    const newContent = { ...fullContent };
    newContent.notes_3 = newNotes;
    this.props.dispatch(speakReviewActions.updateContent(newContent));
  };

  updateAnswerStrat = (location) => {
    const { answers_strat, fullContent, content } = this.props;
    const { active_index, current_subtype } = this.state;
    const newAnswerStrat = JSON.parse(JSON.stringify(answers_strat));

    if (content[active_index].part === EXAMS.SPEAK_PART_2_VALUE) {
      newAnswerStrat[active_index] = location;
    } else {
      newAnswerStrat[active_index][current_subtype] = location;
    }

    const newContent = { ...fullContent };
    newContent.answers_strat = newAnswerStrat;
    this.props.dispatch(speakReviewActions.updateContent(newContent));
  };

  uploadRecording = (file) => {
    const { username } = this.props;
    const key =
      "student_answers/speaking/" + username + "/" + Date.now() + ".mp3";
    uploadFile(file, key)
      .then((location) => {
        this.updateAnswerStrat(location);
      })
      .catch((error) => {
        console.log(error);
        return alert(
          "There was an error uploading your audio: " +
            error +
            "\n\n Please refresh the page multiple times until the audio plays again."
        );
      });
  };

  getHintKey = () => {
    const { content } = this.props;
    const { active_index, current_subtype } = this.state;
    let postfix = "";
    if (current_subtype > 0) {
      postfix = current_subtype + 1;
    }
    return content[active_index][`hint${postfix}`];
  };

  render() {
    const {
      openSidebar,
      content,
      answers,
      answers_strat,
      notes_1,
      notes_2,
      notes_3,
    } = this.props;
    const { active_index, openHintModal, current_subtype, openHintModal2 } =
      this.state;
    let show = "hide";
    if (openSidebar) {
      show = "show";
    }
    let model_answer = "";
    if (content[active_index].part === EXAMS.SPEAK_PART_2_VALUE) {
      model_answer = content[active_index].model_answer;
    } else {
      switch (current_subtype) {
        case 0:
          model_answer = content[active_index].model_answer;
          break;
        case 1:
          model_answer = content[active_index].model_answer2;
          break;
        case 2:
          model_answer = content[active_index].model_answer3;
          break;
        case 3:
          model_answer = content[active_index].model_answer4;
          break;
        default:
          break;
      }
    }

    const isPart2 = content[active_index].part === EXAMS.SPEAK_PART_2_VALUE;

    const vocabs = [
      content[active_index].vocab || "",
      content[active_index].vocab2 || "",
      content[active_index].vocab3 || "",
      content[active_index].vocab4 || "",
    ];

    return (
      <React.Fragment>
        <Header {...this.state} {...this.props} show={show} />
        <Sidebar
          {...this.state}
          {...this.props}
          show={show}
          updateActiveIndex={this.updateActiveIndex}
        />
        <HintModal
          header="Vocab"
          hint={isPart2 ? vocabs[0] : vocabs[current_subtype]}
          open={openHintModal}
          onClose={this.closeHintModal}
        />
        <HintModal
          header="Hints"
          hint={this.getHintKey()}
          open={openHintModal2}
          onClose={this.closeHintModal2}
        />
        <div className="main-container">
          <div className="content-new-ui header-new-ui">
            <div id="content-split" className="content-split">
              {content[active_index].part === EXAMS.SPEAK_PART_2_VALUE ? (
                <div
                  id="content-left"
                  className="content-left"
                  style={{ overflow: "auto", margin: "2rem 1rem 1rem 1rem" }}
                >
                  <div
                    className="paper-box text-center"
                    style={{
                      marginTop: "3px",
                      marginBottom: "0rem",
                      paddingBottom: "0px",
                      overflowY: "hidden",
                      fontWeight: "normal",
                    }}
                  >
                    <h3 style={{ marginTop: "1rem" }}>Part 2 Question</h3>
                    <div
                      style={{ margin: "0rem 1.5rem 1.5rem 1.5rem" }}
                      className="instruction text-left"
                      dangerouslySetInnerHTML={{
                        __html: content[active_index].question,
                      }}
                    />
                  </div>
                  <div className="testing-review-group">
                    <h5 className="speaking-review-header">
                      Your "Testing" Notes (Ideas, Vocab, etc.)
                    </h5>
                    <div className="review-notes-div">
                      <TextArea
                        className="review-notes-text no-resize"
                        rows={8}
                        value={notes_1[active_index]}
                        disabled
                      />
                    </div>
                    <Player
                      title='Your "Testing" Recorded Answer'
                      url={answers[active_index]}
                      key={`audio-player-testing-${active_index}`}
                    />
                  </div>
                  <div className="learning-review-group">
                    {/* <Instruction /> */}
                    <h5 className="speaking-review-header">
                      Your "Learning" Notes Notes (Ideas, Vocab, etc.)
                    </h5>
                    <div className="review-notes-div">
                      <TextArea
                        className="review-notes-text no-resize"
                        rows={8}
                        onChange={this.updateNotes2}
                        value={notes_2[active_index]}
                      />
                    </div>
                    <h5 className="speaking-review-header">
                      Your "Learning" Written Response
                    </h5>
                    <div className="review-notes-div">
                      <TextArea
                        className="review-notes-text no-resize"
                        rows={8}
                        onChange={this.updateNotes3}
                        value={notes_3[active_index]}
                      />
                    </div>
                    <Recorder
                      uploadRecording={this.uploadRecording.bind(this)}
                      title='Audio Recorder To RE-record Your "Learning" Answer'
                    />
                    <Player
                      title='Your "Learning" Recorded Answer'
                      url={answers_strat[active_index]}
                      key={`audio-player-learning-111-${active_index}`}
                    />
                  </div>
                </div>
              ) : (
                <div
                  id="content-left"
                  className="content-left"
                  style={{ overflow: "auto", margin: "2rem 1rem 1rem 1rem" }}
                >
                  <div className="testing-review-group">
                    <Player
                      title="Examiner's Question"
                      url={content[active_index].audio[current_subtype]}
                      key={`audio-player-question-${active_index}-${current_subtype}`}
                    />
                    <Player
                      title='Your "Testing" Recorded Answer'
                      url={answers[active_index][current_subtype]}
                      key={`audio-player-testing-2222-${active_index}-${current_subtype}`}
                    />
                  </div>
                  <div className="learning-review-group">
                    {/* <Instruction /> */}
                    <h5 className="speaking-review-header">
                      Your "Learning" Notes (Ideas, Vocab, etc.)
                    </h5>
                    <div className="review-notes-div">
                      <TextArea
                        className="review-notes-text"
                        rows={5}
                        value={notes_1[active_index][current_subtype]}
                        onChange={this.updateNotes1}
                      />
                    </div>
                    <h5 className="speaking-review-header">
                      Your "Learning" Written Response
                    </h5>
                    <div className="review-notes-div">
                      <TextArea
                        className="review-notes-text"
                        rows={5}
                        value={notes_2[active_index][current_subtype]}
                        onChange={this.updateNotes2}
                      />
                    </div>
                    <Recorder
                      uploadRecording={this.uploadRecording.bind(this)}
                      title='Audio Recorder To RE-record Your "Learning" Answer'
                    />
                    <Player
                      title='Your "Learning" Recorded Answer'
                      key={`audio-player-review-${active_index}-${current_subtype}`}
                      url={answers_strat[active_index][current_subtype]}
                    />
                  </div>
                </div>
              )}
              <div
                id="content-right"
                className="content-right"
                style={{ overflow: "auto", margin: "2rem 1rem 1rem 1rem" }}
              >
                <h3 className="text-center">Model Answer</h3>
                <div
                  className="instruction text-left"
                  dangerouslySetInnerHTML={{ __html: model_answer }}
                />
              </div>
            </div>
            <div className="content-button">
              <Grid columns={"equal"}>
                <Grid.Column />
                <Grid.Column className="text-center">
                  <button type="button" onClick={this.openHintModal}>
                    Vocab
                  </button>
                  <button type="button" onClick={this.saveNotes}>
                    Save Learning Notes, Written Response & Audio
                  </button>{" "}
                </Grid.Column>
                <Grid.Column />
              </Grid>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    id: state.speakReview.content.id,
    fullContent: state.speakReview.content,
    content: state.speakReview.content.content,
    answers: state.speakReview.content.answers,
    answers_strat: state.speakReview.content.answers_strat,
    name: state.speakReview.content.name,
    openSidebar: state.app.openSidebar,
    notes_1:
      typeof state.speakReview.content.notes_1 === "string"
        ? JSON.parse(state.speakReview.content.notes_1)
        : state.speakReview.content.notes_1,
    notes_2:
      typeof state.speakReview.content.notes_2 === "string"
        ? JSON.parse(state.speakReview.content.notes_2)
        : state.speakReview.content.notes_2,
    notes_3:
      typeof state.speakReview.content.notes_3 === "string"
        ? JSON.parse(state.speakReview.content.notes_3)
        : state.speakReview.content.notes_3,
    username: state.auth.username,
  };
};

export default withRouter(connect(mapStateToProps)(SpeakingContainer));
