import React from 'react';
import { connect } from 'react-redux';
import * as mockTestActions from '../../../../../../actions/app/mockTest';
import { Icon } from 'semantic-ui-react';

function pad (string) {
  return ('0' + string).slice(-2)
}

function format(seconds){
  const date = new Date(seconds * 1000);
  const hh = date.getUTCHours();
  const mm = date.getUTCMinutes();
  const ss = pad(date.getUTCSeconds());
  if (hh) {
    return `Time Remaining: ${hh}h ${pad(mm)}m ${ss}s`;
  }
  return `Time Remaining: ${mm}m ${ss}s`;
}

class InstructionTimer extends React.Component {
  componentDidMount(){
    this.timer = setInterval(this.tick.bind(this), 1000);
  }
  componentWillUnmount(){
    this.clearTimer();
  }
  clearTimer(){
    clearInterval(this.timer);
    this.timer = null;
  }
  tick(){
    const {dispatch, timer, currentPage } = this.props;
    const newTimer = timer - 1;
    if (newTimer >= 0){
      dispatch(mockTestActions.updateInstructionTimer(newTimer));
    } else {
      dispatch(mockTestActions.updateCurrentPage(currentPage+1))
    }
  }
	render() {
    const { timer, exam_name } = this.props;
		return (
      <React.Fragment>
        <p>Your IELTS {exam_name} test will start in 2 minutes. Please get ready.</p>
        <Icon style={{marginBottom:'.5em'}} size='big' color='blue' loading name='spinner' />
        <p>{format(timer)}</p>
      </React.Fragment>
		);
	}
}

const mapStateToProps = (state) => {
	return {
    timer : state.mockTest.instructionTimer,
    currentPage : state.mockTest.currentActivePage,
	}
};

export default connect(mapStateToProps)(InstructionTimer);