import React from 'react';
import { Form } from 'semantic-ui-react';
import Instruction from '../Instruction';
import stringReplace from 'react-string-replace';

const ReadSentenceCompletion = (props) => {
  const {
    questions,
    start_number,
    handleOnChange,
    parentIndex,
    answers,
    answers_strat,
    active_index,
    strat_mode,
    size,
    explanation,
    correct_answer,
  } = props;
  const blanks = questions.map((obj, i) => {
    let value = "";
    if (answers.length > 0 && answers_strat.length > 0){
      value = answers[active_index][parentIndex][i];
      if (strat_mode){
        value = answers_strat[active_index][parentIndex][i];
      }
    }
    const htmlString = stringReplace(
      start_number + i + ". " + obj,
      '<input class="replace"/>',
      (match, j) => {
        return  <input
                  size={size?size[i]?size[i]:10:10}
                  value={explanation?correct_answer[i].replace(/{n}/g," / "):value}
                  className="blank2"
                  type="text"
                  onChange={handleOnChange.bind(this, parentIndex, i)}
                  key={"sentencecompletioninput"+parentIndex+i}
                />;
      });
    return (
      <Form.Group inline key={"question" + i}>
        <p>{htmlString}</p>
      </Form.Group>
    )
  });
  return (
    <React.Fragment>
      <Instruction {...props} />
      <Form className="read-mcq-radio">
        {blanks}
      </Form>
    </React.Fragment>
  );
}

export default ReadSentenceCompletion;