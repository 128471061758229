import React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect } from "react-router-dom";

class PrivateRoute2 extends React.Component {
  render() {
    if (!this.props.is_authenticated){
      return <Redirect to={{pathname: "/"}} />;
    }
    const { path, Component } = this.props;
    return <Route path={path} component={() => <Component/>} />;
  }
}

const mapStateToProps = (state) => {
  return {
    is_authenticated : state.auth.is_authenticated,
  }
};

export default connect(mapStateToProps)(PrivateRoute2);