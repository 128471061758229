import * as actions from '../actions/types/marketing';

const defaultState = {
  banner: "",
  my_classes: "",
  listening : "",
  reading   : "",
  writing   : "",
};

export default function reducer(state=defaultState, action) {
  switch(action.type) {
    case actions.UPDATE_MARKETING_CONTENT: {
      return {...action.payload};
    }
    default: return {...state};
  }
};