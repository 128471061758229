import * as tableActions from '../types/speakingTable';

export const updateContent = (payload) => {
  return dispatch => {
    dispatch({
      type    : tableActions.UPDATE_SPEAKING_TABLE_CONTENT,
      payload : payload,
    });
  };
};

export const updateCurrentPage = (payload) => {
  return dispatch => {
    dispatch({
      type    : tableActions.UPDATE_SPEAKING_TABLE_CURRENT_ACTIVE_PAGE,
      payload : payload,
    });
  };
};