import * as actions from '../actions/types/listeningModal';

const defaultState = {
  open:false,
};

export default function reducer(state=defaultState, action) {
  switch(action.type) {
    case actions.TOGGLE_LISTENING_MODAL_STATUS: {
      return {...state, open: action.payload};
    }
    default: return {...state};
  }
};