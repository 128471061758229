import * as profile from '../types/nonStudent';

export const updateCurrentPage = (payload) => {
  return dispatch => {
    dispatch({
      type    : profile.NON_STUDENT_UPDATE_CURRENT_PAGE,
      payload : payload,
    });
  };
};

export const updateProfile = (payload) => {
  return dispatch => {
    dispatch({
      type    : profile.NON_STUDENT_UPDATE_PROFILE,
      payload : payload,
    });
  };
};

export const reset = () => {
  return dispatch => {
    dispatch({
      type    : profile.NON_STUDENT_RESET,
    });
  };
};

