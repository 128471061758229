import React from 'react';
import { connect } from 'react-redux';
import { Route, withRouter } from 'react-router-dom';
import * as Exams from './index'

/* eslint-disable */
class Body extends React.Component {
  render() {
    const { match } = this.props;
    return (
      <React.Fragment>
        <Route exact path={`${match.path}/listen`} component={Exams.Listening} />
        <Route exact path={`${match.path}/mock-test`} component={Exams.MockTest} />
        <Route exact path={`${match.path}/read`} component={Exams.Reading} />
        <Route exact path={`${match.path}/speak`} component={Exams.Speaking} />
        <Route exact path={`${match.path}/write`} component={Exams.Writing} />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    openSidebar: state.app.openSidebar,
    user: state.login,
    is_authenticated: state.auth.is_authenticated
  }
};

export default withRouter(connect(mapStateToProps)(Body));