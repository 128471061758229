import * as answerHistory from '../types/readingTable';

export const updateContent = (payload) => {
  return dispatch => {
    dispatch({
      type    : answerHistory.UPDATE_READING_TABLE_CONTENT,
      payload : payload,
    });
  };
};

export const updateCurrentPage = (payload) => {
  return dispatch => {
    dispatch({
      type    : answerHistory.UPDATE_READING_TABLE_CURRENT_ACTIVE_PAGE,
      payload : payload,
    });
  };
};

