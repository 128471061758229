import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ListenReview from './ListeningReview';
import ReadReview from './ReadingReview';
import WriteReview from './WritingReview';
import SpeakReview from './SpeakingReview';
import Header from './ReviewHeader';
import Sidebar from './ReviewSidebar';
import * as appActions from '../../../../../actions/app/app';

class MainContainer extends React.Component {
  toggleNav() {
    const { dispatch, openSidebar } = this.props;
    dispatch(appActions.updateOpenSidebar(!openSidebar));
  }
  closeNav() {
    this.props.dispatch(appActions.updateOpenSidebar(false));
  }
  changeSelectedIndex = (exam, i) => {
    this.closeNav();
    this.setState({selectedExam:exam, selectedIndex:i});
  }
  changeSelectedSpeaking = (exam, i, subtype) => {
    this.closeNav();
    this.setState({selectedExam:exam, selectedIndex:i, selectedSubtype:subtype})
  }
  state = {
    selectedExam: 'listening',
    selectedIndex: 0,
    selectedSubtype: 0,
    changeSelectedIndex: this.changeSelectedIndex.bind(this),
    changeSelectedSpeaking: this.changeSelectedSpeaking.bind(this),
    toggleNav: this.toggleNav.bind(this),
    closeNav: this.closeNav.bind(this),
  }
  render(){
    const { selectedIndex, selectedExam, selectedSubtype } = this.state;
    const { answers, content } = this.props.answerInstance;
    const { openSidebar } = this.props;
    let show = "hide";
    if (openSidebar){
      show = "show";
    }
    return(
      <React.Fragment>
        <Header
          {...this.state}
          {...this.props}
          show={show}
        />
        <Sidebar
          {...this.state}
          {...this.props}
          show={show}
          updateActiveIndex={this.updateActiveIndex}
        />
        {
          selectedExam === 'listening'?
            <ListenReview
              content={content.listening}
              answers={answers.listening}
              active_index={selectedIndex}
            />
            :
          selectedExam === 'reading'?
            <ReadReview
              content={content.reading}
              answers={answers.reading}
              active_index={selectedIndex}
            />
            :
          selectedExam === 'writing'?
            <WriteReview
              content={content.writing}
              answers={answers.writing}
              active_index={selectedIndex}
            />
            :
          selectedExam === 'speaking'?
            <SpeakReview
              content={content.speaking}
              answers={answers.speaking}
              active_index={selectedIndex}
              current_subtype={selectedSubtype}
            />
            :
            null
        }
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    answerInstance : state.answerInstance,
    openSidebar    : state.app.openSidebar,
  }
};

export default withRouter(connect(mapStateToProps)(MainContainer));