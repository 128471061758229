import * as actions from '../actions/types/speakPart3';

const default_timer = 60;

const defaultState = {
  timer: default_timer,
  timer2: default_timer,
  current_subtype:0,
  strat_mode: false,
  recording: false,
  playing: true,
  strat_url: "",
  strat_file: null,
  recording2: false,
  playing2: false,
};

export default function reducer(state=defaultState, action) {
  switch(action.type) {
    case actions.SPEAK_EXAM_PART3_RESET: {
      return {...defaultState};
    }
    case actions.SPEAK_EXAM_PART3_UPDATE_TIMER: {
      return {...state, timer: action.payload}
    }
    case actions.SPEAK_EXAM_PART3_UPDATE_TIMER2: {
      return {...state, timer2: action.payload}
    }
    case actions.SPEAK_EXAM_PART3_UPDATE_SUBTYPE:{
      return {...state, current_subtype: action.payload}
    }
    case actions.SPEAK_EXAM_PART3_UPDATE_STRAT_MODE: {
      return {...state, strat_mode: action.payload}
    }
    case actions.SPEAK_EXAM_PART3_UPDATE_RECORDING: {
      return {...state, recording: action.payload}
    }
    case actions.SPEAK_EXAM_PART3_UPDATE_PLAYING: {
      return {...state, playing: action.payload}
    }
    case actions.SPEAK_EXAM_PART3_UPDATE_RECORDING2: {
      return {...state, recording2: action.payload}
    }
    case actions.SPEAK_EXAM_PART3_UPDATE_PLAYING2: {
      return {...state, playing2: action.payload}
    }
    case actions.SPEAK_EXAM_PART3_UPDATE_STRAT_URL: {
      return {...state, strat_url: action.payload}
    }
    case actions.SPEAK_EXAM_PART3_UPDATE_STRAT_FILE: {
      return {...state, strat_file: action.payload}
    }
    default: return {...state};
  }
};