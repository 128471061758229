import React from "react";
import {
  Grid,
  Container,
  Form,
  Button,
  Input,
  Select,
  Confirm,
  Segment,
  Tab,
  Icon,
  TextArea,
  Breadcrumb,
} from "semantic-ui-react";
import { connect } from "react-redux";
import { Editor } from "@tinymce/tinymce-react";
import * as readingBuilderActions from "../../actions/app/readBuilder";
import * as apiActions from "../../actions/app/api";
import * as readExamActions from "../../actions/app/readExam";
import * as api from "../../actions/api/requests";
import * as SUBTYPES from "../../constants/exam_subtypes";
import * as EXAM from "../../constants/exams";
import Loader from "../dashboard/parts/Loader";
import _ from "lodash";
import ReadCombined from "../main/components/exams/common/ExamContainer2";
import { withRouter, Link } from "react-router-dom";
import * as Parts from "../dashboard/parts";
import * as TINY_MCE_SETTINGS from "../../constants/tinyMceSettings";
import QuestionEditor from "./QuestionEditor2";

const subtype_options = [
  {
    key: SUBTYPES.READ_PART_1_VALUE,
    value: SUBTYPES.READ_PART_1_VALUE,
    text: SUBTYPES.READ_PART_1_TEXT,
  },
  {
    key: SUBTYPES.READ_PART_2_VALUE,
    value: SUBTYPES.READ_PART_2_VALUE,
    text: SUBTYPES.READ_PART_2_TEXT,
  },
  {
    key: SUBTYPES.READ_PART_3_VALUE,
    value: SUBTYPES.READ_PART_3_VALUE,
    text: SUBTYPES.READ_PART_3_TEXT,
  },
];

const exam_type_options = [
  {
    key: SUBTYPES.ACADEMIC_VALUE,
    value: SUBTYPES.ACADEMIC_VALUE,
    text: SUBTYPES.ACADEMIC_TEXT,
  },
  {
    key: SUBTYPES.GENERAL_TRAINING_VALUE,
    value: SUBTYPES.GENERAL_TRAINING_VALUE,
    text: SUBTYPES.GENERAL_TRAINING_TEXT,
  },
];

const section_otpions = [
  {
    key: EXAM.READ_NOTE_COMPLETION,
    value: EXAM.READ_NOTE_COMPLETION,
    text: "Fill in blank",
  },
  {
    key: EXAM.READ_FLOW_CHART,
    value: EXAM.READ_FLOW_CHART,
    text: "Flow Chart",
  },
  {
    key: EXAM.READ_FLOW_CHART_DETAILED,
    value: EXAM.READ_FLOW_CHART_DETAILED,
    text: "Flow Chart (Detailed)",
  },
  {
    key: EXAM.READ_IMAGE_LABELING,
    value: EXAM.READ_IMAGE_LABELING,
    text: "Image Labeling",
  },
  { key: EXAM.READ_MATCHING, value: EXAM.READ_MATCHING, text: "Matching Type" },
  {
    key: EXAM.READ_RADIO_CHECKBOX,
    value: EXAM.READ_RADIO_CHECKBOX,
    text: "MCQ (Multiple Answers)",
  },
  {
    key: EXAM.READ_RADIO_MCQ,
    value: EXAM.READ_RADIO_MCQ,
    text: "MCQ (One Answer Only)",
  },
  {
    key: EXAM.READ_SENTENCE_COMPLETION,
    value: EXAM.READ_SENTENCE_COMPLETION,
    text: "Sentence Completion",
  },
  {
    key: EXAM.READ_SHORT_ANSWERS,
    value: EXAM.READ_SHORT_ANSWERS,
    text: "Short Answers",
  },
];

class ReadBuilder extends React.Component {
  state = {
    open: false,
  };
  componentDidMount() {
    this.props.dispatch(apiActions.updateApiSending(false));
  }
  open = () => {
    this.setState({ open: true });
  };
  close = () => {
    this.setState({ open: false });
  };
  handleExamPartChange = (e, component) => {
    this.update(component.value, "part");
  };
  handleCodeChange = (e, value) => {
    this.update(value, "code");
  };
  handleVocabChange = (value, editor) => {
    this.update(value, "vocab");
  };
  handleInstructionChange = (value, editor) => {
    this.update(value, "instruction");
  };
  handleInstructionExplanationChange = (value, editor) => {
    this.update(value, "instruction_explanation");
  };
  handleContentChange = (value, editor) => {
    this.update(value, "content");
  };
  handleContentExplanationChange = (value, editor) => {
    this.update(value, "content_explanation");
  };
  handleHintChange = (value, editor) => {
    this.update(value, "hint");
  };
  handleHintReviewChange = (value, editor) => {
    this.update(value, "hint_review");
  };
  handleQuestionInputChange = (value, editor) => {
    this.update(value, "question");
  };
  handleChange = (e) => {
    this.update(e.target.value, e.target.name);
  };
  handleQuestionChange = (i, e, component) => {
    const { dispatch, readingBuilder } = this.props;
    const newObject = _.cloneDeep(readingBuilder);
    try {
      newObject.exam.exams[i].questions = JSON.parse(component.value);
    } catch (e) {}
    dispatch(readingBuilderActions.updateContent(newObject));
  };
  handleQuestionChangeExplanation = (i, e, component) => {
    const { dispatch, readingBuilder } = this.props;
    const newObject = _.cloneDeep(readingBuilder);
    try {
      newObject.exam_explanation.exams[i].questions = JSON.parse(
        component.value
      );
    } catch (e) {}
    dispatch(readingBuilderActions.updateContent(newObject));
  };
  handleQuestionChange2 = (i, value) => {
    const { dispatch, readingBuilder } = this.props;
    const newObject = _.cloneDeep(readingBuilder);
    newObject.exam.exams[i].questions = value;
    dispatch(readingBuilderActions.updateContent(newObject));
  };
  handleQuestionChange2Explanation = (i, value) => {
    const { dispatch, readingBuilder } = this.props;
    const newObject = _.cloneDeep(readingBuilder);
    newObject.exam_explanation.exams[i].questions = value;
    dispatch(readingBuilderActions.updateContent(newObject));
  };
  handleCorrectAnswerChange = (i, e, component) => {
    const { dispatch, readingBuilder } = this.props;
    const newObject = _.cloneDeep(readingBuilder);
    try {
      newObject.exam.exams[i].correct_answer = JSON.parse(component.value);
    } catch (e) {}
    dispatch(readingBuilderActions.updateContent(newObject));
  };
  handleBlankSizeChange = (i, e, component) => {
    const { dispatch, readingBuilder } = this.props;
    const newObject = _.cloneDeep(readingBuilder);
    try {
      newObject.exam.exams[i].size = JSON.parse(component.value);
    } catch (e) {}
    dispatch(readingBuilderActions.updateContent(newObject));
  };
  handleGroupChange = (i, e, component) => {
    const { dispatch, readingBuilder } = this.props;
    const newObject = _.cloneDeep(readingBuilder);
    try {
      newObject.exam.exams[i].answer_group = JSON.parse(component.value);
    } catch (e) {
      newObject.exam.exams[i].answer_group = [];
    }
    dispatch(readingBuilderActions.updateContent(newObject));
  };
  handlePrefixChange = (i, e, component) => {
    const { dispatch, readingBuilder } = this.props;
    const newObject = _.cloneDeep(readingBuilder);
    try {
      newObject.exam.exams[i].prefixes = JSON.parse(component.value);
    } catch (e) {
      newObject.exam.exams[i].prefixes = [];
    }
    dispatch(readingBuilderActions.updateContent(newObject));
  };
  handleExamTypeChange = (e, component) => {
    this.update(component.value, "exam_type");
  };
  update(value, name) {
    const data = { ...this.props.readingBuilder };
    data[name] = value;
    this.props.dispatch(readingBuilderActions.updateContent(data));
  }
  handleSubmit = (e) => {
    e.preventDefault();
    this.open();
  };
  handleConfirm = () => {
    const data = { ...this.props.readingBuilder };
    const { dispatch } = this.props;
    dispatch(apiActions.updateApiSending(true));
    dispatch(api.createReadingExam(data));
    this.close();
  };
  showPreview = () => {
    this.props.dispatch(
      readExamActions.updateContent([{ ...this.props.readingBuilder }])
    );
    window.open("/exam/read");
  };
  createImageLabeling = () => {
    return {
      exam_type: EXAM.READ_IMAGE_LABELING,
      start_number: 1,
      total_count: 1,
      content: "",
      image: "",
      instruction: "",
      correct_answer: [""],
      size: [],
    };
  };
  createFlowChart = () => {
    return {
      exam_type: EXAM.READ_FLOW_CHART,
      start_number: 1,
      content: "",
      instruction: "",
      questions: [""],
      correct_answer: [""],
      size: [],
    };
  };
  createDetailedFlowChart = () => {
    return {
      exam_type: EXAM.READ_FLOW_CHART_DETAILED,
      start_number: 1,
      content: "",
      instruction: "",
      questions: ["", ""],
      correct_answer: ["", ""],
      size: [],
      prefixes: [],
      answer_group: [],
    };
  };
  createMatching = () => {
    return {
      exam_type: EXAM.READ_MATCHING,
      start_number: 1,
      content: "",
      instruction: "",
      questions: [""],
      correct_answer: [""],
      size: [],
    };
  };
  createNoteCompletion = () => {
    return {
      exam_type: EXAM.READ_NOTE_COMPLETION,
      start_number: 1,
      content: "",
      instruction: "",
      questions: [""],
      correct_answer: [""],
      size: [],
      prefixes: [],
      answer_group: [],
    };
  };
  createRadio = () => {
    return {
      exam_type: EXAM.READ_RADIO_MCQ,
      start_number: 1,
      content: "",
      instruction: "",
      questions: [
        {
          text: "",
          choices: ["", ""],
        },
      ],
      correct_answer: [1],
    };
  };
  createSentenceCompletion = () => {
    return {
      exam_type: EXAM.READ_SENTENCE_COMPLETION,
      start_number: 1,
      content: "",
      instruction: "",
      questions: [""],
      correct_answer: [""],
      size: [],
    };
  };
  createShortAnswer = () => {
    return {
      exam_type: EXAM.READ_SHORT_ANSWERS,
      start_number: 1,
      content: "",
      instruction: "",
      questions: [""],
      correct_answer: [""],
      size: [],
    };
  };
  createCheckbox = () => {
    return {
      exam_type: EXAM.READ_RADIO_CHECKBOX,
      start_number: 1,
      content: "",
      instruction: "",
      questions: [
        {
          text: "",
          choices: ["", ""],
        },
      ],
      correct_answer: [[1]],
    };
  };
  handleExamTypeChange2(i, test, e) {
    const { dispatch, readingBuilder } = this.props;
    const newObject = _.cloneDeep(readingBuilder);
    switch (e.value) {
      case EXAM.READ_FLOW_CHART:
        newObject.exam.exams[i] = this.createFlowChart();
        newObject.exam_explanation.exams[i] = this.createFlowChart();
        break;
      case EXAM.READ_FLOW_CHART_DETAILED:
        newObject.exam.exams[i] = this.createDetailedFlowChart();
        newObject.exam_explanation.exams[i] = this.createDetailedFlowChart();
        break;
      case EXAM.READ_IMAGE_LABELING:
        newObject.exam.exams[i] = this.createImageLabeling();
        newObject.exam_explanation.exams[i] = this.createImageLabeling();
        break;
      case EXAM.READ_MATCHING:
        newObject.exam.exams[i] = this.createMatching();
        newObject.exam_explanation.exams[i] = this.createMatching();
        break;
      case EXAM.READ_NOTE_COMPLETION:
        newObject.exam.exams[i] = this.createNoteCompletion();
        newObject.exam_explanation.exams[i] = this.createNoteCompletion();
        break;
      case EXAM.READ_RADIO_CHECKBOX:
        newObject.exam.exams[i] = this.createCheckbox();
        newObject.exam_explanation.exams[i] = this.createCheckbox();
        break;
      case EXAM.READ_RADIO_MCQ:
        newObject.exam.exams[i] = this.createRadio();
        newObject.exam_explanation.exams[i] = this.createRadio();
        break;
      case EXAM.READ_SENTENCE_COMPLETION:
        newObject.exam.exams[i] = this.createSentenceCompletion();
        newObject.exam_explanation.exams[i] = this.createSentenceCompletion();
        break;
      case EXAM.READ_SHORT_ANSWERS:
        newObject.exam.exams[i] = this.createShortAnswer();
        newObject.exam_explanation.exams[i] = this.createShortAnswer();
        break;
      default:
        break;
    }
    dispatch(readingBuilderActions.updateContent(newObject));
  }
  handleStartNumberChange = (i, test, e) => {
    const { dispatch, readingBuilder } = this.props;
    const newObject = _.cloneDeep(readingBuilder);
    newObject.exam.exams[i].start_number = parseInt(e.value, 10);
    dispatch(readingBuilderActions.updateContent(newObject));
  };
  handleStartNumberChangeExplanation = (i, test, e) => {
    const { dispatch, readingBuilder } = this.props;
    const newObject = _.cloneDeep(readingBuilder);
    newObject.exam_explanation.exams[i].start_number = parseInt(e.value, 10);
    dispatch(readingBuilderActions.updateContent(newObject));
  };
  handleTotalCountChange = (i, test, e) => {
    const { dispatch, readingBuilder } = this.props;
    const newObject = _.cloneDeep(readingBuilder);
    newObject.exam.exams[i].total_count = parseInt(e.value, 10);
    dispatch(readingBuilderActions.updateContent(newObject));
  };
  handleTotalCountChangeExplanation = (i, test, e) => {
    const { dispatch, readingBuilder } = this.props;
    const newObject = _.cloneDeep(readingBuilder);
    newObject.exam_explanation.exams[i].total_count = parseInt(e.value, 10);
    dispatch(readingBuilderActions.updateContent(newObject));
  };
  handleContentChange2 = (i, value) => {
    const { dispatch, readingBuilder } = this.props;
    const newObject = _.cloneDeep(readingBuilder);
    newObject.exam.exams[i].content = value;
    dispatch(readingBuilderActions.updateContent(newObject));
  };
  handleImageChange = (i, test, e) => {
    const { dispatch, readingBuilder } = this.props;
    const newObject = _.cloneDeep(readingBuilder);
    newObject.exam.exams[i].image = e.value;
    dispatch(readingBuilderActions.updateContent(newObject));
  };
  handleInstructionChange2 = (i, value) => {
    const { dispatch, readingBuilder } = this.props;
    const newObject = _.cloneDeep(readingBuilder);
    newObject.exam.exams[i].instruction = value;
    dispatch(readingBuilderActions.updateContent(newObject));
  };
  handleInstructionChange2Explanation = (i, value) => {
    const { dispatch, readingBuilder } = this.props;
    const newObject = _.cloneDeep(readingBuilder);
    newObject.exam_explanation.exams[i].instruction = value;
    dispatch(readingBuilderActions.updateContent(newObject));
  };
  openQuestionEditor = (open, i) => {
    this.setState({
      openQuestionEditor: open,
      questionEditorIndex: i,
    });
  };
  closeQuestionEditor = () => {
    this.handleQuestionEditFinish();
  };
  handleMcqQuestionChange = (i, question) => {
    const newQuestion = question;
    const { questionEditorIndex } = this.state;
    const { readingBuilder, dispatch } = this.props;
    const newObject = _.cloneDeep(readingBuilder);
    try {
      newObject.exam_explanation.exams[questionEditorIndex].questions[i].text =
        newQuestion;
    } catch (e) {}
    dispatch(readingBuilderActions.updateContent(newObject));
  };
  handleNormalQuestionChange = (i, question) => {
    const newQuestion = question;
    const { questionEditorIndex } = this.state;
    const { readingBuilder, dispatch } = this.props;
    const newObject = _.cloneDeep(readingBuilder);
    try {
      newObject.exam_explanation.exams[questionEditorIndex].questions[i] =
        newQuestion;
    } catch (e) {}
    dispatch(readingBuilderActions.updateContent(newObject));
  };
  handleQuestionEditFinish = () => {
    const { questionEditorIndex } = this.state;
    const { readingBuilder, dispatch } = this.props;
    const newObject = _.cloneDeep(readingBuilder);
    try {
      const exam_type =
        newObject.exam_explanation.exams[questionEditorIndex].exam_type;
      if (
        exam_type === EXAM.READ_RADIO_MCQ ||
        exam_type === EXAM.READ_RADIO_CHECKBOX
      ) {
        newObject.exam_explanation.exams[questionEditorIndex].questions.forEach(
          (text, i) => {
            text.text = text.text.replace("<p>", "").replace("</p>", "");
          }
        );
      }
      if (
        exam_type === EXAM.READ_FLOW_CHART ||
        exam_type === EXAM.READ_FLOW_CHART_DETAILED ||
        exam_type === EXAM.READ_MATCHING ||
        exam_type === EXAM.READ_SENTENCE_COMPLETION ||
        exam_type === EXAM.READ_SHORT_ANSWERS
      ) {
        newObject.exam_explanation.exams[questionEditorIndex].questions.forEach(
          (text, i) => {
            newObject.exam_explanation.exams[questionEditorIndex].questions[i] =
              text.replace("<p>", "").replace("</p>", "");
          }
        );
      }
    } catch (e) {}
    dispatch(readingBuilderActions.updateContent(newObject));
    this.setState({ openQuestionEditor: false });
  };
  onAccordionClick = (parentIndex, index) => {
    if (
      parentIndex === this.state.accordionIndex.parentIndex &&
      index === this.state.accordionIndex.index
    ) {
      this.setState({
        accordionIndex: { parentIndex: parentIndex, index: -1 },
      });
    } else {
      this.setState({
        accordionIndex: { parentIndex: parentIndex, index: index },
      });
    }
  };
  state = {
    closeQuestionEditor: this.closeQuestionEditor.bind(this),
    handleMcqQuestionChange: this.handleMcqQuestionChange.bind(this),
    handleQuestionEditFinish: this.handleQuestionEditFinish.bind(this),
    handleNormalQuestionChange: this.handleNormalQuestionChange.bind(this),
    openQuestionEditor: false,
    questionEditorIndex: 0,
    activeIndex: 0,
    handleOnChange: this.placeholder.bind(this),
    handleRadioOnChange: this.placeholder.bind(this),
    handleCheckboxOnChange: this.placeholder.bind(this),
    handleDetailedFlowChartChange: this.placeholder.bind(this),
    openHintModal: false,
    openVocabModal: false,
    accordionIndex: { parentIndex: -1, index: -1 },
    onAccordionClick: this.onAccordionClick.bind(this),
  };
  placeholder() {}
  addSection = () => {
    const { dispatch, readingBuilder } = this.props;
    const newObject = _.cloneDeep(readingBuilder);
    newObject.exam.exams.push(this.createImageLabeling());
    newObject.exam_explanation.exams.push(this.createImageLabeling());
    dispatch(readingBuilderActions.updateContent(newObject));
  };
  removeSection = (i) => {
    const { dispatch, readingBuilder } = this.props;
    const newObject = _.cloneDeep(readingBuilder);
    newObject.exam.exams.splice(i, 1);
    newObject.exam_explanation.exams.splice(i, 1);
    dispatch(readingBuilderActions.updateContent(newObject));
  };
  resetForm = () => {
    if (window.confirm("Are you sure you want to reset the data?")) {
      this.props.dispatch(readingBuilderActions.reset());
    }
  };
  goToDashboard = () => {
    this.props.history.push("/");
  };
  copyTextToExplanation = () => {
    const { dispatch, readingBuilder } = this.props;
    const newObject = _.cloneDeep(readingBuilder);
    newObject.content_explanation = newObject.content;
    dispatch(readingBuilderActions.updateContent(newObject));
  };
  copyInstructionToExplanation = () => {
    const { dispatch, readingBuilder } = this.props;
    const newObject = _.cloneDeep(readingBuilder);
    newObject.instruction_explanation = newObject.instruction;
    dispatch(readingBuilderActions.updateContent(newObject));
  };
  copyExamToExplanation = (exam, i) => {
    const { dispatch, readingBuilder } = this.props;
    const newObject = _.cloneDeep(readingBuilder);
    newObject.exam_explanation.exams[i] = _.cloneDeep(exam);
    dispatch(readingBuilderActions.updateContent(newObject));
  };

  removeFlowChartQuestion = (i, j, e) => {
    const { readingBuilder, dispatch } = this.props;
    const newObject = _.cloneDeep(readingBuilder);
    newObject.exam.exams[i].questions.splice(j, 1);
    // newObject.exam_explanation.exams[i].splice(j, 1);
    dispatch(readingBuilderActions.updateContent(newObject));
  };

  addFlowChartQuestion = (i, e) => {
    const { readingBuilder, dispatch } = this.props;
    const newObject = _.cloneDeep(readingBuilder);
    newObject.exam.exams[i].questions.push(this.createDetailedFlowChart());
    dispatch(readingBuilderActions.updateContent(newObject));
  };

  handleFlowChartQuestionChange = (i, j, e) => {
    const { readingBuilder, dispatch } = this.props;
    const newObject = _.cloneDeep(readingBuilder);
    newObject.exam.exams[i].questions[j] = e;
    dispatch(readingBuilderActions.updateContent(newObject));
  };

  render() {
    const data = { ...this.props.readingBuilder };
    const { open } = this.state;
    const { content } = this.props;
    const final_blank_answers = [];
    const final_blank_answers2 = [];
    for (let i = 0; i < data.length; i++) {
      const answers = [];
      const answers2 = [];
      // eslint-disable-next-line
      content[i].exam.exams.map((exam) => {
        answers.push(Array(exam.correct_answer.length).join(".").split("."));
        answers2.push(Array(exam.correct_answer.length).join(".").split("."));
      });
      final_blank_answers.push(answers);
      final_blank_answers2.push(answers2);
    }

    const panes = [
      {
        menuItem: "View Text",
        render: () => {
          return (
            <Tab.Pane>
              <Segment style={{ backgroundColor: "#F5F8FF" }}>
                <h3>Instruction</h3>
                <div
                  className="read-content"
                  dangerouslySetInnerHTML={{ __html: data.instruction }}
                />
              </Segment>
              <Segment style={{ backgroundColor: "#F5F8FF" }}>
                <h3>Text</h3>
                <div
                  className="read-content"
                  dangerouslySetInnerHTML={{ __html: data.content }}
                />
              </Segment>
            </Tab.Pane>
          );
        },
      },
      {
        menuItem: "View Questions",
        render: () => (
          <Tab.Pane style={{ backgroundColor: "#F5F8FF" }}>
            <ReadCombined
              {...this.state}
              answers={final_blank_answers}
              answers_strat={final_blank_answers2}
              content={data}
            />
          </Tab.Pane>
        ),
      },
      {
        menuItem: "View Explanations",
        render: () => {
          return (
            <Tab.Pane>
              <Segment style={{ backgroundColor: "#F5F8FF" }}>
                <h3>Instruction</h3>
                <div
                  className="read-content"
                  dangerouslySetInnerHTML={{
                    __html: data.instruction_explanation,
                  }}
                />
              </Segment>
              <Segment style={{ backgroundColor: "#F5F8FF" }}>
                <h3>Text</h3>
                <div
                  className="read-content"
                  dangerouslySetInnerHTML={{ __html: data.content_explanation }}
                />
              </Segment>
              <Segment style={{ backgroundColor: "#F5F8FF" }}>
                <h3>Questions</h3>
                <ReadCombined
                  {...this.state}
                  explanation={true}
                  answers={final_blank_answers}
                  answers_strat={final_blank_answers2}
                  content={data}
                />
              </Segment>
            </Tab.Pane>
          );
        },
      },
      {
        menuItem: "View Hints",
        render: () => {
          return (
            <Tab.Pane>
              <Segment style={{ backgroundColor: "#F5F8FF" }}>
                <h3>Hints in Learning</h3>
                <div
                  className="read-content"
                  dangerouslySetInnerHTML={{ __html: data.hint }}
                />
              </Segment>
              <Segment style={{ backgroundColor: "#F5F8FF" }}>
                <h3>Hints in Review</h3>
                <div
                  className="read-content"
                  dangerouslySetInnerHTML={{ __html: data.hint_review }}
                />
              </Segment>
            </Tab.Pane>
          );
        },
      },
    ];

    const exams = data.exam.exams.map((exam, i) => {
      if (exam.exam_type === EXAM.READ_IMAGE_LABELING) {
        return (
          <Segment style={{ marginTop: "3em" }} key={`segment${i}`}>
            <Form.Field inline>
              <h4
                style={{
                  marginBottom: "10px",
                  marginTop: "1rem",
                  display: "inline-block",
                  width: "100%",
                }}
              >
                Exam Type
                <Button
                  type="button"
                  primary
                  style={{ float: "right" }}
                  onClick={this.copyExamToExplanation.bind(this, exam, i)}
                  size="tiny"
                >
                  Copy to Explanation
                </Button>
              </h4>
              <Select
                value={exam.exam_type}
                required
                placeholder="Select Section Type"
                options={section_otpions}
                onChange={this.handleExamTypeChange2.bind(this, i)}
              />
              {data.exam.exams.length > 1 ? (
                <Button
                  onClick={this.removeSection.bind(this, i)}
                  type="button"
                  icon
                  color="red"
                  floated="right"
                  size="tiny"
                >
                  <Icon name="cancel" />
                </Button>
              ) : null}
            </Form.Field>
            <Form.Field inline>
              <h4 style={{ marginBottom: "10px" }}>Start Number </h4>
              <Input
                type="number"
                required
                width={4}
                fluid
                name="start_number"
                onChange={this.handleStartNumberChange.bind(this, i)}
                value={exam.start_number}
                placeholder="Start Number"
              />
            </Form.Field>
            <Form.Field inline>
              <h4 style={{ marginBottom: "10px" }}>Total Blanks </h4>
              <Input
                type="number"
                required
                width={4}
                fluid
                name="total_count"
                onChange={this.handleTotalCountChange.bind(this, i)}
                value={exam.total_count}
                placeholder="Total Blanks"
              />
            </Form.Field>
            <Form.Field inline>
              <h4 style={{ marginBottom: "10px" }}>Image </h4>
              <Input
                type="url"
                width={4}
                fluid
                name="image"
                value={exam.image}
                onChange={this.handleImageChange.bind(this, i)}
                placeholder="Image URL"
              />
            </Form.Field>
            <Form.Field inline>
              <h4 style={{ marginBottom: "10px" }}>Instruction </h4>
              <Editor
                init={TINY_MCE_SETTINGS.SETTINGS_NO_BLANK}
                onEditorChange={this.handleInstructionChange2.bind(this, i)}
                value={exam.instruction}
              />
            </Form.Field>
            <Form.Field inline>
              <h4 style={{ marginBottom: "10px" }}>Blank Size </h4>
              <Input
                type="text"
                placeholder="Place sizes of the blanks here"
                onChange={this.handleBlankSizeChange.bind(this, i)}
                defaultValue={JSON.stringify(exam.size)}
              />
            </Form.Field>
            <Form.Field>
              <h4 style={{ marginBottom: "10px" }}>Correct Answers </h4>
              <TextArea
                placeholder="Enter correct answers here"
                onChange={this.handleCorrectAnswerChange.bind(this, i)}
                defaultValue={JSON.stringify(exam.correct_answer)}
              />
            </Form.Field>
          </Segment>
        );
      } else if (exam.exam_type === EXAM.READ_FLOW_CHART) {
        return (
          <Segment style={{ marginTop: "3em" }} key={`segment${i}`}>
            <Form.Field inline>
              <h4
                style={{
                  marginBottom: "10px",
                  marginTop: "1rem",
                  display: "inline-block",
                  width: "100%",
                }}
              >
                Exam Type
                <Button
                  type="button"
                  primary
                  style={{ float: "right" }}
                  onClick={this.copyExamToExplanation.bind(this, exam, i)}
                  size="tiny"
                >
                  Copy to Explanation
                </Button>
              </h4>
              <Select
                value={exam.exam_type}
                required
                placeholder="Select Section Type"
                options={section_otpions}
                onChange={this.handleExamTypeChange2.bind(this, i)}
              />
              {data.exam.exams.length > 1 ? (
                <Button
                  onClick={this.removeSection.bind(this, i)}
                  size="tiny"
                  type="button"
                  icon
                  color="red"
                  floated="right"
                >
                  <Icon name="cancel" />
                </Button>
              ) : null}
            </Form.Field>
            <Form.Field inline>
              <h4 style={{ marginBottom: "10px" }}>Start Number </h4>
              <Input
                type="number"
                required
                width={4}
                fluid
                name="start_number"
                onChange={this.handleStartNumberChange.bind(this, i)}
                value={exam.start_number}
                placeholder="Start Number"
              />
            </Form.Field>
            <Form.Field inline>
              <h4 style={{ marginBottom: "10px" }}>Instructions </h4>
              <Editor
                init={TINY_MCE_SETTINGS.SETTINGS_NO_BLANK}
                onEditorChange={this.handleInstructionChange2.bind(this, i)}
                value={exam.instruction}
              />
            </Form.Field>
            <Form.Field>
              <h4 style={{ marginBottom: "10px" }}>Questions </h4>
              <TextArea
                placeholder="Place questions here"
                onChange={this.handleQuestionChange.bind(this, i)}
                defaultValue={JSON.stringify(exam.questions)}
              />
            </Form.Field>
            <Form.Field inline>
              <h4 style={{ marginBottom: "10px" }}>Blank Size </h4>
              <Input
                type="text"
                placeholder="Place sizes of the blanks here"
                onChange={this.handleBlankSizeChange.bind(this, i)}
                defaultValue={JSON.stringify(exam.size)}
              />
            </Form.Field>
            <Form.Field inline>
              <h4 style={{ marginBottom: "10px" }}>Correct Answers </h4>
              <TextArea
                placeholder="Place correct answers here"
                onChange={this.handleCorrectAnswerChange.bind(this, i)}
                defaultValue={JSON.stringify(exam.correct_answer)}
              />
            </Form.Field>
          </Segment>
        );
      } else if (exam.exam_type === EXAM.READ_FLOW_CHART_DETAILED) {
        return (
          <Segment style={{ marginTop: "3em" }} key={`segment${i}`}>
            <Form.Field inline>
              <h4
                style={{
                  marginBottom: "10px",
                  marginTop: "1rem",
                  display: "inline-block",
                  width: "100%",
                }}
              >
                Exam Type
                <Button
                  type="button"
                  primary
                  style={{ float: "right" }}
                  onClick={this.copyExamToExplanation.bind(this, exam, i)}
                  size="tiny"
                >
                  Copy to Explanation
                </Button>
              </h4>
              <Select
                value={exam.exam_type}
                required
                placeholder="Select Section Type"
                options={section_otpions}
                onChange={this.handleExamTypeChange2.bind(this, i)}
              />
              {data.exam.exams.length > 1 ? (
                <Button
                  onClick={this.removeSection.bind(this, i)}
                  size="tiny"
                  type="button"
                  icon
                  color="red"
                  floated="right"
                >
                  <Icon name="cancel" />
                </Button>
              ) : null}
            </Form.Field>
            <Form.Field inline>
              <h4 style={{ marginBottom: "10px" }}>Start Number </h4>
              <Input
                type="number"
                required
                width={4}
                fluid
                name="start_number"
                onChange={this.handleStartNumberChange.bind(this, i)}
                value={exam.start_number}
                placeholder="Start Number"
              />
            </Form.Field>
            <Form.Field inline>
              <h4 style={{ marginBottom: "10px" }}>Instructions </h4>
              <Editor
                init={TINY_MCE_SETTINGS.SETTINGS_NO_BLANK}
                onEditorChange={this.handleInstructionChange2.bind(this, i)}
                value={exam.instruction}
              />
            </Form.Field>
            <Form.Field>
              <h4>Questions </h4>
              {exam.questions.map((question, j) => {
                return (
                  <React.Fragment>
                    <h5
                      style={{
                        marginTop: ".5rem",
                        paddingBottom: ".5rem",
                        paddingTop: ".75rem",
                      }}
                    >
                      No. {j + 1}
                      {j !== 0 ? (
                        <Button
                          onClick={this.removeFlowChartQuestion.bind(
                            this,
                            i,
                            j
                          )}
                          type="button"
                          negative
                          style={{ float: "right" }}
                          size="tiny"
                          icon
                        >
                          <Icon name="cancel" />
                        </Button>
                      ) : null}
                      {j === exam.questions.length - 1 ? (
                        <Button
                          onClick={this.addFlowChartQuestion.bind(this, i)}
                          type="button"
                          positive
                          style={{ float: "right" }}
                          size="tiny"
                          icon
                        >
                          <Icon name="plus" />
                        </Button>
                      ) : null}
                    </h5>
                    <Editor
                      init={TINY_MCE_SETTINGS.SETTINGS_WITH_BLANK}
                      value={question}
                      onEditorChange={this.handleFlowChartQuestionChange.bind(
                        this,
                        i,
                        j
                      )}
                    />
                  </React.Fragment>
                );
              })}
            </Form.Field>
            <Form.Field inline>
              <h4 style={{ marginBottom: "10px" }}>Blank Size </h4>
              <Input
                type="text"
                placeholder="Place sizes of the blanks here"
                onChange={this.handleBlankSizeChange.bind(this, i)}
                defaultValue={JSON.stringify(exam.size)}
              />
            </Form.Field>
            <Form.Field inline>
              <h4 style={{ marginBottom: "10px" }}>Prefixes</h4>
              <Input
                type="text"
                placeholder="Place prefixes of the blanks here"
                onChange={this.handlePrefixChange.bind(this, i)}
                defaultValue={JSON.stringify(exam.prefixes)}
              />
            </Form.Field>
            <Form.Field inline>
              <h4 style={{ marginBottom: "10px" }}>Answer Group</h4>
              <Input
                type="text"
                placeholder="Place answer groups in the blank here"
                onChange={this.handleGroupChange.bind(this, i)}
                defaultValue={JSON.stringify(exam.answer_group)}
              />
            </Form.Field>
            <Form.Field inline>
              <h4 style={{ marginBottom: "10px" }}>Correct Answers </h4>
              <TextArea
                placeholder="Place correct answers here"
                onChange={this.handleCorrectAnswerChange.bind(this, i)}
                defaultValue={JSON.stringify(exam.correct_answer)}
              />
            </Form.Field>
          </Segment>
        );
      } else if (exam.exam_type === EXAM.READ_MATCHING) {
        return (
          <Segment style={{ marginTop: "3em" }} key={`segment${i}`}>
            <Form.Field inline>
              <h4
                style={{
                  marginBottom: "10px",
                  marginTop: "1rem",
                  display: "inline-block",
                  width: "100%",
                }}
              >
                Exam Type
                <Button
                  type="button"
                  primary
                  style={{ float: "right" }}
                  onClick={this.copyExamToExplanation.bind(this, exam, i)}
                  size="tiny"
                >
                  Copy to Explanation
                </Button>
              </h4>
              <Select
                value={exam.exam_type}
                required
                placeholder="Select Section Type"
                options={section_otpions}
                onChange={this.handleExamTypeChange2.bind(this, i)}
              />
              {data.exam.exams.length > 1 ? (
                <Button
                  onClick={this.removeSection.bind(this, i)}
                  size="tiny"
                  type="button"
                  icon
                  color="red"
                  floated="right"
                >
                  <Icon name="cancel" />
                </Button>
              ) : null}
            </Form.Field>
            <Form.Field inline>
              <h4 style={{ marginBottom: "10px" }}>Start Number </h4>
              <Input
                type="number"
                required
                width={4}
                fluid
                name="start_number"
                onChange={this.handleStartNumberChange.bind(this, i)}
                value={exam.start_number}
                placeholder="Start Number"
              />
            </Form.Field>
            <Form.Field inline>
              <h4 style={{ marginBottom: "10px" }}>Instructions </h4>
              <Editor
                init={TINY_MCE_SETTINGS.SETTINGS_NO_BLANK}
                onEditorChange={this.handleInstructionChange2.bind(this, i)}
                value={exam.instruction}
              />
            </Form.Field>
            <Form.Field>
              <h4 style={{ marginBottom: "10px" }}>Questions </h4>
              <TextArea
                placeholder="Place questions here"
                onChange={this.handleQuestionChange.bind(this, i)}
                defaultValue={JSON.stringify(exam.questions)}
              />
            </Form.Field>
            <Form.Field inline>
              <h4 style={{ marginBottom: "10px" }}>Blank Size </h4>
              <Input
                type="text"
                placeholder="Place sizes of the blanks here"
                onChange={this.handleBlankSizeChange.bind(this, i)}
                defaultValue={JSON.stringify(exam.size)}
              />
            </Form.Field>
            <Form.Field inline>
              <h4 style={{ marginBottom: "10px" }}>Correct Answers </h4>
              <TextArea
                placeholder="Place correct answers here"
                onChange={this.handleCorrectAnswerChange.bind(this, i)}
                defaultValue={JSON.stringify(exam.correct_answer)}
              />
            </Form.Field>
          </Segment>
        );
      } else if (exam.exam_type === EXAM.READ_NOTE_COMPLETION) {
        return (
          <Segment style={{ marginTop: "3em" }} key={`segment${i}`}>
            <Form.Field inline>
              <h4
                style={{
                  marginBottom: "10px",
                  marginTop: "1rem",
                  display: "inline-block",
                  width: "100%",
                }}
              >
                Exam Type
                <Button
                  type="button"
                  primary
                  style={{ float: "right" }}
                  onClick={this.copyExamToExplanation.bind(this, exam, i)}
                  size="tiny"
                >
                  Copy to Explanation
                </Button>
              </h4>
              <Select
                value={exam.exam_type}
                required
                placeholder="Select Section Type"
                options={section_otpions}
                onChange={this.handleExamTypeChange2.bind(this, i)}
              />
              {data.exam.exams.length > 1 ? (
                <Button
                  onClick={this.removeSection.bind(this, i)}
                  size="tiny"
                  type="button"
                  icon
                  color="red"
                  floated="right"
                >
                  <Icon name="cancel" />
                </Button>
              ) : null}
            </Form.Field>
            <Form.Field inline>
              <h4 style={{ marginBottom: "10px" }}>Start Number </h4>
              <Input
                type="number"
                required
                width={4}
                fluid
                name="start_number"
                onChange={this.handleStartNumberChange.bind(this, i)}
                value={exam.start_number}
                placeholder="Start Number"
              />
            </Form.Field>
            <Form.Field inline>
              <h4 style={{ marginBottom: "10px" }}>Instructions </h4>
              <Editor
                init={TINY_MCE_SETTINGS.SETTINGS_NO_BLANK}
                onEditorChange={this.handleInstructionChange2.bind(this, i)}
                value={exam.instruction}
              />
            </Form.Field>
            <Form.Field>
              <h4 style={{ marginBottom: "10px" }}>Questions </h4>
              <Editor
                init={TINY_MCE_SETTINGS.SETTINGS_WITH_BLANK}
                onEditorChange={this.handleQuestionChange2.bind(this, i)}
                value={exam.questions}
              />
            </Form.Field>
            <Form.Field inline>
              <h4 style={{ marginBottom: "10px" }}>Blanks Size </h4>
              <Input
                type="text"
                placeholder="Place sizes of the blanks here"
                onChange={this.handleBlankSizeChange.bind(this, i)}
                defaultValue={JSON.stringify(exam.size)}
              />
            </Form.Field>
            <Form.Field inline>
              <h4 style={{ marginBottom: "10px" }}>Prefixes</h4>
              <Input
                type="text"
                placeholder="Place prefixes of the blanks here"
                onChange={this.handlePrefixChange.bind(this, i)}
                defaultValue={JSON.stringify(exam.prefixes)}
              />
            </Form.Field>
            <Form.Field inline>
              <h4 style={{ marginBottom: "10px" }}>Answer Group</h4>
              <Input
                type="text"
                placeholder="Place answer groups in the blank here"
                onChange={this.handleGroupChange.bind(this, i)}
                defaultValue={JSON.stringify(exam.answer_group)}
              />
            </Form.Field>
            <Form.Field inline>
              <h4 style={{ marginBottom: "10px" }}>Correct Answers </h4>
              <TextArea
                placeholder="Place correct answers here"
                onChange={this.handleCorrectAnswerChange.bind(this, i)}
                defaultValue={JSON.stringify(exam.correct_answer)}
              />
            </Form.Field>
          </Segment>
        );
      } else if (exam.exam_type === EXAM.READ_RADIO_MCQ) {
        return (
          <Segment style={{ marginTop: "3em" }} key={`segment${i}`}>
            <Form.Field inline>
              <h4
                style={{
                  marginBottom: "10px",
                  marginTop: "1rem",
                  display: "inline-block",
                  width: "100%",
                }}
              >
                Exam Type
                <Button
                  type="button"
                  primary
                  style={{ float: "right" }}
                  onClick={this.copyExamToExplanation.bind(this, exam, i)}
                  size="tiny"
                >
                  Copy to Explanation
                </Button>
              </h4>
              <Select
                value={exam.exam_type}
                required
                placeholder="Select Section Type"
                options={section_otpions}
                onChange={this.handleExamTypeChange2.bind(this, i)}
              />
              {data.exam.exams.length > 1 ? (
                <Button
                  onClick={this.removeSection.bind(this, i)}
                  size="tiny"
                  type="button"
                  icon
                  color="red"
                  floated="right"
                >
                  <Icon name="cancel" />
                </Button>
              ) : null}
            </Form.Field>
            <Form.Field inline>
              <h4 style={{ marginBottom: "10px" }}>Start Number </h4>
              <Input
                type="number"
                required
                width={4}
                fluid
                name="start_number"
                onChange={this.handleStartNumberChange.bind(this, i)}
                value={exam.start_number}
                placeholder="Start Number"
              />
            </Form.Field>
            <Form.Field inline>
              <h4 style={{ marginBottom: "10px" }}>Instructions </h4>
              <Editor
                init={TINY_MCE_SETTINGS.SETTINGS_NO_BLANK}
                onEditorChange={this.handleInstructionChange2.bind(this, i)}
                value={exam.instruction}
              />
            </Form.Field>
            <Form.Field>
              <h4 style={{ marginBottom: "10px" }}>Questions</h4>
              <TextArea
                placeholder="Enter questions here"
                style={{ minHeight: 100 }}
                onChange={this.handleQuestionChange.bind(this, i)}
                defaultValue={JSON.stringify(exam.questions)}
              />
            </Form.Field>
            <Form.Field>
              <h4 style={{ marginBottom: "10px" }}>Answers</h4>
              <TextArea
                placeholder="Enter answers here"
                style={{ minHeight: 100 }}
                onChange={this.handleCorrectAnswerChange.bind(this, i)}
                defaultValue={JSON.stringify(exam.correct_answer)}
              />
            </Form.Field>
          </Segment>
        );
      } else if (exam.exam_type === EXAM.READ_SENTENCE_COMPLETION) {
        return (
          <Segment style={{ marginTop: "3em" }} key={`segment${i}`}>
            <Form.Field inline>
              <h4
                style={{
                  marginBottom: "10px",
                  marginTop: "1rem",
                  display: "inline-block",
                  width: "100%",
                }}
              >
                Exam Type
                <Button
                  type="button"
                  primary
                  style={{ float: "right" }}
                  onClick={this.copyExamToExplanation.bind(this, exam, i)}
                  size="tiny"
                >
                  Copy to Explanation
                </Button>
              </h4>
              <Select
                value={exam.exam_type}
                required
                placeholder="Select Section Type"
                options={section_otpions}
                onChange={this.handleExamTypeChange2.bind(this, i)}
              />
              {data.exam.exams.length > 1 ? (
                <Button
                  onClick={this.removeSection.bind(this, i)}
                  type="button"
                  size="tiny"
                  icon
                  color="red"
                  floated="right"
                >
                  <Icon name="cancel" />
                </Button>
              ) : null}
            </Form.Field>
            <Form.Field inline>
              <h4 style={{ marginBottom: "10px" }}>Start Number </h4>
              <Input
                type="number"
                required
                width={4}
                fluid
                name="start_number"
                onChange={this.handleStartNumberChange.bind(this, i)}
                value={exam.start_number}
                placeholder="Start Number"
              />
            </Form.Field>
            <Form.Field inline>
              <h4 style={{ marginBottom: "10px" }}>Instructions </h4>
              <Editor
                init={TINY_MCE_SETTINGS.SETTINGS_NO_BLANK}
                onEditorChange={this.handleInstructionChange2.bind(this, i)}
                value={exam.instruction}
              />
            </Form.Field>
            <Form.Field>
              <h4 style={{ marginBottom: "10px" }}>Questions</h4>
              <TextArea
                placeholder="Enter questions here"
                style={{ minHeight: 100 }}
                onChange={this.handleQuestionChange.bind(this, i)}
                defaultValue={JSON.stringify(exam.questions)}
              />
            </Form.Field>
            <Form.Field inline>
              <h4 style={{ marginBottom: "10px" }}>Blanks Size </h4>
              <Input
                type="text"
                placeholder="Place sizes of the blanks here"
                onChange={this.handleBlankSizeChange.bind(this, i)}
                defaultValue={JSON.stringify(exam.size)}
              />
            </Form.Field>
            <Form.Field>
              <h4 style={{ marginBottom: "10px" }}>Answers</h4>
              <TextArea
                placeholder="Enter answers here"
                style={{ minHeight: 100 }}
                onChange={this.handleCorrectAnswerChange.bind(this, i)}
                defaultValue={JSON.stringify(exam.correct_answer)}
              />
            </Form.Field>
          </Segment>
        );
      } else if (exam.exam_type === EXAM.READ_SHORT_ANSWERS) {
        return (
          <Segment style={{ marginTop: "3em" }} key={`segment${i}`}>
            <Form.Field inline>
              <h4
                style={{
                  marginBottom: "10px",
                  marginTop: "1rem",
                  display: "inline-block",
                  width: "100%",
                }}
              >
                Exam Type
                <Button
                  type="button"
                  primary
                  style={{ float: "right" }}
                  onClick={this.copyExamToExplanation.bind(this, exam, i)}
                  size="tiny"
                >
                  Copy to Explanation
                </Button>
              </h4>
              <Select
                value={exam.exam_type}
                required
                placeholder="Select Section Type"
                options={section_otpions}
                onChange={this.handleExamTypeChange2.bind(this, i)}
              />
              {data.exam.exams.length > 1 ? (
                <Button
                  onClick={this.removeSection.bind(this, i)}
                  size="tiny"
                  type="button"
                  icon
                  color="red"
                  floated="right"
                >
                  <Icon name="cancel" />
                </Button>
              ) : null}
            </Form.Field>
            <Form.Field inline>
              <h4 style={{ marginBottom: "10px" }}>Start Number </h4>
              <Input
                type="number"
                required
                width={4}
                fluid
                name="start_number"
                onChange={this.handleStartNumberChange.bind(this, i)}
                value={exam.start_number}
                placeholder="Start Number"
              />
            </Form.Field>
            <Form.Field inline>
              <h4 style={{ marginBottom: "10px" }}>Instructions </h4>
              <Editor
                init={TINY_MCE_SETTINGS.SETTINGS_NO_BLANK}
                onEditorChange={this.handleInstructionChange2.bind(this, i)}
                value={exam.instruction}
              />
            </Form.Field>
            <Form.Field>
              <h4 style={{ marginBottom: "10px" }}>Questions</h4>
              <TextArea
                placeholder="Enter questions here"
                style={{ minHeight: 100 }}
                onChange={this.handleQuestionChange.bind(this, i)}
                defaultValue={JSON.stringify(exam.questions)}
              />
            </Form.Field>
            <Form.Field inline>
              <h4 style={{ marginBottom: "10px" }}>Blanks Size </h4>
              <Input
                type="text"
                placeholder="Place sizes of the blanks here"
                onChange={this.handleBlankSizeChange.bind(this, i)}
                defaultValue={JSON.stringify(exam.size)}
              />
            </Form.Field>
            <Form.Field>
              <h4 style={{ marginBottom: "10px" }}>Answers</h4>
              <TextArea
                placeholder="Enter answers here"
                style={{ minHeight: 100 }}
                onChange={this.handleCorrectAnswerChange.bind(this, i)}
                defaultValue={JSON.stringify(exam.correct_answer)}
              />
            </Form.Field>
          </Segment>
        );
      } else if (exam.exam_type === EXAM.READ_RADIO_CHECKBOX) {
        return (
          <Segment style={{ marginTop: "3em" }} key={`segment${i}`}>
            <Form.Field inline>
              <h4
                style={{
                  marginBottom: "10px",
                  marginTop: "1rem",
                  display: "inline-block",
                  width: "100%",
                }}
              >
                Exam Type
                <Button
                  type="button"
                  primary
                  style={{ float: "right" }}
                  onClick={this.copyExamToExplanation.bind(this, exam, i)}
                  size="tiny"
                >
                  Copy to Explanation
                </Button>
              </h4>
              <Select
                value={exam.exam_type}
                required
                placeholder="Select Section Type"
                options={section_otpions}
                onChange={this.handleExamTypeChange2.bind(this, i)}
              />
              {data.exam.exams.length > 1 ? (
                <Button
                  onClick={this.removeSection.bind(this, i)}
                  size="tiny"
                  type="button"
                  icon
                  color="red"
                  floated="right"
                >
                  <Icon name="cancel" />
                </Button>
              ) : null}
            </Form.Field>
            <Form.Field inline>
              <h4 style={{ marginBottom: "10px" }}>Start Number </h4>
              <Input
                type="number"
                required
                width={4}
                fluid
                name="start_number"
                onChange={this.handleStartNumberChange.bind(this, i)}
                value={exam.start_number}
                placeholder="Start Number"
              />
            </Form.Field>
            <Form.Field inline>
              <h4 style={{ marginBottom: "10px" }}>Instructions </h4>
              <Editor
                init={TINY_MCE_SETTINGS.SETTINGS_NO_BLANK}
                onEditorChange={this.handleInstructionChange2.bind(this, i)}
                value={exam.instruction}
              />
            </Form.Field>
            <Form.Field>
              <h4 style={{ marginBottom: "10px" }}>Questions</h4>
              <TextArea
                placeholder="Enter questions here"
                style={{ minHeight: 100 }}
                onChange={this.handleQuestionChange.bind(this, i)}
                defaultValue={JSON.stringify(exam.questions)}
              />
            </Form.Field>
            <Form.Field>
              <h4 style={{ marginBottom: "10px" }}>Answers</h4>
              <TextArea
                placeholder="Enter answers here"
                style={{ minHeight: 100 }}
                onChange={this.handleCorrectAnswerChange.bind(this, i)}
                defaultValue={JSON.stringify(exam.correct_answer)}
              />
            </Form.Field>
          </Segment>
        );
      }
      return null;
    });

    const exams_explanation = data.exam_explanation.exams.map((exam, i) => {
      if (exam.exam_type === EXAM.READ_IMAGE_LABELING) {
        return (
          <Segment style={{ marginTop: "3em" }} key={`segment${i}`}>
            <Form.Field inline>
              <h4 style={{ marginBottom: "10px" }}>{`Section ${i + 1}`}</h4>
            </Form.Field>
            <Form.Field inline>
              <h4 style={{ marginBottom: "10px" }}>Instruction </h4>
              <Editor
                init={TINY_MCE_SETTINGS.SETTINGS_NO_BLANK}
                onEditorChange={this.handleInstructionChange2Explanation.bind(
                  this,
                  i
                )}
                value={exam.instruction_explanation}
              />
            </Form.Field>
          </Segment>
        );
      } else if (exam.exam_type === EXAM.READ_FLOW_CHART) {
        return (
          <Segment style={{ marginTop: "3em" }} key={`segment${i}`}>
            <Form.Field inline>
              <h4 style={{ marginBottom: "10px" }}>{`Section ${i + 1}`}</h4>
            </Form.Field>
            <Form.Field inline>
              <h4 style={{ marginBottom: "10px" }}>Instructions </h4>
              <Editor
                init={TINY_MCE_SETTINGS.SETTINGS_NO_BLANK}
                onEditorChange={this.handleInstructionChange2Explanation.bind(
                  this,
                  i
                )}
                value={exam.instruction}
              />
            </Form.Field>
            <Form.Field>
              <h4 style={{ marginBottom: "10px" }}>Questions </h4>
              <TextArea
                placeholder="Place questions here"
                onChange={this.handleQuestionChangeExplanation.bind(this, i)}
                defaultValue={JSON.stringify(exam.questions)}
              />
            </Form.Field>
            <Form.Field>
              <Button
                type="button"
                onClick={this.openQuestionEditor.bind(this, true, i)}
                primary
                icon
                size="tiny"
              >
                <Icon name="edit" /> Edit Questions
              </Button>
            </Form.Field>
          </Segment>
        );
      } else if (exam.exam_type === EXAM.READ_MATCHING) {
        return (
          <Segment style={{ marginTop: "3em" }} key={`segment${i}`}>
            <Form.Field inline>
              <h4 style={{ marginBottom: "10px" }}>{`Section ${i + 1}`}</h4>
            </Form.Field>
            <Form.Field inline>
              <h4 style={{ marginBottom: "10px" }}>Instructions </h4>
              <Editor
                init={TINY_MCE_SETTINGS.SETTINGS_NO_BLANK}
                onEditorChange={this.handleInstructionChange2Explanation.bind(
                  this,
                  i
                )}
                value={exam.instruction}
              />
            </Form.Field>
            <Form.Field>
              <h4 style={{ marginBottom: "10px" }}>Questions </h4>
              <TextArea
                placeholder="Place questions here"
                onChange={this.handleQuestionChangeExplanation.bind(this, i)}
                defaultValue={JSON.stringify(exam.questions)}
              />
            </Form.Field>
            <Form.Field>
              <Button
                type="button"
                onClick={this.openQuestionEditor.bind(this, true, i)}
                primary
                icon
                size="tiny"
              >
                <Icon name="edit" /> Edit Questions
              </Button>
            </Form.Field>
          </Segment>
        );
      } else if (exam.exam_type === EXAM.READ_NOTE_COMPLETION) {
        return (
          <Segment style={{ marginTop: "3em" }} key={`segment${i}`}>
            <Form.Field inline>
              <h4 style={{ marginBottom: "10px" }}>{`Section ${i + 1}`}</h4>
            </Form.Field>
            <Form.Field inline>
              <h4 style={{ marginBottom: "10px" }}>Instructions </h4>
              <Editor
                init={TINY_MCE_SETTINGS.SETTINGS_NO_BLANK}
                onEditorChange={this.handleInstructionChange2Explanation.bind(
                  this,
                  i
                )}
                value={exam.instruction}
              />
            </Form.Field>
            <Form.Field>
              <h4 style={{ marginBottom: "10px" }}>Questions </h4>
              <Editor
                init={TINY_MCE_SETTINGS.SETTINGS_WITH_BLANK}
                onEditorChange={this.handleQuestionChange2Explanation.bind(
                  this,
                  i
                )}
                value={exam.questions}
              />
            </Form.Field>
          </Segment>
        );
      } else if (exam.exam_type === EXAM.READ_RADIO_MCQ) {
        return (
          <Segment style={{ marginTop: "3em" }} key={`segment${i}`}>
            <Form.Field inline>
              <h4 style={{ marginBottom: "10px" }}>{`Section ${i + 1}`}</h4>
            </Form.Field>
            <Form.Field inline>
              <h4 style={{ marginBottom: "10px" }}>Instructions </h4>
              <Editor
                init={TINY_MCE_SETTINGS.SETTINGS_NO_BLANK}
                onEditorChange={this.handleInstructionChange2Explanation.bind(
                  this,
                  i
                )}
                value={exam.instruction}
              />
            </Form.Field>
            <Form.Field>
              <h4 style={{ marginBottom: "10px" }}>Questions</h4>
              <TextArea
                placeholder="Enter questions here"
                style={{ minHeight: 100 }}
                onChange={this.handleQuestionChangeExplanation.bind(this, i)}
                defaultValue={JSON.stringify(exam.questions)}
              />
            </Form.Field>
            <Form.Field>
              <Button
                type="button"
                onClick={this.openQuestionEditor.bind(this, true, i)}
                primary
                icon
                size="tiny"
              >
                <Icon name="edit" /> Edit Questions
              </Button>
            </Form.Field>
          </Segment>
        );
      } else if (exam.exam_type === EXAM.READ_SENTENCE_COMPLETION) {
        return (
          <Segment style={{ marginTop: "3em" }} key={`segment${i}`}>
            <Form.Field inline>
              <h4 style={{ marginBottom: "10px" }}>{`Section ${i + 1}`}</h4>
            </Form.Field>
            <Form.Field inline>
              <h4 style={{ marginBottom: "10px" }}>Instructions </h4>
              <Editor
                init={TINY_MCE_SETTINGS.SETTINGS_NO_BLANK}
                onEditorChange={this.handleInstructionChange2Explanation.bind(
                  this,
                  i
                )}
                value={exam.instruction}
              />
            </Form.Field>
            <Form.Field>
              <h4 style={{ marginBottom: "10px" }}>Questions</h4>
              <TextArea
                placeholder="Enter questions here"
                style={{ minHeight: 100 }}
                onChange={this.handleQuestionChangeExplanation.bind(this, i)}
                defaultValue={JSON.stringify(exam.questions)}
              />
            </Form.Field>
            <Form.Field>
              <Button
                type="button"
                onClick={this.openQuestionEditor.bind(this, true, i)}
                primary
                icon
                size="tiny"
              >
                <Icon name="edit" /> Edit Questions
              </Button>
            </Form.Field>
          </Segment>
        );
      } else if (exam.exam_type === EXAM.READ_SHORT_ANSWERS) {
        return (
          <Segment style={{ marginTop: "3em" }} key={`segment${i}`}>
            <Form.Field inline>
              <h4 style={{ marginBottom: "10px" }}>{`Section ${i + 1}`}</h4>
            </Form.Field>
            <Form.Field inline>
              <h4 style={{ marginBottom: "10px" }}>Instructions </h4>
              <Editor
                init={TINY_MCE_SETTINGS.SETTINGS_NO_BLANK}
                onEditorChange={this.handleInstructionChange2Explanation.bind(
                  this,
                  i
                )}
                value={exam.instruction}
              />
            </Form.Field>
            <Form.Field>
              <h4 style={{ marginBottom: "10px" }}>Questions</h4>
              <TextArea
                placeholder="Enter questions here"
                style={{ minHeight: 100 }}
                onChange={this.handleQuestionChangeExplanation.bind(this, i)}
                defaultValue={JSON.stringify(exam.questions)}
              />
            </Form.Field>
            <Form.Field>
              <Button
                type="button"
                onClick={this.openQuestionEditor.bind(this, true, i)}
                primary
                icon
                size="tiny"
              >
                <Icon name="edit" /> Edit Questions
              </Button>
            </Form.Field>
          </Segment>
        );
      } else if (exam.exam_type === EXAM.READ_RADIO_CHECKBOX) {
        return (
          <Segment style={{ marginTop: "3em" }} key={`segment${i}`}>
            <Form.Field inline>
              <h4 style={{ marginBottom: "10px" }}>{`Section ${i + 1}`}</h4>
            </Form.Field>
            <Form.Field inline>
              <h4 style={{ marginBottom: "10px" }}>Instructions </h4>
              <Editor
                init={TINY_MCE_SETTINGS.SETTINGS_NO_BLANK}
                onEditorChange={this.handleInstructionChange2Explanation.bind(
                  this,
                  i
                )}
                value={exam.instruction}
              />
            </Form.Field>
            <Form.Field>
              <h4 style={{ marginBottom: "10px" }}>Questions</h4>
              <TextArea
                placeholder="Enter questions here"
                style={{ minHeight: 100 }}
                onChange={this.handleQuestionChangeExplanation.bind(this, i)}
                defaultValue={JSON.stringify(exam.questions)}
              />
            </Form.Field>
            <Form.Field>
              <Button
                type="button"
                onClick={this.openQuestionEditor.bind(this, true, i)}
                primary
                icon
                size="tiny"
              >
                <Icon name="edit" /> Edit Questions
              </Button>
            </Form.Field>
          </Segment>
        );
      }
      return null;
    });

    const panes2 = [
      {
        menuItem: "General & Text",
        render: () => {
          return (
            <Tab.Pane>
              <Form.Field inline>
                <h4 style={{ marginBottom: "10px", marginTop: "1rem" }}>
                  Exam Type{" "}
                </h4>
                <Select
                  required
                  value={data.exam_type}
                  name="exam_type"
                  placeholder="Select Exam Type"
                  options={exam_type_options}
                  onChange={this.handleExamTypeChange}
                />
              </Form.Field>
              <br />
              <Form.Field inline>
                <h4 style={{ marginBottom: "10px" }}>Exam Part </h4>
                <Select
                  required
                  value={data.part}
                  name="part"
                  placeholder="Select Exam Part"
                  options={subtype_options}
                  onChange={this.handleExamPartChange}
                />
              </Form.Field>
              <br />
              <Form.Field inline>
                <h4 style={{ marginBottom: "10px" }}>Code </h4>
                <Input
                  required
                  width={4}
                  fluid
                  name="code"
                  onChange={this.handleChange.bind(this)}
                  value={data.code}
                  placeholder="Exam Code"
                />
              </Form.Field>
              <br />
              <Form.Field>
                <h4 style={{ display: "inline-block", width: "100%" }}>
                  Instruction
                  <Button
                    type="button"
                    primary
                    style={{ float: "right" }}
                    onClick={this.copyInstructionToExplanation}
                    size="tiny"
                  >
                    Copy to Explanation
                  </Button>
                </h4>
                <Editor
                  init={TINY_MCE_SETTINGS.SETTINGS_NO_BLANK}
                  value={data.instruction}
                  onEditorChange={this.handleInstructionChange.bind(this)}
                />
              </Form.Field>
              <br />
              <Form.Field>
                <h4 style={{ display: "inline-block", width: "100%" }}>
                  Text
                  <Button
                    type="button"
                    primary
                    style={{ float: "right" }}
                    onClick={this.copyTextToExplanation}
                    size="tiny"
                  >
                    Copy to Explanation
                  </Button>
                </h4>
                <Editor
                  init={TINY_MCE_SETTINGS.SETTINGS_NO_BLANK}
                  value={data.content}
                  onEditorChange={this.handleContentChange.bind(this)}
                />
              </Form.Field>
              <br />
            </Tab.Pane>
          );
        },
      },
      {
        menuItem: "Questions",
        render: () => {
          return (
            <Tab.Pane>
              <Form.Field inline>
                <Grid.Row>
                  <Button
                    onClick={this.addSection}
                    type="button"
                    color="green"
                    floated="right"
                    size="tiny"
                  >
                    <Icon name="plus" /> Add Section
                  </Button>
                </Grid.Row>
              </Form.Field>
              {exams}
            </Tab.Pane>
          );
        },
      },
      {
        menuItem: "Explanations",
        render: () => {
          return (
            <Tab.Pane>
              <br />
              <Form.Field>
                <h4>Instruction </h4>
                <Editor
                  init={TINY_MCE_SETTINGS.SETTINGS_NO_BLANK}
                  value={data.instruction_explanation}
                  onEditorChange={this.handleInstructionExplanationChange.bind(
                    this
                  )}
                />
              </Form.Field>
              <br />
              <Form.Field>
                <h4>Text </h4>
                <Editor
                  init={TINY_MCE_SETTINGS.SETTINGS_NO_BLANK}
                  value={data.content_explanation}
                  onEditorChange={this.handleContentExplanationChange.bind(
                    this
                  )}
                />
              </Form.Field>
              <br />
              {exams_explanation}
            </Tab.Pane>
          );
        },
      },
      {
        menuItem: "Hints",
        render: () => {
          return (
            <Tab.Pane>
              <Form.Field>
                <h4>Hints in Learning </h4>
                <Editor
                  required
                  init={TINY_MCE_SETTINGS.SETTINGS_NO_BLANK}
                  value={data.hint}
                  onEditorChange={this.handleHintChange.bind(this)}
                />
              </Form.Field>
              <br />
              <Form.Field>
                <h4>Hints Table in Review</h4>
                <Editor
                  required
                  init={TINY_MCE_SETTINGS.SETTINGS_NO_BLANK}
                  value={data.hint_review}
                  onEditorChange={this.handleHintReviewChange.bind(this)}
                />
              </Form.Field>
              <br />
            </Tab.Pane>
          );
        },
      },
    ];

    return (
      <React.Fragment>
        <QuestionEditor {...this.state} {...this.props} />
        <Grid
          doubling
          stackable
          padded
          className="padding-top-1rem padding-bottom-2rem"
        >
          <Confirm
            open={open}
            content="Do you want to proceed with the submission?"
            onCancel={this.close}
            onConfirm={this.handleConfirm}
          />
          <Parts.Header />
          <Grid.Row style={{ paddingBottom: "0px" }}>
            <Grid.Column>
              <Breadcrumb>
                <Breadcrumb.Section>
                  <Link to="/">Home</Link>
                </Breadcrumb.Section>
                <Breadcrumb.Divider icon="right angle" />
                <Breadcrumb.Section>Create Reading Exam</Breadcrumb.Section>
              </Breadcrumb>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={"equal"} className="footer-margin">
            <Loader />
            <Grid.Column>
              <Container>
                <Segment style={{ paddingBottom: "0px!important" }}>
                  <Form onSubmit={this.handleSubmit}>
                    <Tab className="overflow-y-auto" panes={panes2} />
                    <Form.Field className="text-center margin-top-half-rem">
                      <Button type="submit" color="green" size="tiny">
                        Finish Exam
                      </Button>
                      &nbsp;
                      <Button
                        onClick={this.showPreview}
                        type="button"
                        color="orange"
                        size="tiny"
                      >
                        Preview Exam
                      </Button>
                      &nbsp;
                      <Button
                        onClick={this.resetForm}
                        type="button"
                        color="red"
                        size="tiny"
                      >
                        Reset
                      </Button>
                    </Form.Field>
                  </Form>
                  <br />
                  <br />
                </Segment>
              </Container>
            </Grid.Column>
            <Grid.Column>
              <Segment>
                <Tab className="overflow-y-auto" panes={panes} />
              </Segment>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    readingBuilder: state.readingBuilder,
  };
};

export default withRouter(connect(mapStateToProps)(ReadBuilder));
