import * as app from '../types/app';

export const updateStartExam = (payload) => {
  return dispatch => {
    dispatch({
      type    : app.UPDATE_START_EXAM,
      payload : payload,
    });
  };
};

export const updateOpenSidebar = (payload) => {
  return dispatch => {
    dispatch({
      type    : app.UPDATE_OPEN_SIDEBAR,
      payload : payload,
    });
  };
};