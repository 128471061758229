import React from "react";
import { Form } from "semantic-ui-react";
import Instruction from "./Instruction";

const Matching = (props) => {
  const {
    questions,
    start_number,
    handleOnChange,
    parentIndex,
    answers,
    answers_strat,
    active_index,
    strat_mode,
    size,
    correct_answer,
    explanation,
  } = props;
  const blanks = questions.map((question, i) => {
    let value = "";
    if (answers.length > 0 && answers_strat.length > 0) {
      value = answers[active_index][parentIndex][i];
      if (strat_mode) {
        value = answers_strat[active_index][parentIndex][i];
      }
    }
    return (
      <Form.Group inline key={"question" + i}>
        <p className="no-margin-top-bottom">
          <span
            dangerouslySetInnerHTML={{
              __html: start_number + i + ". " + question,
            }}
          ></span>
          &nbsp;
          <input
            size={size ? (size[i] ? size[i] : 10) : 10}
            onChange={handleOnChange.bind(this, parentIndex, i)}
            key={"matchinginput" + parentIndex + i}
            className="blank2"
            type="text"
            value={
              explanation ? correct_answer[i]?.replace(/{n}/g, " / ") : value
            }
          />
        </p>
      </Form.Group>
    );
  });
  return (
    <React.Fragment>
      <Instruction {...props} />
      <Form className="read-matching">{blanks}</Form>
    </React.Fragment>
  );
};

export default Matching;
