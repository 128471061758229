export const LISTEN_EXAM_UPDATE_TIMER = 'LISTEN_EXAM_UPDATE_TIMER';
export const LISTEN_EXAM_RESET_LISTEN_EXAM = 'LISTEN_EXAM_RESET_LISTEN_EXAM';
export const LISTEN_EXAM_RESET_TIMER = 'LISTEN_EXAM_RESET_TIMER';
export const LISTEN_EXAM_UPDATE_ACTIVE_INDEX = 'LISTEN_EXAM_UPDATE_ACTIVE_INDEX';
export const LISTEN_EXAM_UPDATE_STRAT_MODE = 'LISTEN_EXAM_UPDATE_STRAT_MODE';
export const LISTEN_EXAM_UPDATE_CONTENT = 'LISTEN_EXAM_UPDATE_CONTENT';
export const LISTEN_EXAM_UPDATE_ANSWERS = 'LISTEN_EXAM_UPDATE_ANSWERS';
export const LISTEN_EXAM_UPDATE_ANSWERS_STRAT = 'LISTEN_EXAM_UPDATE_ANSWERS_STRAT';
export const LISTEN_EXAM_UPDATE_POSITION = 'LISTEN_EXAM_UPDATE_POSITION';
export const LISTEN_EXAM_RESET_POSITION = 'LISTEN_EXAM_RESET_POSITION';
export const LISTEN_EXAM_SET_COUNTDOWN_STARTED = 'LISTEN_EXAM_SET_COUNTDOWN_STARTED';
export const LISTEN_EXAM_UPDATE_CURRENT_INDEX = 'LISTEN_EXAM_UPDATE_CURRENT_INDEX';
export const LISTEN_EXAM_UPDATE_AUDIO_INDEX = 'LISTEN_EXAM_UPDATE_AUDIO_INDEX';