import React from "react";
import { Form, Checkbox, Accordion, Icon } from "semantic-ui-react";
import Instruction from "./Instruction";

const McqCheckbox = (props) => {
  const {
    questions,
    start_number,
    parentIndex,
    handleCheckboxOnChange,
    answers,
    current_index,
    explanation,
    correct_answer,
    onAccordionClick,
    accordionIndex,
  } = props;
  const aIndex = accordionIndex.index;
  const pIndex = accordionIndex.parentIndex;
  const blanks = questions.map((obj, i) => {
    let value = [];
    if (answers.length > 0) {
      value = answers[current_index][parentIndex][i].split(",");
    }
    let text = obj.text.replace(/<\/br>/g, "");
    text = text.replace(/<br>/g, "");
    text = text.replace(/<b\/r>/g, "");
    return (
      <React.Fragment key={"question" + i}>
        <Accordion.Title
          active
          onClick={onAccordionClick.bind(this, parentIndex, i)}
          index={i}
        >
          <Icon name="dropdown" />
          {obj.number ? obj.number + ". " : start_number + i + ". "}
          <span dangerouslySetInnerHTML={{ __html: text }} />
        </Accordion.Title>
        <Accordion.Content active style={{ marginLeft: "1.5rem" }}>
          {obj.choices.map((choice, j) => {
            const answer = j + 1;
            return (
              <Form.Field
                inline
                key={"choice" + j}
                style={{ lineHeight: "17px" }}
              >
                <Checkbox
                  style={{ paddingTop: "3px" }}
                  name={"chkboxGroup" + i}
                  onClick={handleCheckboxOnChange.bind(
                    this,
                    parentIndex,
                    i,
                    j + 1,
                    answers.length > 0 &&
                      answers[current_index][parentIndex][i].indexOf(j + 1) ===
                        -1
                  )}
                  key={`readcheckboxmcq${parentIndex}${i}`}
                  checked={
                    explanation
                      ? correct_answer[i].indexOf(j + 1) !== -1
                      : value.indexOf(answer.toString()) !== -1
                  }
                />
                &nbsp;&nbsp;
                <span
                  className="cursor-pointer"
                  onClick={handleCheckboxOnChange.bind(
                    this,
                    parentIndex,
                    i,
                    j + 1,
                    answers.length > 0 &&
                      answers[current_index][parentIndex][i].indexOf(j + 1) ===
                        -1
                  )}
                  dangerouslySetInnerHTML={{ __html: choice }}
                />
              </Form.Field>
            );
          })}
        </Accordion.Content>
      </React.Fragment>
    );
  });
  return (
    <React.Fragment>
      <Instruction {...props} />
      <Accordion
        styled
        fluid
        style={{ width: "98%", borderLeft: "rgba(0,0,0,.4) solid 1px" }}
        exclusive={false}
      >
        {blanks}
      </Accordion>
    </React.Fragment>
  );
};

export default McqCheckbox;
