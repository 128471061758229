import React from "react";
import { connect } from "react-redux";
import {
  Button,
  Form,
  Grid,
  Header,
  Message,
  Segment,
} from "semantic-ui-react";
import * as apiActions from "../../actions/app/api";
import * as loginActions from "../../actions/app/login";
import { Link, Redirect } from "react-router-dom";
import Loader from "./Loader";

import * as apiRequests from "../../actions/api/requests";
import * as URLS from "../../constants/urls";
import queryString from "query-string";
import "../../includes/css/old.css";
import "semantic-ui-css/semantic.min.css";

const EmailVerified = () => (
  <Message positive>
    <Message.Header>Success</Message.Header>
    <p>Your email has now been successfully verified. You may now log in.</p>
  </Message>
);

class NormalLoginForm extends React.Component {
  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(apiActions.updateApiSending(false));
    const values = queryString.parse(this.props.location.search);
    if (values.email_success) {
      this.setState({ email_success: true });
    }
  }
  handleChange = (e) => {
    const { user, dispatch } = this.props;
    user[e.target.name] = e.target.value;
    dispatch(loginActions.updateLoginFields(user));
  };
  handleSubmit = (e) => {
    const { dispatch, user, history } = this.props;
    dispatch(apiActions.updateApiSending(true));
    dispatch(apiRequests.login(user, history));
  };
  state = {
    email_success: false,
  };
  render() {
    const { username, password } = this.props.user;
    if (this.props.is_authenticated) {
      return <Redirect to={{ pathname: "/dashboard" }} />;
    }
    return (
      <Segment>
        <Loader />
        <Grid textAlign="center" className="login-bg" verticalAlign="middle">
          <Grid.Column style={{ maxWidth: 450 }}>
            {this.state.email_success ? (
              <div className="text-left">
                <EmailVerified />
              </div>
            ) : null}
            <Header as="h2" color="orange" textAlign="center">
              Log-in to your account
            </Header>
            <Form size="large" onSubmit={this.handleSubmit}>
              <Segment stacked>
                <Form.Input
                  fluid
                  icon="user"
                  iconPosition="left"
                  placeholder="Username"
                  name="username"
                  onChange={this.handleChange.bind(this)}
                  defaultValue={username}
                  required={true}
                />
                <Form.Input
                  fluid
                  icon="lock"
                  iconPosition="left"
                  placeholder="Password"
                  type="password"
                  name="password"
                  onChange={this.handleChange.bind(this)}
                  defaultValue={password}
                  required={true}
                />
                <Button color="orange" fluid size="large" type="submit">
                  Login
                </Button>
              </Segment>
            </Form>
            <Message>
              New to us? <Link to="signup">Sign Up</Link>
              {/* <br />
              Forgot password?
              {
                // eslint-disable-next-line
                <span>&nbsp;Reset Now</span>
              } */}
            </Message>
          </Grid.Column>
        </Grid>
      </Segment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.login,
    is_authenticated: state.auth.is_authenticated,
  };
};

export default connect(mapStateToProps)(NormalLoginForm);
