import { combineReducers } from "redux";
import answerHistory from "./answerHistory";
import answerInstance from "./answerInstance";
import api from "./api";
import app from "./app";
import auth from "./auth";
import examCount from "./examCount";
import listenExam from "./listenExam";
import listeningBuilder from "./listeningBuilder";
import listeningModal from "./listeningModal";
import listeningTable from "./listeningTable";
import listenReview from "./listenReview";
import login from "./login";
import marketing from "./marketing";
import mockExamTable from "./mockExamTable";
import mockTest from "./mockTest";
import nonStudent from "./nonStudent";
import nonStudentList from "./nonStudentList";
import profile from "./profile";
import readExam from "./readExam";
import readingBuilder from "./readingBuilder";
import readingTable from "./readingTable";
import readReview from "./readReview";
import signup from "./signup";
import speakExam from "./speakExam";
import speakReview from "./speakReview";
import speakingBuilder from "./speakingBuilder";
import speakPart1 from "./speakPart1";
import speakPart2 from "./speakPart2";
import speakPart3 from "./speakPart3";
import speakingTable from "./speakingTable";
import studentIPs from "./studentIPs";
import writeExam from "./writeExam";
import writeReview from "./writeReview";
import writingBuilder from "./writingBuilder";
import writingTable from "./writingTable";

export default combineReducers({
  answerHistory,
  answerInstance,
  api,
  app,
  auth,
  examCount,
  listenExam,
  listeningBuilder,
  listeningModal,
  listeningTable,
  listenReview,
  login,
  marketing,
  mockExamTable,
  mockTest,
  nonStudent,
  nonStudentList,
  profile,
  readExam,
  readingBuilder,
  readingTable,
  readReview,
  signup,
  speakExam,
  speakReview,
  speakingBuilder,
  speakPart1,
  speakPart2,
  speakPart3,
  speakingTable,
  studentIPs,
  writeExam,
  writeReview,
  writingBuilder,
  writingTable,
});
