import React from 'react';

/* eslint-disable */
const Sidebar = (props) => {
  const {
    openSidebar,
    show,
    content,
    active_index,
    strat_mode,
    updateActiveIndex,
  } = props;
  const nav_menus = content.map((exam, i) => {
    return (
      <a key={`menuitem${i}`} onClick={updateActiveIndex.bind(this, i)} className={active_index===i && !strat_mode?"active":""}>{`${i+1}) ${exam.code}`}</a>
    );
  });
  return (
    <React.Fragment>
      <div id="exam-overlay" onClick={props.closeNav} style={openSidebar?{width:"100%"}:null}></div>
      <div id="exam-side-nav" className="exam-side-nav r-light" style={openSidebar?{width:"415px"}:null}>
        <div id="exam-side-nav-close" className={"exam-side-nav-close " + show}>
          <a className="closebtn" onClick={props.closeNav}>&times;</a>
        </div>
        <div id="exam-side-nav-list" className={"exam-side-nav-list " + show}>
          {nav_menus}
        </div>
      </div>
    </React.Fragment>
  );
}

export default Sidebar;