import React from "react";
import { connect } from "react-redux";
import { Grid, Progress, Segment, Icon, Button } from "semantic-ui-react";
import { ReactMic } from "react-mic";
import * as speakPart1Actions from "../../../../../../actions/app/speakPart1";
import * as apiActions from "../../../../../../actions/app/api";
import ReactPlayer from "react-player";

function pad(string) {
  return ("0" + string).slice(-2);
}

function format(seconds) {
  const date = new Date(seconds * 1000);
  const mm = date.getUTCMinutes();
  const ss = pad(date.getUTCSeconds());
  return `${mm}:${ss}`;
}

class SpeakAdmin extends React.Component {
  componentDidMount() {
    const { dispatch, strat_mode } = this.props;
    if (!strat_mode) {
      dispatch(speakPart1Actions.reset());
    }
    dispatch(apiActions.updateApiSending(false));
  }
  componentWillUnmount() {
    this.clearTimer();
    const { dispatch, strat_mode } = this.props;
    if (!strat_mode) {
      dispatch(speakPart1Actions.reset());
    }
  }
  clearTimer() {
    clearInterval(this.timer);
    this.timer = null;
  }
  onStop = () => {
    this.props.startRecording();
    this.props.dispatch(speakPart1Actions.updatePlaying(false));
    this.props.dispatch(speakPart1Actions.updateRecording(true));
    this.timer = setInterval(this.tick.bind(this), 1000);
  };
  tick = () => {
    const { timer, dispatch } = this.props;
    const newTimer = timer - 1;
    if (newTimer >= 0) {
      dispatch(speakPart1Actions.updateTimer(newTimer));
    } else {
      this.stopRecording();
    }
  };
  stopRecording = () => {
    this.clearTimer();
    const { recording, dispatch } = this.props;
    dispatch(apiActions.updateApiSending(true));
    dispatch(speakPart1Actions.updateRecording(false));
    if (recording) {
      this.props.stopRecording();
    } else {
      this.props.updateAnswer("");
    }
  };
  state = {
    playing2: false,
  };
  stopPlaying2 = () => {
    this.setState({ playing2: false });
  };
  render() {
    const { timer, recording, admin_q, admin_q_index } = this.props;
    const { playing2 } = this.state;
    const url = admin_q[admin_q_index];
    let record_percent = 100 - (timer / 45) * 100;
    return (
      <center>
        <Segment className="speaking-title">Administrative Questions</Segment>
        <ReactPlayer
          key={"player1"}
          className="invisible"
          url={url}
          playing={true}
          onEnded={this.onStop}
        />
        <ReactPlayer
          key={"player2"}
          className="invisible"
          url={url}
          playing={playing2}
          onEnded={this.stopPlaying2}
        />
        <Grid
          className="speaking-container"
          columns={"equal"}
          style={{ margin: ".5rem", width: "50%" }}
        >
          <Grid.Column
            className="content-speaking-rec"
            style={{ margin: ".5rem" }}
          >
            <div
              className="paper-box"
              style={{
                marginBottom: "2rem",
                height: "182px",
                paddingBottom: "0px",
                overflowY: "hidden",
              }}
            >
              <div style={{ height: "65%" }}>
                <h4
                  style={{
                    marginTop: "1rem",
                    marginLeft: "1rem",
                    textAlign: "left",
                  }}
                >
                  Examiner's Question
                </h4>
                {!recording || playing2 ? (
                  <React.Fragment>
                    <Icon
                      color={"green"}
                      name={"user"}
                      className="interviewer-icon"
                    />
                    <Icon
                      color={"green"}
                      name={"comment alternate"}
                      className="interviewer-icon"
                    />
                  </React.Fragment>
                ) : (
                  <Icon
                    color={"red"}
                    name={"user cancel"}
                    className="interviewer-icon"
                  />
                )}
              </div>
              <div className="recorder-bottom text-center"></div>
            </div>
            <p className="text-center">
              {recording
                ? "Recording your answer"
                : "Please answer the examiner's question. Your response is being recorded. When you've finished you can click the red stop button or the button \"Finish and Proceed\" below (blink as Recorder is recording)"}
            </p>
            <div
              className="paper-box"
              style={{
                marginTop: "3rem",
                height: "182px",
                paddingBottom: "0px",
                overflowY: "hidden",
              }}
            >
              <div style={{ height: "65%" }}>
                <h4
                  style={{
                    marginTop: "1rem",
                    marginLeft: "1rem",
                    textAlign: "left",
                  }}
                >
                  Audio Recorder
                </h4>
                <ReactMic
                  key={"microphone"}
                  record={recording}
                  className="sound-wave"
                  backgroundColor="#F5F8FF"
                />
              </div>
              <div className="recorder-bottom text-center">
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    style={{
                      marginTop: "7px",
                      marginLeft: "1rem",
                      marginRight: "1rem",
                    }}
                  >
                    <Button
                      disabled={!recording}
                      onClick={this.stopRecording.bind(this)}
                      circular
                      className={recording ? "blinking" : ""}
                      icon={recording ? "stop" : "circle"}
                      style={{ color: "red" }}
                    />
                  </div>
                  <Progress
                    className="progress-bar"
                    percent={record_percent}
                    size="small"
                    style={{
                      flexGrow: "1",
                      marginTop: "1.46rem",
                      backgroundColor: "#DDDDDD",
                    }}
                  />
                  <div
                    style={{
                      float: "right",
                      marginLeft: "1rem",
                      marginRight: "1rem",
                      marginTop: ".1rem",
                    }}
                  >
                    <p>{format(timer)}</p>
                  </div>
                </div>
              </div>
            </div>
          </Grid.Column>
        </Grid>
        <button
          className={"speaking-stop-button"}
          onClick={this.props.finishSpeaking.bind(this)}
        >
          Finish Speaking Test Early And Proceed To Instructions To Get Results
        </button>
      </center>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    content: state.speakExam.content,
    active_index: state.speakExam.active_index,
    answers: state.speakExam.answers,
    answers_strat: state.speakExam.answers_strat,
    current_subtype: state.speakExam.current_subtype,
    strat_mode: false,
    timer: state.speakPart1.timer,
    playing: state.speakPart1.playing,
    recording: state.speakPart1.recording,
    strat_url: state.speakPart1.strat_url,
    strat_file: state.speakPart1.strat_file,
    admin_q: state.mockTest.content.admin_questions,
    admin_q_mode: state.speakExam.admin_q_mode,
    admin_q_index: state.speakExam.admin_q_index,
  };
};

export default connect(mapStateToProps)(SpeakAdmin);
