import React from "react";
import {
  Grid,
  Container,
  Form,
  Button,
  Input,
  Select,
  Confirm,
  Breadcrumb,
  Segment,
} from "semantic-ui-react";
import { connect } from "react-redux";
import { Editor } from "@tinymce/tinymce-react";
import * as apiActions from "../../actions/app/api";
import * as speakExamActions from "../../actions/app/speakExam";
import * as api from "../../actions/api/requests";
import * as SUBTYPES from "../../constants/exam_subtypes";
import Loader from "../dashboard/parts/Loader";
import { withRouter, Link } from "react-router-dom";
import * as Parts from "../dashboard/parts";
import * as TINY_MCE_SETTINGS from "../../constants/tinyMceSettings";
import queryString from "query-string";

const subtype_options = [
  {
    key: SUBTYPES.SPEAK_PART_1_VALUE,
    value: SUBTYPES.SPEAK_PART_1_VALUE,
    text: SUBTYPES.SPEAK_PART_1_TEXT,
  },
  {
    key: SUBTYPES.SPEAK_PART_2_VALUE,
    value: SUBTYPES.SPEAK_PART_2_VALUE,
    text: SUBTYPES.SPEAK_PART_2_TEXT,
  },
  {
    key: SUBTYPES.SPEAK_PART_3_VALUE,
    value: SUBTYPES.SPEAK_PART_3_VALUE,
    text: SUBTYPES.SPEAK_PART_3_TEXT,
  },
];

class SpeakingEditor extends React.Component {
  state = {
    open: false,
    open2: false,
    speakingBuilder: {
      code: "",
      part: "SpeakPart1",
      audio: ["", "", "", ""],
      vocab: "",
      vocab2: "",
      vocab3: "",
      vocab4: "",
      model_answer: "",
      question: "",
      subtype: "",
      hint: "",
    },
  };
  componentDidMount() {
    this.props.dispatch(apiActions.updateApiSending(false));
    const values = queryString.parse(this.props.location.search);
    let content = this.props.content.find(
      (content) => content.id === values.edit_id
    );
    this.setState({ speakingBuilder: content });
  }
  open = () => {
    this.setState({ open: true });
  };
  close = () => {
    this.setState({ open: false });
  };
  open2 = () => {
    this.setState({ open2: true });
  };
  close2 = () => {
    this.setState({ open2: false });
  };
  handleExamPartChange = (e, component) => {
    this.update(component.value, "part");
  };
  handleCodeChange = (e, value) => {
    console.log(value);
    // this.update(value, "code");
  };
  handleVocabChange = (value, editor) => {
    this.update(value, "vocab");
  };
  handleVocab2Change = (value, editor) => {
    this.update(value, "vocab2");
  };
  handleVocab3Change = (value, editor) => {
    this.update(value, "vocab3");
  };
  handleVocab4Change = (value, editor) => {
    this.update(value, "vocab4");
  };
  handleModelAnswerChange = (value, editor) => {
    this.update(value, "model_answer");
  };
  handleModelAnswerChange2 = (value, editor) => {
    this.update(value, "model_answer2");
  };
  handleModelAnswerChange3 = (value, editor) => {
    this.update(value, "model_answer3");
  };
  handleModelAnswerChange4 = (value, editor) => {
    this.update(value, "model_answer4");
  };
  handleQuestionInputChange = (value, editor) => {
    this.update(value, "question");
  };
  handleChange = (e) => {
    this.update(e.target.value, e.target.name);
  };
  handleQuestionChange = (i, e) => {
    const data = { ...this.state.speakingBuilder };
    data.audio[i] = e.target.value;
    this.setState({ speakingBuilder: data });
  };
  update(value, name) {
    const data = { ...this.state.speakingBuilder };
    data[name] = value;
    this.setState({ speakingBuilder: data });
  }
  handleSubmit = (e) => {
    e.preventDefault();
    this.open();
  };
  handleConfirm = () => {
    const data = { ...this.state.speakingBuilder };
    const { dispatch } = this.props;
    dispatch(api.updateSpeakingExam(data, this.props.history));
    dispatch(apiActions.updateApiSending(true));
    this.close();
  };
  handleConfirm2 = () => {
    const data = { ...this.state.speakingBuilder };
    delete data["id"];
    data["code"] = data["code"] + " (Copy)";
    const { dispatch } = this.props;
    dispatch(apiActions.updateApiSending(true));
    dispatch(api.createSpeakingExamWithHistory(data, this.props.history));
    this.close2();
  };
  showPreview = () => {
    this.props.dispatch(speakExamActions.reset());
    this.props.dispatch(
      speakExamActions.updateContent([{ ...this.state.speakingBuilder }])
    );
    window.open("/exam/speak");
  };
  goToDashboard = () => {
    this.props.history.push("/");
  };
  handleClick = () => {
    const form = document.getElementsByTagName("form")[0];
    const valid = form.reportValidity();
    if (valid) {
      this.open2();
    }
  };
  render() {
    const data = { ...this.state.speakingBuilder };
    const { open, open2 } = this.state;

    const question_players = [];

    data.audio.forEach((url, i) => {
      question_players.push(
        <h5 key={"h5title" + i}>{`Audio URL ${i + 1}`}</h5>
      );
      question_players.push(
        <audio
          key={"questionurl" + i}
          controls
          src={url}
          controlsList="nodownload"
        />
      );
    });

    return (
      <React.Fragment>
        <Grid
          doubling
          stackable
          padded
          className="padding-top-1rem padding-bottom-2rem"
        >
          <Confirm
            open={open}
            content="Do you want to proceed with the submission?"
            onCancel={this.close}
            onConfirm={this.handleConfirm}
          />
          <Confirm
            open={open2}
            content="Do you want to proceed with the creation of a new test?"
            onCancel={this.close2}
            onConfirm={this.handleConfirm2}
          />
          <Parts.Header />
          <Grid.Row style={{ paddingBottom: "0px" }}>
            <Grid.Column>
              <Breadcrumb>
                <Breadcrumb.Section>
                  <Link to="/">Home</Link>
                </Breadcrumb.Section>
                <Breadcrumb.Divider icon="right angle" />
                <Breadcrumb.Section>Create Speaking Exam</Breadcrumb.Section>
              </Breadcrumb>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={"equal"} className="footer-margin-8">
            <Loader />
            <Grid.Column>
              <Container>
                <Segment
                  className={"overflow-y-auto"}
                  style={{ paddingBottom: "0px!important" }}
                >
                  <Form onSubmit={this.handleSubmit}>
                    <Form.Field inline>
                      <h4 style={{ marginBottom: "10px" }}>Exam Part </h4>
                      <Select
                        required
                        value={data.part}
                        name="part"
                        placeholder="Select Exam Part"
                        options={subtype_options}
                        onChange={this.handleExamPartChange}
                      />
                    </Form.Field>
                    <br />
                    <Form.Field inline>
                      <h4 style={{ marginBottom: "10px" }}>Code </h4>
                      <Input
                        required
                        width={4}
                        fluid
                        name="code"
                        onChange={this.handleChange.bind(this)}
                        value={data.code}
                        placeholder="Exam Code"
                      />
                    </Form.Field>
                    <br />
                    <Form.Field inline>
                      <h4 style={{ marginBottom: "10px" }}>Audio Questions </h4>
                      {data.part === SUBTYPES.SPEAK_PART_2_VALUE ? (
                        <React.Fragment>
                          <Input
                            required
                            fluid
                            type="url"
                            onChange={this.handleQuestionChange.bind(this, 0)}
                            value={data.audio[0]}
                            placeholder="Audio Question One"
                          />
                          <Input
                            required
                            fluid
                            type="url"
                            onChange={this.handleQuestionChange.bind(this, 1)}
                            value={data.audio[1]}
                            placeholder="Audio Question Two"
                          />
                        </React.Fragment>
                      ) : data.part === SUBTYPES.SPEAK_PART_3_VALUE ? (
                        <React.Fragment>
                          <Input
                            required
                            fluid
                            type="url"
                            onChange={this.handleQuestionChange.bind(this, 0)}
                            value={data.audio[0]}
                          />
                          <Input
                            required
                            fluid
                            type="url"
                            onChange={this.handleQuestionChange.bind(this, 1)}
                            value={data.audio[1]}
                          />
                          <Input
                            required
                            fluid
                            type="url"
                            name="url3"
                            onChange={this.handleQuestionChange.bind(this, 2)}
                            value={data.audio[2]}
                          />
                        </React.Fragment>
                      ) : (
                        <React.Fragment>
                          <Input
                            required
                            fluid
                            type="url1"
                            onChange={this.handleQuestionChange.bind(this, 0)}
                            value={data.audio[0]}
                            placeholder="Question a"
                          />
                          <Input
                            required
                            fluid
                            type="url"
                            name="url2"
                            onChange={this.handleQuestionChange.bind(this, 1)}
                            value={data.audio[1]}
                            placeholder="Question b"
                          />
                          <Input
                            required
                            fluid
                            type="url"
                            name="url3"
                            onChange={this.handleQuestionChange.bind(this, 2)}
                            value={data.audio[2]}
                            placeholder="Question c"
                          />
                          <Input
                            required={data.part !== SUBTYPES.SPEAK_PART_3_VALUE}
                            fluid
                            name="url4"
                            onChange={this.handleQuestionChange.bind(this, 3)}
                            value={data.audio[3]}
                            placeholder="Question d"
                          />
                        </React.Fragment>
                      )}
                    </Form.Field>
                    {data.part === SUBTYPES.SPEAK_PART_2_VALUE ? (
                      <React.Fragment>
                        <br />
                        <Form.Field>
                          <h4>Question </h4>
                          <Editor
                            init={TINY_MCE_SETTINGS.SETTINGS_NO_BLANK}
                            value={data.question}
                            onEditorChange={this.handleQuestionInputChange.bind(
                              this
                            )}
                          />
                        </Form.Field>
                      </React.Fragment>
                    ) : null}
                    {data.part === SUBTYPES.SPEAK_PART_2_VALUE ? (
                      <React.Fragment>
                        <br />
                        <Form.Field>
                          <h4>Model Answer </h4>
                          <Editor
                            init={TINY_MCE_SETTINGS.SETTINGS_NO_BLANK}
                            value={data.model_answer}
                            onEditorChange={this.handleModelAnswerChange.bind(
                              this
                            )}
                          />
                        </Form.Field>
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <br />
                        <Form.Field>
                          <h4>Model Answer 1</h4>
                          <Editor
                            init={TINY_MCE_SETTINGS.SETTINGS_NO_BLANK}
                            value={data.model_answer}
                            onEditorChange={this.handleModelAnswerChange.bind(
                              this
                            )}
                          />
                        </Form.Field>
                        <br />
                        <Form.Field>
                          <h4>Model Answer 2</h4>
                          <Editor
                            init={TINY_MCE_SETTINGS.SETTINGS_NO_BLANK}
                            value={data.model_answer2}
                            onEditorChange={this.handleModelAnswerChange2.bind(
                              this
                            )}
                          />
                        </Form.Field>
                        <br />
                        <Form.Field>
                          <h4>Model Answer 3</h4>
                          <Editor
                            init={TINY_MCE_SETTINGS.SETTINGS_NO_BLANK}
                            value={data.model_answer3}
                            onEditorChange={this.handleModelAnswerChange3.bind(
                              this
                            )}
                          />
                        </Form.Field>
                        <br />
                        <Form.Field>
                          <h4>Model Answer 4</h4>
                          <Editor
                            init={TINY_MCE_SETTINGS.SETTINGS_NO_BLANK}
                            value={data.model_answer4}
                            onEditorChange={this.handleModelAnswerChange4.bind(
                              this
                            )}
                          />
                        </Form.Field>
                      </React.Fragment>
                    )}
                    {data.part === SUBTYPES.SPEAK_PART_1_VALUE && (
                      <>
                        <br />
                        <Form.Field>
                          <h4>Vocab a</h4>
                          <Editor
                            required
                            init={TINY_MCE_SETTINGS.SETTINGS_NO_BLANK}
                            value={data.vocab}
                            onEditorChange={this.handleVocabChange.bind(this)}
                          />
                        </Form.Field>
                        <Form.Field>
                          <h4>Vocab b</h4>
                          <Editor
                            required
                            init={TINY_MCE_SETTINGS.SETTINGS_NO_BLANK}
                            value={data.vocab2 || ""}
                            onEditorChange={this.handleVocab2Change.bind(this)}
                          />
                        </Form.Field>
                        <Form.Field>
                          <h4>Vocab c</h4>
                          <Editor
                            required
                            init={TINY_MCE_SETTINGS.SETTINGS_NO_BLANK}
                            value={data.vocab3 || ""}
                            onEditorChange={this.handleVocab3Change.bind(this)}
                          />
                        </Form.Field>
                        <Form.Field>
                          <h4>Vocab d</h4>
                          <Editor
                            required
                            init={TINY_MCE_SETTINGS.SETTINGS_NO_BLANK}
                            value={data.vocab4 || ""}
                            onEditorChange={this.handleVocab4Change.bind(this)}
                          />
                        </Form.Field>
                      </>
                    )}
                    {data.part === SUBTYPES.SPEAK_PART_2_VALUE && (
                      <>
                        <br />
                        <Form.Field>
                          <h4>Vocab </h4>
                          <Editor
                            required
                            init={TINY_MCE_SETTINGS.SETTINGS_NO_BLANK}
                            value={data.vocab}
                            onEditorChange={this.handleVocabChange.bind(this)}
                          />
                        </Form.Field>
                      </>
                    )}
                    {data.part === SUBTYPES.SPEAK_PART_3_VALUE && (
                      <>
                        <br />
                        <Form.Field>
                          <h4>Vocab a</h4>
                          <Editor
                            required
                            init={TINY_MCE_SETTINGS.SETTINGS_NO_BLANK}
                            value={data.vocab}
                            onEditorChange={this.handleVocabChange.bind(this)}
                          />
                        </Form.Field>
                        <Form.Field>
                          <h4>Vocab b</h4>
                          <Editor
                            required
                            init={TINY_MCE_SETTINGS.SETTINGS_NO_BLANK}
                            value={data.vocab2 || ""}
                            onEditorChange={this.handleVocab2Change.bind(this)}
                          />
                        </Form.Field>
                        <Form.Field>
                          <h4>Vocab c</h4>
                          <Editor
                            required
                            init={TINY_MCE_SETTINGS.SETTINGS_NO_BLANK}
                            value={data.vocab3 || ""}
                            onEditorChange={this.handleVocab3Change.bind(this)}
                          />
                        </Form.Field>
                      </>
                    )}
                    <br />
                    <Form.Field className="text-center margin-top-25-impt">
                      <Button type="submit" color="green" size="tiny">
                        Finish Exam
                      </Button>
                      &nbsp;
                      <Button
                        type="button"
                        color="teal"
                        size="tiny"
                        onClick={this.handleClick}
                      >
                        Save As Another Exam
                      </Button>
                      &nbsp;
                      <Button
                        onClick={this.showPreview}
                        type="button"
                        color="orange"
                        size="tiny"
                      >
                        Preview Exam
                      </Button>
                    </Form.Field>
                  </Form>
                  <br />
                  <br />
                </Segment>
              </Container>
            </Grid.Column>
            <Grid.Column>
              <Container className="overflow-y-auto">
                <Segment style={{ backgroundColor: "#F5F8FF" }}>
                  <h4>Question URLs</h4>
                  {question_players}
                </Segment>
                {data.part === SUBTYPES.SPEAK_PART_2_VALUE ? (
                  <React.Fragment>
                    <Segment style={{ backgroundColor: "#F5F8FF" }}>
                      <h4>Question</h4>
                      <div
                        className="instruction"
                        dangerouslySetInnerHTML={{ __html: data.question }}
                      />
                    </Segment>
                    <Segment style={{ backgroundColor: "#F5F8FF" }}>
                      <h4>Model Answer</h4>
                      <div
                        className="instruction"
                        dangerouslySetInnerHTML={{ __html: data.model_answer }}
                      />
                    </Segment>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <Segment style={{ backgroundColor: "#F5F8FF" }}>
                      <h4>Model Answer 1</h4>
                      <div
                        className="instruction"
                        dangerouslySetInnerHTML={{ __html: data.model_answer }}
                      />
                    </Segment>
                    <Segment style={{ backgroundColor: "#F5F8FF" }}>
                      <h4>Model Answer 2</h4>
                      <div
                        className="instruction"
                        dangerouslySetInnerHTML={{ __html: data.model_answer2 }}
                      />
                    </Segment>
                    <Segment style={{ backgroundColor: "#F5F8FF" }}>
                      <h4>Model Answer 3</h4>
                      <div
                        className="instruction"
                        dangerouslySetInnerHTML={{ __html: data.model_answer3 }}
                      />
                    </Segment>
                    <Segment style={{ backgroundColor: "#F5F8FF" }}>
                      <h4>Model Answer 4</h4>
                      <div
                        className="instruction"
                        dangerouslySetInnerHTML={{ __html: data.model_answer4 }}
                      />
                    </Segment>
                  </React.Fragment>
                )}
                {data.part === SUBTYPES.SPEAK_PART_1_VALUE && (
                  <>
                    <Segment style={{ backgroundColor: "#F5F8FF" }}>
                      <h4>Vocab a</h4>
                      <div
                        className="instruction"
                        dangerouslySetInnerHTML={{ __html: data.vocab }}
                      />
                    </Segment>
                    <Segment style={{ backgroundColor: "#F5F8FF" }}>
                      <h4>Vocab b</h4>
                      <div
                        className="instruction"
                        dangerouslySetInnerHTML={{ __html: data.vocab2 || "" }}
                      />
                    </Segment>
                    <Segment style={{ backgroundColor: "#F5F8FF" }}>
                      <h4>Vocab c</h4>
                      <div
                        className="instruction"
                        dangerouslySetInnerHTML={{ __html: data.vocab3 || "" }}
                      />
                    </Segment>
                    <Segment style={{ backgroundColor: "#F5F8FF" }}>
                      <h4>Vocab d</h4>
                      <div
                        className="instruction"
                        dangerouslySetInnerHTML={{ __html: data.vocab4 || "" }}
                      />
                    </Segment>
                  </>
                )}
                {data.part === SUBTYPES.SPEAK_PART_2_VALUE && (
                  <>
                    <Segment style={{ backgroundColor: "#F5F8FF" }}>
                      <h4>Vocab</h4>
                      <div
                        className="instruction"
                        dangerouslySetInnerHTML={{ __html: data.vocab }}
                      />
                    </Segment>
                  </>
                )}
                {data.part === SUBTYPES.SPEAK_PART_3_VALUE && (
                  <>
                    <Segment style={{ backgroundColor: "#F5F8FF" }}>
                      <h4>Vocab a</h4>
                      <div
                        className="instruction"
                        dangerouslySetInnerHTML={{ __html: data.vocab }}
                      />
                    </Segment>
                    <Segment style={{ backgroundColor: "#F5F8FF" }}>
                      <h4>Vocab b</h4>
                      <div
                        className="instruction"
                        dangerouslySetInnerHTML={{ __html: data.vocab2 || "" }}
                      />
                    </Segment>
                    <Segment style={{ backgroundColor: "#F5F8FF" }}>
                      <h4>Vocab c</h4>
                      <div
                        className="instruction"
                        dangerouslySetInnerHTML={{ __html: data.vocab3 || "" }}
                      />
                    </Segment>
                  </>
                )}
              </Container>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    content: state.speakingTable.content,
  };
};

export default withRouter(connect(mapStateToProps)(SpeakingEditor));
