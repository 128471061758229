import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Segment, Grid, Header, Form, Button } from 'semantic-ui-react';
import * as actions from '../../../../actions/app/nonStudent'
import * as apiActions from '../../../../actions/app/api'
import * as api from '../../../../actions/api/requests'
import Loader from './Loader';
import moment from 'moment';

class SecondPage extends React.Component {
  state ={
    others:'',
    examName: 'IELTS Mock',
  }
  componentDidMount(){
    const pathname = this.props.history.location.pathname;
    if (pathname.includes('imt')){
      this.setState({examName:'IELTS Mock'});
    } else if (pathname.includes('listening')) {
      this.setState({examName:'Listening'});
    } else if (pathname.includes('reading')) {
      this.setState({examName:'Reading'});
    } else if (pathname.includes('writing')) {
      this.setState({examName:'Writing'});
    } else if (pathname.includes('speaking')) {
      this.setState({examName:'Speaking'});
    }
    this.props.dispatch(apiActions.updateApiSending(false));
  }
  updateProfile = (e, value) => {
    const { dispatch, profile } = this.props;
    if (value.name === 'takenExam'){
      profile[value.name] = value.value.toLowerCase() === 'yes';
      if (!profile["takenExam"]){
        profile['previous_score'] = "";
      }
    } else {
      profile[value.name] = value.value;
    }
    dispatch(actions.updateProfile(profile));
  }
  handleSubmit = (e) => {
    e.preventDefault();
    const { profile, dispatch, history } = this.props;

    if (profile.test === 'not_sure'){
      profile.test = 'gt';
    }

    if (
      profile.target_score === ""      ||
      profile.planned_test_date === "" ||
      (profile.takenExam && profile.previous_score === "")
    ){
      alert("Please answer all the questions before taking the test.")
      return;
    }
    if (profile.previous_score === ""){
      delete profile['previous_score'];
    }
    dispatch(apiActions.updateApiSending(true));
    const pathname = history.location.pathname;
    if (pathname.includes('imt')){
      profile['test_type'] = 'imt';
    } else if (pathname.includes('listening')) {
      profile['test_type'] = 'listening';
    } else if (pathname.includes('reading')) {
      profile['test_type'] = 'reading';
    } else if (pathname.includes('writing')) {
      profile['test_type'] = 'writing';
    } else if (pathname.includes('speaking')) {
      profile['test_type'] = 'speaking';
    }

    if (profile.country.toLowerCase() === 'sg'){
      dispatch(api.registerSingapore(profile, history));
    } else {
      dispatch(api.registerInternational(profile, history));
    }
  }
  generatePotentialDates = () => {
    const potentialDates = [];
    potentialDates.push(moment().format('MMMM YYYY'));
    potentialDates.push(moment().add(1, 'M').format('MMMM YYYY'));
    potentialDates.push(moment().add(2, 'M').format('MMMM YYYY'));
    potentialDates.push(moment().add(3, 'M').format('MMMM YYYY'));
    potentialDates.push("After " + moment().add(4, 'M').format('MMMM YYYY'));
    return potentialDates
  }
  generateBandScores = () => {
    const bandScores = [];
    for (let i = 5.0; i <= 9.0; i = i + 0.5){
      bandScores.push(i.toString());
    }
    return bandScores;
  }
  handleTargetCountryChange = (e, value) => {
    const { dispatch, profile } = this.props;
    const { target_country } = this.props.profile;
    const new_countries = target_country.split(",");
    if (value.checked){
      new_countries.push(value.value);
    } else {
      const i = new_countries.indexOf(value.value);
      new_countries.splice(i, 1);
    }
    profile.target_country = new_countries.toString();
    dispatch(actions.updateProfile(profile));
  }
  handleOtherCountryChange = (e, value) => {
    this.setState({others:value.value})
  }
  render(){
    const {
      takenExam,
      previous_score,
      planned_test_date,
      target_score,
      target_country,
    } = this.props.profile;

    const dateOptions = this.generatePotentialDates().map((date, i) => {
      return {key:`potentialdate${i}`, value:date, text:date}
    })

    const bandScores = this.generateBandScores().map((score, i) => {
      return {key:`bandScore${i}`, value:score, text:score}
    })

    const target_countries = target_country.split(",");

    return (
      <Segment>
        <Loader/>
        <Grid textAlign='center' style={{height:"99.6vh"}}>
          <Grid.Column style={{ maxWidth: 700, marginTop: "2rem"}}>
            <Form size='large' onSubmit={this.handleSubmit}>
              <center>
                <Segment stacked style={{ maxWidth: 600 }}>
                  <Form.Field style={{ marginTop: "1rem"}}>
                    <Header as='h3' style={{ lineHeight:"1.5", fontWeight:"550", textAlign:"left","fontSize":"1.5rem" }}>
                      Please choose an answer below
                    </Header>
                  </Form.Field>
                  <Form.Field className='text-left' style={{ marginTop: "1.5rem"}}>
                    <Header className='nonstudenttakenexam' as='h3' style={{ lineHeight:"1.5", fontWeight:"500", textAlign:"left" }}>
                      Have you taken the IELTS exam before?
                    </Header>
                    <Form.Radio
                      className='nonstudenttakenexam'
                      style={{ textAlign:"left" }}
                      label='Yes'
                      name='takenExam'
                      value='yes'
                      checked={takenExam}
                      onClick={this.updateProfile}
                    />
                    <Form.Radio
                      style={{ textAlign:"left" }}
                      label='No'
                      name='takenExam'
                      value='no'
                      checked={takenExam!==null && !takenExam}
                      onClick={this.updateProfile}
                    />
                  </Form.Field>
                  {
                    takenExam !== null?
                      <React.Fragment>
                        {
                          takenExam?
                            <Form.Field className='text-left' style={{ marginTop: "1.5rem"}}>
                              <Header className='nonstudenttakenexam' as='h3' style={{ lineHeight:"1.5", fontWeight:"500", textAlign:"left" }}>
                                What was your last/latest IELTS band score?
                              </Header>
                              <Form.Select
                                width={8}
                                style={{ textAlign:"left" }}
                                name='previous_score'
                                value={previous_score}
                                options={bandScores}
                                onChange={this.updateProfile}
                                placeholder='IELTS band score'
                              />
                            </Form.Field>
                            :
                            null
                        }
                        <Form.Field className='text-left' style={{ marginTop: "1.5rem"}}>
                          <Header className='nonstudenttakenexam' as='h3' style={{ lineHeight:"1.5", fontWeight:"500", textAlign:"left" }}>
                            When do you plan to take the IELTS test again?
                          </Header>
                          <Form.Select
                            width={8}
                            style={{ textAlign:"left" }}
                            name='planned_test_date'
                            value={planned_test_date}
                            options={dateOptions}
                            onChange={this.updateProfile}
                            placeholder='Planned exam date'
                          />
                        </Form.Field>
                        <Form.Field className='text-left' style={{ marginTop: "1.5rem"}}>
                          <Header className='nonstudenttakenexam' as='h3' style={{ lineHeight:"1.5", fontWeight:"500", textAlign:"left" }}>
                            What is your target IELTS band score?
                          </Header>
                          <Form.Select
                            width={8}
                            style={{ textAlign:"left" }}
                            name='target_score'
                            value={target_score}
                            options={bandScores}
                            onChange={this.updateProfile}
                            placeholder='Planned exam date'
                          />
                        </Form.Field>
                        <Form.Field className='text-left' style={{ marginTop: "1.5rem"}}>
                          <Header className='nonstudenttakenexam' as='h3' style={{ lineHeight:"1.5", fontWeight:"500", textAlign:"left" }}>
                            If you're taking the IELTS exam for immigration, which country are you hoping to go – (select up to two options):
                          </Header>
                          <Form.Checkbox
                            className='nonstudenttakenexam'
                            style={{ textAlign:"left" }}
                            label='Australia'
                            name='target_country'
                            value='Australia'
                            checked={target_countries.indexOf("Australia") !== -1}
                            onClick={this.handleTargetCountryChange}
                          />
                          <Form.Checkbox
                            className='nonstudenttakenexam'
                            style={{ textAlign:"left" }}
                            label='Canada'
                            name='target_country'
                            value='Canada'
                            checked={target_countries.indexOf("Canada") !== -1}
                            onClick={this.handleTargetCountryChange}
                          />
                          <Form.Checkbox
                            className='nonstudenttakenexam'
                            style={{ textAlign:"left" }}
                            label='The United Kingdom'
                            name='target_country'
                            value='The United Kingdom'
                            checked={target_countries.indexOf("The United Kingdom") !== -1}
                            onClick={this.handleTargetCountryChange}
                          />
                          <Form.Checkbox
                            className='nonstudenttakenexam'
                            style={{ textAlign:"left" }}
                            label='New Zealand'
                            name='target_country'
                            value='New Zealand'
                            checked={target_countries.indexOf("New Zealand") !== -1}
                            onClick={this.handleTargetCountryChange}
                          />
                          <Form.Checkbox
                            className='nonstudenttakenexam'
                            style={{ textAlign:"left" }}
                            label='Ireland'
                            name='target_country'
                            value='Ireland'
                            checked={target_countries.indexOf("Ireland") !== -1}
                            onClick={this.handleTargetCountryChange}
                          />
                          <Form.Checkbox
                            className='nonstudenttakenexam'
                            style={{ textAlign:"left" }}
                            label='The United States of America'
                            name='target_country'
                            value='The United States of America'
                            checked={target_countries.indexOf("The United States of America") !== -1}
                            onClick={this.handleTargetCountryChange}
                          />
                          <Form.Checkbox
                            className='nonstudenttakenexam'
                            style={{ textAlign:"left" }}
                            label="I'm not sure"
                            name='target_country'
                            value="I'm not sure"
                            checked={target_countries.indexOf("I'm not sure") !== -1}
                            onClick={this.handleTargetCountryChange}
                          />
                          <Form.Group inline width={'equals'}>
                            Others: &nbsp;&nbsp;
                            <Form.Input
                              placeholder='Other countries'
                              width={10}
                              value={this.state.others}
                              onChange={this.handleOtherCountryChange}
                            />
                          </Form.Group>
                        </Form.Field>
                        <Button style={{marginTop:"2rem"}} primary fluid type="submit">
                          Take {this.state.examName} Test
                        </Button>
                      </React.Fragment>
                      :
                      null
                  }
                </Segment>
              </center>
            </Form>
          </Grid.Column>
        </Grid>
      </Segment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    current_page : state.nonStudent.current_page,
    profile : state.nonStudent.profile,
  }
};

export default withRouter(connect(mapStateToProps)(SecondPage));