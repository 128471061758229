import React from "react";
import { connect } from "react-redux";
import MicRecorder from "mic-recorder-to-mp3";
import * as speakExamActions from "../../../../../actions/app/speakExam";
import * as speakPart1Actions from "../../../../../actions/app/speakPart1";
import * as speakPart2Actions from "../../../../../actions/app/speakPart2";
import * as speakPart3Actions from "../../../../../actions/app/speakPart3";
import * as speakReviewActions from "../../../../../actions/app/speakReview";
import * as apiActions from "../../../../../actions/app/api";
import * as appActions from "../../../../../actions/app/app";
import * as api from "../../../../../actions/api/requests";
import EXAM from "../../../../../constants/examTypes";
import * as EXAMS from "../../../../../constants/exam_subtypes";
import { withRouter } from "react-router-dom";
import SpeakPart1 from "./SpeakPart1";
import Loader from "../../../../others/Loader";
import SpeakPart2 from "./SpeakPart2";
import SpeakPart3 from "./SpeakPart3";
import Header from "./Header";
import Sidebar from "./Sidebar";
import { uploadFile } from "../../../../../actions/api/storage";

const recorder = new MicRecorder({
  bitRate: 128,
});

class ReadingContainer extends React.Component {
  toggleNav() {
    const { dispatch, openSidebar } = this.props;
    dispatch(appActions.updateOpenSidebar(!openSidebar));
  }
  closeNav() {
    this.props.dispatch(appActions.updateOpenSidebar(false));
  }
  componentDidMount() {
    const { dispatch, answers } = this.props;
    dispatch(apiActions.updateApiSending(false));
    if (answers.length === 0) {
      this.setBlankAnswers();
    }
    if (!this.props.strat_mode) {
      dispatch(speakPart1Actions.reset());
      dispatch(speakPart2Actions.reset());
      dispatch(speakPart3Actions.reset());
    }
    navigator.getMedia =
      navigator.getUserMedia ||
      navigator.webkitGetUserMedia ||
      navigator.mozGetUserMedia ||
      navigator.msGetUserMedia;
    navigator.getMedia(
      { audio: true },
      () => {},
      () => {
        this.setState({ micPermitted: false });
      }
    );
  }
  setBlankAnswers() {
    const { content, dispatch } = this.props;
    const final_blank_answers = [];
    const final_blank_answers2 = [];
    const notes = [];
    for (let i = 0; i < content.length; i++) {
      if (content[i].part === EXAMS.SPEAK_PART_2_VALUE) {
        final_blank_answers.push("");
        final_blank_answers2.push("");
        notes.push("");
      } else {
        final_blank_answers.push(Array(4).join(".").split("."));
        final_blank_answers2.push(Array(4).join(".").split("."));
        notes.push(Array(4).join(".").split("."));
      }
    }
    dispatch(speakExamActions.updateAnswer(final_blank_answers));
    dispatch(speakExamActions.updateAnswerStrat(final_blank_answers2));
    dispatch(speakExamActions.updateNotes1(notes.slice()));
    dispatch(speakExamActions.updateNotes2(notes.slice()));
    dispatch(speakExamActions.updateNotes3(notes.slice()));
  }
  startRecording = () => {
    recorder
      .start()
      .then(() => {})
      .catch((e) => {
        alert("Unable to start recording: " + e);
      });
  };
  stopRecording = () => {
    const { strat_mode, dispatch, content, active_index } = this.props;
    recorder
      .stop()
      .getMp3()
      .then(([buffer, blob]) => {
        const file = new File(buffer, "recording.mp3", {
          type: blob.type,
          lastModified: Date.now(),
        });
        if (!strat_mode) {
          this.uploadRecording(file);
        } else {
          if (content[active_index].part === EXAMS.SPEAK_PART_1_VALUE) {
            dispatch(speakPart1Actions.updateFile(file));
            dispatch(speakPart1Actions.updateUrl(URL.createObjectURL(file)));
          } else if (content[active_index].part === EXAMS.SPEAK_PART_2_VALUE) {
            dispatch(speakPart2Actions.updateFile(file));
            dispatch(speakPart2Actions.updateUrl(URL.createObjectURL(file)));
          } else if (content[active_index].part === EXAMS.SPEAK_PART_3_VALUE) {
            dispatch(speakPart3Actions.updateFile(file));
            dispatch(speakPart3Actions.updateUrl(URL.createObjectURL(file)));
          }
        }
      })
      .catch((e) => {
        alert("Something is wrong with the microphone: " + e);
      });
  };
  stopRecordingStratMode = () => {
    recorder
      .stop()
      .getMp3()
      .then(([buffer, blob]) => {
        const file = new File(buffer, "recording.mp3", {
          type: blob.type,
          lastModified: Date.now(),
        });
        this.uploadRecording(file);
      })
      .catch((e) => {
        alert("Something is wrong with the microphone: " + e);
      });
  };
  uploadRecording = (file) => {
    const { username } = this.props;
    const key =
      "student_answers/speaking/" + username + "/" + Date.now() + ".mp3";
    uploadFile(file, key)
      .then((location) => {
        this.updateAnswer(location);
      })
      .catch((error) => {
        console.log(error);
        return alert(
          "There was an error uploading your audio: " +
            error +
            "\n\n Please refresh the page multiple times until the audio plays again."
        );
      });
  };

  reset = () => {
    const { dispatch } = this.props;
    dispatch(speakPart1Actions.reset());
    dispatch(speakPart2Actions.reset());
    dispatch(speakPart3Actions.reset());
  };

  resetStratUrl = () => {
    const { dispatch } = this.props;
    dispatch(speakPart1Actions.updateUrl(""));
    dispatch(speakPart2Actions.updateUrl(""));
    dispatch(speakPart3Actions.updateUrl(""));
  };

  turnOnStratMode = () => {
    const { dispatch } = this.props;
    dispatch(speakPart1Actions.updatePlaying(false));
    dispatch(speakPart1Actions.updatePlaying2(false));
    dispatch(speakPart1Actions.updateRecording(false));
    dispatch(speakPart1Actions.updateRecording2(false));

    dispatch(speakPart2Actions.updatePlaying(false));
    dispatch(speakPart2Actions.updatePlaying2(false));
    dispatch(speakPart2Actions.updatePlaying3(false));
    dispatch(speakPart2Actions.updateRecording(false));
    dispatch(speakPart2Actions.updateRecording2(false));

    dispatch(speakPart3Actions.updatePlaying(false));
    dispatch(speakPart3Actions.updatePlaying2(false));
    dispatch(speakPart3Actions.updateRecording(false));
    dispatch(speakPart3Actions.updateRecording2(false));
  };

  updateAnswer(location) {
    const {
      answers,
      answers_strat,
      active_index,
      current_subtype,
      dispatch,
      strat_mode,
      content,
      answerInstance,
      is_staff,
      history,
      notes_1,
      notes_2,
      notes_3,
    } = this.props;
    if (strat_mode) {
      const newAnswers = answers_strat.slice();

      if (content[active_index].part === EXAMS.SPEAK_PART_2_VALUE) {
        newAnswers[active_index] = location;
      } else {
        newAnswers[active_index][current_subtype] = location;
      }

      dispatch(speakExamActions.updateAnswerStrat(newAnswers));
      dispatch(apiActions.updateApiSending(false));

      // Save to speakExamReview reducer
      const data = {
        name: answerInstance.name,
        answers: answers,
        answers_strat: newAnswers,
        content: content,
        id: answerInstance.id,
        finished_at: new Date(),
        test_type: EXAM.SPEAKING,
        notes_1,
        notes_2,
        notes_3,
      };
      dispatch(speakReviewActions.updateContent(data));

      this.resetStratUrl();

      // Check if Speaking Part 2
      if (content[active_index].part === EXAMS.SPEAK_PART_2_VALUE) {
        // If last element, proceed with review page
        if (active_index + 1 === content.length) {
          if (is_staff) {
            history.push("/review/speak");
            return;
          } else {
            // If student submit to server
            dispatch(apiActions.updateApiSending(true));
            dispatch(
              api.updateAnswerInstance({ ...data }, history, "/review/speak")
            );
            return;
          }
        }
        dispatch(speakExamActions.updateActiveIndex(active_index + 1));
        return;
      }

      // Check if Speaking Part 3
      if (content[active_index].part === EXAMS.SPEAK_PART_3_VALUE) {
        // If final item in the test
        if (active_index + 1 === content.length) {
          // If 3rd audio
          if (current_subtype === 2) {
            if (is_staff) {
              history.push("/review/speak");
              return;
            } else {
              dispatch(apiActions.updateApiSending(true));
              dispatch(
                api.updateAnswerInstance({ ...data }, history, "/review/speak")
              );
              return;
            }
          } else {
            // If not on the last subtype, update the current subtype
            dispatch(speakExamActions.updateSubtype(current_subtype + 1));
          }
          // If not on the last item, update the current index
        } else {
          if (current_subtype === 2) {
            dispatch(speakExamActions.updateSubtype(0));
            dispatch(speakExamActions.updateActiveIndex(active_index + 1));
          } else {
            dispatch(speakExamActions.updateSubtype(current_subtype + 1));
          }
        }
        return;
      }

      // If final item in the test
      if (active_index + 1 === content.length) {
        // If last item in the subtype, push to review page (or also send to server if student)
        if (current_subtype === 3) {
          if (is_staff) {
            history.push("/review/speak");
            return;
          } else {
            dispatch(apiActions.updateApiSending(true));
            dispatch(
              api.updateAnswerInstance({ ...data }, history, "/review/speak")
            );
            return;
          }
        } else {
          // If not on the last subtype, update the current subtype
          dispatch(speakExamActions.updateSubtype(current_subtype + 1));
        }
        // If not on the last item, update the current index
      } else {
        if (current_subtype === 3) {
          dispatch(speakExamActions.updateSubtype(0));
          dispatch(speakExamActions.updateActiveIndex(active_index + 1));
        } else {
          dispatch(speakExamActions.updateSubtype(current_subtype + 1));
        }
      }
    } else {
      const newAnswers = answers.slice();
      if (content[active_index].part === EXAMS.SPEAK_PART_2_VALUE) {
        newAnswers[active_index] = location;
      } else {
        newAnswers[active_index][current_subtype] = location;
      }
      dispatch(speakExamActions.updateAnswer(newAnswers));
      dispatch(apiActions.updateApiSending(false));

      // Check if Speaking Part 2
      // If part 2, check if its the end of the list.
      // If not, increase the active_index by +1.
      // If it is, change strat_mode to true
      if (content[active_index].part === EXAMS.SPEAK_PART_2_VALUE) {
        if (active_index + 1 === content.length) {
          this.turnOnStratMode();
          dispatch(speakExamActions.updateStratMode(true));
          dispatch(speakExamActions.updateActiveIndex(0));
          dispatch(speakExamActions.updateSubtype(0));
        } else {
          this.reset();
          dispatch(speakExamActions.updateActiveIndex(active_index + 1));
        }
        return;
      }

      // Check if Speaking Part 3
      // If part 3, check if its the end of the list.
      // If not, increase the active_index by +1.
      // If it is, change strat_mode to true
      if (content[active_index].part === EXAMS.SPEAK_PART_3_VALUE) {
        if (active_index + 1 === content.length) {
          if (current_subtype === 2) {
            this.turnOnStratMode();
            dispatch(speakExamActions.updateStratMode(true));
            dispatch(speakExamActions.updateSubtype(0));
            dispatch(speakExamActions.updateActiveIndex(0));
          } else {
            this.reset();
            dispatch(speakExamActions.updateSubtype(current_subtype + 1));
          }
        } else {
          this.reset();
          if (current_subtype === 2) {
            dispatch(speakExamActions.updateSubtype(0));
            dispatch(speakExamActions.updateActiveIndex(active_index + 1));
          } else {
            dispatch(speakExamActions.updateSubtype(current_subtype + 1));
          }
        }
        return;
      }

      // For Speaking Part 1
      // Check if its the end of the list.
      // If it is and is the last subtype, change strat_mode to true
      // If not the last subtype, increase subtype to +1
      // If it is not the end of the list, check subtype.
      // If it is the last subtype,
      if (active_index + 1 === content.length) {
        if (current_subtype === 3) {
          this.turnOnStratMode();
          dispatch(speakExamActions.updateStratMode(true));
          dispatch(speakExamActions.updateActiveIndex(0));
          dispatch(speakExamActions.updateSubtype(0));
        } else {
          this.reset();
          dispatch(speakExamActions.updateSubtype(current_subtype + 1));
        }
      } else {
        if (current_subtype === 3) {
          dispatch(speakExamActions.updateSubtype(0));
          dispatch(speakExamActions.updateActiveIndex(active_index + 1));
        } else {
          dispatch(speakExamActions.updateSubtype(current_subtype + 1));
        }
        this.reset();
        return;
      }
    }
  }
  state = {
    micPermitted: true,
    answers: [],
    toggleNav: this.toggleNav.bind(this),
    closeNav: this.closeNav.bind(this),
    openHintModal: false,
    openVocabModal: false,
    startRecording: this.startRecording.bind(this),
    stopRecording: this.stopRecording.bind(this),
    uploadRecording: this.uploadRecording.bind(this),
    updateAnswer: this.updateAnswer.bind(this),
    stopRecordingStratMode: this.stopRecordingStratMode.bind(this),
  };
  render() {
    const { openSidebar, content, active_index, strat_mode } = this.props;
    let show = "hide";
    if (openSidebar) {
      show = "show";
    }
    if (!this.state.micPermitted) {
      return (
        <center style={{ paddingTop: "2rem" }}>
          <h1>
            To proceed with the test, please grant this app access to your
            microphone.
          </h1>
          <h3>
            For information on how to enable your microphone, please visit{" "}
            <a
              target="_blank"
              href="https://support.google.com/chrome/answer/2693767?co=GENIE.Platform%3DDesktop&hl=en&oco=1"
            >
              this link
            </a>
          </h3>
        </center>
      );
    }

    return (
      <React.Fragment>
        <Loader />
        <Header
          {...this.state}
          {...this.props}
          show={show}
          strat_mode={strat_mode}
          part={content[active_index].part}
        />
        <Sidebar {...this.state} {...this.props} show={show} />
        {content[active_index].part === EXAMS.SPEAK_PART_1_VALUE ? (
          <SpeakPart1 {...this.state} />
        ) : content[active_index].part === EXAMS.SPEAK_PART_2_VALUE ? (
          <SpeakPart2 {...this.state} />
        ) : content[active_index].part === EXAMS.SPEAK_PART_3_VALUE ? (
          <SpeakPart3 {...this.state} />
        ) : null}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    content: state.speakExam.content,
    active_index: state.speakExam.active_index,
    answers: state.speakExam.answers,
    answers_strat: state.speakExam.answers_strat,
    current_subtype: state.speakExam.current_subtype,
    username: state.auth.username,
    answerInstance: state.answerInstance,
    is_staff: state.auth.is_staff,
    strat_mode: state.speakExam.strat_mode,
    openSidebar: state.app.openSidebar,
    notes_1: state.speakExam.notes_1,
    notes_2: state.speakExam.notes_2,
    notes_3: state.speakExam.notes_3,
  };
};

export default withRouter(connect(mapStateToProps)(ReadingContainer));
