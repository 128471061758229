import * as signup from "../types/signup";

export const updateSignup = (payload) => {
  return (dispatch) => {
    dispatch({
      type: signup.UPDATE_SIGNUP_FIELDS,
      payload: payload,
    });
  };
};

export const resetSignupFields = () => {
  return (dispatch) => {
    dispatch({
      type: signup.RESET_SIGNUP_FIELDS,
    });
  };
};
