import * as actionTypes from '../types/readBuilder';

export const reset = () => {
  return dispatch => {
    dispatch({
      type : actionTypes.READ_BUILDER_RESET,
    });
  };
};

export const updateContent = (payload) => {
  return dispatch => {
    dispatch({
      type    : actionTypes.READ_BUILDER_UPDATE_CONTENT,
      payload : payload,
    });
  };
};