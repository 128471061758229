import * as actions from '../actions/types/login';

const defaultState = {
  username: '',
  password: ''
};

export default function reducer(state=defaultState, action) {
  switch(action.type) {
    case actions.UPDATE_LOGIN_FIELDS: {
      return {...action.payload};
    }
    default: return {...state};
  }
};