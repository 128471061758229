import React from "react";
import ReactDOM from "react-dom";
import Routes from "./Route";
import { Provider } from "react-redux";
import store from "./store/store";

import { Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import { Amplify } from "aws-amplify";

const region = process.env.REACT_APP_REGION;
const endpoint = process.env.REACT_APP_API_URL;

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region,
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_USER_POOL_CLIENT_ID,
  },
  API: {
    endpoints: [
      {
        name: "ielts-appify-api",
        region,
        endpoint,
      },
    ],
  },
});

ReactDOM.render(
  <Provider store={store}>
    <Router history={createBrowserHistory()}>
      <Routes />
    </Router>
  </Provider>,
  document.getElementById("root")
);
