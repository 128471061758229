import React from "react";
import { connect } from "react-redux";
import MicRecorder from "mic-recorder-to-mp3";
import * as speakExamActions from "../../../../../../actions/app/speakExam";
import * as speakPart1Actions from "../../../../../../actions/app/speakPart1";
import * as speakPart2Actions from "../../../../../../actions/app/speakPart2";
import * as speakPart3Actions from "../../../../../../actions/app/speakPart3";
import * as apiActions from "../../../../../../actions/app/api";
import * as appActions from "../../../../../../actions/app/app";
import * as api from "../../../../../../actions/api/requests";
import * as EXAMS from "../../../../../../constants/exam_subtypes";
import { withRouter } from "react-router-dom";
import SpeakPart1 from "./SpeakPart1";
import SpeakPart2 from "./SpeakPart2";
import SpeakPart3 from "./SpeakPart3";
import Header from "./Header";
import Sidebar from "./Sidebar";
import * as mockTestActions from "../../../../../../actions/app/mockTest";
import SpeakAdmin from "./SpeakAdmin";
import { Confirm } from "semantic-ui-react";
import * as answerInstanceActions from "../../../../../../actions/app/answerInstance";
import Loader from "../../../../../others/Loader";
import { uploadFile } from "../../../../../../actions/api/storage";

const recorder = new MicRecorder({
  bitRate: 128,
});

class MockSpeaking extends React.Component {
  componentDidMount() {
    const { dispatch, answers } = this.props;
    dispatch(mockTestActions.updateConfirmModal(false));
    if (answers.length === 0) {
      this.setBlankAnswers();
    }
    dispatch(speakPart1Actions.reset());
    dispatch(speakPart2Actions.reset());
    dispatch(speakPart3Actions.reset());
  }
  setBlankAnswers() {
    const { content, dispatch } = this.props;
    const final_blank_answers = [];
    const final_blank_answers2 = [];
    for (let i = 0; i < content.length; i++) {
      if (content[i].part === EXAMS.SPEAK_PART_2_VALUE) {
        final_blank_answers.push("");
        final_blank_answers2.push("");
      } else {
        final_blank_answers.push(Array(4).join(".").split("."));
        final_blank_answers2.push(Array(4).join(".").split("."));
      }
    }
    final_blank_answers.push(["", "", ""]);
    dispatch(speakExamActions.updateAnswer(final_blank_answers));
    dispatch(speakExamActions.updateAnswerStrat(final_blank_answers2));
  }
  constructor(props) {
    super(props);
    this.state = {
      startRecording: this.startRecording.bind(this),
      stopRecording: this.stopRecording.bind(this),
      uploadRecording: this.uploadRecording.bind(this),
      updateAnswer: this.updateAnswer.bind(this),
      stopRecordingStratMode: this.stopRecordingStratMode.bind(this),
      finishSpeaking: this.finishSpeaking.bind(this),
    };
  }
  startRecording = () => {
    recorder
      .start()
      .then(() => {})
      .catch((e) => {
        alert("Unable to start recording: " + e);
      });
  };
  stopRecording = () => {
    const { strat_mode, dispatch, content, active_index } = this.props;
    recorder
      .stop()
      .getMp3()
      .then(([buffer, blob]) => {
        const file = new File(buffer, "recording.mp3", {
          type: blob.type,
          lastModified: Date.now(),
        });
        if (!strat_mode) {
          this.uploadRecording(file);
        } else {
          if (content[active_index].exam.part === EXAMS.SPEAK_PART_1_VALUE) {
            dispatch(speakPart1Actions.updateFile(file));
            dispatch(speakPart1Actions.updateUrl(URL.createObjectURL(file)));
          } else if (
            content[active_index].exam.part === EXAMS.SPEAK_PART_2_VALUE
          ) {
            dispatch(speakPart2Actions.updateFile(file));
            dispatch(speakPart2Actions.updateUrl(URL.createObjectURL(file)));
          } else if (
            content[active_index].exam.part === EXAMS.SPEAK_PART_3_VALUE
          ) {
            dispatch(speakPart3Actions.updateFile(file));
            dispatch(speakPart3Actions.updateUrl(URL.createObjectURL(file)));
          }
        }
      })
      .catch((e) => {
        alert("Something is wrong with the microphone: " + e);
      });
  };
  stopRecordingStratMode = () => {
    recorder
      .stop()
      .getMp3()
      .then(([buffer, blob]) => {
        const file = new File(buffer, "recording.mp3", {
          type: blob.type,
          lastModified: Date.now(),
        });
        this.uploadRecording(file);
      })
      .catch((e) => {
        alert("Something is wrong with the microphone: " + e);
      });
  };
  stopRecordingAdmin = () => {
    recorder
      .stop()
      .getMp3()
      .then(([buffer, blob]) => {
        const file = new File(buffer, "recording.mp3", {
          type: blob.type,
          lastModified: Date.now(),
        });
        this.uploadRecording(file);
      })
      .catch((e) => {
        alert("Something is wrong with the microphone: " + e);
      });
  };
  uploadRecording = (file) => {
    const { username } = this.props;
    const key =
      "student_answers/speaking/" + username + "/" + Date.now() + ".mp3";
    uploadFile(file, key)
      .then((location) => {
        this.updateAnswer(location);
      })
      .catch((error) => {
        console.log(error);
        return alert(
          "There was an error uploading your audio: " +
            error +
            "\n\n Please refresh the page multiple times until the audio plays again."
        );
      });
  };

  updateAnswer(location) {
    const {
      answers,
      active_index,
      current_subtype,
      dispatch,
      content,
      admin_q_mode,
      admin_q_index,
    } = this.props;
    const newAnswers = answers.slice();

    if (admin_q_mode) {
      newAnswers[newAnswers.length - 1][admin_q_index] = location;
    } else if (content[active_index].exam.part === EXAMS.SPEAK_PART_2_VALUE) {
      newAnswers[active_index] = location;
    } else {
      newAnswers[active_index][current_subtype] = location;
    }
    dispatch(speakExamActions.updateAnswer(newAnswers));

    dispatch(apiActions.updateApiSending(false));
    dispatch(speakPart1Actions.reset());
    dispatch(speakPart2Actions.reset());
    dispatch(speakPart3Actions.reset());

    if (admin_q_mode) {
      if (admin_q_index <= 1) {
        dispatch(speakExamActions.updateAdminQuestionIndex(admin_q_index + 1));
      } else {
        dispatch(speakExamActions.updateAdminQuestionMode(false));
      }
      dispatch(apiActions.updateApiSending(false));
      return;
    }

    // Check if Speaking Part 2
    if (content[active_index].exam.part === EXAMS.SPEAK_PART_2_VALUE) {
      // If last element, proceed with review page
      if (active_index + 1 === content.length) {
        this.doFinishSpeaking();
        return;
      }
      dispatch(speakExamActions.updateActiveIndex(active_index + 1));
      return;
    }

    // Check if Speaking Part 3
    if (content[active_index].exam.part === EXAMS.SPEAK_PART_3_VALUE) {
      // If final item in the test
      if (active_index + 1 === content.length) {
        // If 4th audio is blank or
        // last item in the subtype,
        // push to review page (or also send to server if student)
        if (
          (current_subtype === 2 &&
            content[active_index].exam.audio[3].trim() === "") ||
          current_subtype === 3
        ) {
          this.doFinishSpeaking();
          return;
        } else {
          // If not on the last subtype, update the current subtype
          dispatch(speakExamActions.updateSubtype(current_subtype + 1));
          return;
        }
        // If not on the last item, update the current index
      } else {
        if (
          (current_subtype === 2 &&
            content[active_index].exam.audio[3].trim() === "") ||
          current_subtype === 3
        ) {
          dispatch(speakExamActions.updateSubtype(0));
          dispatch(speakExamActions.updateActiveIndex(active_index + 1));
          return;
        } else {
          dispatch(speakExamActions.updateSubtype(current_subtype + 1));
          return;
        }
      }
    }

    // If final item in the test
    if (active_index + 1 === content.length) {
      // If last item in the subtype, push to review page (or also send to server if student)
      if (current_subtype === 3) {
        this.doFinishSpeaking();
        return;
      } else {
        // If not on the last subtype, update the current subtype
        dispatch(speakExamActions.updateSubtype(current_subtype + 1));
      }
      // If not on the last item, update the current index
    } else {
      if (current_subtype === 3) {
        dispatch(speakExamActions.updateSubtype(0));
        dispatch(speakExamActions.updateActiveIndex(active_index + 1));
      } else {
        dispatch(speakExamActions.updateSubtype(current_subtype + 1));
      }
    }
  }

  doFinishSpeaking = () => {
    const {
      dispatch,
      data,
      answers,
      content,
      is_staff,
      nonStudent,
      activePage,
      test_type,
      user_id,
    } = this.props;
    if (test_type !== "speaking") {
      dispatch(speakPart1Actions.updatePlaying(false));
      dispatch(speakPart2Actions.updatePlaying(false));
      dispatch(speakPart3Actions.updatePlaying(false));
      data["answers"]["speaking"] = answers;
      data["content"]["speaking"] = content;
      data["user"] = user_id;
      data["finished_at"] = new Date();
      dispatch(answerInstanceActions.updateContent(data));
      dispatch(apiActions.updateApiSending(true));
      dispatch(mockTestActions.updateCurrentPage(activePage + 1));
      if (is_staff) {
        dispatch(apiActions.updateApiSending(false));
        window.location = "/review/mock-test";
      } else if (nonStudent.profile.id) {
        dispatch(
          api.createNonStudentAnswerInstance(
            data,
            nonStudent.profile,
            this.props.history
          )
        );
      } else {
        dispatch(api.createAnswerInstance(data, this.props.history));
      }
    } else {
      const data = {
        name: `Mock Test (${new Date().toLocaleString()})`,
        answers: {
          speaking: answers,
        },
        content: {
          speaking: content,
        },
        test_type: "speaking",
      };
      data["user"] = user_id;
      data["answers"]["reading"] = [];
      data["content"]["reading"] = [];
      data["answers"]["writing"] = [];
      data["content"]["writing"] = [];
      data["answers"]["listening"] = [];
      data["content"]["listening"] = [];
      data["finished_at"] = new Date();
      dispatch(apiActions.updateApiSending(true));
      dispatch(
        api.createNonStudentAnswerInstance(
          data,
          nonStudent.profile,
          this.props.history
        )
      );
      dispatch(mockTestActions.updateCurrentPage(5));
    }
  };

  finishSpeaking() {
    const { dispatch } = this.props;
    dispatch(mockTestActions.updateConfirmModal(true));
  }

  closeModal = () => {
    this.props.dispatch(mockTestActions.updateConfirmModal(false));
  };
  confirmNextPage = () => {
    const { dispatch } = this.props;
    dispatch(mockTestActions.updateConfirmModal(true));
  };
  toggleNav() {
    const { dispatch, openSidebar } = this.props;
    dispatch(appActions.updateOpenSidebar(!openSidebar));
  }
  closeNav() {
    this.props.dispatch(appActions.updateOpenSidebar(false));
  }
  state = {
    answers: [],
    toggleNav: this.toggleNav.bind(this),
    closeNav: this.closeNav.bind(this),
    openHintModal: false,
    openVocabModal: false,
    startRecording: this.startRecording.bind(this),
    stopRecording: this.stopRecording.bind(this),
    uploadRecording: this.uploadRecording.bind(this),
    updateAnswer: this.updateAnswer.bind(this),
    stopRecordingStratMode: this.stopRecordingStratMode.bind(this),
  };
  render() {
    const {
      openSidebar,
      content,
      active_index,
      admin_q_mode,
      openConfirmModal,
    } = this.props;
    let show = "hide";
    if (openSidebar) {
      show = "show";
    }
    return (
      <React.Fragment>
        <Loader />
        <Header {...this.state} {...this.props} show={show} />
        <Sidebar {...this.state} {...this.props} show={show} />
        {admin_q_mode ? (
          <React.Fragment>
            <Confirm
              open={openConfirmModal}
              header="Confirm"
              onCancel={this.closeModal}
              onConfirm={this.doFinishSpeaking}
              content="Are you sure you want to finish the Speaking Exam and finish the test?"
            />
            <SpeakAdmin {...this.state} />
          </React.Fragment>
        ) : content[active_index].exam.part === EXAMS.SPEAK_PART_1_VALUE ? (
          <React.Fragment>
            <Confirm
              open={openConfirmModal}
              header="Confirm"
              onCancel={this.closeModal}
              onConfirm={this.doFinishSpeaking}
              content="Are you sure you want to finish the Speaking Exam and finish the test?"
            />
            <SpeakPart1 {...this.state} />
          </React.Fragment>
        ) : content[active_index].exam.part === EXAMS.SPEAK_PART_2_VALUE ? (
          <React.Fragment>
            <Confirm
              open={openConfirmModal}
              header="Confirm"
              onCancel={this.closeModal}
              onConfirm={this.doFinishSpeaking}
              content="Are you sure you want to finish the Speaking Exam and finish the test?"
            />
            <SpeakPart2 {...this.state} />
          </React.Fragment>
        ) : content[active_index].exam.part === EXAMS.SPEAK_PART_3_VALUE ? (
          <React.Fragment>
            <Confirm
              open={openConfirmModal}
              header="Confirm"
              onCancel={this.closeModal}
              onConfirm={this.doFinishSpeaking}
              content="Are you sure you want to finish the Speaking Exam and finish the test?"
            />
            <SpeakPart3 {...this.state} />
          </React.Fragment>
        ) : null}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    content: state.speakExam.content,
    active_index: state.speakExam.active_index,
    answers: state.speakExam.answers,
    answers_strat: state.speakExam.answers_strat,
    current_subtype: state.speakExam.current_subtype,
    username: state.auth.username,
    answerInstance: state.answerInstance,
    is_staff: state.auth.is_staff,
    strat_mode: state.speakExam.strat_mode,
    openSidebar: state.app.openSidebar,
    user_id: state.auth.id,
    admin_q_mode: state.speakExam.admin_q_mode,
    admin_q_index: state.speakExam.admin_q_index,
    openConfirmModal: state.mockTest.openConfirmModal,
    user: state.mockTest.content.user,
    data: state.answerInstance,
    nonStudent: state.nonStudent,
    activePage: state.mockTest.currentActivePage,
    test_type: state.mockTest.content.test_type,
  };
};

export default withRouter(connect(mapStateToProps)(MockSpeaking));
