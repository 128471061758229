import React from 'react'
import { Modal } from 'semantic-ui-react'

const HintModal = (props) => (
  <Modal closeOnEscape={true} closeOnDimmerClick={true} open={props.open} onClose={props.onClose} closeIcon>
    <Modal.Header>{props.header}</Modal.Header>
    <Modal.Content>
      <div
        className="instruction"
        dangerouslySetInnerHTML={{__html: props.hint}}
      />
    </Modal.Content>
  </Modal>
)

export default HintModal;