export const SPEAK_EXAM_PART2_UPDATE_TIMER = 'SPEAK_EXAM_PART2_UPDATE_TIMER';
export const SPEAK_EXAM_PART2_RESET = 'SPEAK_EXAM_PART2_RESET';
export const SPEAK_EXAM_PART2_RESET_TIMER = 'SPEAK_EXAM_PART2_RESET_TIMER';
export const SPEAK_EXAM_PART2_UPDATE_ACTIVE_INDEX = 'SPEAK_EXAM_PART2_UPDATE_ACTIVE_INDEX';
export const SPEAK_EXAM_PART2_UPDATE_STRAT_MODE = 'SPEAK_EXAM_PART2_UPDATE_STRAT_MODE';
export const SPEAK_EXAM_PART2_UPDATE_CONTENT = 'SPEAK_EXAM_PART2_UPDATE_CONTENT';
export const SPEAK_EXAM_PART2_UPDATE_ANSWERS = 'SPEAK_EXAM_PART2_UPDATE_ANSWERS';
export const SPEAK_EXAM_PART2_UPDATE_ANSWERS_STRAT = 'SPEAK_EXAM_PART2_UPDATE_ANSWERS_STRAT';
export const SPEAK_EXAM_PART2_UPDATE_SUBTYPE = 'SPEAK_EXAM_PART2_UPDATE_SUBTYPE';
export const SPEAK_EXAM_PART2_UPDATE_RECORDING = 'SPEAK_EXAM_PART2_UPDATE_RECORDING';
export const SPEAK_EXAM_PART2_UPDATE_PLAYING = 'SPEAK_EXAM_PART2_UPDATE_PLAYING';
export const SPEAK_EXAM_PART2_UPDATE_STRAT_URL = 'SPEAK_EXAM_PART2_UPDATE_STRAT_URL';
export const SPEAK_EXAM_PART2_UPDATE_STRAT_FILE = 'SPEAK_EXAM_PART2_UPDATE_STRAT_FILE';
export const SPEAK_EXAM_PART2_UPDATE_TIMER2 = 'SPEAK_EXAM_PART2_UPDATE_TIMER2';
export const SPEAK_EXAM_PART2_UPDATE_RECORDING2 = 'SPEAK_EXAM_PART2_UPDATE_RECORDING2';
export const SPEAK_EXAM_PART2_UPDATE_PLAYING2 = 'SPEAK_EXAM_PART2_UPDATE_PLAYING2';
export const SPEAK_EXAM_PART2_UPDATE_PLAYING3 = 'SPEAK_EXAM_PART2_UPDATE_PLAYING3';