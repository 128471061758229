import * as actions from "../actions/types/speakExam";

const default_timer = 45;

const defaultState = {
  timer: default_timer,
  active_index: 0,
  current_subtype: 0,
  strat_mode: false,
  recording: false,
  playing: true,
  content: [],
  answers: [],
  answers_strat: [],
  admin_q_mode: true,
  admin_q_index: 0,
  notes_1: [],
  notes_2: [],
  notes_3: [],
};

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case actions.SPEAK_EXAM_RESET_SPEAK_EXAM: {
      return { ...defaultState };
    }
    case actions.SPEAK_EXAM_UPDATE_TIMER: {
      return { ...state, timer: action.payload };
    }
    case actions.SPEAK_EXAM_UPDATE_ACTIVE_INDEX: {
      return { ...state, active_index: action.payload };
    }
    case actions.SPEAK_EXAM_UPDATE_STRAT_MODE: {
      return { ...state, strat_mode: action.payload };
    }
    case actions.SPEAK_EXAM_RESET_TIMER: {
      return { ...state, timer: default_timer };
    }
    case actions.SPEAK_EXAM_UPDATE_CONTENT: {
      return { ...state, content: action.payload };
    }
    case actions.SPEAK_EXAM_UPDATE_ANSWERS: {
      return { ...state, answers: action.payload.slice() };
    }
    case actions.SPEAK_EXAM_UPDATE_ANSWERS_STRAT: {
      return { ...state, answers_strat: action.payload.slice() };
    }
    case actions.SPEAK_EXAM_UPDATE_SUBTYPE: {
      return { ...state, current_subtype: action.payload };
    }
    case actions.SPEAK_EXAM_UPDATE_RECORDING: {
      return { ...state, recording: action.payload };
    }
    case actions.SPEAK_EXAM_UPDATE_PLAYING: {
      return { ...state, playing: action.payload };
    }
    case actions.SPEAK_EXAM_UPDATE_ADMIN_QUESTION_MODE: {
      return { ...state, admin_q_mode: action.payload };
    }
    case actions.SPEAK_EXAM_UPDATE_ADMIN_QUESTION_INDEX: {
      return { ...state, admin_q_index: action.payload };
    }
    case actions.SPEAK_EXAM_UPDATE_NOTES_1: {
      return { ...state, notes_1: action.payload };
    }
    case actions.SPEAK_EXAM_UPDATE_NOTES_2: {
      return { ...state, notes_2: action.payload };
    }
    case actions.SPEAK_EXAM_UPDATE_NOTES_3: {
      return { ...state, notes_3: action.payload };
    }
    default:
      return { ...state };
  }
}
