import React from 'react';

const ReadingContent = props => {
  let content;
  if (props.reading) {
    if (props.explanation){
      content = props.content[props.active_index].exam.content_explanation.split('<p></p>').join('<br/>');
    } else {
      content = props.content[props.active_index].exam.content.split('<p></p>').join('<br/>');
    }
  } else {
    content = props.content[props.active_index].exam.content.split('<p></p>').join('<br/>');
  }
  return (<div
      className="read-content"
      dangerouslySetInnerHTML={{__html: content}}
  />)
};
export default ReadingContent;