import React from "react";
import { connect } from "react-redux";
import { Grid, Icon, Button, Progress, Segment } from "semantic-ui-react";
import HintModal from "./HintModal";
import { ReactMic } from "react-mic";
import * as speakPart2Actions from "../../../../../../actions/app/speakPart2";
import * as apiActions from "../../../../../../actions/app/api";
import ReactPlayer from "react-player";
import AudioPlayer from "./Player";
import Recorder from "./Recorder";

function pad(string) {
  return ("0" + string).slice(-2);
}

function format(seconds) {
  const date = new Date(seconds * 1000);
  const mm = date.getUTCMinutes();
  const ss = pad(date.getUTCSeconds());
  return `${pad(mm)}:${pad(ss)}`;
}

class SpeakPart2 extends React.Component {
  state = {
    openModal: false,
    oscillationOn: false,
    studentAnswer: "",
    showQuestion: false,
  };
  openModal = () => {
    this.setState({ openModal: true });
  };
  closeModal = () => {
    this.setState({ openModal: false });
  };
  componentDidMount() {
    const { dispatch, strat_mode } = this.props;
    if (!strat_mode) {
      dispatch(speakPart2Actions.reset());
    }
    dispatch(apiActions.updateApiSending(false));
  }
  componentWillUnmount() {
    this.clearTimer();
    const { dispatch, strat_mode } = this.props;
    if (!strat_mode) {
      dispatch(speakPart2Actions.reset());
    }
  }
  clearTimer() {
    clearInterval(this.timer);
    this.timer = null;
  }
  onStop = () => {
    this.setState({ showQuestion: true });
    this.timer = setInterval(this.tick.bind(this), 1000);
  };
  tick = () => {
    const { timer, dispatch } = this.props;
    const newTimer = timer - 1;
    if (newTimer >= 0) {
      dispatch(speakPart2Actions.updateTimer(newTimer));
    } else {
      this.clearTimer();
      this.setState({ showQuestion: false, finishShowingQuestion: true });
      dispatch(speakPart2Actions.updatePlaying3(true));
    }
  };
  tick2 = () => {
    const { timer2, dispatch } = this.props;
    const newTimer = timer2 - 1;
    if (newTimer >= 0) {
      dispatch(speakPart2Actions.updateTimer2(newTimer));
    } else {
      this.clearTimer();
      this.stopRecording();
    }
  };
  stopRecording = () => {
    const { dispatch, recording } = this.props;
    this.clearTimer();
    dispatch(apiActions.updateApiSending(true));
    dispatch(speakPart2Actions.updateRecording(false));
    if (recording) {
      this.props.stopRecording();
    } else {
      this.props.updateAnswer("");
    }
  };
  toggleRecording(on) {
    const { dispatch } = this.props;
    if (on) {
      dispatch(speakPart2Actions.updateTimer2(120));
      this.timer = setInterval(this.tick2.bind(this), 1000);
      this.props.startRecording();
    } else {
      dispatch(speakPart2Actions.updateTimer2(120));
      this.clearTimer();
      this.props.stopRecording();
    }
    dispatch(speakPart2Actions.updateRecording2(on));
  }
  stopPlaying2 = () => {
    this.props.dispatch(speakPart2Actions.updatePlaying2(false));
  };
  finishStratMode = () => {
    const { dispatch, strat_file, recording2 } = this.props;
    if (recording2) {
      dispatch(apiActions.updateApiSending(true));
      this.props.stopRecordingStratMode();
      dispatch(speakPart2Actions.updateRecording2(false));
    } else {
      if (strat_file !== null) {
        dispatch(apiActions.updateApiSending(true));
        this.props.uploadRecording(strat_file);
      } else {
        this.props.updateAnswer("");
      }
    }
  };
  onStop2 = () => {
    const { dispatch } = this.props;
    dispatch(speakPart2Actions.updateTimer(120));
    this.timer = setInterval(this.tick2.bind(this), 1000);
    dispatch(speakPart2Actions.updateRecording(true));
    this.props.startRecording();
  };
  render() {
    const {
      active_index,
      answers,
      content,
      current_subtype,
      playing,
      recording,
      strat_mode,
      strat_url,
      timer,
      timer2,
      recording2,
      playing2,
      playing3,
    } = this.props;
    const { openModal, showQuestion } = this.state;
    let record_percent2 = 100 - (timer2 / 120) * 100;
    const width = showQuestion ? "75%" : "50%";
    return (
      <center>
        <Segment className="speaking-title">Part 2</Segment>
        <HintModal
          header="Vocab"
          hint={content[active_index].vocab}
          open={openModal}
          onClose={this.closeModal}
        />
        <ReactPlayer
          key={"player1" + active_index}
          className="invisible"
          url={content[active_index].exam.audio[current_subtype]}
          playing={playing}
          onEnded={this.onStop}
        />
        <ReactPlayer
          key={"player2" + active_index}
          className="invisible"
          url={content[active_index].exam.audio[current_subtype]}
          playing={playing2}
          onEnded={this.stopPlaying2}
        />
        <ReactPlayer
          key={"player3" + active_index}
          className="invisible"
          url={content[active_index].exam.audio[1]}
          playing={playing3}
          onEnded={this.onStop2}
        />
        <Grid
          className="speaking-container"
          columns={"equal"}
          style={{ margin: ".5rem", width: width }}
        >
          {showQuestion ? (
            <Grid.Column
              className="content-speaking-rec"
              style={{ margin: "1rem" }}
            >
              <div
                className="paper-box text-center"
                style={{
                  marginBottom: "2rem",
                  paddingBottom: "0px",
                  overflowY: "hidden",
                  fontWeight: "normal",
                }}
              >
                <Icon
                  style={{ marginTop: "1rem" }}
                  size="big"
                  color="blue"
                  loading
                  name="spinner"
                />
                <p>
                  {recording
                    ? `Time Remaining for Your Answer: ${format(timer)}`
                    : `Time Remaining for Preparation: ${format(timer)}`}
                </p>
                <h3>Question</h3>
                <div
                  style={{ margin: "0rem 1.5rem 1.5rem 1.5rem" }}
                  className="instruction text-left"
                  dangerouslySetInnerHTML={{
                    __html: content[active_index].exam.question,
                  }}
                />
              </div>
            </Grid.Column>
          ) : !strat_mode ? (
            <Grid.Column
              className="content-speaking-rec"
              style={{ margin: "1rem" }}
            >
              {recording ? (
                <div
                  className="paper-box text-center"
                  style={{
                    marginBottom: "2rem",
                    paddingBottom: "0px",
                    overflowY: "hidden",
                    fontWeight: "normal",
                  }}
                >
                  <h3 style={{ marginTop: "1rem" }}>Question</h3>
                  <div
                    style={{ margin: "0rem 1.5rem 1.5rem 1.5rem" }}
                    className="instruction text-left"
                    dangerouslySetInnerHTML={{
                      __html: content[active_index].exam.question,
                    }}
                  />
                </div>
              ) : (
                <div
                  className="paper-box"
                  style={{
                    marginBottom: "2rem",
                    height: "182px",
                    paddingBottom: "0px",
                    overflowY: "hidden",
                  }}
                >
                  <div style={{ height: "65%" }}>
                    <h4
                      style={{
                        marginTop: "1rem",
                        marginLeft: "1rem",
                        textAlign: "left",
                      }}
                    >
                      Examiner's Question
                    </h4>
                    {!recording || playing2 ? (
                      <React.Fragment>
                        <Icon
                          color={"green"}
                          name={"user"}
                          className="interviewer-icon"
                        />
                        <Icon
                          color={"green"}
                          name={"comment alternate"}
                          className="interviewer-icon"
                        />
                      </React.Fragment>
                    ) : (
                      <Icon
                        color={"red"}
                        name={"user cancel"}
                        className="interviewer-icon"
                      />
                    )}
                  </div>
                  <div className="recorder-bottom text-center"></div>
                </div>
              )}
              <p className="text-center">
                {recording
                  ? "Recording your answer"
                  : "Please answer the examiner's question. Your response is being recorded. When you've finished you can click the red stop button or the button \"Finish and Proceed\" below (blink as Recorder is recording)"}
              </p>
              {recording ? (
                <div
                  className="paper-box"
                  style={{
                    marginTop: "3rem",
                    height: "182px",
                    paddingBottom: "0px",
                    overflowY: "hidden",
                  }}
                >
                  <div style={{ height: "65%" }}>
                    <h4
                      style={{
                        marginTop: "1rem",
                        marginLeft: "1rem",
                        textAlign: "left",
                      }}
                    >
                      Audio Recorder To Record Your "Testing" Answer
                    </h4>
                    <ReactMic
                      key={"microphone"}
                      record={recording}
                      className="sound-wave"
                      backgroundColor="#F5F8FF"
                    />
                  </div>
                  <div className="recorder-bottom text-center">
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div
                        style={{
                          marginTop: "7px",
                          marginLeft: "1rem",
                          marginRight: "1rem",
                        }}
                      >
                        <Button
                          disabled={!recording}
                          onClick={this.stopRecording.bind(this)}
                          circular
                          className={recording ? "blinking" : ""}
                          icon={recording ? "stop" : "circle"}
                          style={{ color: "red" }}
                        />
                      </div>
                      <Progress
                        className="progress-bar"
                        percent={recording ? record_percent2 : 0}
                        size="small"
                        style={{
                          flexGrow: "1",
                          marginTop: "1.46rem",
                          backgroundColor: "#DDDDDD",
                        }}
                      />
                      <div
                        style={{
                          float: "right",
                          marginLeft: "1rem",
                          marginRight: "1rem",
                          marginTop: ".1rem",
                        }}
                      >
                        <p>{recording ? format(timer) : format(120)}</p>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
            </Grid.Column>
          ) : (
            <Grid.Column
              className="content-speaking-rec"
              style={{ margin: "1rem" }}
            >
              <div
                className="paper-box text-center"
                style={{
                  marginBottom: "1rem",
                  paddingBottom: "0px",
                  overflowY: "hidden",
                  fontWeight: "normal",
                }}
              >
                <h3 style={{ marginTop: "1rem" }}>Question</h3>
                <div
                  style={{ margin: "0rem 1.5rem 1.5rem 1.5rem" }}
                  className="instruction text-left"
                  dangerouslySetInnerHTML={{
                    __html: content[active_index].exam.question,
                  }}
                />
              </div>
              <AudioPlayer
                title="Your Recorded Answer"
                url={answers[active_index]}
              />
              <Recorder
                recording2={recording2}
                record_percent2={recording2 ? record_percent2 : 0}
                timer={recording2 ? format(timer2) : format(120)}
                toggleRecording={this.toggleRecording.bind(this)}
              />
              <AudioPlayer title="Your Recorded Answer" url={strat_url} />
              <div style={{ marginTop: "1rem" }}>
                <button
                  onClick={this.openModal.bind(this)}
                  className={"speaking-stop-button"}
                >
                  Vocab
                </button>
              </div>
            </Grid.Column>
          )}
        </Grid>
        {recording || strat_mode ? (
          <button
            className={"speaking-stop-button"}
            onClick={
              strat_mode ? this.finishStratMode : this.stopRecording.bind(this)
            }
          >
            Finish And Proceed
          </button>
        ) : null}
      </center>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    content: state.speakExam.content,
    active_index: state.speakExam.active_index,
    answers: state.speakExam.answers,
    answers_strat: state.speakExam.answers_strat,
    current_subtype: state.speakExam.current_subtype,
    strat_mode: state.speakExam.strat_mode,
    timer: state.speakPart2.timer,
    playing: state.speakPart2.playing,
    recording: state.speakPart2.recording,
    strat_url: state.speakPart2.strat_url,
    strat_file: state.speakPart2.strat_file,
    timer2: state.speakPart2.timer2,
    recording2: state.speakPart2.recording2,
    playing2: state.speakPart2.playing2,
    playing3: state.speakPart2.playing3,
  };
};

export default connect(mapStateToProps)(SpeakPart2);
