import * as actions from "../actions/types/writeExam";

const default_timer = 1200;

const defaultState = {
  timer: default_timer,
  active_index: 0,
  strat_mode: false,
  content: [],
  answers: [],
  answers_strat: [],
  notes_1: [],
};

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case actions.WRITE_EXAM_RESET_WRITE_EXAM: {
      return { ...defaultState };
    }
    case actions.WRITE_EXAM_UPDATE_TIMER: {
      return { ...state, timer: action.payload };
    }
    case actions.WRITE_EXAM_UPDATE_ACTIVE_INDEX: {
      return { ...state, active_index: action.payload };
    }
    case actions.WRITE_EXAM_UPDATE_STRAT_MODE: {
      return { ...state, strat_mode: action.payload };
    }
    case actions.WRITE_EXAM_RESET_TIMER: {
      return { ...state, timer: default_timer };
    }
    case actions.WRITE_EXAM_UPDATE_CONTENT: {
      return { ...state, content: action.payload };
    }
    case actions.WRITE_EXAM_UPDATE_ANSWERS: {
      return { ...state, answers: action.payload.slice() };
    }
    case actions.WRITE_EXAM_UPDATE_ANSWERS_STRAT: {
      return { ...state, answers_strat: action.payload.slice() };
    }
    case actions.WRITE_EXAM_UPDATE_NOTES: {
      return { ...state, notes_1: action.payload };
    }
    default:
      return { ...state };
  }
}
