import * as actions from '../actions/types/mockTest';

const defaultExamTimer = 3600;
const defaultInstructionTimer = 120;

const defaultState = {
  content:{
    reading:[],
    listening:[],
    writing:[],
    speaking:[],
    reading_instruction:"",
    listening_instruction:"",
    writing_instruction:"",
    speaking_instruction:"",
    test_type:'',
  },
  currentActivePage: 1,
  isReady:false,
  examTimer: defaultExamTimer,
  instructionTimer: defaultInstructionTimer,
  openConfirmModal:false,
};

export default function reducer(state=defaultState, action) {
  switch(action.type) {
    case actions.UPDATE_MOCK_TEST_CONTENT: {
      return {...state, content: action.payload};
    }
    case actions.UPDATE_MOCK_TEST_CURRENT_ACTIVE_PAGE: {
      return {...state, currentActivePage: action.payload};
    }
    case actions.UPDATE_MOCK_TEST_IS_READY: {
      return {...state, isReady: action.payload};
    }
    case actions.RESET_MOCK_TEST_EXAM_TIMER: {
      return {...state, examTimer: defaultExamTimer};
    }
    case actions.RESET_MOCK_TEST_INSTRUCTION_TIMER: {
      return {...state, instructionTimer: defaultInstructionTimer};
    }
    case actions.UPDATE_MOCK_TEST_EXAM_TIMER: {
      return {...state, examTimer: action.payload};
    }
    case actions.UPDATE_MOCK_TEST_INSTRUCTION_TIMER: {
      return {...state, instructionTimer: action.payload};
    }
    case actions.UPDATE_MOCK_TEST_SHOW_CONFIRM_MODAL: {
      return {...state, openConfirmModal: action.payload};
    }
    case actions.RESET_MOCK_TEST: {
      return {...defaultState};
    }
    default: return {...state};
  }
};