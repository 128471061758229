import React from 'react';
import _ from 'lodash';
import { Form, Image } from 'semantic-ui-react';
import Instruction from '../Instruction';

const ImageLabeling = (props) => {
	const {
    total_count,
    start_number,
    image,
    handleOnChange,
    parentIndex,
    answers,
    answers_strat,
    active_index,
    strat_mode,
    size,
    explanation,
    correct_answer,
  } = props;
  const blanks = _.times(
    total_count, i => {
      let value = "";
      if (answers.length > 0 && answers_strat.length > 0){
        value = answers[active_index][parentIndex][i];
        if (strat_mode){
          value = answers_strat[active_index][parentIndex][i];
        }
      }
      let explanationvalue = "";
      try {
        explanationvalue = correct_answer[i].replace(/{n}/g," / ");
      } catch (ex) {explanationvalue = value;}
      return (
        <div className="inline one fields" key={"ImageLabeling" + parentIndex + i}>
          <div className="field blank">
            <label>{start_number + i + ". "}</label>
            <div className="ui input">
              <input size={size?size[i]?size[i]:10:10} value={explanation?explanationvalue:value} key={"labelinginput"+parentIndex+i} type="text" className="blank2" onChange={handleOnChange.bind(this, parentIndex, i)} />
            </div>
          </div>
        </div>
      );
    }
  );
  return (
    <React.Fragment>
      <Instruction {...props} />
      <Image centered src={image} />
      <Form key={"FormImageLabeling" + parentIndex}>
        {blanks}
      </Form>
    </React.Fragment>
  );
}

export default ImageLabeling;