import * as mockTest from '../types/mockTest';

export const updateContent = (payload) => {
  return dispatch => {
    dispatch({
      type    : mockTest.UPDATE_MOCK_TEST_CONTENT,
      payload : payload,
    });
  };
};

export const updateCurrentPage = (payload) => {
  return dispatch => {
    dispatch({
      type    : mockTest.UPDATE_MOCK_TEST_CURRENT_ACTIVE_PAGE,
      payload : payload,
    });
  };
};

export const updateIsReady = (payload) => {
  return dispatch => {
    dispatch({
      type    : mockTest.UPDATE_MOCK_TEST_IS_READY,
      payload : payload,
    });
  };
};

export const resetInstructionTimer = () => {
  return dispatch => {
    dispatch({
      type    : mockTest.RESET_MOCK_TEST_INSTRUCTION_TIMER
    });
  };
};

export const resetExamTimer = () => {
  return dispatch => {
    dispatch({
      type    : mockTest.RESET_MOCK_TEST_EXAM_TIMER
    });
  };
};

export const updateInstructionTimer = (payload) => {
  return dispatch => {
    dispatch({
      type    : mockTest.UPDATE_MOCK_TEST_INSTRUCTION_TIMER,
      payload : payload,
    });
  };
};

export const updateExamTimer = (payload) => {
  return dispatch => {
    dispatch({
      type    : mockTest.UPDATE_MOCK_TEST_EXAM_TIMER,
      payload : payload,
    });
  };
};

export const updateConfirmModal = (payload) => {
  return dispatch => {
    dispatch({
      type    : mockTest.UPDATE_MOCK_TEST_SHOW_CONFIRM_MODAL,
      payload : payload
    });
  };
};

export const reset = () => {
  return dispatch => {
    dispatch({
      type    : mockTest.RESET_MOCK_TEST,
    });
  };
};