import React from 'react';
import Sound from 'react-sound';
import { connect } from 'react-redux';
import * as listenExamActions from '../../../../../../actions/app/listenExam';
import * as mockTestActions from '../../../../../../actions/app/mockTest';

class SpeakingSounds extends React.Component {
  onStop = (e) => {
    const { dispatch, content, current_page, active_index } = this.props;
    this.setState({playing:false});
    if (content.length - 1 === active_index){
      dispatch(mockTestActions.updateCurrentPage(current_page+1));
    } else {
      dispatch(listenExamActions.updateAudioPosition(0));
      dispatch(listenExamActions.updateCurrentIndex(active_index + 1));
      dispatch(listenExamActions.updateActiveIndex(active_index + 1));
    }
  }
  render() {
    const { url, dispatch, audio_position } = this.props;
    return (
      <Sound
        url={url}
        playStatus={Sound.status.PLAYING}
        onPlaying={({ position }) => dispatch(listenExamActions.updateAudioPosition(position))}
        playFromPosition={audio_position}
        onFinishedPlaying={this.onStop.bind(this)}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    content         : state.listenExam.content,
    active_index    : state.listenExam.active_index,
    audio_position  : state.listenExam.position,
    current_page    : state.mockTest.currentActivePage,
  }
};

export default connect(mapStateToProps)(SpeakingSounds);