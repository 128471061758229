import React from 'react';
import {
  Segment,
  Button,
  Grid,
  Container,
  Divider,
  Form,
  Radio
} from 'semantic-ui-react';
import InstructionTimer from './InstructionTimer';
import MicTest from './MicTest';
import Info from './Info';

const Instruction = props => {
  let instruction = "";
  let button_title = "";

  if (props.content.test_type === 'imt'){
    switch(props.activePage){
      case 1:
        instruction = props.content.listening_instruction;
        button_title = "Listening";
        break;
      case 3:
        instruction = props.content.reading_instruction;
        button_title = "Reading";
        break;
      case 5:
        instruction = props.content.writing_instruction;
        button_title = "Writing";
        break;
      case 7:
        instruction = props.content.speaking_instruction;
        button_title = "Speaking";
        break;
      default: break;
    }
  } else if (props.content.test_type === 'listening') {
    instruction = props.content.listening_instruction;
    button_title = "Listening";
  } else if (props.content.test_type === 'reading') {
    instruction = props.content.reading_instruction;
    button_title = "Reading";
  } else if (props.content.test_type === 'writing') {
    instruction = props.content.writing_instruction;
    button_title = "Writing";
  } else if (props.content.test_type === 'speaking') {
    instruction = props.content.speaking_instruction;
    button_title = "Speaking";
  }

  const test_type = props.content.test_type;

  return (
    <React.Fragment>
      <Grid.Row style={{paddingTop:"2rem", marginBottom:"2rem", overflowY:"scroll", maxHeight:"95vh"}}>
        {
          props.activePage !== 7 || props.content.test_type === 'speaking'?
            <Info />
            :
            null
        }
        <Container>
          <center style={{marginBottom:"1rem"}}>
            <Segment compact style={{width:"70%",padding:"16px 24px 24px"}} className="text-left">
              {
                (props.activePage !== 7 && test_type === 'imt') || test_type === 'reading' || test_type === 'listening' || test_type === 'writing' ?
                  <center>
                    <InstructionTimer exam_name={button_title} className="text-center" />
                    <Button type='button' size='tiny' primary onClick={props.nextPage}>
                      {`Click to start ${button_title} test immediately`.toUpperCase()}
                    </Button>
                  </center>
                  :
                  <React.Fragment>
                    <p>{"Before you proceed, scroll down and 1) read the information about the Speaking Test below and 2) do the microphone check once again. After that, select one of these two options to continue:"}</p>
                    <Form>
                      <Form.Field>
                        <Radio
                          label='My microphone is ready and I want to start the Speaking Test now!'
                          name='radioGroup'
                          value='ready'
                          checked={props.speakMode === 'ready'}
                          onChange={props.handleChangeSpeakMode}
                        />
                      </Form.Field>
                      <Form.Field>
                        <Radio
                          label='My microphone is NOT working and I want to skip the Speaking Test and proceed to the instructions to get my Listening, Reading and Writing Test results'
                          name='radioGroup'
                          checked={props.speakMode !== 'ready'}
                          onChange={props.handleChangeSpeakMode}
                          value='notready'
                        />
                      </Form.Field>
                    </Form>
                    <Button onClick={props.speakMode==='ready'? props.nextPage:props.finishExam} style={{marginTop:'1em'}} size={'tiny'} primary fluid>Proceed</Button>
                    <Divider />
                  </React.Fragment>
              }
              <div className="instruction" dangerouslySetInnerHTML={{__html: instruction}} style={{marginBottom:"1rem"}} />
              {
                props.activePage === 7 || props.content.test_type === 'speaking'?
                  <React.Fragment>
                    <Divider />
                    <MicTest/>
                  </React.Fragment>
                  :
                  null
              }
            </Segment>
          </center>
        </Container>
      </Grid.Row>
    </React.Fragment>
  )
};

export default Instruction;