import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Segment, Grid, Header, Form, Button } from 'semantic-ui-react';
import * as actions from '../../../../actions/app/nonStudent'

class FirstPage extends React.Component {
  updateProfile = (e, value) => {
    const { dispatch, profile } = this.props;
    profile[value.name] = value.value;
    dispatch(actions.updateProfile(profile));
  }
  handleSubmit = (e) => {
    e.preventDefault();
    this.props.dispatch(actions.updateCurrentPage(this.props.current_page + 1))
  }
  render(){
    const { name, email, test, } = this.props.profile;
    return (
      <Segment>
        <Grid textAlign='center' style={{height:"99.6vh"}}>
          <Grid.Column style={{ maxWidth: 700, marginTop: "2rem"}}>
            <Segment style={{ maxWidth: 700 }}>
              <p style={{ lineHeight:"1.5", "fontSize":"1.15rem", fontWeight:"500", marginTop:"1rem", "marginBottom":"1rem" }}>
                If you have not read the instructions for the mock test, please click <a href="http://www.ieltsmocktestonline.com/instructions/" rel="noopener noreferrer" target="_blank">Mock Test Instructions</a> now and read the page carefully so your test will be successful.
              </p>
            </Segment>
            <Form size='large' onSubmit={this.handleSubmit}>
              <center>
                <Segment stacked style={{ maxWidth: 450 }}>
                  <Form.Field style={{ marginTop: "1rem"}}>
                    <Header as='h3' textAlign='center' style={{ lineHeight:"1.5", fontWeight:"500"}}>
                      Before you take the test, please let us know your name and email.
                    </Header>
                  </Form.Field>
                  <Form.Input
                    style={{ marginTop: "1.5rem"}}
                    fluid icon='user'
                    iconPosition='left'
                    placeholder='First Name'
                    name='name'
                    value={name}
                    required={true}
                    onChange={this.updateProfile}
                  />
                  <Form.Input
                    fluid
                    icon='mail'
                    iconPosition='left'
                    placeholder='Email'
                    type='email'
                    name='email'
                    value={email}
                    required={true}
                    onChange={this.updateProfile}
                  />
                  <Form.Group>
                    <Form.Radio
                      label='General Training'
                      name='test'
                      value='gt'
                      style={{marginLeft:"3rem"}}
                      checked={test === 'gt'}
                      onClick={this.updateProfile}
                    />
                    <Form.Radio
                      label='Academic'
                      name='test'
                      value='ac'
                      checked={test === 'ac'}
                      onClick={this.updateProfile}
                    />
                    <Form.Radio
                      label='Not Sure'
                      name='test'
                      value='not_sure'
                      checked={test === 'not_sure'}
                      onClick={this.updateProfile}
                    />
                  </Form.Group>
                  <Button style={{marginTop:"2rem"}} primary fluid type="submit">
                    Proceed
                  </Button>
                </Segment>
              </center>
            </Form>
          </Grid.Column>
        </Grid>
      </Segment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    current_page : state.nonStudent.current_page,
    profile : state.nonStudent.profile,
  }
};

export default withRouter(connect(mapStateToProps)(FirstPage));