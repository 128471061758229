import React from "react";

const Instruction = (props) => {
  if (!props) {
    return <></>;
  }
  return (
    <div
      className="instruction margin-top-1rem"
      style={{ marginBottom: "2rem" }}
      dangerouslySetInnerHTML={{
        __html: props.content[props.active_index].instruction,
      }}
    />
  );
};

export default Instruction;
