import React from "react";
import { connect } from "react-redux";
import {
  Button,
  Form,
  Grid,
  Header,
  Message,
  Segment,
} from "semantic-ui-react";
import * as loginActions from "../../actions/app/login";

import { resetSignupFields } from "../../actions/app/signup";
import * as apiActions from "../../actions/app/api";
import { Link } from "react-router-dom";
import * as api from "../../actions/api/requests";

import Loader from "./Loader";

function hasWhiteSpace(s) {
  return /\s/g.test(s);
}

class RegistrationForm extends React.Component {
  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(apiActions.updateApiSending(false));
    dispatch(resetSignupFields());
  }
  handleChange = (e) => {
    const { user, dispatch } = this.props;
    user[e.target.name] = e.target.value;
    dispatch(loginActions.updateLoginFields(user));
  };
  handleSubmit = (e) => {
    e.preventDefault();
    const { dispatch, user, history } = this.props;
    if (hasWhiteSpace(user.username)) {
      alert("Do not put spaces in the user name");
      return;
    }
    if (user.password !== user.password2) {
      alert(
        "Password mismatch. Please enter the correct password for both fields."
      );
      return;
    }
    dispatch(apiActions.updateApiSending(true));
    dispatch(api.signup(user, history));
  };
  render() {
    const { username, first_name, last_name, password, password2 } =
      this.props.user;
    return (
      <Segment>
        <Loader />
        <Grid textAlign="center" className="login-bg">
          <Grid.Column className="margin-top-50" style={{ maxWidth: 650 }}>
            <Header as="h2" color="orange" textAlign="center">
              Sign Up
            </Header>
            <Form size="large" onSubmit={this.handleSubmit}>
              <Segment stacked>
                <Form.Input
                  placeholder="Email"
                  name="username"
                  onChange={this.handleChange.bind(this)}
                  defaultValue={username}
                  required={true}
                  type="email"
                />
                <Form.Input
                  placeholder="First Name"
                  name="first_name"
                  onChange={this.handleChange.bind(this)}
                  defaultValue={first_name}
                  required={true}
                />
                <Form.Input
                  placeholder="Last Name"
                  name="last_name"
                  onChange={this.handleChange.bind(this)}
                  defaultValue={last_name}
                  required={true}
                />
                <Form.Input
                  fluid
                  placeholder="Password"
                  type="password"
                  name="password"
                  onChange={this.handleChange.bind(this)}
                  defaultValue={password}
                  required={true}
                />
                <Form.Input
                  fluid
                  placeholder="Confirm Password"
                  type="password"
                  name="password2"
                  onChange={this.handleChange.bind(this)}
                  defaultValue={password2}
                  required={true}
                />
                <Button color="orange" fluid size="large" type="submit">
                  Register
                </Button>
              </Segment>
            </Form>
            <Message>
              Go back to <Link to="/">Login Screen</Link>
            </Message>
          </Grid.Column>
        </Grid>
      </Segment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.signup,
    sending: state.api.sending,
  };
};

export default connect(mapStateToProps)(RegistrationForm);
