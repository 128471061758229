import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as api from '../../../../actions/api/requests';
import FirstPage from './FirstPage';
import SecondPage from './SecondPage';

class NonStudentContainer extends React.Component {
  componentDidMount(){
    const { dispatch, profile } = this.props;
    dispatch(api.lookUpCountry(profile));
  }
  render(){
    const { current_page } = this.props;
    return (
      <React.Fragment>
        {
          current_page === 1?
            <FirstPage/>
            :
          current_page === 2?
            <SecondPage/>
            :
            null
        }
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    current_page  : state.nonStudent.current_page,
    profile       : state.nonStudent.profile,
  }
};

export default withRouter(connect(mapStateToProps)(NonStudentContainer));