import React, { Component } from 'react';
import {
  Grid,
} from 'semantic-ui-react';
import { Route } from "react-router-dom";
import * as Parts from './parts';
import * as ReadingExams from './exams/reading';
import ListeningContainer from './exams/listening/ListenContainer';
import WritingContainer from './exams/writing/WritingContainer';
import SpeakingContainer from './exams/speaking/SpeakingContainer';
import * as CustomizeExam from './customize';

export default class Main extends Component {
  render() {
    const { match } = this.props;
    const exam_path = match.path === '/exam';
    return (
      <Grid doubling stackable padded>
        <Parts.Loader/>
        <Parts.ListeningModal/>
          {
            !exam_path?
              <React.Fragment>
                <Parts.Header/>
                <Grid.Row>
                  <Grid.Column width={16}>
                    <Route exact path={`${match.path}`} component={Parts.AnswerHistory} />
                    <Route path={`${match.path}/build/listen`} component={CustomizeExam.CustomizeListening} />
                    <Route path={`${match.path}/build/read`} component={CustomizeExam.CustomizeReading} />
                    <Route path={`${match.path}/build/write`} component={CustomizeExam.CustomizeWriting} />
                    <Route path={`${match.path}/build/speak`} component={CustomizeExam.CustomizeSpeaking} />
                  </Grid.Column>
                </Grid.Row>
                <Parts.Footer/>
              </React.Fragment>
              :
              <React.Fragment>
                <Route path={`${match.path}/read`} component={ReadingExams.ReadContainer} />
                <Route path={`${match.path}/listen`} component={ListeningContainer} />
                <Route path={`${match.path}/write`} component={WritingContainer} />
                <Route path={`${match.path}/speak`} component={SpeakingContainer} />
              </React.Fragment>
          }
      </Grid>
    )
  }
}