import React from "react";
import { Form, Radio, Accordion, Icon } from "semantic-ui-react";
import Instruction from "./Instruction";

const ReadRadioMcq = (props) => {
  const {
    questions,
    start_number,
    handleRadioOnChange,
    parentIndex,
    answers,
    current_index,
    explanation,
    correct_answer,
    accordionIndex,
    onAccordionClick,
  } = props;
  const aIndex = accordionIndex.index;
  const pIndex = accordionIndex.parentIndex;
  const blanks = questions.map((obj, i) => {
    let value = "";
    if (answers.length > 0) {
      value = answers[current_index][parentIndex][i];
    }
    let text = obj.text.replace(/<\/br>/g, "");
    text = text.replace(/<br>/g, "");
    text = text.replace(/<b\/r>/g, "");
    return (
      <React.Fragment key={"question" + i}>
        <Accordion.Title
          active
          onClick={onAccordionClick.bind(this, parentIndex, i)}
          index={i}
        >
          <Icon name="dropdown" />
          {obj.number ? obj.number + ". " : start_number + i + ". "}
          <span dangerouslySetInnerHTML={{ __html: text }} />
        </Accordion.Title>
        <Accordion.Content active style={{ marginLeft: "1.5rem" }}>
          {obj.choices.map((choice, j) => {
            return (
              <Form.Field key={"choice" + j}>
                <Radio
                  key={`readradiomcq${parentIndex}${i}`}
                  onClick={handleRadioOnChange.bind(
                    this,
                    parentIndex,
                    i,
                    j + 1
                  )}
                  name={"radioGroup" + i}
                  checked={
                    explanation
                      ? correct_answer[i] === j + 1
                      : parseInt(value, 10) === j + 1
                  }
                />
                &nbsp;
                <span
                  className="cursor-pointer"
                  onClick={handleRadioOnChange.bind(
                    this,
                    parentIndex,
                    i,
                    j + 1
                  )}
                  dangerouslySetInnerHTML={{ __html: choice }}
                />
              </Form.Field>
            );
          })}
        </Accordion.Content>
      </React.Fragment>
    );
  });
  return (
    <React.Fragment>
      <Instruction {...props} />
      <Accordion
        styled
        fluid
        style={{ width: "98%", borderLeft: "rgba(0,0,0,.4) solid 1px" }}
        exclusive={false}
      >
        {blanks}
      </Accordion>
    </React.Fragment>
  );
};

export default ReadRadioMcq;
