// export const API = 'https://iit-backend.herokuapp.com/api/';
// export const SERVER = 'https://iit-backend.herokuapp.com/';

export const API = 'https://theieltssummit.com/api/';
export const SERVER = 'https://theieltssummit.com/';

// export const API = 'http://localhost:8000/api/';
// export const SERVER = 'http://localhost:8000/';

export const IP_API = 'http://ip-api.com/json/';

export const SG_EXAM_DESTINATION_URL = 'http://www.ieltsuniversity.com/imt-get-results-sg';
export const INT_EXAM_DESTINATION_URL = 'https://ieltsuniversity.online/imt-get-results-int';

export const GENERATE_READING_EXAMS = SERVER + 'generate_reading_exams/';
export const GENERATE_LISTENING_EXAMS = SERVER + 'generate_listening_exams/';
export const GENERATE_SPEAKING_EXAMS = SERVER + 'generate_speaking_exams/';
export const GENERATE_WRITING_EXAMS = SERVER + 'generate_writing_exams/';
export const GENERATE_MOCK_TEST_API = SERVER+ 'generate_mock_test/';
export const GENERATE_MOCK_TEST_STUDENT_API = SERVER+ 'generate_mock_test_student/';
export const PREVIEW_MOCK_TEST_API = SERVER+ 'preview_mock_test/';

export const ANSWER_API = API + 'answer/';

export const WRITE_API = API + 'write/';
export const READ_API = API + 'read/';
export const LISTEN_API = API + 'listen/';
export const SPEAK_API = API + 'speak/';
export const MOCK_TEST_API = API + 'mock-test/';

export const NON_STUDENT_SG_API = API + 'non-student-singapore/';
export const NON_STUDENT_ITL_API = API + 'non-student-international/';
export const NON_STUDENT_ANSWER_API = API + 'non-student-answer/';
export const NON_STUDENT_LIST = SERVER + 'non_students/';
export const STUDENT_IP_API = API + 'student-ip/';
export const VERIFY_EMAIL = SERVER + 'verify_email?username=';

export const MARKETING_API = API + 'marketing/';

export const RESET_PASSWORD_URL = SERVER + 'accounts/password_reset';
export const IP_LOOKUP = 'https://ipinfo.io/?token=ec3af8868580b7';