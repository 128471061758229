import React from "react";
import { Segment, Icon, Form } from "semantic-ui-react";
import stringReplace from "react-string-replace";
import Instruction from "./Instruction";

const ReadFlowChart = (props) => {
  const {
    questions,
    start_number,
    handleOnChange,
    parentIndex,
    answers,
    answers_strat,
    active_index,
    strat_mode,
    size,
    explanation,
    correct_answer,
  } = props;
  const blanks = questions.map((obj, i) => {
    let value = "";
    if (answers.length > 0 && answers_strat.length > 0) {
      value = answers[active_index][parentIndex][i];
      if (strat_mode) {
        value = answers_strat[active_index][parentIndex][i];
      }
    }
    let htmlString = "";
    if (explanation) {
      let underlined_answer = "";
      try {
        underlined_answer =
          '<span style="text-decoration: underline;">&nbsp;&nbsp;' +
          correct_answer[i].replace(/{n}/g, " / ") +
          "&nbsp;&nbsp;</span>";
      } catch (ex) {}
      htmlString = obj
        .replace('<input class="replace"/>', underlined_answer)
        .replace('<input class="replace" type="text" />', underlined_answer);
    } else {
      htmlString = stringReplace(
        start_number + i + ". " + obj,
        '<input class="replace"/>',
        (match, j) => {
          return (
            <input
              className="blank2"
              size={size ? (size[i] ? size[i] : 10) : 10}
              value={
                explanation ? correct_answer[i]?.replace(/{n}/g, " / ") : value
              }
              type="text"
              key={"flowchart" + parentIndex + i}
              onChange={handleOnChange.bind(this, parentIndex, i)}
            />
          );
        }
      );
    }
    return (
      <div
        key={"ReadFlowChartContainer" + parentIndex + i}
        className="text-center"
      >
        <Segment textAlign="center" className="flow-chart-box full-height-100">
          {explanation ? (
            <span dangerouslySetInnerHTML={{ __html: htmlString }} />
          ) : (
            htmlString
          )}
        </Segment>
        {i !== questions.length - 1 ? (
          <Icon name="arrow down" size="big" />
        ) : null}
      </div>
    );
  });
  return (
    <React.Fragment>
      <Instruction {...props} className="margin-top-1rem" />
      <Form key={"ReadFlowChartForm" + parentIndex}>{blanks}</Form>
    </React.Fragment>
  );
};

export default ReadFlowChart;
