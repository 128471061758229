import React from "react";
import { connect } from "react-redux";
import { Grid, Icon, Button, Progress, TextArea } from "semantic-ui-react";
import HintModal from "./HintModal";
import { ReactMic } from "react-mic";
import * as speakPart2Actions from "../../../../../actions/app/speakPart2";
import * as speakExamActions from "../../../../../actions/app/speakExam";
import * as apiActions from "../../../../../actions/app/api";
import ReactPlayer from "react-player";
import AudioPlayer from "./Player";
import Recorder from "./Recorder";

function pad(string) {
  return ("0" + string).slice(-2);
}

function format(seconds) {
  const date = new Date(seconds * 1000);
  const mm = date.getUTCMinutes();
  const ss = pad(date.getUTCSeconds());
  return `${pad(mm)}:${pad(ss)}`;
}

class SpeakPart2 extends React.Component {
  state = {
    openModal: false,
    openHint: false,
    oscillationOn: false,
    studentAnswer: "",
    showQuestion: false,
  };
  openModal = () => {
    this.setState({ openModal: true });
  };
  closeModal = () => {
    this.setState({ openModal: false });
  };
  openHint = () => {
    this.setState({ openHint: true });
  };
  closeHint = () => {
    this.setState({ openHint: false });
  };
  componentDidMount() {
    const { dispatch, strat_mode } = this.props;
    if (!strat_mode) {
      dispatch(speakPart2Actions.reset());
    }
    dispatch(apiActions.updateApiSending(false));
  }
  componentWillUnmount() {
    this.clearTimer();
  }
  clearTimer() {
    clearInterval(this.timer);
    this.timer = null;
  }
  onStop = () => {
    this.setState({ showQuestion: true });
    this.timer = setInterval(this.tick.bind(this), 1000);
  };
  tick = () => {
    const { timer, dispatch } = this.props;
    const newTimer = timer - 1;
    if (newTimer >= 0) {
      dispatch(speakPart2Actions.updateTimer(newTimer));
    } else {
      this.clearTimer();
      this.setState({ showQuestion: false, finishShowingQuestion: true });
      dispatch(speakPart2Actions.updatePlaying3(true));
    }
  };
  tick2 = () => {
    const { timer2, dispatch } = this.props;
    const newTimer = timer2 - 1;
    if (newTimer >= 0) {
      dispatch(speakPart2Actions.updateTimer2(newTimer));
    } else {
      this.clearTimer();
      this.stopRecording();
    }
  };
  stopRecording = () => {
    const { dispatch, recording } = this.props;
    this.clearTimer();
    dispatch(apiActions.updateApiSending(true));
    dispatch(speakPart2Actions.updateRecording(false));
    if (recording) {
      this.props.stopRecording();
    } else {
      this.props.updateAnswer("");
    }
  };
  toggleRecording(on) {
    const { dispatch } = this.props;
    if (on) {
      dispatch(speakPart2Actions.updateTimer2(120));
      this.timer = setInterval(this.tick2.bind(this), 1000);
      this.props.startRecording();
    } else {
      dispatch(speakPart2Actions.updateTimer2(120));
      this.clearTimer();
      this.props.stopRecording();
    }
    dispatch(speakPart2Actions.updateRecording2(on));
  }
  stopPlaying2 = () => {
    this.props.dispatch(speakPart2Actions.updatePlaying2(false));
  };
  finishStratMode = () => {
    const { dispatch, strat_file, recording2 } = this.props;
    if (recording2) {
      dispatch(apiActions.updateApiSending(true));
      this.props.stopRecordingStratMode();
      dispatch(speakPart2Actions.updateRecording2(false));
    } else {
      if (strat_file !== null) {
        dispatch(apiActions.updateApiSending(true));
        this.props.uploadRecording(strat_file);
      } else {
        this.props.updateAnswer("");
      }
    }
  };
  onStop2 = () => {
    const { dispatch } = this.props;
    dispatch(speakPart2Actions.updateTimer(120));
    this.timer = setInterval(this.tick2.bind(this), 1000);
    dispatch(speakPart2Actions.updateRecording(true));
    this.props.startRecording();
  };

  updateNotes1 = (event, data) => {
    const { notes_1, active_index } = this.props;
    const newNotes = JSON.parse(JSON.stringify(notes_1));
    newNotes[active_index] = data.value;
    this.props.dispatch(speakExamActions.updateNotes1(newNotes));
  };
  updateNotes2 = (event, data) => {
    const { notes_2, active_index } = this.props;
    const newNotes = JSON.parse(JSON.stringify(notes_2));
    newNotes[active_index] = data.value;
    this.props.dispatch(speakExamActions.updateNotes2(newNotes));
  };
  updateNotes3 = (event, data) => {
    const { notes_3, active_index } = this.props;
    const newNotes = JSON.parse(JSON.stringify(notes_3));
    newNotes[active_index] = data.value;
    this.props.dispatch(speakExamActions.updateNotes3(newNotes));
  };

  render() {
    const {
      active_index,
      answers,
      content,
      current_subtype,
      playing,
      recording,
      strat_mode,
      strat_url,
      timer,
      timer2,
      recording2,
      playing2,
      playing3,
      notes_1,
      notes_2,
      notes_3,
    } = this.props;
    const { openModal, showQuestion, openHint } = this.state;
    let record_percent2 = 100 - (timer2 / 120) * 100;
    const width = showQuestion ? "75%" : "50%";

    return (
      <center>
        <HintModal
          header="Vocab"
          hint={content[active_index].vocab}
          open={openModal}
          onClose={this.closeModal}
        />
        <HintModal
          header="Hints"
          hint={content[active_index].hint}
          open={openHint}
          onClose={this.closeHint}
        />
        <ReactPlayer
          key={"player1" + active_index}
          className="invisible"
          url={content[active_index].audio[current_subtype]}
          playing={playing}
          onEnded={this.onStop}
        />
        <ReactPlayer
          key={"player2" + active_index}
          className="invisible"
          url={content[active_index].audio[current_subtype]}
          playing={playing2}
          onEnded={this.stopPlaying2}
        />
        <ReactPlayer
          key={"player3" + active_index}
          className="invisible"
          url={content[active_index].audio[1]}
          playing={playing3}
          onEnded={this.onStop2}
        />
        <Grid
          className="speaking-container"
          columns={"equal"}
          style={{
            margin: ".5rem",
            width: width,
          }}
        >
          {showQuestion ? (
            <Grid.Column
              className="content-speaking-rec"
              style={{ margin: "1rem" }}
            >
              <div
                className="paper-box text-center"
                style={{
                  marginBottom: "2rem",
                  paddingBottom: "0px",
                  overflowY: "hidden",
                  fontWeight: "normal",
                }}
              >
                <Icon
                  style={{ marginTop: "1rem" }}
                  size="big"
                  color="blue"
                  loading
                  name="spinner"
                />
                <p>
                  {recording
                    ? `Time Remaining for Your Answer: ${format(timer)}`
                    : `Time Remaining for Making Notes: ${format(timer)}`}
                </p>
                <h3>Part 2 Question</h3>
                <div
                  style={{ margin: "0rem 1.5rem 1.5rem 1.5rem" }}
                  className="instruction text-left"
                  dangerouslySetInnerHTML={{
                    __html: content[active_index].question,
                  }}
                />
              </div>
              <h5 className="speaking-review-header">
                You have 1 minute to write your notes. Do note that in the
                actual IELTS exam you would be doing so on paper and in front of
                a human examiner.
              </h5>
              <h5 className="speaking-review-header">
                Your "Testing" Notes (Ideas, Vocab, etc.)
              </h5>
              <TextArea
                key="text-area-1"
                className="notes-text"
                rows={5}
                onChange={this.updateNotes1}
                value={notes_1[active_index]}
              />
              <br />
              <br />
              <br />
            </Grid.Column>
          ) : !strat_mode ? (
            <Grid.Column
              className="content-speaking-rec"
              style={{ margin: "1rem", height: recording ? "100%" : "280px" }}
            >
              {recording ? (
                <div
                  className="paper-box text-center"
                  style={{
                    marginBottom: "2rem",
                    paddingBottom: "0px",
                    overflowY: "hidden",
                    fontWeight: "normal",
                  }}
                >
                  <h3 style={{ marginTop: "1rem" }}>Part 2 Question</h3>
                  <div
                    style={{ margin: "0rem 1.5rem 1.5rem 1.5rem" }}
                    className="instruction text-left"
                    dangerouslySetInnerHTML={{
                      __html: content[active_index].question,
                    }}
                  />
                </div>
              ) : (
                <div
                  className="paper-box"
                  style={{
                    marginBottom: "2rem",
                    height: "182px",
                    paddingBottom: "0px",
                    overflowY: "hidden",
                  }}
                >
                  <div style={{ height: "65%" }}>
                    <h4
                      style={{
                        marginTop: "1rem",
                        marginLeft: "1rem",
                        textAlign: "left",
                      }}
                    >
                      Examiner
                    </h4>
                    {!recording || playing2 ? (
                      <React.Fragment>
                        <Icon
                          color={"green"}
                          name={"user"}
                          className="interviewer-icon"
                        />
                        <Icon
                          color={"green"}
                          name={"comment alternate"}
                          className="interviewer-icon"
                        />
                      </React.Fragment>
                    ) : (
                      <Icon
                        color={"red"}
                        name={"user cancel"}
                        className="interviewer-icon"
                      />
                    )}
                  </div>
                  <div className="recorder-bottom text-center"></div>
                </div>
              )}

              {recording && (
                <>
                  <h5 className="speaking-review-header">
                    Your "Testing" Notes (Ideas, Vocab, etc.)
                  </h5>
                  <TextArea
                    key="text-area-1"
                    className="notes-text"
                    rows={5}
                    value={notes_1[active_index]}
                    disabled
                  />
                </>
              )}

              <p className={recording ? "text-center blinking" : "text-center"}>
                {recording
                  ? "Recording your answer"
                  : "Please listen to the audio and prepare your response"}
              </p>

              <div
                className="paper-box"
                style={{
                  marginTop: "2rem",
                  height: "182px",
                  paddingBottom: "0px",
                  visibility: recording ? "visible" : "hidden",
                }}
              >
                <div style={{ height: "65%" }}>
                  <h4
                    style={{
                      marginTop: "1rem",
                      marginLeft: "1rem",
                      textAlign: "left",
                    }}
                  >
                    Audio Recorder To Record Your "Testing" Answer
                  </h4>
                  <ReactMic
                    key={"microphone12345"}
                    record={recording}
                    className="sound-wave"
                    backgroundColor="#F5F8FF"
                  />
                </div>
                <div className="recorder-bottom text-center">
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div
                      style={{
                        marginTop: "7px",
                        marginLeft: "1rem",
                        marginRight: "1rem",
                      }}
                    >
                      <Button
                        disabled={!recording}
                        onClick={this.stopRecording.bind(this)}
                        circular
                        className={recording ? "blinking" : ""}
                        icon={recording ? "stop" : "circle"}
                        style={{ color: "red" }}
                      />
                    </div>
                    <Progress
                      className="progress-bar"
                      percent={recording ? record_percent2 : 0}
                      size="small"
                      style={{
                        flexGrow: "1",
                        marginTop: "1.46rem",
                        backgroundColor: "#DDDDDD",
                      }}
                    />
                    <div
                      style={{
                        float: "right",
                        marginLeft: "1rem",
                        marginRight: "1rem",
                        marginTop: ".1rem",
                      }}
                    >
                      <p>{format(timer2)}</p>
                    </div>
                  </div>
                </div>
              </div>
            </Grid.Column>
          ) : (
            <Grid.Column
              className="content-speaking-rec"
              style={{ margin: "1rem" }}
            >
              <div
                className="paper-box text-center"
                style={{
                  marginBottom: "1rem",
                  paddingBottom: "0px",
                  overflowY: "hidden",
                  fontWeight: "normal",
                }}
              >
                <h3 style={{ marginTop: "1rem" }}>Part 2 Question</h3>
                <div
                  style={{ margin: "0rem 1.5rem 1.5rem 1.5rem" }}
                  className="instruction text-left"
                  dangerouslySetInnerHTML={{
                    __html: content[active_index].question,
                  }}
                />
              </div>
              <div className="testing-area-speaking">
                <h5 className="speaking-review-header">
                  Your "Testing" Notes (Ideas, Vocab, etc.)
                </h5>
                <TextArea
                  key="text-area-2"
                  className="notes-text"
                  rows={5}
                  value={notes_1[active_index]}
                  disabled
                />
                <AudioPlayer
                  title='Your "Testing" Recorded Answer'
                  url={answers[active_index]}
                  key={`audio-player-testing-${active_index}`}
                />
              </div>
              <div className="learning-area-speaking">
                <h5 className="speaking-review-header">
                  Your "Learning" Notes (Ideas, Vocab, etc.)
                </h5>
                <TextArea
                  key="text-area-3"
                  className="notes-text"
                  rows={5}
                  value={notes_2[active_index]}
                  onChange={this.updateNotes2}
                />
                <h5 className="speaking-review-header">
                  Your "Learning" Written Response
                </h5>
                <TextArea
                  key="text-area-4"
                  className="notes-text"
                  rows={5}
                  value={notes_3[active_index]}
                  onChange={this.updateNotes3}
                />
                <Recorder
                  recording2={recording2}
                  record_percent2={recording2 ? record_percent2 : 0}
                  timer={format(timer2)}
                  toggleRecording={this.toggleRecording.bind(this)}
                  title={'Audio Recorder To Record Your "Learning" Answer'}
                />
                <AudioPlayer
                  title={'Your "Learning" Recorded Answer'}
                  key={`audio-player-learning-${active_index}`}
                  url={strat_url}
                />
              </div>
              <div style={{ marginTop: "1rem" }}>
                <button
                  onClick={this.openModal.bind(this)}
                  className={"speaking-stop-button"}
                >
                  Vocab
                </button>
              </div>
            </Grid.Column>
          )}
        </Grid>
        {recording || strat_mode ? (
          <button
            className={"speaking-stop-button"}
            onClick={
              strat_mode ? this.finishStratMode : this.stopRecording.bind(this)
            }
          >
            Finish And Proceed
          </button>
        ) : null}
        <br />
        <br />
        <br />
      </center>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    content: state.speakExam.content,
    active_index: state.speakExam.active_index,
    answers: state.speakExam.answers,
    answers_strat: state.speakExam.answers_strat,
    current_subtype: state.speakExam.current_subtype,
    strat_mode: state.speakExam.strat_mode,
    timer: state.speakPart2.timer,
    playing: state.speakPart2.playing,
    recording: state.speakPart2.recording,
    strat_url: state.speakPart2.strat_url,
    strat_file: state.speakPart2.strat_file,
    timer2: state.speakPart2.timer2,
    recording2: state.speakPart2.recording2,
    playing2: state.speakPart2.playing2,
    playing3: state.speakPart2.playing3,
    notes_1: state.speakExam.notes_1,
    notes_2: state.speakExam.notes_2,
    notes_3: state.speakExam.notes_3,
  };
};

export default connect(mapStateToProps)(SpeakPart2);
