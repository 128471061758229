import React from "react";
import { Grid, Container, Segment, Button, Menu } from "semantic-ui-react";
import { connect } from "react-redux";

import ReadCombined from "../reading/ReadCombined";
import * as listenExamActions from "../../../../actions/app/listenExam";
import * as listenReviewActions from "../../../../actions/app/listenReview";
import ExamNav from "../ExamNav";

import * as apiActions from "../../../../actions/app/api";
import * as api from "../../../../actions/api/requests";

import EXAM from "../../../../constants/examTypes";
import Cassette from "../../../others/Cassette";

import { withRouter } from "react-router-dom";

function onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}

class ListeningContainer extends React.Component {
  componentDidMount() {
    const { answers, countdown_started } = this.props;
    if (answers.length === 0) {
      this.setBlankAnswers();
    }
    if (countdown_started) {
      this.startTimer();
    }
  }
  setBlankAnswers() {
    const { content, dispatch } = this.props;
    const final_blank_answers = [];
    const final_blank_answers2 = [];
    for (let i = 0; i < content.length; i++) {
      const answers = [];
      const answers2 = [];
      // eslint-disable-next-line
      content[i].exam.exams.map((exam) => {
        answers.push(Array(exam.correct_answer.length).join(".").split("."));
        answers2.push(Array(exam.correct_answer.length).join(".").split("."));
      });
      final_blank_answers.push(answers);
      final_blank_answers2.push(answers2);
    }
    dispatch(listenExamActions.updateAnswer(final_blank_answers));
    dispatch(listenExamActions.updateAnswerStrat(final_blank_answers2));
  }
  componentWillUnmount() {
    this.clearTimer();
    this.props.dispatch(listenExamActions.reset());
  }
  startTimer() {
    this.timer = setInterval(this.tick.bind(this), 1000);
  }
  tick() {
    const { timer, dispatch } = this.props;
    const newTimer = timer - 1;
    if (newTimer >= 0) {
      dispatch(listenExamActions.updateTimer(newTimer));
    } else {
      this.clearTimer();
      this.saveAnswer();
    }
  }
  clearTimer() {
    clearInterval(this.timer);
    this.timer = null;
  }
  handleOnChange = (i, j, e) => {
    const { answers, answers_strat, active_index, dispatch, strat_mode } =
      this.props;
    let newAnswers = answers.slice();
    if (strat_mode) {
      newAnswers = answers_strat.slice();
    }
    newAnswers[active_index][i][j] = e.target.value;
    if (strat_mode) {
      dispatch(listenExamActions.updateAnswerStrat(newAnswers));
    } else {
      dispatch(listenExamActions.updateAnswer(newAnswers));
    }
  };
  handleRadioOnChange = (i, j, answer, e) => {
    const { answers, answers_strat, active_index, dispatch, strat_mode } =
      this.props;
    let newAnswers = answers.slice();
    if (strat_mode) {
      newAnswers = answers_strat.slice();
    }
    newAnswers[active_index][i][j] = answer;
    if (strat_mode) {
      dispatch(listenExamActions.updateAnswerStrat(newAnswers));
    } else {
      dispatch(listenExamActions.updateAnswer(newAnswers));
    }
  };
  handleCheckboxOnChange = (i, j, answer, checked) => {
    const {
      answers,
      answers_strat,
      active_index,
      dispatch,
      strat_mode,
      content,
    } = this.props;
    // Get max answer size
    const max_answers =
      content[active_index].exam.exams[i].correct_answer[j].length;
    let newAnswers = answers.slice();
    if (strat_mode) {
      newAnswers = answers_strat.slice();
    }
    let newArr = [];
    if (newAnswers[active_index][i][j].length > 0) {
      newArr = newAnswers[active_index][i][j].split(",");
    }
    if (newArr.indexOf(answer.toString()) === -1) {
      // If current is equal or greater than the size of max answer, do not proceed
      if (newArr.length >= max_answers) {
        return;
      }
      newArr.push(answer);
    } else {
      newArr.splice(newArr.indexOf(answer.toString()), 1);
    }
    newArr = newArr.filter(onlyUnique).join(",");
    newAnswers[active_index][i][j] = newArr;
    if (strat_mode) {
      dispatch(listenExamActions.updateAnswerStrat(newAnswers));
    } else {
      dispatch(listenExamActions.updateAnswer(newAnswers));
    }
  };
  handleDetailedFlowChartChange = (i, j, e) => {
    const { answers, answers_strat, active_index, dispatch, strat_mode } =
      this.props;
    let newAnswers = answers.slice();
    if (strat_mode) {
      newAnswers = answers_strat.slice();
    }
    newAnswers[active_index][i][j] = e.target.value;
    if (strat_mode) {
      dispatch(listenExamActions.updateAnswerStrat(newAnswers));
    } else {
      dispatch(listenExamActions.updateAnswer(newAnswers));
    }
  };
  state = {
    answers: [],
    handleOnChange: this.handleOnChange.bind(this),
    handleRadioOnChange: this.handleRadioOnChange.bind(this),
    handleCheckboxOnChange: this.handleCheckboxOnChange.bind(this),
    handleDetailedFlowChartChange:
      this.handleDetailedFlowChartChange.bind(this),
    showTranscript: false,
  };
  toggleTranscript = () => {
    this.setState({ showTranscript: !this.state.showTranscript });
  };
  saveAnswer = () => {
    const elements = document.getElementsByClassName("exam-divs");
    for (let i = 0; i < elements.length; i++) {
      elements[i].scrollTo(0, 0);
    }
    const {
      dispatch,
      strat_mode,
      active_index,
      content,
      history,
      answers,
      answers_strat,
      answerInstance,
      is_staff,
    } = this.props;

    if (!strat_mode) {
      this.clearTimer();
      dispatch(listenExamActions.updateStratMode(true));
    } else {
      if (active_index + 1 === content.length) {
        const data = {
          name: answerInstance.name,
          answers: answers,
          answers_strat: answers_strat,
          content: content,
          id: answerInstance.id,
          finished_at: new Date(),
          test_type: EXAM.LISTENING,
        };
        dispatch(listenReviewActions.updateContent(data));
        dispatch(apiActions.updateApiSending(true));
        if (is_staff) {
          history.push("/review/listen");
          return;
        } else {
          dispatch(
            api.updateAnswerInstance({ ...data }, history, "/review/listen")
          );
          return;
        }
      }
      dispatch(listenExamActions.resetTimer());
      dispatch(listenExamActions.resetPosition());
      dispatch(listenExamActions.setCountdownStarted(false));
      dispatch(listenExamActions.updateStratMode(false));
      dispatch(listenExamActions.updateActiveIndex(active_index + 1));
    }
  };
  onPlaying = (e) => {
    this.props.dispatch(listenExamActions.updateAudioPosition(e.position));
  };
  onStop = (e) => {
    this.props.dispatch(listenExamActions.setCountdownStarted(true));
    this.startTimer();
  };
  render() {
    const { strat_mode, content, active_index } = this.props;
    const { showTranscript } = this.state;
    return (
      <Grid.Row columns={2}>
        <Grid.Column width={2} className="no-padding-right">
          <Container fluid className="full-height exam-nav">
            <Menu fluid vertical className="test-nav">
              <ExamNav {...this.props} />
            </Menu>
          </Container>
        </Grid.Column>
        <Grid.Column width={14}>
          <Grid columns={"equal"}>
            <Grid.Column
              stretched={!strat_mode}
              style={
                !strat_mode || !showTranscript ? { maxWidth: "50%" } : null
              }
            >
              <Container>
                <Segment className="exam-divs listen-height-85vh listen-max-height-85vh margin-bottom-child-10px">
                  <ReadCombined {...this.state} {...this.props} />
                </Segment>
                {!strat_mode ? (
                  <Button
                    style={{
                      backgroundColor: "rgb(242, 113, 28)",
                      color: "white",
                    }}
                    floated="right"
                    onClick={this.saveAnswer}
                    size="tiny"
                  >
                    Finish And Proceed
                  </Button>
                ) : (
                  <React.Fragment>
                    {
                      //<audio src={content[active_index].audio} controls controlsList="nodownload" />
                    }
                    <Cassette
                      playlist={[
                        {
                          url: content[active_index].audio,
                          title: content[active_index].code,
                        },
                      ]}
                    />
                    {!showTranscript ? (
                      <Grid.Row style={{ marginTop: "1rem" }}>
                        <Button
                          style={{
                            backgroundColor: "rgb(242, 113, 28)",
                            color: "white",
                          }}
                          floated="right"
                          onClick={this.saveAnswer}
                          size="tiny"
                        >
                          Finish And Proceed
                        </Button>
                        {/* <Button
                          primary
                          floated="right"
                          onClick={this.toggleTranscript}
                          size="tiny"
                        >
                          {showTranscript
                            ? "Hide Transcript"
                            : "Show Transcript"}
                        </Button> */}
                      </Grid.Row>
                    ) : null}
                  </React.Fragment>
                )}
              </Container>
            </Grid.Column>
            {strat_mode && showTranscript ? (
              <Grid.Column>
                <Container>
                  <Segment className="exam-divs listen-height-85vh listen-max-height-85vh margin-bottom-child-10px">
                    <h3 className="text-center">Transcript</h3>
                    <div
                      className="instruction"
                      dangerouslySetInnerHTML={{
                        __html: content[active_index].transcript,
                      }}
                    />
                  </Segment>
                  <Button
                    style={{
                      backgroundColor: "rgb(242, 113, 28)",
                      color: "white",
                    }}
                    floated="right"
                    onClick={this.saveAnswer}
                    size="tiny"
                  >
                    Finish And Proceed
                  </Button>
                  {/* <Button primary onClick={this.toggleTranscript} size="tiny">
                    {showTranscript ? "Hide Transcript" : "Show Transcript"}
                  </Button> */}
                </Container>
              </Grid.Column>
            ) : null}
          </Grid>
        </Grid.Column>
      </Grid.Row>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    content: state.listenExam.content,
    timer: state.listenExam.timer,
    active_index: state.listenExam.active_index,
    strat_mode: state.listenExam.strat_mode,
    answers: state.listenExam.answers,
    answers_strat: state.listenExam.answers_strat,
    audio_position: state.listenExam.position,
    countdown_started: state.listenExam.countdown_started,
    answerInstance: state.answerInstance,
    is_staff: state.auth.is_staff,
  };
};

export default withRouter(connect(mapStateToProps)(ListeningContainer));
