import React from 'react';
import { Table, Pagination, Container, Grid, Breadcrumb, Button, Icon, Modal, Header, Select } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import * as nonStudentListActions from '../../actions/app/nonStudentList';
import * as api from '../../actions/api/requests';
import * as Parts from '../dashboard/parts';
import _ from 'lodash';
import country from "country-code-lookup";

const countryOptions = [
  { key: 'sg', value: 'sg', text: 'Singapore' },
  { key: 'it', value: 'it', text: 'International' },
]

const UrlModal = (props) => (
  <Modal open={props.open} onClose={props.close} basic size='small'>
    <Header icon='linkify' content='Generated URL' />
    <Modal.Content>
      <p>{props.generatedURL}</p>
    </Modal.Content>
    <Modal.Actions>
      <Button onClick={props.copyToClipBoard} color={props.copiedToClipboard?'green':'teal'} inverted>
				<Icon name={props.copiedToClipboard?'check':'clipboard'} />
				{
					props.copiedToClipboard?
						'Copied to Clipboard'
						:
						'Copy to Clipboard'
				}
      </Button>
    </Modal.Actions>
  </Modal>
)

class NonStudentList extends React.Component {
	componentDidMount(){
		this.resetCurrentPage();
		this.props.dispatch(api.getNonStudentList());
	}
	componentWillUnmount(){
		this.resetCurrentPage();
	}
	resetCurrentPage(){
		const { dispatch } = this.props;
		dispatch(nonStudentListActions.updateCurrentPage(1));
	}
	onPageChange = (e, data) => {
		const { dispatch } = this.props;
		dispatch(nonStudentListActions.updateCurrentPage(data.activePage));
	}
	close = () => {
		this.setState({open:false,copiedToClipboard:false});
	}
	openModal = (exam_id) => {
		exam_id = exam_id.toString();
		const encrypted_id = window.btoa(exam_id.trim());
		const url = window.location.origin + '/imt/review?id=' + encodeURIComponent(encrypted_id);
		this.setState({open:true, generatedURL: url});
	}
	copyToClipBoard(){
		const { generatedURL } = this.state;
		navigator.clipboard.writeText(generatedURL);
		this.setState({copiedToClipboard:true});
	}
	state = {
    column: null,
    data: [],
		direction: null,
		generatedURL: "",
		copiedToClipboard:false,
		open:false,
		close:this.close.bind(this),
		copyToClipBoard:this.copyToClipBoard.bind(this),
		mode:'sg',
		test_type:"imt",
	}
	handleSort = clickedColumn => () => {
    const { column, direction } = this.state

		let data = this.props.content;

    if (column !== clickedColumn) {
			data = _.sortBy(data, [clickedColumn])
      this.setState({
        column: clickedColumn,
        direction: 'ascending',
			})
			this.props.dispatch(nonStudentListActions.updateContent(data));
			return;
		}

    this.setState({
      direction: direction === 'ascending' ? 'descending' : 'ascending',
		})
		this.props.dispatch(nonStudentListActions.updateContent(data.reverse()));
	}
	changeCountry = (e, value) => {
		this.setState({mode:value.value})
	}
	changeTestType = (e, value) => {
		this.setState({test_type:value.value})
	}
	generateTestTypeTitle(test_type){
		switch(test_type) {
			case undefined: return "";
			case "":
			case "5": return "IMT";
			default: return test_type.toUpperCase();
		}
	}
	render() {
		const { content, currentActivePage, maxPerPage } = this.props;
		const { column, direction, mode } = this.state;
		const startIndex = (currentActivePage - 1) * maxPerPage;
		const finalIndex = maxPerPage * currentActivePage;
		let slicedContent = content;
		let slicedLength = 0;
		if (mode === 'sg'){
			slicedContent = slicedContent.filter((content) => content.sg);
		} else {
			slicedContent = slicedContent.filter((content) => !content.sg);
		}
		slicedLength = slicedContent.length;
		slicedContent = slicedContent.slice(startIndex, finalIndex);

		const rows = slicedContent.map((item, i) => {
			return (
				<Table.Row key={i}>
          <Table.Cell>{item.name}</Table.Cell>
					<Table.Cell>{item.email}</Table.Cell>
					<Table.Cell>{this.generateTestTypeTitle(item.test_type)}</Table.Cell>
					<Table.Cell>{country.byIso(item.country).country}</Table.Cell>
					<Table.Cell>{item.target_country}</Table.Cell>
					<Table.Cell>{item.target_score}</Table.Cell>
					<Table.Cell>{item.previous_score}</Table.Cell>
					<Table.Cell>{item.planned_test_date}</Table.Cell>
					<Table.Cell>{new Date(item.created_at).toLocaleString()}</Table.Cell>
					<Table.Cell>
					{
						item.answer_id?
							<Button onClick={this.openModal.bind(this, item.answer_id)} color='teal' icon labelPosition='left' size='mini'>
								<Icon name='linkify' /> Generate Link
							</Button>
							:
							null
					}
					</Table.Cell>
				</Table.Row>
			)
		});
		return (
			<Grid doubling stackable padded>
				<UrlModal {...this.state}/>
				<Parts.Header/>
				<Grid.Row style={{paddingBottom:"0px", paddingTop:"2rem"}} >
					<Grid.Column>
						<Breadcrumb>
							<Breadcrumb.Section link><Link to='/'>Home</Link></Breadcrumb.Section>
							<Breadcrumb.Divider icon='right angle' />
							<Breadcrumb.Section>Non Student List</Breadcrumb.Section>
						</Breadcrumb>
					</Grid.Column>
				</Grid.Row>
				<Grid.Row>
					<Grid.Column width={16} style={{maxHeight:"70vh", overflowY:"scroll"}} >
						<Select options={countryOptions} value={mode} onChange={this.changeCountry} />
						<Table striped sortable celled compact stackable>
							<Table.Header>
								<Table.Row>
									<Table.HeaderCell
										sorted={column === 'name' ? direction : null}
										onClick={this.handleSort('name')}>
										Name
									</Table.HeaderCell>
									<Table.HeaderCell
										sorted={column === 'email' ? direction : null}
										onClick={this.handleSort('email')}>
										Email
									</Table.HeaderCell>
									<Table.HeaderCell
										sorted={column === 'test_type' ? direction : null}
										onClick={this.handleSort('test_type')}>
										Test Type
									</Table.HeaderCell>
									<Table.HeaderCell
										sorted={column === 'country' ? direction : null}
										onClick={this.handleSort('country')}>
										Country
									</Table.HeaderCell>
									<Table.HeaderCell
										sorted={column === 'target_country' ? direction : null}
										onClick={this.handleSort('target_country')}>
										Target Country
									</Table.HeaderCell>
									<Table.HeaderCell
										sorted={column === 'target_score' ? direction : null}
										onClick={this.handleSort('target_score')}>
										Target Score
									</Table.HeaderCell>
									<Table.HeaderCell
										sorted={column === 'previous_score' ? direction : null}
										onClick={this.handleSort('previous_score')}>
										Previous Score
									</Table.HeaderCell>
									<Table.HeaderCell
										sorted={column === 'planned_test_date' ? direction : null}
										onClick={this.handleSort('planned_test_date')}>
										Planned Test Date
									</Table.HeaderCell>
									<Table.HeaderCell
										sorted={column === 'created_at' ? direction : null}
										onClick={this.handleSort('created_at')}>
										Created At
									</Table.HeaderCell>
									<Table.HeaderCell>Actions</Table.HeaderCell>
								</Table.Row>
							</Table.Header>
							<Table.Body>
							{ rows }
							</Table.Body>
						</Table>
						<Container textAlign={"center"}>
							<Pagination
								activePage={currentActivePage}
								totalPages={Math.ceil(slicedLength/maxPerPage)}
								onPageChange={this.onPageChange}
							/>
						</Container>
					</Grid.Column>
				</Grid.Row>
				<Parts.Footer/>
			</Grid>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		content: state.nonStudentList.content,
		currentActivePage: state.nonStudentList.currentActivePage,
		maxPerPage: state.nonStudentList.maxPerPage,
		is_staff: state.auth.is_staff,
	}
};

export default connect(mapStateToProps)(NonStudentList);