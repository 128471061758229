import * as actions from "../actions/types/speakBuilder";

const defaultState = {
  code: "",
  part: "SpeakPart1",
  audio: ["", "", "", ""],
  vocab: "",
  vocab2: "",
  vocab3: "",
  vocab4: "",
  model_answer: "",
  model_answer2: "",
  model_answer3: "",
  model_answer4: "",
  question: "",
  subtype: "",
  hint: "",
  hint2: "",
  hint3: "",
  hint4: "",
};

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case actions.SPEAK_BUILDER_RESET: {
      return { ...defaultState };
    }
    case actions.SPEAK_BUILDER_UPDATE_CONTENT: {
      return { ...action.payload };
    }
    default:
      return { ...state };
  }
}
