import React from "react";
import { connect } from "react-redux";
import {
  Grid,
  Container,
  Segment,
  Icon,
  Button,
  Menu,
} from "semantic-ui-react";
import Loader from "../../parts/Loader";
import HintModal from "../HintModal";
import ExamNavSpeaking from "../ExamNavSpeaking";
import { ReactMic } from "react-mic";
import * as speakPart1Actions from "../../../../actions/app/speakPart1";
import * as apiActions from "../../../../actions/app/api";
import ReactPlayer from "react-player";

function pad(string) {
  return ("0" + string).slice(-2);
}

function format(seconds) {
  const date = new Date(seconds * 1000);
  const hh = date.getUTCHours();
  const mm = date.getUTCMinutes();
  const ss = pad(date.getUTCSeconds());
  if (hh) {
    return `Time Remaining: ${hh}h ${pad(mm)}m ${ss}s`;
  }
  return `Time Remaining: ${mm}m ${ss}s`;
}

class SpeakPart1 extends React.Component {
  state = {
    openModal: false,
    oscillationOn: false,
    studentAnswer: "",
    recording2: false,
    playing2: false,
  };
  openModal = () => {
    this.setState({ openModal: true });
  };
  closeModal = () => {
    this.setState({ openModal: false });
  };
  componentDidMount() {
    const { dispatch, strat_mode } = this.props;
    if (!strat_mode) {
      dispatch(speakPart1Actions.reset());
    }
    dispatch(apiActions.updateApiSending(false));
  }
  componentWillUnmount() {
    this.clearTimer();
    const { dispatch, strat_mode } = this.props;
    if (!strat_mode) {
      dispatch(speakPart1Actions.reset());
    }
  }
  clearTimer() {
    clearInterval(this.timer);
    this.timer = null;
  }
  onStop = () => {
    this.props.startRecording();
    this.props.dispatch(speakPart1Actions.updatePlaying(false));
    this.props.dispatch(speakPart1Actions.updateRecording(true));
    this.timer = setInterval(this.tick.bind(this), 1000);
  };
  tick = () => {
    const { timer, dispatch } = this.props;
    const newTimer = timer - 1;
    if (newTimer >= 0) {
      dispatch(speakPart1Actions.updateTimer(newTimer));
    } else {
      this.stopRecording();
    }
  };
  stopRecording = () => {
    this.clearTimer();
    const { recording, dispatch } = this.props;
    dispatch(apiActions.updateApiSending(true));
    dispatch(speakPart1Actions.updateRecording(false));
    if (recording) {
      this.props.stopRecording();
    } else {
      dispatch(speakPart1Actions.updatePlaying(false));
      this.props.updateAnswer("");
    }
  };
  toggleRecording(on) {
    if (on) {
      this.props.startRecording();
    } else {
      this.props.stopRecording();
    }
    this.setState({ recording2: on });
  }
  stopPlaying2 = () => {
    this.setState({ playing2: false });
  };
  finishStratMode = () => {
    const { dispatch, strat_file } = this.props;
    const { recording2 } = this.state;
    if (recording2) {
      this.setState({ recording2: false });
      dispatch(apiActions.updateApiSending(true));
      this.props.stopRecordingStratMode();
    } else {
      if (strat_file !== null) {
        dispatch(apiActions.updateApiSending(true));
        this.props.uploadRecording(strat_file);
      } else {
        this.props.updateAnswer("");
      }
    }
  };
  render() {
    const {
      content,
      active_index,
      strat_mode,
      timer,
      recording,
      current_subtype,
      playing,
      strat_url,
      answers,
    } = this.props;
    const { openModal, recording2, playing2 } = this.state;
    return (
      <Grid doubling stackable padded className="full-width">
        <HintModal
          header="Vocab"
          hint={content[active_index].vocab}
          open={openModal}
          onClose={this.closeModal}
        />
        <Loader />
        <ReactPlayer
          key={"player1"}
          className="invisible"
          url={content[active_index].audio[current_subtype]}
          playing={playing}
          onEnded={this.onStop}
        />
        <ReactPlayer
          key={"player2"}
          className="invisible"
          url={content[active_index].audio[current_subtype]}
          playing={playing2}
          onEnded={this.stopPlaying2}
        />
        <Grid.Row columns={2} centered stretched>
          <Grid.Column width={2} className="no-padding-right">
            <Container fluid className="full-height exam-nav">
              <Menu fluid vertical className="test-nav">
                <ExamNavSpeaking {...this.props} />
              </Menu>
            </Container>
          </Grid.Column>
          <Grid.Column width={14}>
            <Container fluid>
              {!strat_mode ? (
                <Segment className={"text-center"}>
                  <p>
                    <Icon
                      color={recording ? "red" : "green"}
                      name={recording ? "user cancel" : "user"}
                      size="massive"
                    />
                  </p>
                  <h3>Examiner's Quesion</h3>
                  <br />
                  <br />
                  <p>
                    {recording
                      ? "Recording your answer"
                      : "Please answer the examiner's question. Your response is being recorded. When you've finished you can click the red stop button or the button \"Finish and Proceed\" below (blink as Recorder is recording)"}
                  </p>
                  <br />
                  <br />
                  <p>
                    <Icon
                      color={recording ? "green" : "red"}
                      name={recording ? "microphone" : "microphone slash"}
                      size="huge"
                    />
                  </p>
                  <p>{format(timer)}</p>
                  <ReactMic
                    key={"microphone"}
                    record={recording}
                    className="sound-wave"
                  />
                  <br />
                  <br />
                  <Button
                    onClick={() => this.setState({ playing2: true })}
                    color="green"
                    size={"tiny"}
                  >
                    Repeat
                  </Button>
                </Segment>
              ) : (
                <Segment className={"text-center"}>
                  <br />
                  <Container textAlign="center">
                    <h3>Question</h3>
                    <audio
                      controls
                      src={content[active_index].audio[current_subtype]}
                      controlsList="nodownload"
                    />
                  </Container>
                  <br />
                  <Container textAlign="center">
                    <h3>Your "Testing" Recorded Answer</h3>
                    <audio
                      controls
                      src={answers[active_index][current_subtype]}
                      controlsList="nodownload"
                    />
                  </Container>
                  <br />
                  <h3>Record Your New Answer</h3>
                  <br />
                  <ReactMic
                    key={"microphone"}
                    record={recording2}
                    className="sound-wave"
                  />
                  <br />
                  <Button.Group>
                    <Button
                      positive
                      icon
                      disabled={recording2}
                      onClick={this.toggleRecording.bind(this, true)}
                      className="text-center"
                    >
                      <Icon size="large" name="microphone" />
                    </Button>
                    <Button.Or />
                    <Button
                      negative
                      icon
                      disabled={!recording2}
                      onClick={this.toggleRecording.bind(this, false)}
                      className="text-center"
                    >
                      <Icon size="large" name="microphone slash" />
                    </Button>
                  </Button.Group>
                  <br />
                  <br />
                  <br />
                  <h3>Your Recorded Answer</h3>
                  <audio controls src={strat_url} controlsList="nodownload" />
                  <br />
                  <br />
                  <br />
                  <br />
                </Segment>
              )}
            </Container>
            <Grid.Row className="padding-top-1rem">
              <div style={{ width: "33%", display: "inline-block" }}>
                &nbsp;
              </div>
              {/* <div
                style={{ width: "34%", display: "inline-block" }}
                className="text-center"
              >
                {strat_mode ? (
                  <Button
                    style={{
                      backgroundColor: "rgb(242, 113, 28)",
                      color: "white",
                    }}
                    onClick={this.openModal}
                    size="tiny"
                  >
                    Vocab
                  </Button>
                ) : null}
              </div> */}
              <div
                style={{ width: "33%", display: "inline-block" }}
                className="text-right"
              >
                <Button
                  size="tiny"
                  style={{
                    backgroundColor: "rgb(242, 113, 28)",
                    color: "white",
                  }}
                  type="button"
                  onClick={
                    strat_mode
                      ? this.finishStratMode
                      : this.stopRecording.bind(this)
                  }
                >
                  Finish And Proceed
                </Button>
              </div>
            </Grid.Row>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    content: state.speakExam.content,
    active_index: state.speakExam.active_index,
    answers: state.speakExam.answers,
    answers_strat: state.speakExam.answers_strat,
    current_subtype: state.speakExam.current_subtype,
    strat_mode: state.speakExam.strat_mode,
    timer: state.speakPart1.timer,
    playing: state.speakPart1.playing,
    recording: state.speakPart1.recording,
    strat_url: state.speakPart1.strat_url,
    strat_file: state.speakPart1.strat_file,
  };
};

export default connect(mapStateToProps)(SpeakPart1);
