import React from "react";
import { Form } from "semantic-ui-react";
import Instruction from "./Instruction";
import parse from "html-react-parser";
import { unescapeHTML } from "../../../../../common";

const NoteCompletion = (props) => {
  const {
    questions,
    start_number,
    handleOnChange,
    parentIndex,
    answers,
    answers_strat,
    active_index,
    strat_mode,
    size,
    explanation,
    correct_answer,
    prefixes,
  } = props;
  let i = 0;
  let str_replaced = questions
    .toString()
    .split("__blank__")
    .join('<input class="replace" />');
  str_replaced = str_replaced
    .split("__blank2__")
    .join('<input id="nonumber" class="replace" />');
  str_replaced = unescapeHTML(str_replaced);
  let item_number = 0;
  const question = parse(str_replaced, {
    replace: (domNode) => {
      if (domNode.attribs && domNode.attribs.class === "replace") {
        let value = "";
        if (answers.length > 0 && answers_strat.length > 0) {
          value = answers[active_index][parentIndex][i];
          if (strat_mode) {
            value = answers_strat[active_index][parentIndex][i];
          }
        }
        i++;
        let explanation_answer = "";
        try {
          explanation_answer = correct_answer[i - 1]?.replace(/{n}/g, " / ");
        } catch (ex) {
          explanation_answer = value;
        }
        let prefix = "";
        try {
          if (prefixes[i - 1]) {
            prefix = prefixes[i - 1];
          }
        } catch (ex) {}
        return (
          <React.Fragment>
            {domNode.attribs.id !== "nonumber"
              ? start_number + item_number++ + ". "
              : null}
            {prefix}
            <input
              size={size ? (size[i - 1] ? size[i - 1] : 10) : 10}
              type={"text"}
              value={explanation ? explanation_answer : value}
              className="blank2"
              key={"notecompletioninput" + parentIndex + i}
              onChange={handleOnChange.bind(this, parentIndex, i - 1)}
            />
          </React.Fragment>
        );
      }
    },
  });
  return (
    <React.Fragment>
      <Instruction {...props} />
      <Form className="read-mcq-radio">{question}</Form>
    </React.Fragment>
  );
};

export default NoteCompletion;
