import React from 'react';
import { connect } from 'react-redux';
import { Grid, Container } from 'semantic-ui-react';
import { Link, withRouter } from 'react-router-dom';
import * as apiActions from '../../../../actions/app/api';
import * as api from '../../../../actions/api/requests';
import * as mockTestActions from '../../../../actions/app/mockTest';

/* eslint-disable */
class Home extends React.Component {
  state = {
    openSubMenu: false,
    openSubMenu2: false,
  }
  openSubMenu() {
    this.setState({openSubMenu:true});
  }
  closeSubMenu() {
    this.setState({openSubMenu:false});
  }
  openSubMenu2() {
    this.setState({openSubMenu2:true});
  }
  closeSubMenu2() {
    this.setState({openSubMenu2:false});
  }
  startMockTest = (exam_type) => {
    const { dispatch, user_id } = this.props;
    dispatch(apiActions.updateApiSending(true));
    dispatch(mockTestActions.reset());
    dispatch(api.startMockTestStudent({exam_type:exam_type, test_type:'imt', id: user_id}, this.props.history));
  }
  render() {
    const { openSubMenu, openSubMenu2 } = this.state;
    return (
      <div className="card card-home">
        <Container fluid>
          <Grid doubling stackable padded textAlign="center" verticalAlign="middle">
            <Grid.Row columns={"equal"}>
              <Grid.Column>
                <h1 className="home-header r-reg">HOME</h1>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={"equal"}>
              <Grid.Column>
                <div className="card-col">
                  <a
                    id="exam-btn"
                    onMouseOut={this.closeSubMenu.bind(this)}
                    onMouseOver={this.openSubMenu.bind(this)}
                    style={{display:openSubMenu?"none":"block"}}
                  >
                    <button id="btn-exam"></button>
                  </a>
                  <div
                    id="exam-btn-group"
                    className="btn-exam_group mouseoverlist"
                    onMouseOver={this.openSubMenu.bind(this)}
                    onMouseOut={this.closeSubMenu.bind(this)}
                    style={{display:openSubMenu?"block":"none"}}
                  >
                    <Link to="/dashboard/listening">&nbsp;&nbsp;&nbsp;&nbsp;<i className="fa fa-headphones-alt"></i>&nbsp;&nbsp;Listening</Link>
                    <Link to="/dashboard/reading">&nbsp;&nbsp;&nbsp;&nbsp;<i className="fa fa-book"></i>&nbsp;&nbsp;Reading</Link>
                    <Link to="/dashboard/writing">&nbsp;&nbsp;&nbsp;&nbsp;<i className="fa fa-pen"></i>&nbsp;&nbsp;Writing</Link>
                    <Link to="/dashboard/speaking">&nbsp;&nbsp;&nbsp;&nbsp;<i className="fa fa-microphone"></i>&nbsp;&nbsp;Speaking</Link>
                  </div>
                </div>
              </Grid.Column>
              <Grid.Column>
                <div className="card-col">
                  <a
                    id="exam-btn2"
                    onMouseOut={this.closeSubMenu2.bind(this)}
                    onMouseOver={this.openSubMenu2.bind(this)}
                    style={{display:openSubMenu2?"none":"block"}}
                  >
                    <button id="btn-mock_exam"></button>
                  </a>
                  <div
                    id="exam-btn-group2"
                    className="btn-exam_group mouseoverlist"
                    onMouseOut={this.closeSubMenu2.bind(this)}
                    onMouseOver={this.openSubMenu2.bind(this)}
                    style={{display:openSubMenu2?"block":"none"}}
                  >
                    <a onClick={this.startMockTest.bind(this, 'gt')}>&nbsp;&nbsp;&nbsp;&nbsp;<i className="fas fa-award"></i>&nbsp;&nbsp;General Training</a>
                    <a onClick={this.startMockTest.bind(this, 'ac')}>&nbsp;&nbsp;&nbsp;&nbsp;<i className="fas fa-school"></i>&nbsp;&nbsp;Academic</a>
                  </div>
                </div>
              </Grid.Column>
              <Grid.Column>
                <div className="card-col">
                  <Link to="/dashboard/exams">
                    <button id="btn-recent_exam"></button>
                  </Link>
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    openSidebar: state.app.openSidebar,
    user: state.login,
    is_authenticated: state.auth.is_authenticated,
    user_id: state.auth.id,
  }
};

export default withRouter(connect(mapStateToProps)(Home));