const EXAMS = {
  READING: 1,
  LISTENING: 2,
  SPEAKING: 3,
  WRITING: 4,
  MOCK_TEST: 5,
  READING_INDIVIDUAL: 6,
  LISTENING_INDIVIDUAL: 7,
  SPEAKING_INDIVIDUAL: 8,
  WRITING_INDIVIDUAL: 9,
};
export default EXAMS;