import React from 'react';
import { Grid, Table, Pagination, Container, Button, Icon, Breadcrumb } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import * as mockExamTableActions from '../../actions/app/mockExamTable';
import * as api from '../../actions/api/requests';
import * as Parts from '../dashboard/parts';
import _ from 'lodash';

class MockExamTable extends React.Component {
	componentDidMount(){
		this.resetCurrentPage();
		this.props.dispatch(api.getMockExams());
	}
	componentWillUnmount(){
		this.resetCurrentPage();
	}
	resetCurrentPage(){
		const { dispatch } = this.props;
		dispatch(mockExamTableActions.updateCurrentPage(1));
	}
	onPageChange = (e, data) => {
		const { dispatch } = this.props;
		dispatch(mockExamTableActions.updateCurrentPage(data.activePage));
	}
	doEdit = (answerInstance) => {
		window.open("/dashboard/edit/mock-test/actual?edit_id=" + answerInstance.id, "_blank");
	}
	componentDidUpdate(prevProps) {
		if (this.props.content !== prevProps.content) {
			this.setState({data:this.props.content.slice()});
		}
	}
	state = {
    column: null,
    data: [],
    direction: null,
  }
  handleSort = clickedColumn => () => {
    const { column, data, direction } = this.state

    if (column !== clickedColumn) {
      this.setState({
        column: clickedColumn,
        data: _.sortBy(data, [clickedColumn]),
        direction: 'ascending',
      })
      return
    }

    this.setState({
      data: data.reverse(),
      direction: direction === 'ascending' ? 'descending' : 'ascending',
    })
  }
	render() {
		const { currentActivePage, maxPerPage } = this.props;
		const { column, direction } = this.state;
		const content = this.state.data;
		const startIndex = (currentActivePage - 1) * maxPerPage;
		const finalIndex = maxPerPage * currentActivePage;
		const slicedContent = content.slice(startIndex, finalIndex);
		const rows = slicedContent.map((item, i) => {
			return (
				<Table.Row key={i}>
          <Table.Cell>{item.code}</Table.Cell>
          <Table.Cell>
          {
            item.gt_type?
              "General Type"
              :
              "Academic"
          }
          </Table.Cell>
          <Table.Cell>
          {
            item.available?
              <Icon size='tiny' bordered circular fitted inverted name='check' color='green' />
              :
              <Icon size='tiny' bordered circular fitted inverted name='cancel' color='red' />
          }
          </Table.Cell>
					<Table.Cell>{new Date(item.created_at).toLocaleString()}</Table.Cell>
					<Table.Cell>{new Date(item.updated_at).toLocaleString()}</Table.Cell>
					<Table.Cell>
						<Button onClick={this.doEdit.bind(this, item)} color='teal' icon labelPosition='left' size='tiny'>
							<Icon name='edit' /> Edit
						</Button>
					</Table.Cell>
				</Table.Row>
			)
		});
		return (
			<Grid doubling stackable padded>
				<Parts.Header/>
				<Grid.Row style={{paddingBottom:"0px", paddingTop:"2rem"}} >
					<Grid.Column>
						<Breadcrumb>
							<Breadcrumb.Section link><Link to='/'>Home</Link></Breadcrumb.Section>
							<Breadcrumb.Divider icon='right angle' />
							<Breadcrumb.Section>Mock Test List</Breadcrumb.Section>
						</Breadcrumb>
					</Grid.Column>
				</Grid.Row>
				<Grid.Row>
					<Grid.Column width={16} style={{maxHeight:"70vh", overflowY:"scroll"}} >
						<Table striped sortable celled compact stackable>
							<Table.Header>
								<Table.Row>
									<Table.HeaderCell
										sorted={column === 'code' ? direction : null}
										onClick={this.handleSort('code')}>
										Code
                  </Table.HeaderCell>
                  <Table.HeaderCell
										sorted={column === 'gt_type' ? direction : null}
										onClick={this.handleSort('gt_type')}>
										Test Classification
									</Table.HeaderCell>
									<Table.HeaderCell
										sorted={column === 'available' ? direction : null}
										onClick={this.handleSort('available')}>
										Available
									</Table.HeaderCell>
									<Table.HeaderCell
										sorted={column === 'created_at' ? direction : null}
										onClick={this.handleSort('created_at')}>
										Created At
									</Table.HeaderCell>
									<Table.HeaderCell
										sorted={column === 'updated_at' ? direction : null}
										onClick={this.handleSort('updated_at')}>
										Updated At
									</Table.HeaderCell>
									<Table.HeaderCell>Actions</Table.HeaderCell>
								</Table.Row>
							</Table.Header>
							<Table.Body>
								{rows}
							</Table.Body>
						</Table>
						<Container textAlign={"center"}>
							<Pagination
								activePage={currentActivePage}
								totalPages={Math.ceil(content.length/maxPerPage)}
								onPageChange={this.onPageChange}
							/>
						</Container>
					</Grid.Column>
				</Grid.Row>
				<Parts.Footer/>
			</Grid>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		content: state.mockExamTable.content,
		currentActivePage: state.mockExamTable.currentActivePage,
		maxPerPage: state.mockExamTable.maxPerPage,
		is_staff: state.auth.is_staff,
	}
};

export default withRouter(connect(mapStateToProps)(MockExamTable));