import * as actions from '../actions/types/answerHistory';

const defaultState = {
  content: [],
  currentActivePage:1,
  maxPerPage:10,
};

export default function reducer(state=defaultState, action) {
  switch(action.type) {
    case actions.UPDATE_ANSWER_HISTORY_CONTENT: {
      return {...state, content: action.payload};
    }
    case actions.UPDATE_ANSWER_HISTORY_CURRENT_ACTIVE_PAGE: {
      return {...state, currentActivePage: action.payload};
    }
    default: return {...state};
  }
};