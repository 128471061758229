export const WRITE_EXAM_UPDATE_TIMER = "WRITE_EXAM_UPDATE_TIMER";
export const WRITE_EXAM_RESET_WRITE_EXAM = "WRITE_EXAM_RESET_WRITE_EXAM";
export const WRITE_EXAM_RESET_TIMER = "WRITE_EXAM_RESET_TIMER";
export const WRITE_EXAM_UPDATE_ACTIVE_INDEX = "WRITE_EXAM_UPDATE_ACTIVE_INDEX";
export const WRITE_EXAM_UPDATE_STRAT_MODE = "WRITE_EXAM_UPDATE_STRAT_MODE";
export const WRITE_EXAM_UPDATE_CONTENT = "WRITE_EXAM_UPDATE_CONTENT";
export const WRITE_EXAM_UPDATE_ANSWERS = "WRITE_EXAM_UPDATE_ANSWERS";
export const WRITE_EXAM_UPDATE_ANSWERS_STRAT =
  "WRITE_EXAM_UPDATE_ANSWERS_STRAT";
export const WRITE_EXAM_UPDATE_NOTES = "WRITE_EXAM_UPDATE_NOTES";
