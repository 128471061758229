import React from 'react';
import { Menu } from 'semantic-ui-react';
import * as EXAMS from '../../../constants/exam_subtypes';

const NavMenus = (props) => {
  const {
    content,
    active_index,
    strat_mode,
    current_subtype,
  } = props;
  let num = 1;
  const nav_menus = content.map((exam, i) => {
    return (
      <React.Fragment key={`menuitem${i}`}>
        {
          exam.part === EXAMS.SPEAK_PART_2_VALUE?
            <React.Fragment>
              <Menu.Item className="font-small" active={active_index===i && !strat_mode}>{`${num++}) ${exam.code}T`}</Menu.Item>
              <Menu.Item className="font-small" active={active_index===i && strat_mode}>{`${num++}) ${exam.code}L`}</Menu.Item>
            </React.Fragment>
            :
          exam.part === EXAMS.SPEAK_PART_3_VALUE?
            <React.Fragment>
              <Menu.Item className="font-small" active={active_index===i && current_subtype === 0 && !strat_mode}>{`${num++}) ${exam.code}aT`}</Menu.Item>
              <Menu.Item className="font-small" active={active_index===i && current_subtype === 0 && strat_mode}>{`${num++}) ${exam.code}aL`}</Menu.Item>
              <Menu.Item className="font-small" active={active_index===i && current_subtype === 1 && !strat_mode}>{`${num++}) ${exam.code}bT`}</Menu.Item>
              <Menu.Item className="font-small" active={active_index===i && current_subtype === 1 && strat_mode}>{`${num++}) ${exam.code}bL`}</Menu.Item>
              <Menu.Item className="font-small" active={active_index===i && current_subtype === 2 && !strat_mode}>{`${num++}) ${exam.code}cT`}</Menu.Item>
              <Menu.Item className="font-small" active={active_index===i && current_subtype === 2 && strat_mode}>{`${num++}) ${exam.code}cL`}</Menu.Item>
              {
                exam.audio[3].trim() !== "" ?
                  <React.Fragment>
                    <Menu.Item className="font-small" active={active_index===i && current_subtype === 3 && !strat_mode}>{`${num++}) ${exam.code}dT`}</Menu.Item>
                    <Menu.Item className="font-small" active={active_index===i && current_subtype === 3 && strat_mode}>{`${num++}) ${exam.code}dL`}</Menu.Item>
                  </React.Fragment>
                  :
                  null
              }
            </React.Fragment>
            :
            <React.Fragment>
              <Menu.Item className="font-small" active={active_index===i && current_subtype === 0 && !strat_mode}>{`${num++}) ${exam.code}aT`}</Menu.Item>
              <Menu.Item className="font-small" active={active_index===i && current_subtype === 0 && strat_mode}>{`${num++}) ${exam.code}aL`}</Menu.Item>
              <Menu.Item className="font-small" active={active_index===i && current_subtype === 1 && !strat_mode}>{`${num++}) ${exam.code}bT`}</Menu.Item>
              <Menu.Item className="font-small" active={active_index===i && current_subtype === 1 && strat_mode}>{`${num++}) ${exam.code}bL`}</Menu.Item>
              <Menu.Item className="font-small" active={active_index===i && current_subtype === 2 && !strat_mode}>{`${num++}) ${exam.code}cT`}</Menu.Item>
              <Menu.Item className="font-small" active={active_index===i && current_subtype === 2 && strat_mode}>{`${num++}) ${exam.code}cL`}</Menu.Item>
              <Menu.Item className="font-small" active={active_index===i && current_subtype === 3 && !strat_mode}>{`${num++}) ${exam.code}dT`}</Menu.Item>
              <Menu.Item className="font-small" active={active_index===i && current_subtype === 3 && strat_mode}>{`${num++}) ${exam.code}dL`}</Menu.Item>
            </React.Fragment>
        }
      </React.Fragment>
    );
  });
  return nav_menus;
}

export default NavMenus;