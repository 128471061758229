import * as actions from '../actions/types/writingTable';

const defaultState = {
  content: [],
  currentActivePage:1,
  maxPerPage:100,
};

export default function reducer(state=defaultState, action) {
  switch(action.type) {
    case actions.UPDATE_WRITING_TABLE_CONTENT: {
      return {...state, content: action.payload};
    }
    case actions.UPDATE_WRITING_TABLE_CURRENT_ACTIVE_PAGE: {
      return {...state, currentActivePage: action.payload};
    }
    default: return {...state};
  }
};