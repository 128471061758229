import React from 'react';
import * as EXAMS from '../../../../../../constants/exam_subtypes';

/* eslint-disable */
const Sidebar = (props) => {
  const {
    openSidebar,
    show,
    content,
    active_index,
    strat_mode,
    current_subtype,
  } = props;
  let num = 1;
  const nav_menus = content.map((exam, i) => {
    return (
      <React.Fragment key={'speakingsidebar'+i}>
        {
          exam.part === EXAMS.SPEAK_PART_2_VALUE?
            <React.Fragment>
              <a className={active_index===i && !strat_mode?"active":""}>{`${num++}) ${exam.code}T`}</a>
              <a className={active_index===i && strat_mode?"active":""}>{`${num++}) ${exam.code}L`}</a>
            </React.Fragment>
            :
          exam.part === EXAMS.SPEAK_PART_3_VALUE?
            <React.Fragment>
              <a className={active_index===i && current_subtype === 0 && !strat_mode?"active":""}>{`${num++}) ${exam.code}aT`}</a>
              <a className={active_index===i && current_subtype === 0 && strat_mode?"active":""}>{`${num++}) ${exam.code}aL`}</a>
              <a className={active_index===i && current_subtype === 1 && !strat_mode?"active":""}>{`${num++}) ${exam.code}bT`}</a>
              <a className={active_index===i && current_subtype === 1 && strat_mode?"active":""}>{`${num++}) ${exam.code}bL`}</a>
              <a className={active_index===i && current_subtype === 2 && !strat_mode?"active":""}>{`${num++}) ${exam.code}cT`}</a>
              <a className={active_index===i && current_subtype === 2 && strat_mode?"active":""}>{`${num++}) ${exam.code}cL`}</a>
              {
                exam.audio[3].trim() !== "" ?
                  <React.Fragment>
                    <a className={active_index===i && current_subtype === 3 && !strat_mode?"active":""}>{`${num++}) ${exam.code}dT`}</a>
                    <a className={active_index===i && current_subtype === 3 && strat_mode?"active":""}>{`${num++}) ${exam.code}dL`}</a>
                  </React.Fragment>
                  :
                  null
              }
            </React.Fragment>
            :
            <React.Fragment>
              <a className={active_index===i && current_subtype === 0 && !strat_mode?"active":""}>{`${num++}) ${exam.code}aT`}</a>
              <a className={active_index===i && current_subtype === 0 && strat_mode?"active":""}>{`${num++}) ${exam.code}aL`}</a>
              <a className={active_index===i && current_subtype === 1 && !strat_mode?"active":""}>{`${num++}) ${exam.code}bT`}</a>
              <a className={active_index===i && current_subtype === 1 && strat_mode?"active":""}>{`${num++}) ${exam.code}bL`}</a>
              <a className={active_index===i && current_subtype === 2 && !strat_mode?"active":""}>{`${num++}) ${exam.code}cT`}</a>
              <a className={active_index===i && current_subtype === 2 && strat_mode?"active":""}>{`${num++}) ${exam.code}cL`}</a>
              <a className={active_index===i && current_subtype === 3 && !strat_mode?"active":""}>{`${num++}) ${exam.code}dT`}</a>
              <a className={active_index===i && current_subtype === 3 && strat_mode?"active":""}>{`${num++}) ${exam.code}dL`}</a>
            </React.Fragment>
        }
      </React.Fragment>
    );
  });
  return (
    <React.Fragment>
      <div id="exam-overlay" onClick={props.closeNav} style={openSidebar?{width:"100%"}:null}></div>
      <div id="exam-side-nav" className="exam-side-nav r-light" style={openSidebar?{width:"415px"}:null}>
        <div id="exam-side-nav-close" className={"exam-side-nav-close " + show}>
          <a className="closebtn" onClick={props.closeNav}>&times;</a>
        </div>
        <div id="exam-side-nav-list" className={"exam-side-nav-list " + show}>
          {nav_menus}
        </div>
      </div>
    </React.Fragment>
  );
}

export default Sidebar;