import React, { useState } from "react";
import { Button, Progress, Icon } from "semantic-ui-react";
import MicRecorder from "mic-recorder-to-mp3";
import { useTimer } from "react-timer-hook";
import { format } from "../../../../../utils/format";
import { ReactMic } from "react-mic";

const recorder = new MicRecorder({
  bitRate: 128,
});

const DEFAULT_TIMER = 120;

const getExpTime = () => {
  const time = new Date();
  time.setSeconds(time.getSeconds() + DEFAULT_TIMER);
  return time;
};

const RecorderV2 = ({ uploadRecording, title }) => {
  const [recording, setRecording] = useState(false);

  const upload = () => {
    recorder
      .stop()
      .getMp3()
      .then(([buffer, blob]) => {
        const file = new File(buffer, "recording.mp3", {
          type: blob.type,
          lastModified: Date.now(),
        });
        uploadRecording(file);
      })
      .catch((e) => {
        alert("Something is wrong with the microphone: " + e);
      });
    setRecording(false);
  };

  const { minutes, seconds, start, pause, restart } = useTimer({
    expiryTimestamp: getExpTime(),
    onExpire: () => {
      upload();
    },
    autoStart: false,
  });

  const stopRecording = () => {
    upload();
    pause();
  };

  const startRecording = () => {
    recorder.start().catch((e) => {
      alert("Unable to start recording: " + e);
    });
    setRecording(true);
    restart(getExpTime());
    start();
  };

  let progress = 100;
  try {
    progress = recording
      ? 100 - ((minutes * 60 + seconds) / DEFAULT_TIMER) * 100
      : 100;
  } catch (e) {}

  return (
    <div
      className="paper-box"
      style={{
        marginTop: "1rem",
        paddingBottom: "0px",
      }}
    >
      <div>
        <h4
          style={{
            marginTop: "1rem",
            marginLeft: "1rem",
            marginRight: "1rem",
            textAlign: "left",
          }}
          className="red"
        >
          {title}
        </h4>
        <div style={{ height: 70, position: "relative" }}>
          <ReactMic
            key={"microphone2"}
            record={recording}
            className={
              "sound-wave new-sound-wave " + (recording ? "visible" : "hidden")
            }
            backgroundColor="#F5F8FF"
          />
          <p
            style={{ padding: "1rem", position: "relative" }}
            className={"text-center " + (recording ? "hidden" : "visible")}
          >
            Press the <Icon name="circle" style={{ color: "red" }} />
            button to start recording
          </p>
        </div>
      </div>
      <div className="recorder-bottom text-center">
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              marginTop: "7px",
              marginLeft: "1rem",
              marginRight: "1rem",
            }}
          >
            <Button
              circular
              icon={!recording ? "circle" : "stop"}
              style={{ cursor: "default", color: "red" }}
              onClick={recording ? stopRecording : startRecording}
            />
          </div>
          <Progress
            className="progress-bar"
            percent={progress}
            size="small"
            style={{
              flexGrow: "1",
              marginTop: "1.46rem",
              backgroundColor: "#DDDDDD",
            }}
          />
          <div
            style={{
              float: "right",
              marginLeft: "1rem",
              marginRight: "1rem",
              marginTop: ".1rem",
            }}
          >
            <p>
              {recording
                ? format(minutes * 60 + seconds)
                : format(DEFAULT_TIMER)}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RecorderV2;
