import * as nonStudentList from '../types/nonStudentList';

export const updateContent = (payload) => {
  return dispatch => {
    dispatch({
      type    : nonStudentList.UPDATE_NON_STUDENT_LIST_CONTENT,
      payload : payload,
    });
  };
};

export const updateCurrentPage = (payload) => {
  return dispatch => {
    dispatch({
      type    : nonStudentList.UPDATE_NON_STUDENT_LIST_CURRENT_ACTIVE_PAGE,
      payload : payload,
    });
  };
};

