import React from "react";
import {
  Grid,
  Confirm,
  Container,
  Form,
  Button,
  Select,
  Breadcrumb,
  Segment,
  Input,
  Icon,
  Checkbox,
  Tab,
} from "semantic-ui-react";
import { connect } from "react-redux";
import * as SUBTYPES from "../../constants/exam_subtypes";
import Loader from "../dashboard/parts/Loader";
import { withRouter, Link } from "react-router-dom";
import * as Parts from "../dashboard/parts";
import _ from "lodash";
import * as api from "../../actions/api/requests";
import * as apiActions from "../../actions/app/api";
import * as mockTest from "../../actions/app/mockTest";
import queryString from "query-string";
import { Editor } from "@tinymce/tinymce-react";
import * as TINY_MCE_SETTINGS from "../../constants/tinyMceSettings";

const exam_type_options = [
  {
    key: SUBTYPES.ACADEMIC_VALUE,
    value: SUBTYPES.ACADEMIC_VALUE.toLowerCase(),
    text: SUBTYPES.ACADEMIC_TEXT,
  },
  {
    key: SUBTYPES.GENERAL_TRAINING_VALUE,
    value: SUBTYPES.GENERAL_TRAINING_VALUE.toLowerCase(),
    text: SUBTYPES.GENERAL_TRAINING_TEXT,
  },
];

const mock_test_options = [
  { key: "mocktestoption1", value: "imt", text: "IMT" },
  { key: "mocktestoption2", value: "listening", text: "Listening Individual" },
  { key: "mocktestoption3", value: "reading", text: "Reading Individual" },
  { key: "mocktestoption4", value: "writing", text: "Writing Individual" },
  { key: "mocktestoption5", value: "speaking", text: "Speaking Individual" },
];

const width = "40%";

function pad(string) {
  return ("0" + string).slice(-2);
}

function format(seconds) {
  const date = new Date(seconds * 1000);
  const hh = date.getUTCHours();
  const mm = date.getUTCMinutes();
  const ss = pad(date.getUTCSeconds());
  if (hh) {
    return `Time Remaining: ${hh}h ${pad(mm)}m ${ss}s`;
  }
  return `Time Remaining: ${mm}m ${ss}s`;
}

const SampleLoading = (props) => {
  return (
    <center>
      <p>
        Your IELTS {props.name} test will start in 2 minutes. Please get ready.
      </p>
      <Icon
        style={{ marginBottom: ".5em" }}
        size="big"
        color="blue"
        loading
        name="spinner"
      />
      <p>{format(120)}</p>
      <Button size="tiny" primary>
        {`Click to start ${props.name} test immediately`.toUpperCase()}
      </Button>
    </center>
  );
};

class MockTestBuilder extends React.Component {
  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(apiActions.updateApiSending(false));
    dispatch(api.getListeningExams());
    dispatch(api.getReadingExams());
    dispatch(api.getWritingExams());
    dispatch(api.getSpeakingExams());
    this.props.dispatch(apiActions.updateApiSending(false));
    const values = queryString.parse(this.props.location.search);
    let content = this.props.content.find(
      (content) => content.id === values.edit_id
    );
    this.setState({ ...content, open: false });
  }
  createNewSection = () => {
    return {
      id: "",
      name: "",
    };
  };
  state = {
    code: "",
    gt_type: true,
    available: false,
    reading: [this.createNewSection()],
    listening: [this.createNewSection()],
    writing: [this.createNewSection()],
    speaking: [this.createNewSection()],
    open: false,
    test_type: "imt",
    admin_q1: "",
    admin_q2: "",
    admin_q3: "",
  };
  reset = () => {
    this.setState({
      code: "",
      gt_type: true,
      available: false,
      test_type: "imt",
      reading: [this.createNewSection()],
      listening: [this.createNewSection()],
      writing: [this.createNewSection()],
      speaking: [this.createNewSection()],
    });
  };
  handleFieldChange = (i, test, e) => {
    switch (e.name) {
      case "gt_type":
        this.setState({ [e.name]: e.value === "gt" });
        break;
      case "available":
        this.setState({ [e.name]: e.checked });
        break;
      case "test_type":
        this.setState({ [e.name]: e.value });
        break;
      default:
        this.setState({ [e.name]: e.value });
    }
  };
  handleListeningChange = (i, e, component) => {
    const { listening } = this.state;
    if (component.name === "name") {
      listening[i].name = component.value;
    } else {
      listening[i].id = component.value.toString();
    }
    this.setState({ listening: listening });
  };
  addListening = () => {
    const { listening } = this.state;
    listening.push(this.createNewSection());
    this.setState({ listening: listening });
  };
  removeListening = (i) => {
    const { listening } = this.state;
    listening.splice(i, 1);
    this.setState({ listening: listening });
  };
  addreading = () => {
    const { reading } = this.state;
    reading.push(this.createNewSection());
    this.setState({ reading: reading });
  };
  removereading = (i) => {
    const { reading } = this.state;
    reading.splice(i, 1);
    this.setState({ reading: reading });
  };
  handlereadingChange = (i, e, component) => {
    const { reading } = this.state;
    if (component.name === "name") {
      reading[i].name = component.value;
    } else {
      reading[i].id = component.value.toString();
    }
    this.setState({ reading: reading });
  };
  addwriting = () => {
    const { writing } = this.state;
    writing.push(this.createNewSection());
    this.setState({ writing: writing });
  };
  removewriting = (i) => {
    const { writing } = this.state;
    writing.splice(i, 1);
    this.setState({ writing: writing });
  };
  handlewritingChange = (i, e, component) => {
    const { writing } = this.state;
    if (component.name === "name") {
      writing[i].name = component.value;
    } else {
      writing[i].id = component.value.toString();
    }
    this.setState({ writing: writing });
  };
  addspeaking = () => {
    const { speaking } = this.state;
    speaking.push(this.createNewSection());
    this.setState({ speaking: speaking });
  };
  removespeaking = (i) => {
    const { speaking } = this.state;
    speaking.splice(i, 1);
    this.setState({ speaking: speaking });
  };
  handlespeakingChange = (i, e, component) => {
    const { speaking } = this.state;
    if (component.name === "name") {
      speaking[i].name = component.value;
    } else {
      speaking[i].id = component.value.toString();
    }
    this.setState({ speaking: speaking });
  };
  handleSubmit = (e) => {
    e.preventDefault();
    console.log("here???");
    this.setState({ open: true });
  };
  close = () => {
    this.setState({ open: false });
  };
  handleConfirm = () => {
    const { dispatch } = this.props;
    const data = _.cloneDeep({ ...this.state });
    if (this.verify(data)) {
      data.listening = JSON.stringify(data.listening);
      data.reading = JSON.stringify(data.reading);
      data.writing = JSON.stringify(data.writing);
      data.speaking = JSON.stringify(data.speaking);
      dispatch(apiActions.updateApiSending(true));
      dispatch(api.updateMockTest(data, this.props.history));
    } else {
      alert("Please select the correct exam code for each section");
    }
    this.close();
  };
  verify(data) {
    const { test_type } = this.state;
    switch (test_type) {
      case "imt":
        return this.verifyImt(data);
      default:
        return this.verifyIndividual(data, test_type);
    }
  }
  verifyImt(data) {
    let flag = true;
    data.listening.forEach((exam) => {
      if (exam.id.trim() === "") {
        flag = false;
      }
    });
    if (!flag) {
      return false;
    }
    data.reading.forEach((exam) => {
      if (exam.id.trim() === "") {
        flag = false;
      }
    });
    if (!flag) {
      return false;
    }
    data.writing.forEach((exam) => {
      if (exam.id.trim() === "") {
        flag = false;
      }
    });
    if (!flag) {
      return false;
    }
    data.speaking.forEach((exam) => {
      if (exam.id.trim() === "") {
        flag = false;
      }
    });
    if (!flag) {
      return false;
    }
    return true;
  }
  verifyIndividual(data, test_type) {
    let flag = true;
    data[test_type].forEach((exam) => {
      if (exam.id.trim() === "") {
        flag = false;
      }
    });
    if (!flag) {
      return false;
    }
    return true;
  }

  handleInstructionChange = (name, value) => {
    this.setState({ [name]: value });
  };
  startMockTest = () => {
    const { dispatch, history } = this.props;
    if (this.verify(this.state)) {
      dispatch(apiActions.updateApiSending(true));
      dispatch(mockTest.reset());
      dispatch(api.previewMockTest({ ...this.state }, history));
    } else {
      alert("Add exams before previewing the test.");
    }
  };
  handleAdminQuestionChange = (i, component) => {
    this.setState({ [component.name]: component.value });
  };
  render() {
    const { listeningExams, readingExams, writingExams, speakingExams } =
      this.props;

    const {
      code,
      gt_type,
      available,
      listening,
      reading,
      writing,
      speaking,
      open,
      listening_instruction,
      reading_instruction,
      writing_instruction,
      speaking_instruction,
      admin_q1,
      admin_q2,
      admin_q3,
      test_type,
    } = this.state;

    const listening_exam_options = [];
    listeningExams.forEach((exam, i) => {
      listening_exam_options.push({
        key: `listeningkey${i}`,
        value: exam.id.toString(),
        text: exam.code,
      });
    });

    const reading_exam_options = [];
    readingExams.forEach((exam, i) => {
      reading_exam_options.push({
        key: `readingkey${i}`,
        value: exam.id.toString(),
        text: exam.code,
      });
    });

    const writing_exam_options = [];
    writingExams.forEach((exam, i) => {
      writing_exam_options.push({
        key: `writingkey${i}`,
        value: exam.id.toString(),
        text: exam.code,
      });
    });

    const speaking_exam_options = [];
    speakingExams.forEach((exam, i) => {
      speaking_exam_options.push({
        key: `speakingkey${i}`,
        value: exam.id.toString(),
        text: exam.code,
      });
    });

    const listening_sections = listening.map((section, i) => {
      return (
        <Form.Field inline key={`listeningname${i}`}>
          <Input
            required
            style={{ width: width }}
            placeholder="Enter section name"
            value={section.name}
            name="name"
            onChange={this.handleListeningChange.bind(this, i)}
          />
          <Select
            style={{ marginRight: "0.85rem", width: width }}
            required
            placeholder="Select exam code"
            onChange={this.handleListeningChange.bind(this, i)}
            value={section.id}
            options={listening_exam_options}
          />
          <span>
            {i === listening.length - 1 ? (
              <Button
                onClick={this.addListening}
                size="tiny"
                color="green"
                icon
              >
                <Icon name="plus" />
              </Button>
            ) : null}
            {i === 0 && listening.length === 1 ? null : (
              <Button
                onClick={this.removeListening.bind(this, i)}
                size="tiny"
                color="red"
                icon
              >
                <Icon name="cancel" />
              </Button>
            )}
          </span>
        </Form.Field>
      );
    });

    const reading_sections = reading.map((section, i) => {
      return (
        <Form.Field inline key={`readingname${i}`}>
          <Input
            required
            style={{ width: width }}
            placeholder="Enter section name"
            value={section.name}
            name="name"
            onChange={this.handlereadingChange.bind(this, i)}
          />
          <Select
            style={{ marginRight: "0.85rem", width: width }}
            required
            placeholder="Select exam code"
            onChange={this.handlereadingChange.bind(this, i)}
            value={section.id}
            options={reading_exam_options}
          />
          <span>
            {i === reading.length - 1 ? (
              <Button onClick={this.addreading} size="tiny" color="green" icon>
                <Icon name="plus" />
              </Button>
            ) : null}
            {i === 0 && reading.length === 1 ? null : (
              <Button
                onClick={this.removereading.bind(this, i)}
                size="tiny"
                color="red"
                icon
              >
                <Icon name="cancel" />
              </Button>
            )}
          </span>
        </Form.Field>
      );
    });

    const writing_sections = writing.map((section, i) => {
      return (
        <Form.Field inline key={`writingname${i}`}>
          <Input
            required
            style={{ width: width }}
            placeholder="Enter section name"
            value={section.name}
            name="name"
            onChange={this.handlewritingChange.bind(this, i)}
          />
          <Select
            style={{ marginRight: "0.85rem", width: width }}
            required
            placeholder="Select exam code"
            onChange={this.handlewritingChange.bind(this, i)}
            value={section.id}
            options={writing_exam_options}
          />
          <span>
            {i === writing.length - 1 ? (
              <Button onClick={this.addwriting} size="tiny" color="green" icon>
                <Icon name="plus" />
              </Button>
            ) : null}
            {i === 0 && writing.length === 1 ? null : (
              <Button
                onClick={this.removewriting.bind(this, i)}
                size="tiny"
                color="red"
                icon
              >
                <Icon name="cancel" />
              </Button>
            )}
          </span>
        </Form.Field>
      );
    });

    const speaking_sections = speaking.map((section, i) => {
      return (
        <Form.Field inline key={`speakingname${i}`}>
          <Input
            required
            style={{ width: width }}
            placeholder="Enter section name"
            value={section.name}
            name="name"
            onChange={this.handlespeakingChange.bind(this, i)}
          />
          <Select
            style={{ marginRight: "0.85rem", width: width }}
            required
            placeholder="Select exam code"
            onChange={this.handlespeakingChange.bind(this, i)}
            value={section.id}
            options={speaking_exam_options}
          />
          <span>
            {i === speaking.length - 1 ? (
              <Button onClick={this.addspeaking} size="tiny" color="green" icon>
                <Icon name="plus" />
              </Button>
            ) : null}
            {i === 0 && speaking.length === 1 ? null : (
              <Button
                onClick={this.removespeaking.bind(this, i)}
                size="tiny"
                color="red"
                icon
              >
                <Icon name="cancel" />
              </Button>
            )}
          </span>
        </Form.Field>
      );
    });

    const left_panes = [
      {
        menuItem: "General",
        render: () => {
          return (
            <Tab.Pane>
              <h4 style={{ marginBottom: "10px" }}>Test Code</h4>
              <Form.Field>
                <Input
                  required
                  style={{ width: width }}
                  placeholder="Enter test code"
                  value={code}
                  name="code"
                  onChange={this.handleFieldChange.bind(this, 0)}
                />
              </Form.Field>
              <h4 style={{ marginBottom: "10px" }}>
                Full / Individual Mock Test
              </h4>
              <Form.Field>
                <Select
                  style={{ marginRight: "0.85rem", width: width }}
                  required
                  placeholder="Select if IMT or individual test"
                  value={test_type}
                  name="test_type"
                  onChange={this.handleFieldChange.bind(this, 0)}
                  options={mock_test_options}
                />
              </Form.Field>
              <h4 style={{ marginBottom: "10px" }}>Exam Type</h4>
              <Form.Field>
                <Select
                  style={{ marginRight: "0.85rem", width: width }}
                  required
                  placeholder="Select exam type"
                  value={gt_type ? "gt" : "ac"}
                  name="gt_type"
                  onChange={this.handleFieldChange.bind(this, 0)}
                  options={exam_type_options}
                />
              </Form.Field>
              <h4 style={{ marginBottom: "10px" }}>Availability</h4>
              <Form.Field>
                <Checkbox
                  label="Available to students after creation"
                  name="available"
                  checked={available}
                  onClick={this.handleFieldChange.bind(this, 0)}
                />
              </Form.Field>
            </Tab.Pane>
          );
        },
      },
      {
        menuItem: "Instructions",
        render: () => {
          return (
            <Tab.Pane>
              {test_type === "listening" || test_type === "imt" ? (
                <React.Fragment>
                  <h4 style={{ marginBottom: "10px" }}>
                    Listening Instructions
                  </h4>
                  <Editor
                    init={TINY_MCE_SETTINGS.SETTINGS_NO_BLANK}
                    onEditorChange={this.handleInstructionChange.bind(
                      this,
                      "listening_instruction"
                    )}
                    value={listening_instruction}
                  />
                </React.Fragment>
              ) : null}
              {test_type === "reading" || test_type === "imt" ? (
                <React.Fragment>
                  <h4 style={{ marginBottom: "10px" }}>Reading Instructions</h4>
                  <Editor
                    init={TINY_MCE_SETTINGS.SETTINGS_NO_BLANK}
                    onEditorChange={this.handleInstructionChange.bind(
                      this,
                      "reading_instruction"
                    )}
                    value={reading_instruction}
                  />
                </React.Fragment>
              ) : null}
              {test_type === "writing" || test_type === "imt" ? (
                <React.Fragment>
                  <h4 style={{ marginBottom: "10px" }}>Writing Instructions</h4>
                  <Editor
                    init={TINY_MCE_SETTINGS.SETTINGS_NO_BLANK}
                    onEditorChange={this.handleInstructionChange.bind(
                      this,
                      "writing_instruction"
                    )}
                    value={writing_instruction}
                  />
                </React.Fragment>
              ) : null}
              {test_type === "speaking" || test_type === "imt" ? (
                <React.Fragment>
                  <h4 style={{ marginBottom: "10px" }}>
                    Speaking Instructions
                  </h4>
                  <Editor
                    init={TINY_MCE_SETTINGS.SETTINGS_NO_BLANK}
                    onEditorChange={this.handleInstructionChange.bind(
                      this,
                      "speaking_instruction"
                    )}
                    value={speaking_instruction}
                  />
                </React.Fragment>
              ) : null}
            </Tab.Pane>
          );
        },
      },
      {
        menuItem: "Exams",
        render: () => {
          return (
            <Tab.Pane>
              {test_type === "listening" || test_type === "imt" ? (
                <React.Fragment>
                  <h4 style={{ marginBottom: "10px" }}>Listening Exam</h4>
                  {listening_sections}
                </React.Fragment>
              ) : null}
              {test_type === "reading" || test_type === "imt" ? (
                <React.Fragment>
                  <h4 style={{ marginBottom: "10px" }}>Reading Exam</h4>
                  {reading_sections}
                </React.Fragment>
              ) : null}
              {test_type === "writing" || test_type === "imt" ? (
                <React.Fragment>
                  <h4 style={{ marginBottom: "10px" }}>Writing Exam </h4>
                  {writing_sections}
                </React.Fragment>
              ) : null}
              {test_type === "speaking" || test_type === "imt" ? (
                <React.Fragment>
                  <h4>Speaking Exam </h4>
                  <h5 style={{ marginBottom: "10px", marginTop: "0px" }}>
                    Admin Questions
                  </h5>
                  <Form.Field inline key={`admin_q100`}>
                    <Input
                      style={{ width: "70%" }}
                      required
                      placeholder="Enter admin question one url"
                      name="admin_q1"
                      type="url"
                      onChange={this.handleAdminQuestionChange}
                      value={admin_q1}
                    />
                  </Form.Field>
                  <Form.Field inline key={`admin_q201`}>
                    <Input
                      style={{ width: "70%" }}
                      required
                      placeholder="Enter admin question two url"
                      name="admin_q2"
                      type="url"
                      onChange={this.handleAdminQuestionChange}
                      value={admin_q2}
                    />
                  </Form.Field>
                  <Form.Field inline key={`admin_q302`}>
                    <Input
                      style={{ width: "70%" }}
                      required
                      placeholder="Enter admin question three url"
                      name="admin_q3"
                      type="url"
                      onChange={this.handleAdminQuestionChange}
                      value={admin_q3}
                    />
                  </Form.Field>
                  <h5 style={{ marginBottom: "10px" }}>Parts</h5>
                  {speaking_sections}
                </React.Fragment>
              ) : null}
            </Tab.Pane>
          );
        },
      },
    ];

    let right_panes = [
      {
        menuItem: "Listening Instruction",
        render: () => {
          return (
            <Tab.Pane>
              <SampleLoading name="Listening" />
              <div
                className="instruction"
                dangerouslySetInnerHTML={{ __html: listening_instruction }}
              />
            </Tab.Pane>
          );
        },
      },
      {
        menuItem: "Reading Instruction",
        render: () => {
          return (
            <Tab.Pane>
              <SampleLoading name="Reading" />
              <div
                className="instruction"
                dangerouslySetInnerHTML={{ __html: reading_instruction }}
              />
            </Tab.Pane>
          );
        },
      },
      {
        menuItem: "Writing Instruction",
        render: () => {
          return (
            <Tab.Pane>
              <SampleLoading name="Writing" />
              <div
                className="instruction"
                dangerouslySetInnerHTML={{ __html: writing_instruction }}
              />
            </Tab.Pane>
          );
        },
      },
      {
        menuItem: "Speaking Instruction",
        render: () => {
          return (
            <Tab.Pane>
              <SampleLoading name="Speaking" />
              <div
                className="instruction"
                dangerouslySetInnerHTML={{ __html: speaking_instruction }}
              />
            </Tab.Pane>
          );
        },
      },
    ];

    if (test_type === "reading") {
      right_panes = [right_panes[1]];
    } else if (test_type === "listening") {
      right_panes = [right_panes[0]];
    } else if (test_type === "writing") {
      right_panes = [right_panes[2]];
    } else if (test_type === "speaking") {
      right_panes = [right_panes[3]];
    }

    console.log("open: " + this.state.open);

    return (
      <React.Fragment>
        <Loader />
        <Grid
          doubling
          stackable
          padded
          className="padding-top-1rem padding-bottom-2rem"
        >
          <Confirm
            open={open}
            content="Do you want to proceed with the update?"
            onCancel={this.close.bind(this)}
            onConfirm={this.handleConfirm.bind(this)}
          />
          <Parts.Header />
          <Grid.Row style={{ paddingBottom: "0px" }}>
            <Grid.Column>
              <Breadcrumb>
                <Breadcrumb.Section>
                  <Link to="/">Home</Link>
                </Breadcrumb.Section>
                <Breadcrumb.Divider icon="right angle" />
                <Breadcrumb.Section>Update Mock Test</Breadcrumb.Section>
              </Breadcrumb>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={"equal"} className="footer-margin-8">
            <Grid.Column>
              <Container>
                <Segment style={{ paddingBottom: "0px!important" }}>
                  <Form onSubmit={this.handleSubmit.bind(this)}>
                    <Tab className="overflow-y-auto" panes={left_panes} />
                    <Form.Field className="text-center margin-top-25-impt">
                      <Button type="submit" color="green" size="tiny">
                        Update Test
                      </Button>
                      &nbsp;
                      <Button
                        disabled={test_type !== "imt"}
                        onClick={this.startMockTest}
                        type="button"
                        color="orange"
                        size="tiny"
                      >
                        Preview Test
                      </Button>
                      &nbsp;
                      <Button
                        onClick={this.reset}
                        type="button"
                        color="red"
                        size="tiny"
                      >
                        Reset
                      </Button>
                    </Form.Field>
                  </Form>
                  <br />
                  <br />
                </Segment>
              </Container>
            </Grid.Column>
            <Grid.Column>
              <Container>
                <Segment style={{ paddingBottom: "0px!important" }}>
                  <Tab className="overflow-y-auto" panes={right_panes} />
                </Segment>
              </Container>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    listeningExams: state.listeningTable.content,
    readingExams: state.readingTable.content,
    writingExams: state.writingTable.content,
    speakingExams: state.speakingTable.content,
    content: state.mockExamTable.content,
  };
};

export default withRouter(connect(mapStateToProps)(MockTestBuilder));
