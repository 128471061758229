import * as actions from '../actions/types/listenExam';

const default_position = 0;
const default_timer = 120;

const defaultState = {
  countdown_started: false,
  position: default_position,
  timer: default_timer,
  active_index: 0,
  strat_mode: false,
  content: [],
  answers:[],
  answers_strat:[],
  audio_index:0,
  current_index:0,
};

export default function reducer(state=defaultState, action) {
  switch(action.type) {
    case actions.LISTEN_EXAM_RESET_LISTEN_EXAM: {
      return {...defaultState};
    }
    case actions.LISTEN_EXAM_UPDATE_TIMER:{
      return {...state, timer: action.payload}
    }
    case actions.LISTEN_EXAM_UPDATE_ACTIVE_INDEX:{
      return {...state, active_index: action.payload}
    }
    case actions.LISTEN_EXAM_UPDATE_STRAT_MODE:{
      return {...state, strat_mode: action.payload}
    }
    case actions.LISTEN_EXAM_RESET_TIMER: {
      return {...state, timer: default_timer}
    }
    case actions.LISTEN_EXAM_UPDATE_CONTENT: {
      return {...state, content: action.payload}
    }
    case actions.LISTEN_EXAM_UPDATE_ANSWERS: {
      return {...state, answers: action.payload.slice()}
    }
    case actions.LISTEN_EXAM_UPDATE_ANSWERS_STRAT: {
      return {...state, answers_strat: action.payload.slice()}
    }
    case actions.LISTEN_EXAM_UPDATE_POSITION: {
      return {...state, position: action.payload}
    }
    case actions.LISTEN_EXAM_RESET_POSITION: {
      return {...state, position: default_position}
    }
    case actions.LISTEN_EXAM_SET_COUNTDOWN_STARTED: {
      return {...state, countdown_started: action.payload}
    }
    case actions.LISTEN_EXAM_UPDATE_CURRENT_INDEX: {
      return {...state, current_index: action.payload}
    }
    case actions.LISTEN_EXAM_UPDATE_AUDIO_INDEX: {
      return {...state, audio_index: action.payload}
    }
    default: return {...state};
  }
};