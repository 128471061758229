import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import * as appActions from "../../../../../actions/app/app";
import * as apiActions from "../../../../../actions/app/api";
import * as api from "../../../../../actions/api/requests";
import * as writeExamActions from "../../../../../actions/app/writeExam";
import * as writeReviewActions from "../../../../../actions/app/writeReview";
import * as EXAM_SUBTYPES from "../../../../../constants/exam_subtypes";
import EXAM from "../../../../../constants/examTypes";
import { Grid, TextArea } from "semantic-ui-react";
import Header from "./Header";
import Sidebar from "./Sidebar";
import wordcount from "wordcount";
import HintModal from "./HintModal";

function pad(string) {
  return ("0" + string).slice(-2);
}

function format(seconds) {
  const date = new Date(seconds * 1000);
  const hh = date.getUTCHours();
  const mm = date.getUTCMinutes();
  const ss = pad(date.getUTCSeconds());
  if (hh) {
    return `Time Remaining: ${hh}h ${pad(mm)}m ${ss}s`;
  }
  return `Time Remaining: ${mm}m ${ss}s`;
}

class WritingContainer extends React.Component {
  componentDidMount() {
    const { answers, strat_mode, content, active_index, dispatch, timer } =
      this.props;
    if (answers.length === 0) {
      this.setBlankAnswers();
    }

    if (!(timer !== 2400 && timer !== 1200)) {
      if (content[active_index].part === EXAM_SUBTYPES.WRITE_PART_2_VALUE) {
        dispatch(writeExamActions.updateTimer(2400));
      } else {
        dispatch(writeExamActions.resetTimer());
      }
    }

    if (!strat_mode) {
      this.startTimer();
    }
  }
  setBlankAnswers() {
    const { content, dispatch } = this.props;
    const final_blank_answers = Array(content.length).join(".").split(".");
    const final_blank_answers2 = Array(content.length).join(".").split(".");
    const final_notes = Array(content.length).join(".").split(".");
    dispatch(writeExamActions.updateAnswer(final_blank_answers));
    dispatch(writeExamActions.updateAnswerStrat(final_blank_answers2));
    dispatch(writeExamActions.updateNotes(final_notes));
  }
  componentWillUnmount() {
    this.clearTimer();
    // this.props.dispatch(writeExamActions.reset());
  }
  startTimer() {
    this.timer = setInterval(this.tick.bind(this), 1000);
  }
  tick() {
    const { timer, dispatch } = this.props;
    const newTimer = timer - 1;
    if (newTimer >= 0) {
      dispatch(writeExamActions.updateTimer(newTimer));
    } else {
      this.clearTimer();
      this.saveAnswer();
    }
  }
  clearTimer() {
    clearInterval(this.timer);
    this.timer = null;
  }
  handleOnChange = (event, data) => {
    const { answers, answers_strat, strat_mode, active_index, dispatch } =
      this.props;
    let newAnswers = answers.slice();
    if (strat_mode) {
      newAnswers = answers_strat.slice();
    }
    newAnswers[active_index] = data.value;
    if (strat_mode) {
      dispatch(writeExamActions.updateAnswerStrat(newAnswers));
    } else {
      dispatch(writeExamActions.updateAnswer(newAnswers));
    }
  };
  handleNotesChange = (event, data) => {
    const { notes_1, active_index, dispatch } = this.props;
    const newNotes = notes_1.slice();
    newNotes[active_index] = data.value;
    dispatch(writeExamActions.updateNotes(newNotes));
  };
  openModal = () => {
    this.setState({ openModal: true });
  };
  closeModal = () => {
    this.setState({ openModal: false });
  };
  openHint = () => {
    this.setState({ openHint: true });
  };
  closeHint = () => {
    this.setState({ openHint: false });
  };
  saveAnswer = () => {
    const {
      dispatch,
      strat_mode,
      active_index,
      content,
      history,
      answers,
      answers_strat,
      answerInstance,
      is_staff,
      notes_1,
    } = this.props;

    if (!strat_mode) {
      this.clearTimer();
      dispatch(writeExamActions.updateStratMode(true));
    } else {
      if (active_index + 1 === content.length) {
        const data = {
          name: answerInstance.name,
          answers: answers,
          answers_strat: answers_strat,
          content: content,
          id: answerInstance.id,
          finished_at: new Date(),
          test_type: EXAM.WRITING,
          notes_1,
        };
        dispatch(writeReviewActions.updateContent(data));
        dispatch(apiActions.updateApiSending(true));
        if (is_staff) {
          history.push("/review/write");
          return;
        } else {
          dispatch(
            api.updateAnswerInstance({ ...data }, history, "/review/write")
          );
          return;
        }
      }
      if (content[active_index + 1].part === EXAM_SUBTYPES.WRITE_PART_2_VALUE) {
        dispatch(writeExamActions.updateTimer(2400));
      } else {
        dispatch(writeExamActions.resetTimer());
      }
      dispatch(writeExamActions.updateStratMode(false));
      dispatch(writeExamActions.updateActiveIndex(active_index + 1));
      this.startTimer();
    }
  };
  state = {
    toggleNav: this.toggleNav.bind(this),
    closeNav: this.closeNav.bind(this),
    openModal: false,
    openHint: false,
  };
  toggleNav() {
    const { dispatch, openSidebar } = this.props;
    dispatch(appActions.updateOpenSidebar(!openSidebar));
  }
  closeNav() {
    this.props.dispatch(appActions.updateOpenSidebar(false));
  }
  render() {
    const {
      openSidebar,
      content,
      timer,
      active_index,
      strat_mode,
      answers,
      answers_strat,
      notes_1,
    } = this.props;
    const { openModal, openHint } = this.state;
    let text = "";
    if (answers.length > 0) {
      text = answers[active_index];
      if (strat_mode) {
        text = answers_strat[active_index];
      }
    }
    let show = "hide";
    if (openSidebar) {
      show = "show";
    }
    return (
      <React.Fragment>
        <HintModal
          header="Points & Vocab"
          hint={content[active_index].vocab}
          open={openModal}
          onClose={this.closeModal}
        />
        <HintModal
          header="Hints"
          hint={content[active_index]?.hint}
          open={openHint}
          onClose={this.closeHint}
        />
        <Header
          {...this.state}
          {...this.props}
          show={show}
          timer={format(timer)}
          strat_mode={strat_mode}
          part={content[active_index]?.part}
        />
        <Sidebar {...this.state} {...this.props} show={show} />
        <div className="main-container">
          <div className="content-new-ui header-new-ui">
            <div id="content-split" className="content-split">
              <div
                id="content-left"
                className="content-left"
                style={{
                  overflow: "auto",
                  margin: "2rem .5rem .5rem .5rem",
                  paddingBottom: "2rem",
                }}
              >
                <div
                  className="instruction"
                  style={{ padding: ".5rem" }}
                  dangerouslySetInnerHTML={{
                    __html: content[active_index].content,
                  }}
                />
              </div>
              <div
                id="content-right"
                className="content-right text-center"
                style={{
                  overflow: "auto",
                  margin: "2rem .5rem .5rem .5rem",
                  paddingBottom: "2rem",
                }}
              >
                {!strat_mode ? (
                  <div className="testing-area-speaking">
                    <h5 className="speaking-review-header">
                      Your "Testing" Written Response
                    </h5>
                    <TextArea
                      className="full-max-width"
                      rows={15}
                      onChange={this.handleOnChange.bind(this)}
                      value={text}
                    />
                    <span id="wordCount" style={{ marginTop: ".25rem" }}>
                      <strong>Word Count:</strong>&nbsp;{wordcount(text)}
                    </span>
                  </div>
                ) : (
                  <>
                    <div className="testing-area-speaking">
                      <h5 className="speaking-review-header">
                        Your "Testing" Written Response
                      </h5>
                      <TextArea
                        className="full-max-width"
                        rows={10}
                        value={answers[active_index]}
                        disabled
                      />
                      <span id="wordCount" style={{ marginTop: ".25rem" }}>
                        <strong>Word Count:</strong>&nbsp;
                        {wordcount(answers[active_index])}
                      </span>
                    </div>
                    <div className="learning-area-speaking">
                      <h5 className="speaking-review-header">
                        Your "Learning" Notes (Ideas, Vocab, etc.)
                      </h5>
                      <TextArea
                        className="full-max-width"
                        rows={8}
                        value={notes_1[active_index]}
                        onChange={this.handleNotesChange.bind(this)}
                      />
                      <h5 className="speaking-review-header">
                        Your "Learning" Written Response
                      </h5>
                      <TextArea
                        className="full-max-width"
                        rows={8}
                        onChange={this.handleOnChange.bind(this)}
                        value={text}
                      />
                      <span id="wordCount" style={{ marginTop: ".25rem" }}>
                        <strong>Word Count:</strong>&nbsp;{wordcount(text)}
                      </span>
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className="content-button">
              <Grid columns={"equal"}>
                <Grid.Column></Grid.Column>
                <Grid.Column className="text-center">
                  {strat_mode && content[active_index].vocab ? (
                    <button type="button" onClick={this.openModal}>
                      Vocab
                    </button>
                  ) : null}
                </Grid.Column>
                <Grid.Column className="text-right">
                  <button type="button" onClick={this.saveAnswer}>
                    Finish And Proceed
                  </button>
                </Grid.Column>
              </Grid>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    timer: state.writeExam.timer,
    active_index: state.writeExam.active_index,
    strat_mode: state.writeExam.strat_mode,
    content: state.writeExam.content,
    answers: state.writeExam.answers,
    answers_strat: state.writeExam.answers_strat,
    answerInstance: state.answerInstance,
    is_staff: state.auth.is_staff,
    openSidebar: state.app.openSidebar,
    notes_1: state.writeExam.notes_1,
  };
};

export default withRouter(connect(mapStateToProps)(WritingContainer));
