import * as actions from '../actions/types/nonStudent';

const defaultState = {
  current_page:1,
  profile: {
    name: "",
    email: "",
    takenExam: null,
    previous_score: "",
    target_score: "",
    planned_test_date: "",
    country: "",
    target_country: "",
    test: "gt",
  }
};

export default function reducer(state=defaultState, action) {
  switch(action.type) {
    case actions.NON_STUDENT_UPDATE_CURRENT_PAGE: {
      return {...state, current_page: action.payload}
    }
    case actions.NON_STUDENT_UPDATE_PROFILE: {
      return {...state, profile: {...action.payload}}
    }
    case actions.NON_STUDENT_RESET: {
      return {...defaultState};
    }
    default: return {...state};
  }
};