import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as appActions from '../../../../../actions/app/app';
import { Grid } from 'semantic-ui-react';
import HintModal from './HintModal';

class WritingContainer extends React.Component {
  toggleNav() {
		const { dispatch, openSidebar } = this.props;
		dispatch(appActions.updateOpenSidebar(!openSidebar));
	}
	closeNav() {
		this.props.dispatch(appActions.updateOpenSidebar(false));
	}
	state = {
    active_index: 0,
    openHintModal: false,
    myAnswerMode: true,
    handleOnChange: this.placeholder.bind(this),
    handleRadioOnChange: this.placeholder.bind(this),
    handleCheckboxOnChange: this.placeholder.bind(this),
    toggleNav: this.toggleNav.bind(this),
		closeNav: this.closeNav.bind(this),
  }
  placeholder(){
  }
  updateActiveIndex = (i) => {
    this.toggleNav();
    this.setState({active_index : i});
  }
  openHintModal = () => {
    this.setState({openHintModal:true});
  }
  closeHintModal = () => {
    this.setState({openHintModal:false});
  }
  myAnswerModeOn = () => {
    this.setState({myAnswerMode: true});
  }
  myAnswerModeOff = () => {
    this.setState({myAnswerMode: false});
  }
  goToDashboard = () => {
    this.props.history.push('/');
  }
  goToDashboard = () => {
    this.props.history.push('/');
  }
	render() {
		const { content, answers, active_index } = this.props;
    const { openHintModal, myAnswerMode } = this.state;
		return (
			<React.Fragment>
        <HintModal
          header="Points & Vocab"
          hint={content[active_index].vocab}
          open={openHintModal}
          onClose={this.closeHintModal}
        />
				<div className="main-container">
					<div className="content-new-ui header-new-ui">
            <div id="content-split" className="content-split" style={myAnswerMode?{width:"100%"}:null}>
            {
              myAnswerMode?
                <center style={{width:"100%"}}>
                  <div id="content-left" style={{width:"50%", overflow:"auto", margin:"2rem 1rem 1rem 1rem",}}>
                    <h3 className="text-center">Answers (Testing)</h3>
                    <div
                      className="instruction"
                      style={{padding:".5rem", textAlign:"left"}}
                      dangerouslySetInnerHTML={{__html: answers[active_index]}}
                    />
                  </div>
                </center>
              :
                <React.Fragment>
                  <div id="content-left" className="content-left" style={{overflow:"auto", margin:"2rem 1rem 1rem 1rem",}}>
                    <div className="instruction" dangerouslySetInnerHTML={{__html: content[active_index].exam}} />
                  </div>
                  <div id="content-right" className="content-right" style={{overflow:"auto", margin:"2rem 1rem 1rem 1rem",}}>
                    <h3 className="text-center">Model Answer</h3>
                    <div className="instruction" dangerouslySetInnerHTML={{__html: content[active_index].model_answer}} />
                  </div>
                </React.Fragment>
            }
						</div>
						<div className="content-button">
							<Grid columns={'equal'}>
								<Grid.Column>
								</Grid.Column>
                <Grid.Column className="text-center">
                  <button
                    type="button"
                    onClick={this.openHintModal}
                  >
                    Points & Vocab
                  </button>
								</Grid.Column>
                <Grid.Column className="text-right">
                  <button
                    type="button"
                    disabled={myAnswerMode}
                    className={myAnswerMode?"active-art":""}
                    onClick={this.myAnswerModeOn}
                  >
                    My Answers
                  </button>
                  <button
                    type="button"
                    disabled={!myAnswerMode}
                    className={!myAnswerMode?"active-art":""}
                    onClick={this.myAnswerModeOff}
                  >
                    Model Answers
                  </button>
								</Grid.Column>
							</Grid>
						</div>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state) => {
	return {
    marketing     : state.marketing,
		openSidebar   : state.app.openSidebar,
	}
};

export default withRouter(connect(mapStateToProps)(WritingContainer));